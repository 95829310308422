import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { TransactionService } from './order.transaction.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import TransactionDetailsView from './transaction.details/transaction.details.view';
import { Grid } from '../../../../shared/components/grid/grid.component';
import global from '../../../../shared/helpers/global.variable.helper';

const useOrderTransactionsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [transactions, setTransactions] = useState([]);
    const [selectedTransaction, setSelectedTransaction] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No transaction selected!",
            message: "Please select an transaction from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New transaction",
            successMessage: {
                title: "Transaction created successfully!",
                message: "Transaction was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit transaction details",
            alertMessage: {
                title: "Multiple transactions selected!",
                message: "Oops!, you selected multiple transactions. Please select a transaction from the list to continue"
            },
            successMessage: {
                title: "Transaction updated successfully!",
                message: "Transaction was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit transaction details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these transactions?" : "this transaction?")
                }
            },
            successMessage: {
                title: "Transaction deleted successfully!",
                message: "Transaction was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these transactions?" : "this transaction?")
                }
            },
            successMessage: {
                title: "Transaction published successfully!",
                message: "Transaction was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these transactions?" : "this transaction?")
                }
            },
            successMessage: {
                title: "Transaction published successfully!",
                message: "Transaction was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these transactions?" : "this transaction?")
                }
            },
            successMessage: {
                title: "Transaction archived successfully!",
                message: "Transaction was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these transactions?" : "this transaction?")
                }
            },
            successMessage: {
                title: "Transaction restored successfully!",
                message: "Transaction was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available transactions, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditTransactionObserver = useRef();
    const transactionDetailsViewObserver = useRef();

    // components
    const datagridName = "transactions-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "transactions-left-pane-toolbar";
    const leftToolbarButtons = {
        addTransactionButtonName: "add-transaction-button",
        editTransactionButtonName: "edit-btransaction-button",
        deleteTransactionButtonName: "delete-transaction-button",
        reloadTransactionButtonName: "reload-transaction-button",
    }

    const rightPaneToolbarName = "transactions-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const transactionService = TransactionService();

    // dialogs
    const createOrEditTransactionDialogName = "createoredit-btransaction-modal";
    const createOrEditTransactionDialog = useModalDialog({
        name: createOrEditTransactionDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getTransactions = useCallback(async (request) => {

        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await transactionService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setTransactions(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getTransactions({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedTransaction({});

        // show dialog
        createOrEditTransactionDialog.show({
            title: "New transaction",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditTransactionObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple transactions selected!",
                message: "Oops!, you selected multiple transactions. Please select a transaction from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedTransaction = transactions.find(transaction => transaction.transactionId === datagrid.getSelectedRowKeyValue());
                setSelectedTransaction(selectedTransaction);

                createOrEditTransactionDialog.show({
                    title: "Edit transaction",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditTransactionObserver.current({
                    data: selectedTransaction,
                    callback: () => {
                        getTransactions({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No transaction selected!",
                    message: "Please select a transaction from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these transactions?" : "this transaction?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    transactionService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getTransactions({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No transaction selected!",
                message: "Please select a transaction from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getTransactions({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getTransactions({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getTransactions({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getTransactions({
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages) {
            setRowsPerPage(args?.value);
            getTransactions({
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
        }
    }

    const handlePageNumberChange = (e, args) => {
        setPageNumber(args?.value);
        getTransactions({
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleSelectRecord = (e, args) => {
        datagrid.setSelectedRow(args.key);
        const selectedTransaction = transactions.find(transaction => transaction.transactionId === args.key);
        setSelectedTransaction(selectedTransaction);
    }

    const handleDatagridRowSelected = (args) => {
        transactionDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    return {
        componentObserver,
        transactions,

        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addTransactionButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add transaction",

                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.editTransactionButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteTransactionButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                // {
                                //     name: leftToolbarButtons.reloadTransactionButtonName,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     leading: icons?.PublishRoundedIcon,
                                //     onClick: (e) => handlePublish(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                                // {
                                //     type: TOOLBARITEMTYPE.SPACE,
                                //     style: themes?.toolbar?.space?.space4
                                // },
                                // {
                                //     name: leftToolbarButtons.reloadTransactionButtonName,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     text: "Unpublish",
                                //     leading: icons?.VpnLockRoundedIcon,
                                //     onClick: (e) => handleUnpublish(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                                // {
                                //     type: TOOLBARITEMTYPE.SPACE,
                                //     style: themes?.toolbar?.space?.space15
                                // },
                                // {
                                //     name: leftToolbarButtons.reloadTransactionButtonName,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     leading: icons?.DriveFolderUploadRoundedIcon,
                                //     onClick: (e) => handleArchive(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                                // {
                                //     type: TOOLBARITEMTYPE.SPACE,
                                //     style: themes?.toolbar?.space?.space4
                                // },
                                // {
                                //     name: leftToolbarButtons.reloadTransactionButtonName,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     text: "Restore",
                                //     leading: icons?.ReplyRoundedIcon,
                                //     onClick: (e) => handleRestoreArchive(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        gridArea: (() => {
            return (
                <div className={"w-100 position-absolute end-0px top-53px start-0px bottom-0px "}>
                    <Grid attributes={{
                        dockType: DOCKTYPE.FILL,
                        fixedPane: GRIDPANE?.RIGHT,
                        paneWidth: themes?.minWidth440px,
                        allowAutoScroll: true,
                        pane: {
                            left: (
                                <div className={"position-absolute top-0px start-15px bottom-10px end-15px p-3 pt-1 pb-3"}>
                                    <DataGrid attributes={{
                                        name: datagridName,
                                        key: ["transactionId"],
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: "transactionId",
                                                style: themes?.width30px
                                            },
                                            {
                                                title: "Reference",
                                                value: "referenceNumber",
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                title: "Customer",
                                                value: "customer",
                                                default: "",
                                                style: themes?.width100,
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Amount",
                                                value: "amount",
                                                default: "",
                                                style: themes?.width120px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Wallet. ID",
                                                value: "amount",
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.FLAG,
                                                title: "",
                                                value: "status",
                                                style: themes?.width30px,
                                                flags: [
                                                    {
                                                        title: "Completed",
                                                        color: "bg-success-info-theme btransaction btransaction-top-1px btransaction-start-1px btransaction-bottom-1px btransaction-end-1px btransaction-success-info-btransaction-theme text-success-info-dark-theme",
                                                        icon: icons?.CheckRoundedIcon
                                                    },
                                                    {
                                                        title: "Cancelled",
                                                        color: "bg-danger-info-theme btransaction btransaction-top-1px btransaction-start-1px btransaction-bottom-1px btransaction-end-1px btransaction-danger-info-theme text-danger-info-dark-theme",
                                                        icon: icons?.ClearRoundedIcon
                                                    },
                                                    {
                                                        title: "Discontinued",
                                                        color: "bg-disabled-theme btransaction btransaction-top-1px btransaction-start-1px btransaction-bottom-1px btransaction-end-1px btransaction-disabled-info-theme text-disabled-info-dark-theme",
                                                        icon: icons?.DoDisturbRoundedIcon
                                                    },
                                                    {
                                                        title: "Low On Stock",
                                                        color: "bg-warning-info-theme btransaction btransaction-top-1px btransaction-start-1px btransaction-bottom-1px btransaction-end-1px btransaction-warning-info-theme text-warning-info-dark-theme",
                                                        icon: icons?.AccessTimeRoundedIcon
                                                    }
                                                ]
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Trans. Date",
                                                value: "dateCreated",
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                        ],
                                        rowHeight: 37,
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        onRowSelected: handleDatagridRowSelected,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onHomeClick: handleFirstPage,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        dataset: transactions
                                    }} />
                                </div>
                            ),

                            right: (
                                <></>
                            )
                        }
                    }} />
                </div>
            )

        })(),

        detailsComponent: <TransactionDetailsView attributes={{
            observer: transactionDetailsViewObserver
        }} />,

        createOrEditDialog: {
            name: createOrEditTransactionDialogName,
            data: selectedTransaction,
            observer: createOrEditTransactionObserver,
            widget: null,
            style: {
                window: "max-width-530px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getTransactions({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useOrderTransactionsHook;