import { https } from "../../../../../../shared/utilities";

const CountryService = () => {
    const request = https();
    const controller = "/countries";
    
    return { 
        getAll: async (data) => await request.post(controller + "/list", data), 
        get: async (id) => await request.post(controller + "/list", id), 
        search: async (data) => await request.post(controller + "/list", data),
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
        publish: async (data) => await request.post(controller + "/publish", data),
        unpublish: async (data) => await request.post(controller + "/unpublish", data),
        sendToArchive: async (data) => await request.post(controller + "/archive", data),
        restoreArchive: async (data) => await request.post(controller + "/restore-archive", data),
    }
}

export { CountryService }