import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MarketTypeService } from '../market.type.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditMarketTypeHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [types, setTypes] = useState([]);
    const [type, setType] = useState({
        typeName: '',
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const _parentTypeDgridName = "createoredit-parent-type-datagrid";
    const parentTypesDatagrid = useDataGrid({
        name: _parentTypeDgridName
    });
    const _parentTypeTokenSearchFieldName = "createoredit-parent-type-token-search-field";
    const parentTypesTokenSearchField = useTokenSearchField({
        name: _parentTypeTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const typeService = MarketTypeService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        // clear token
        parentTypesTokenSearchField?.clear();

        // clear datagrid
        parentTypesDatagrid.clearSelection();

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

            // add token
            if ((args?.data?.parentTypeId !== null) && (args?.data?.parentTypeId !== "null")) {
                parentTypesTokenSearchField?.addToken({
                    value: args?.data?.parentTypeId,
                    text: args?.data?.parentTypeName,
                    onButtonClick: (e, args) => {
                        parentTypesDatagrid.clearSelection();
                    }
                });
            }
        } else {
            form.clear();
        }
    }, [form])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setType({
            ...type,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        //
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await typeService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await typeService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        type: type,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        typeNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        typeNameField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for your type",
            id: "marketType",
            value: type?.marketType,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        typeDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        typeDescriptionField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Type description",
            id: "marketTypeDescription",
            isMultiline: true,
            value: type?.marketTypeDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditMarketTypeHook;