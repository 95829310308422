import { useCallback, useEffect, useRef, useState } from 'react';
import { CityService } from '../city.service';
import { usePrimaryUIThemes } from '../../../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../../../shared/hooks/ui.icons.hook';
import { useForm } from '../../../../../../../shared/components/form/form.component';

const useCreateOrEditCityHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // city objects
    const [city, setCity] = useState([]);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const cityService = CityService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        // handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);


        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setCity({
            ...city,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await cityService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await cityService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        city: city,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // city name
        cityNameFieldAttributes: {
            formElement: true,
            validate: true,
            id: "cityName",
            value: city?.cityName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // city postal code
        cityCodeFieldAttributes: {
            formElement: true,
            validate: true,
            id: "cityCode",
            value: city?.CityCode,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // latitude
        latitudeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "",
            id: "latitude",
            value: city?.latitude,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // longitude
        longitudeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "",
            id: "longitude",
            value: city?.longitude,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditCityHook;