import React, { useCallback, useEffect, useRef } from "react";
import { DOCKTYPE } from "../../enums/enums";

const ToolbarCollection = ({ attributes }) => {

    // components
    const toolbarCollection = useToolbarCollection(attributes);

    // refs
    const toolbarCollectionComponent = useRef()

    const resizer = useCallback(() => {
        if (toolbarCollection.target()) {
            const toolbarCollectionButtonHeight = toolbarCollection.getButtonContainerHeight();
            const getToolbarContainer = toolbarCollection.getToolbarContainer();
            if (getToolbarContainer)
                getToolbarContainer.setAttribute("style", "top: " + toolbarCollectionButtonHeight + "px");
        }
    }, [toolbarCollection]);

    useEffect(() => {
        if (attributes?.dockType) {
            resizer();
            window.addEventListener("resize", resizer);

            // garbage collection
            return () => {
                window.removeEventListener("resize", resizer);
            }
        }
    }, [resizer, attributes])

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return attributes?.themes + " position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return attributes?.themes + " position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return attributes?.themes + " position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return attributes?.themes + " position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return attributes?.themes + " position-absolute top-0px start-0px end-0px";
            default:
                return attributes?.themes + " position-relative";
        }
    }

    return (
        <div aria-controls="toolbar-collection" id={attributes?.name} ref={toolbarCollectionComponent} className={"w-100 " + getDockStyle(attributes?.dockType)}>
            {
                (() => {
                    let dockStyle;
                    switch (attributes?.dockType) {
                        case DOCKTYPE.FILL:
                            dockStyle = getDockStyle(DOCKTYPE.BOTTOM);
                            break;
                        default:
                            dockStyle = "position-relative";
                            break;
                    }

                    return (
                        <div aria-controls="toolbar-collection-panel-container" className={"" + dockStyle}>
                            <div aria-controls="stackable-panel" className={getDockStyle(DOCKTYPE.FILL)}>
                                {
                                    attributes?.items?.map((item, i) => {
                                        return (
                                            <div aria-controls="panel" panel-index={i} className={"position-absolute top-0px start-0px bottom-0px end-0px " + ((i === 0) ? "" : "hide")} key={attributes.name + "-stackable-panel-" + i}>
                                                {
                                                    (() => {
                                                        if (item?.widget) {
                                                            return item?.widget
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })()
            }
        </div>
    );
}

const useToolbarCollection = (attributes) => {

    const themes = attributes?.themes?.toolbarCollection;

    const addWidget = () => {

    }

    const getButtonContainerHeight = (index) => {
        const _toolbarCollection = target();
        if (_toolbarCollection) {
            const buttonContainrHeight = _toolbarCollection?.querySelector("div[aria-controls=\"toolbar-collection-button-container\"]");
            return buttonContainrHeight?.offsetHeight;
        }
        return 0;
    }

    const getToolbarContainer = () => {
        const _toolbarCollection = target();
        return _toolbarCollection.querySelector("div[aria-controls=\"toolbar-collection-panel-container\"]");
    }

    const getToolbars = () => {

    }

    const getToolbarByIndex = (index) => {
        const _toolbarCollection = target();
        if (_toolbarCollection) {
            const toolbars = _toolbarCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            const toolbarArray = [...toolbars];
            return toolbarArray[index];
        }
        return null;
    }

    const getToolbarByName = (name) => {
        const _toolbarCollection = target();
        if (_toolbarCollection) {
            const toolbars = _toolbarCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            const toolbarArray = [...toolbars];
            return toolbarArray.find(x => x.querySelector("div[aria-owns=\"" + name + "\"]"));
        }
        return null;
    }

    const target = () => {
        return document.getElementById(attributes?.name);
    }

    const selectToolbar = (clickedToolbar) => {
        try {
            let _toolbarCollection = target();
            const selectedToolbar = clickedToolbar.currentTarget.firstChild;
            setToolbar(selectedToolbar.getAttribute("aria-posinset"));
        } catch (e) { }
    }

    const selectedToolbar = (clickedToolbar) => {
        const currentTarget = clickedToolbar.currentTarget;
        return {
            selectedToolbar: currentTarget,
            handle: currentTarget.getAttribute("aria-owns"),
            index: currentTarget.getAttribute("aria-posinset"),
            target: currentTarget.getAttribute("aria-owns")
        }
    }

    const setToolbar = (index) => {
        try {
            const _toolbarCollection = target();
            if (_toolbarCollection) {
                const toolbars = _toolbarCollection.querySelectorAll("div[aria-controls=\"panel\"]");
                const activeToolbar = [...toolbars].find(x => !x.classList.contains("hide"));
                activeToolbar.classList.add("hide");

                const selectedToolbar = toolbars[index]; // _toolbarCollection.querySelector("div[panel-index=\"" + index + "\"]");
                selectedToolbar.classList.remove("hide");
            }
        } catch (e) {
            //
        }
    }

    return {
        addWidget,
        getButtonContainerHeight,
        getToolbarContainer,
        getToolbars,
        getToolbarByIndex,
        getToolbarByName,
        name: attributes?.name,
        selectToolbar,
        selectedToolbar,
        setToolbar,
        target
    }
}

export { ToolbarCollection, useToolbarCollection };