import React, { useEffect } from "react"

const Error404Component = () => {
    useEffect(() => {

    })

    return (
        <div></div>
    )
}

export default Error404Component;