import { https } from '../../../../shared/utilities';


const TransactionService = () => {
    const request = https();
    const controller = "/transactions";

    return { 
        getAll: async (data) => await request.post(controller + "/list", data), 
        getAllByCustomer: async (data) => await request.post(controller + "/list-transactions", data),
        getAllByShopper: async (data) => await request.post(controller + "/list-agent-shoppings", data),
        getAllByRider: async (data) => await request.post(controller + "/list-agent-shoppings", data),
        getAllWalletTransactions: async (data) => await request.post(controller + "/list-agent-shoppings", data),
        get: async (id) => await request.post(controller + "/list", id),
        search: async (data) => await request.post(controller + "/list", data),
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
    }
}

export { TransactionService }