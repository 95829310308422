import React, { useEffect, useRef, useState } from "react";

const ToggleIcon = ({ attributes }) => {

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const notifier = (e) => {
        //
    }

    const noAction = () => {
        // 
    }

    const handleOnClick = (e) => {
        if (attributes?.args) {
            attributes.onClick(e, attributes?.args);
        } else {
            attributes.onClick(e);
        }

        e.stopPropagation();
    }

    const getStatusBarState = (status) => {
        return status ? "btn-green-theme" : "btn-red-theme";
    }

    return (
        <div className={"d-flex"} onClick={attributes?.onClick ? handleOnClick : () => noAction()}>
            <div className={"d-flex justify-content-center align-items-center position-relative border-0px- p-0 " + (((attributes?.style?.icon) ? attributes?.style?.button : " border-0px w-100 height-30px "))}>
                {attributes?.leading}
            </div>
        </div>
    );
}
export default ToggleIcon;