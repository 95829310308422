
import React from 'react';
import { ModalDialogCollection } from '../../../../shared/components/dialog/modal.dialog.collection.component';
import { StackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';
import useLoanManagementHook from './loan.management.hook';
import { Grid } from '../../../../shared/components/grid/grid.component';

const LoanManagementView = () => {

    const hook = useLoanManagementHook();

    return (
        <div>
            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 bcustomerLoan-solid bcustomerLoan-top-0px bcustomerLoan-bottom-0px bcustomerLoan-start-0px bcustomerLoan-end-0px"}>
                {/* toolbar */}
                {/* <ToolbarCollection attributes={manageToolbarCollectionAttributes()} /> */}

                {hook?.toolbarAttributes?.widget}

                {/* datagrid view */}
                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                    <Grid attributes={hook?.gridAttributes} />
                </div>
            </div>

            <ModalDialogCollection attributes={hook?.modalDialogCollectionAttributes} />
        </div>
    );
}

export default LoanManagementView;