import React from 'react';
import { TextField } from '../../../../shared/components/field/textfield.component';
import useCreateOrEditContentCategoryHook from './createoredit.content.category.hook';
import Label from '../../../../shared/components/label/label.component';
import { DataGrid } from '../../../../shared/components/datagrid';
import { HiddenTextField } from '../../../../shared/components/field/hidden.textfield.component';
import { TokenSearchField } from '../../../../shared/components/field/token.search.textfield.component';

const CreateOrEditContentCategoryView = ({ attributes }) => {

    const hook = useCreateOrEditContentCategoryHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0"}>
            <div className={"w-100 height-460px position-relative "}>
                <form ref={hook?.reactiveForm} method={attributes.method} autoComplete={"false"} className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>
                        {/* name field */}
                        <div className={"w-100 m-0 mb-4"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.categoryNameLabel} />
                            </div>
                            <TextField
                                attributes={hook?.categoryNameField} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateOrEditContentCategoryView;