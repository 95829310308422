import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BankService } from '../bank.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useStackablePanel } from '../../../../../../shared/components/stackable.panel/stackable.panel.component';
import MenuList from '../../../../../../shared/components/menu/menu.list.component';
import { useDataGrid } from '../../../../../../shared/components/datagrid';
import { useModalDialog } from '../../../../../../shared/components/dialog/modal.dialog.component';

const useBankDetailsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [wallet, setBank] = useState([]);
    const [walletRecentTransactions, setBankRecentTransactions] = useState([]);

    const actionButtonsMenuItems = {
        fundBankMenuItem: "fund-wallet-menuitem",
        walletToBankTransferMenuItem: "wallet-to-wallet-transfer-menuitem",
        bankTransferMenuItem: "bank-transfer-menuitem",
        placeLienOnBankMenuItem: "place-lien-on-wallet-menuitem",
        lockBankAccountMenuItem: "lock-wallet-account-menuitem",
        sendMessageMenuItem: "send-message-menuitem",
        printAccountStatmentMenuItem: "print-account-statment-menuitem",
        downloadAccountStatmentPdfMenuItem: "download-account-statment-pdf-menuitem",
        shareAccountStatmentPdfMenuItem: "share-account-statment-pdf-menuitem",
    };

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const fundBankObserver = useRef(null);
    const walletTransferObserver = useRef(null);
    const bankTransferObserver = useRef(null);

    const profileDetailsTabName = "wallet-details-tab";
    const stackablePanelComponentName = "wallet-stackable-panel";
    const walletOptionToolbarName = "wallet-option-toolbar";
    const walletAccountDetailsDatagridName = "wallet-account-details-datagrid";
    const recentTransactionsDatagridName = "recent-transactions-datagrid";
    const fundBankModalDialogName = "fund-wallet-modal-dialog";
    const walletTransferModalDialogName = "wallet-transfer-modal-dialog";
    const bankTransferModalDialogName = "bank-transfer-modal-dialog";

    // components
    const form = useForm({
        ref: reactiveForm
    });
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });
    const walletOptionToolbarButtons = {
        actionsButtonName: "add-market-product-button",
    };
    const recentTransactionsDatagrid = useDataGrid({
        name: recentTransactionsDatagridName
    });
    const fundBankModalDialog = useModalDialog({
        name: fundBankModalDialogName
    });
    const walletTransferModalDialog = useModalDialog({
        name: walletTransferModalDialogName
    });
    const bankTransferModalDialog = useModalDialog({
        name: bankTransferModalDialogName
    });

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        // handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setBank(args?.data);

        // get wallet recent transactionns
        getBankRecentTransactions({
            walletId: args?.data?.walletId,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 5
        });
    }, [])

    // services
    const labels = useMemo(() => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], [])

    const walletService = BankService();

    const getBankRecentTransactions = useCallback(async (request) => {
        recentTransactionsDatagrid.clearSelection();
        await walletService.getBankRecentTransactions(request)
            .then(response => {
                setBankRecentTransactions(response?.data?.list);
            })
            .catch(error => {
                //
            });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    // 
    const handleTabButtonClick = (e, args) => {
        stackablePanelComponent.setPanel(args?.index);
    }

    const handleFundBank = (args) => {
        fundBankModalDialog.show({
            title: "Fund wallet"
        });
        fundBankObserver.current({
            data: wallet
        })
    }

    const handleBankTransfer = (args) => {
        walletTransferModalDialog.show({
            title: "Transfer to wallet"
        });
        walletTransferObserver.current({
            data: wallet
        })
    }

    const handleBankToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.lockBankAccountMenuItem:
                // updateMarketWithNewProducts();
                break;
            case actionButtonsMenuItems?.placeLienOnBankMenuItem:
                // addProductsToMarket();
                break;
            case actionButtonsMenuItems?.bankTransferMenuItem:
                handleBankTransfer();
                break;
            case actionButtonsMenuItems?.walletToBankTransferMenuItem:
                handleBankTransfer();
                break;
            case actionButtonsMenuItems.fundBankMenuItem:
            default:
                handleFundBank();
                break;
        }
    }

    return {
        wallet: wallet,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // profile photo
        profilePhotoAttributes: {
            formElement: true,
            id: "media",
            value: wallet?.media,
            placeholder: icons?.PersonIcon,
            style: themes?.avatar280px,
            observer: componentObserver
        },

        // actions toolbar
        actionsMenuButton: {
            name: walletOptionToolbarName,
            items: [
                {
                    name: walletOptionToolbarButtons.actionsButtonName,
                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.MenuRoundedIcon,
                    onClick: (e) => { },
                    observer: componentObserver,
                    style: themes?.toolbar?.button,
                    widget: <MenuList attributes={{
                        items: [
                            {
                                leading: icons?.DataSaverOnIcon,
                                name: actionButtonsMenuItems.fundBankMenuItem,
                                title: "Fund wallet",
                            },
                            {
                                leading: icons?.CompareArrowsRoundedIcon,
                                name: actionButtonsMenuItems.walletToBankTransferMenuItem,
                                title: "Bank-to-wallet transfer ",
                            },
                            {
                                leading: icons?.AccountBalanceRoundedIcon,
                                name: actionButtonsMenuItems.bankTransferMenuItem,
                                title: "Transfer to other banks",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.PanToolIcon,
                                name: actionButtonsMenuItems?.placeLienOnBankMenuItem,
                                title: "Place lien on wallet",
                            },
                            {
                                leading: icons?.LockRoundedIcon,
                                name: actionButtonsMenuItems?.lockBankAccountMenuItem,
                                title: "Lock wallet",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.MailIcon,
                                name: actionButtonsMenuItems?.sendMessageMenuItem,
                                title: "Send message",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.LocalPrintshopIcon,
                                name: actionButtonsMenuItems?.printAccountStatmentMenuItem,
                                title: "Print statment",
                            },
                            {
                                leading: icons?.LocalPrintshopIcon,
                                name: actionButtonsMenuItems?.downloadAccountStatmentPdfMenuItem,
                                title: "Download PDF",
                            },
                            {
                                leading: icons?.ShareIcon,
                                name: actionButtonsMenuItems?.shareAccountStatmentPdfMenuItem,
                                title: "Share statment as PDF",
                            },
                        ],
                        style: themes?.menuList,
                        onItemClick: handleBankToolbarOptionsMenuItemClick,
                        isSelectable: false
                    }} />
                }
            ]
        },

        themes: themes,
        icons: icons,

    }
}

export default useBankDetailsHook;