import React, { useEffect, useRef, useState } from "react";
import Button from "../button/button.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const ConfirmDialog = ({ attributes }) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [params, setParams] = useState({
        title: attributes?.title,
        message: attributes?.message
    });

    const confirmDialogObserver = useRef();

    useEffect(() => {
        //
    }, [attributes])

    const confirm = (e) => {
        if (attributes?.onConfirmed)
            attributes?.onConfirmed({});
        confirmDialogObserver.current.querySelector("div[aria-owns=\"confirm-dialog-modal\"]").classList.remove("display-block");
    }

    const close = (e) => {
        // confirmDialogObserver.current.querySelector("div[aria-owns=\"confirm-dialog-modal\"]").classList.remove("display-block");
    }

    return (
        <div ref={confirmDialogObserver}>
            <div aria-owns={"confirm-dialog-modal"} className={"modal transparent-bg-dark-theme "} id="confirm-dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered text-blue-dark-theme"}>
                    <div className={"modal-content shadow-lg border-1px transparent-border-blue-dark-theme"}>
                        <div className={"modal-header border-0px pb-0"}>
                            <h1 aria-owns={"title-section"} className={"modal-title text-14px text-bold " + themes.labelColor} id="staticBackdropLabel">{params.title}</h1>
                        </div>
                        <div aria-owns={"message-section"} className={"modal-body text-13px " + themes.labelColor}>{params.message}</div>
                        <div className={"modal-footer border-top-0px pt-3"}>
                            <div className={"d-flex justify-content-end"}>
                                <div className={"me-2"}>
                                    <Button attributes={{
                                        name: "confirm-cancel-button",
                                        text: "Cancel",
                                        style: themes?.button?.secondary,
                                        onClick: close
                                    }} />
                                </div>
                                <div>
                                    <Button attributes={{
                                        name: "confirm-okay-button",
                                        text: attributes?.button ? attributes?.button?.text : "Yes, continue",
                                        style: themes?.button?.primary,
                                        onClick: confirm
                                    }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useConfirmDialog = () => {

    const show = (attributes) => {
        const dialog = document.body.querySelector("div[aria-owns=\"confirm-dialog-modal\"]");
        dialog.classList.add("display-block");

        const h1 = dialog.querySelector("h1");
        h1.innerHTML = attributes?.title || "";

        const message = dialog.querySelector("*[aria-owns=\"message-section\"]");
        message.innerHTML = attributes?.message || "";

        const confirmButton = dialog.querySelector("div[id=\"confirm-okay-button\"]");
        const confirmButtonAddEventListener = (e) => {
            attributes?.onConfirmed({});
            dialog.classList.remove("display-block");
            garbageCollection();
        }
        confirmButton.addEventListener("click", confirmButtonAddEventListener);

        // 
        const cancelButton = dialog.querySelector("div[id=\"confirm-cancel-button\"]");
        cancelButton.addEventListener("click", (e) => {
            close();
            garbageCollection();
        });

        const garbageCollection = () => {
            confirmButton.removeEventListener("click", confirmButtonAddEventListener);
            cancelButton.removeEventListener("click", close);
        }
    }

    const close = () => {
        const dialog = document.body.querySelector("*[aria-owns=\"confirm-dialog-modal\"]");
        dialog.classList.remove("display-block");
    }

    return { show, close }
}

export { ConfirmDialog, useConfirmDialog }