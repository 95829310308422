import React from 'react';
import usePharmacyProductVariationsHook from './pharmacy.product.variations.hook';
import { DataGrid } from '../../../../../shared/components/datagrid';

const PharmacyProductVariationView = ({ attributes }) => {

    const hook = usePharmacyProductVariationsHook(attributes);

    return (
        <div className={"min-height-300px"}>
            {hook?.toolbar?.widget}
            <div className={"w-100- position-absolute end-15px top-53px start-15px bottom-0px "}>
                <DataGrid attributes={hook?.datagridAttributes} />
            </div>
        </div>
    );
}

export default PharmacyProductVariationView;