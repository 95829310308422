import React from 'react';
import useCouriersHook from './couriers.hook';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';
import { DataGrid } from '../../../../shared/components/datagrid';

const CouriersView = () => {
    const hook = useCouriersHook();

    return (
        <div>
            {hook?.toolbar}
            {hook?.gridArea}

            <ModalDialog attributes={hook?.courierKycDialog} />
            <ModalDialog attributes={hook?.viewCourierWalletTransactionsDialog} />
            <ModalDialog attributes={hook?.fundCourierWalletDialog} />
            <ModalDialog attributes={hook?.viewCourierDeliveryHistoryDialog} />
        </div >
    );
}

export default CouriersView;