import React from 'react';
import useCreateOrEditProductColorHook from './createoredit.product.color.hook';
import Label from '../../../../../../shared/components/label/label.component';
import { TextField } from '../../../../../../shared/components/field/textfield.component';

const CreateOrEditProductColorView = ({ attributes }) => {

    const hook = useCreateOrEditProductColorHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0"}>
            <div className={"w-100 height-320px position-relative "}>
                <form ref={hook?.reactiveForm} method={attributes.method} autoComplete={"false"} className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>
                        {/* name field */}
                        <div className={"w-100 m-0 mb-4"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.colorNameLabel} />
                            </div>
                            <TextField
                                attributes={hook?.colorNameField} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateOrEditProductColorView;