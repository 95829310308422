import React, { useEffect, useRef, useState } from "react";
import Button from "../button/button.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes/index";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import { MODALACTION } from "../../enums/enums";

const ModalDialog = ({ attributes }) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [params, setParams] = useState({
        title: attributes?.title,
        message: attributes?.message,
        showCloseButton: attributes?.showCloseButton | true,
        style: {
            window: attributes?.style?.window,
        },
        widget: attributes?.widget,
        isNew: attributes?.isNew
    });
    const [inProgress, setInProgress] = useState(false);

    // dialog
    const dialog = useModalDialog();

    // refs
    const dialogRef = useRef();
    const dialogNotifier = useRef();

    useEffect(() => {
        (async () => {
            //
        })();
    }, []);

    const close = (currentTarget) => {
        dialog.close(currentTarget);
    }

    return (
        <div>
            <div ref={dialogRef} aria-owns={attributes?.name} aria-controls={"modal-dialog-modal"} className={"modal transparent-bg-dark-theme z-index-5000"} id="modal-dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered " + params?.style?.window}>
                    <div className={"modal-content shadow-lg border-1px transparent-border-blue-dark-theme"}>
                        <div className={"modal-header border-0px pb-1 position-relative"}>
                            <div className={"w-100 d-flex justify-content-between align-items-center"}>
                                <h1 className={"modal-title text-16px text-bold line-height-23px pt-0 " + themes?.modal?.title}>{params?.title}</h1>
                                <div className={"ms-3 me-2 d-flex"}>
                                    <div className={"spinner-border text-dark icon icon-14px text-8px " + (inProgress ? "" : "hide")} role="status"></div>
                                </div>
                            </div>
                            <button type="button" className={"btn-close position-absolute top-20px end-15px icon-14px p-0 me-0 " + (params?.showCloseButton ? "" : "hide")} onClick={e => { close(e.currentTarget) }}></button>
                        </div>
                        <div className={"modal-body text-13px p-0"}>
                            {
                                (() => {
                                    if (params?.widget) {
                                        return params?.widget({
                                            attributes: {
                                                observer: attributes?.observer,
                                                reactiveFormModule: dialogNotifier,
                                                data: attributes?.data
                                            }
                                        });
                                    }
                                })()
                            }
                        </div>
                        <div className={"modal-footer border-top-0px"}>
                            {
                                (() => {
                                    return attributes?.buttons?.map((buttonAttributes, i) => {
                                        buttonAttributes["args"] = {
                                            apply: (e, callback) => {
                                                dialogNotifier.current({
                                                    action: MODALACTION.APPLY,
                                                    data: attributes?.data,
                                                    isNew: (() => {
                                                        try {
                                                            return JSON.parse(dialogRef?.current?.getAttribute("is-new"));
                                                        } catch (e) {
                                                            return false;
                                                        }
                                                    })(),
                                                    inProgress: (state) => {
                                                        setInProgress(state);
                                                    },
                                                    successful: (args) => {
                                                        callback(args);
                                                    },
                                                    error: () => {
                                                        //
                                                    }
                                                });
                                            },
                                            close: e => {
                                                console.log("Hey");
                                                dialog.close(e?.currentTarget);
                                            },
                                            submit: (e, callback) => {
                                                const currentTarget = e.currentTarget;
                                                dialogNotifier.current({
                                                    action: MODALACTION.SUBMIT,
                                                    data: attributes?.data,
                                                    isNew: (() => {
                                                        try {
                                                            return JSON.parse(dialogRef?.current?.getAttribute("is-new"));
                                                        } catch (e) {
                                                            return false;
                                                        }
                                                    })(),
                                                    inProgress: (state) => {
                                                        setInProgress(state);
                                                    },
                                                    successful: (args) => {
                                                        dialog.close(currentTarget || e?.currentTarget);
                                                        callback(args);
                                                    },
                                                    close: () => {
                                                        dialog.close(currentTarget || e?.currentTarget);
                                                    },
                                                    error: () => {
                                                        //
                                                    }
                                                });
                                            }
                                        };
                                        return (
                                            <Button key={"modal-footer-button-" + i} attributes={buttonAttributes} />
                                        );
                                    })
                                })()
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const useModalDialog = (attributes) => {

    const show = async (attr) => {
        const dialog = document.body.querySelector("*[aria-owns=\"" + attributes?.name + "\"]");
        const h1 = dialog?.querySelector("h1");
        if (h1)
            h1.innerHTML = attr?.title || "";
        dialog?.classList.add("display-block");
        dialog?.setAttribute("is-new", attr?.isNew);
    }

    const close = (currentTarget) => {
        console.log("Hey");
        if (currentTarget) {
            const dialog = currentTarget?.parentNode?.parentNode?.parentNode?.parentNode;
            if (dialog) {
                dialog.classList.remove("display-block");
            } else {
                //
            }
        }
    }

    return { show, close }
}
export { ModalDialog, useModalDialog }