import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar } from '../../../../../shared/components/avatar';
import { CalloutWindow } from '../../../../../shared/components/callout/callout.window.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import CatererCustomersOrdersView from '../caterer.customer.order/caterer.customers.orders.view';
import CatererCustomersTransactionsView from '../caterer.customer.transaction/caterer.customers.transactions.view';
import CatererProductCategoriesView from '../caterer.product.categories/caterer.product.categories.view';
import CatererProductColorsView from '../caterer.product.colors/caterer.product.colors.view';
import CatererProductTypesView from '../caterer.product.types/caterer.product.types.view';
import CatererProductVariationsView from '../caterer.product.variations/caterer.product.variations.view';
import CreateOrEditCatererProductView from '../createoredit.caterer.product/createoredit.caterer.product.view';
import { CatererService } from '../caterer.service';
import { CatererProductService } from './caterer.product.service';
import global from '../../../../../shared/helpers/global.variable.helper';

const useCatererProductsHook = (attributes) => {

    const appConfig = useAppConfig();

    // theme
    const icons = useIconsThemes().rounded;
    const themes = usePrimaryUIThemes();

    // state objects
    const [selectedCaterer, setSelectedCaterer] = useState({});
    const [catererProducts, setCatererProducts] = useState([]);
    const [selectedCatererProduct, setSelectedCatererProduct] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);


    const actionButtonsMenuItems = {
        customerOrderMenuItem: "caterer-customer-order-menuitem",
        transactionsMenuItem: "caterer-product-transactions-menuitem",
        categoriesMenuItem: "caterer-product-categories-menuitem",
        variationsMenuItem: "caterer-product-variations-menuitem",
        typeMenuItem: "caterer-product-types-menuitem",
        colorMenuItem: "caterer-product-colors-menuitem",
        browseCatererPageMenuItem: "browse-caterer-products-page-menuitem",
        manageCatererAccountMenuItem: "manage-caterer-account-menuitem"
    };

    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No product selected!",
            message: "Please select an product from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New product",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },

        handleEdit: {
            dialogTitle: "Edit product",
            alertMessage: {
                title: "Multiple products selected!",
                message: "Oops!, you selected multiple products. Please select a product from the list to continue"
            },
            successMessage: {
                title: "Product updated successfully!",
                message: "Product was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Delete product",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product deleted successfully!",
                message: "Product was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product published successfully!",
                message: "Product was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product published successfully!",
                message: "Product was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product archived successfully!",
                message: "Product was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these products?" : "this product?")
                }
            },
            successMessage: {
                title: "Product restored successfully!",
                message: "Product was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available products, please wait..."
        },


        manageCategory: {
            dialogTitle: "Product categories",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },


        manageVariations: {
            dialogTitle: "Product variations",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },


        manageTypes: {
            dialogTitle: "Product types",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },


        manageColors: {
            dialogTitle: "Product colors",
            successMessage: {
                title: "Product added successfully!",
                message: "Product was successfully added."
            }
        },
    };

    // observers
    const componentObserver = useRef();
    const catererProductDatagridListObserver = useRef([]);
    const catererLogoObserver = useRef();
    const createOrEditCatererProductCalloutWindowObserver = useRef();
    const catererCustomersOrdersCalloutWindowObserver = useRef();
    const catererCustomersTransactionsCalloutWindowObserver = useRef();
    const catererProductCategoriesCalloutWindowObserver = useRef();
    const catererProductVariationsCalloutWindowObserver = useRef();
    const catererProductColorsCalloutWindowObserver = useRef();
    const catererProductTypesCalloutWindowObserver = useRef();

    // components
    const stackablePanelComponentName = "caterer-products-stackable-panel";
    const catererProductDatagridName = "products-datagrid";

    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const datagrid = useDataGrid({
        name: catererProductDatagridName
    });

    const toolbarButtons = {
        addProductButtonName: "add-caterer-product-button",
        editProductButtonName: "edit-caterer-product-button",
        deleteProductButtonName: "delete-caterer-product-button",
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    const toolbarName = "products-right-pane-toolbar";
    const toolbar = useToolbar({
        name: toolbarName
    });

    // dialogs
    const createOrEditCatererProductCalloutWindowName = "createoredit-caterer-product-modal";
    const catererCustomersOrdersCalloutWindowName = "caterer-customer-orders-dialog-modal";
    const catererCustomersTransactionsCalloutWindowName = "caterer-customer-transactions-dialog-modal";
    const catererProductCategoriesCalloutWindowName = "caterer-product-categories-modal";
    const catererProductVariationsCalloutWindowName = "caterer-product-variations-modal";
    const catererProductColorsCalloutWindowName = "caterer-product-color-modal";
    const catererProductTypesCalloutWindowName = "caterer-product-types-modal";
    // 
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const catererService = CatererService();
    const catererProductService = CatererProductService();

    const getCatererProducts = useCallback(async (request) => {
        pageLoader.show();
        await catererProductService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();
                pageLoader.close();

                // populate datagrid 
                setCatererProducts(response?.data?.list);
                // catererProductDatagridListObserver.current.updateList(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

            })
            .catch(error => {
                datagrid?.hideLoader();
                pageLoader.close();
            });

    }, []);


    // observers
    const widgetObserver = useCallback(async (args) => {
        stackablePanelComponent.setPanel(0);

        // close any opened window
        createOrEditCatererProductCalloutWindowObserver.current.close();
        catererProductCategoriesCalloutWindowObserver.current.close();
        catererProductColorsCalloutWindowObserver.current.close();
        catererProductTypesCalloutWindowObserver.current.close();
        catererProductVariationsCalloutWindowObserver.current.close();

        // load image
        catererLogoObserver.current.loadImage(args?.data);

        if (args?.data !== null) {

            // selected caterer
            setSelectedCaterer(args?.data);

            // get products in caterer
            await getCatererProducts({
                catererId: args?.data?.catererId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
                sort: {}
            });

        } else {
            // form.clear();
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;
        })();
    }, []);

    const handleCreateCatererProduct = (e) => {
        createOrEditCatererProductCalloutWindowObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {
                catererId: selectedCaterer?.catererId
            },
            isNew: true
        });
    }

    const handleEditCatererProduct = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show(messages?.handleEdit?.alertMessage);
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedCatererProduct = catererProducts.find(x => x.productId === datagrid.getSelectedRowKeyValue());
                setSelectedCatererProduct(selectedCatererProduct);

                // listen to dialog for changes
                createOrEditCatererProductCalloutWindowObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedCatererProduct,
                    isNew: false,
                    callback: () => {
                        getCatererProducts({
                            catererId: selectedCaterer?.catererId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show(messages?.noRecordSelectedErrorMessage);
            }
        }
    }

    const handleDeleteProduct = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    catererProductService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCatererProducts({
                            catererId: selectedCaterer?.catererId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleSearchRecords = async (e, args) => {
        await getCatererProducts({
            catererId: selectedCaterer?.catererId,
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getCatererProducts({
                catererId: selectedCaterer?.catererId,
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getCatererProducts({
                catererId: selectedCaterer?.catererId,
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages)
            getCatererProducts({
                marketId: selectedCaterer?.marketId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
    }

    const handlePageNumberChange = (e, args) => {
        getCatererProducts({
            marketId: selectedCaterer?.marketId,
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getCatererProducts({
                marketId: selectedCaterer?.marketId,
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleProductToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.manageCatererAccountMenuItem:
                // loadMarketWithProducts();
                break;

            case actionButtonsMenuItems?.browseCatererPageMenuItem:
                // loadMarketWithProducts();
                break;

            case actionButtonsMenuItems?.typeMenuItem:
                catererProductTypesCalloutWindowObserver.current.show({
                    title: messages?.manageTypes?.dialogTitle,
                    data: {
                        catererId: selectedCaterer?.catererId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.colorMenuItem:
                catererProductColorsCalloutWindowObserver.current.show({
                    title: messages?.manageColors?.dialogTitle,
                    data: {
                        catererId: selectedCaterer?.catererId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.variationsMenuItem:
                catererProductVariationsCalloutWindowObserver.current.show({
                    title: messages?.manageVariations?.dialogTitle,
                    data: {
                        catererId: selectedCaterer?.catererId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.categoriesMenuItem:
                catererProductCategoriesCalloutWindowObserver.current.show({
                    title: messages?.manageCategory?.dialogTitle,
                    data: {
                        catererId: selectedCaterer?.catererId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems?.transactionsMenuItem:
                catererCustomersTransactionsCalloutWindowObserver.current.show({
                    title: messages?.handleCreate?.dialogTitle,
                    data: {
                        catererId: selectedCaterer?.catererId
                    },
                    isNew: true
                });
                break;

            case actionButtonsMenuItems.customerOrderMenuItem:
            default:
                catererCustomersOrdersCalloutWindowObserver.current.show({
                    title: messages?.handleCreate?.dialogTitle,
                    data: {
                        catererId: selectedCaterer?.catererId
                    },
                    isNew: true
                });
                break;
        }
    }

    const handleRowDoubleClick = handleEditCatererProduct

    return {

        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                {/* create or edit product */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: createOrEditCatererProductCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: createOrEditCatererProductCalloutWindowObserver,
                                    widget: CreateOrEditCatererProductView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    },
                                    buttons: [
                                        {
                                            text: "Save & continue",
                                            style: themes?.button?.primary,
                                            onClick: (e, winArgs) => {
                                                winArgs.submit(e, args => {
                                                    pageLoader.close();
                                                    getCatererProducts({
                                                        catererId: selectedCaterer?.catererId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                    setSelectedCatererProduct({});
                                                    createOrEditCatererProductCalloutWindowObserver.current.close();
                                                });
                                            }
                                        },
                                        {
                                            text: "Cancel",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => winArgs.close(e)
                                        }
                                    ]
                                }} />

                                {/* manage caterer's customers orders */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: catererCustomersOrdersCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: catererCustomersOrdersCalloutWindowObserver,
                                    widget: CatererCustomersOrdersView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-710px"
                                    }
                                }} />

                                {/* manage caterer's customers transactions */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: catererCustomersTransactionsCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: catererCustomersTransactionsCalloutWindowObserver,
                                    widget: CatererCustomersTransactionsView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-710px"
                                    }
                                }} />

                                {/* manage caterer's categories */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageCategory?.dialogTitle,
                                    name: catererProductCategoriesCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: catererProductCategoriesCalloutWindowObserver,
                                    widget: CatererProductCategoriesView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.FILL,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* manage caterer's weight/size/variation */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageVariations?.dialogTitle,
                                    name: catererProductVariationsCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: catererProductVariationsCalloutWindowObserver,
                                    widget: CatererProductVariationsView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* manage caterer's type */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageTypes?.dialogTitle,
                                    name: catererProductTypesCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: catererProductTypesCalloutWindowObserver,
                                    widget: CatererProductTypesView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* manage caterer's color */}
                                <CalloutWindow attributes={{
                                    title: messages?.manageColors?.dialogTitle,
                                    name: catererProductColorsCalloutWindowName,
                                    data: selectedCatererProduct,
                                    observer: catererProductColorsCalloutWindowObserver,
                                    widget: CatererProductColorsView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* toolbar */}
                                <div className={themes?.toolbarCollectionWrapperRightPadding}>
                                    <div className={"w-50 d-flex justify-content-start align-items-center mb-1 text-blue-theme text-truncate"}>
                                        <div className={"max-width-38px min-width-38px height-38px position-relative me-2"}>
                                            <Avatar attributes={{
                                                name: "caterer-logo",
                                                formElement: true,
                                                validate: true,
                                                id: "mediaId",
                                                key: "mediaId",
                                                url: "mediaUrl",
                                                size: themes?.sizeAbsolute,
                                                leading: icons?.PhotoSizeSelectActualIcon,
                                                style: themes?.image38,
                                                model: selectedCaterer,
                                                observer: catererLogoObserver,
                                                config: {
                                                    ownerId: appConfig?.sessionAuthDetails?.userId,
                                                    uploadUrl: catererService?.uploadUrl,
                                                    downloadUrl: catererService?.downloadUrl
                                                }
                                            }} />
                                        </div>
                                        <div className={"mt-1"}>
                                            <div className={"d-flex align-items-center text-13px text-bold mb-1 max-width-200px-"}>
                                                <span className={"me-2 text-truncate line-height-14px height-15px"}>{selectedCaterer?.businessName}</span>
                                            </div>
                                            <div className={"d-flex text-12px m-0 opacity-8 text-truncate"}>
                                                <div className={"d-flex align-items-center justify-content-start text-12px me-3 text-truncate"}>
                                                    <i className={"d-flex me-1 icon-14px"}>{icons?.LocationOnIcon}</i>
                                                    <span className={"d-flex text-truncate w-100"}>{selectedCaterer?.contactAddress}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"w-70 d-flex justify-content-end"}>
                                        <Toolbar attributes={{
                                            name: toolbarName,
                                            items: [
                                                {
                                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                                    name: toolbarButtons.filterButtonName,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.SearchRoundedIcon,
                                                    placeholder: "Find a caterer...",
                                                    trailing: icons?.ArrowForwardRoundedIcon,
                                                    style: themes?.toolbar?.searchField,
                                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: toolbarButtons.addProductButtonName,
                                                    type: TOOLBARITEMTYPE.BUTTON,
                                                    leading: icons?.AddRoundedIcon,
                                                    onClick: handleCreateCatererProduct,
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.button,
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                // {
                                                //     name: toolbarButtons.editProductButtonName,
                                                //     type: TOOLBARITEMTYPE.BUTTON,
                                                //     leading: icons?.EditRoundedIcon,
                                                //     onClick: handleEditCatererProduct,
                                                //     observer: componentObserver,
                                                //     style: themes?.toolbar?.button,
                                                // },
                                                // {
                                                //     type: TOOLBARITEMTYPE.SPACE,
                                                //     style: themes?.toolbar?.space?.space4
                                                // },
                                                // {
                                                //     name: toolbarButtons.deleteProductButtonName,
                                                //     type: TOOLBARITEMTYPE.BUTTON,
                                                //     leading: icons?.DeleteRoundedIcon,
                                                //     onClick: handleDeleteProduct,
                                                //     observer: componentObserver,
                                                //     style: themes?.toolbar?.button,
                                                // },
                                                // {
                                                //     type: TOOLBARITEMTYPE.SPACE,
                                                //     style: themes?.toolbar?.space?.space4
                                                // },
                                                {
                                                    name: toolbarButtons.deleteProductButtonName,
                                                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.MoreVertRoundedIcon,
                                                    onClick: () => { },
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.endButton,
                                                    widget: <MenuList attributes={{
                                                        items: [
                                                            {
                                                                name: actionButtonsMenuItems.transactionsMenuItem,
                                                                leading: icons?.EditRoundedIcon,
                                                                title: "Edit product",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.transactionsMenuItem,
                                                                leading: icons?.DeleteRoundedIcon,
                                                                title: "Delete",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.transactionsMenuItem,
                                                                leading: icons?.ReceiptRoundedIcon,
                                                                title: "Browse and upload .csv file",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.customerOrderMenuItem,
                                                                leading: icons?.ShoppingBasketRoundedIcon,
                                                                title: "Customers' orders",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.transactionsMenuItem,
                                                                leading: icons?.ReceiptRoundedIcon,
                                                                title: "Transactions",
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.categoriesMenuItem,
                                                                leading: icons?.CategoryCardsRoundedIcon,
                                                                title: "Manage categories",
                                                                transparent: true,
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.variationsMenuItem,
                                                                leading: icons?.ScaleIcon,
                                                                title: "Manage size/weight",
                                                                transparent: true,
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.typeMenuItem,
                                                                leading: icons?.TaskIcon,
                                                                title: "Manage type",
                                                                transparent: true,
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.colorMenuItem,
                                                                leading: icons?.ColorLensRoundedIcon,
                                                                title: "Manage color",
                                                                transparent: true,
                                                            },
                                                            {
                                                                type: MENUITEMTYPE.SEPARATOR
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.browseCatererPageMenuItem,
                                                                leading: icons?.AutoStoriesIcon,
                                                                title: "Browse caterer's products page",
                                                            },
                                                            {
                                                                name: actionButtonsMenuItems.manageCatererAccountMenuItem,
                                                                leading: icons?.AccountTreeIcon,
                                                                title: "Manage caterer's account",
                                                            }
                                                        ],
                                                        style: themes?.menuList,
                                                        onItemClick: handleProductToolbarOptionsMenuItemClick,
                                                        isSelectable: false
                                                    }} />,
                                                },
                                                // {
                                                //     type: TOOLBARITEMTYPE.SEARCHFIELD,
                                                //     name: toolbarButtons.filterButtonName,
                                                //     leading: icons?.SearchRoundedIcon,
                                                //     placeholder: "Find product...",
                                                //     trailing: icons?.ArrowForwardRoundedIcon,
                                                //     style: themes?.toolbar?.searchField,
                                                //     onClick: (e, args) => { handleSearchRecords(e, args) },
                                                // },
                                                // {
                                                //     type: TOOLBARITEMTYPE.SPACE,
                                                //     style: themes?.toolbar?.space?.space6
                                                // },
                                            ]
                                        }} />
                                    </div>
                                </div>

                                {/* datagrid view */}
                                <div className={"position-absolute end-15px top-50px start-0px bottom-0px "}>
                                    <DataGrid attributes={{
                                        name: catererProductDatagridName,
                                        key: "productId",
                                        observer: catererProductDatagridListObserver,
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: ["productId"],
                                                style: themes?.width30px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                value: ["mediaUrl"],
                                                default: "",
                                                style: themes?.width35px,
                                                image: {
                                                    id: "mediaId",
                                                    key: "mediaId",
                                                    url: "mediaUrl",
                                                    size: themes?.size35px,
                                                    leading: icons?.BrandingWatermarkRoundedIcon,
                                                    style: themes?.image,
                                                    config: {
                                                        downloadUrl: catererProductService?.downloadUrl
                                                    },
                                                }
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                title: "Product",
                                                value: ["productName"],
                                                default: "",
                                                style: themes?.width100
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                title: "Price",
                                                value: ["priceAlias"],
                                                default: "",
                                                textAlign: ALIGNMENTTYPE.RIGHT,
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Category",
                                                value: ["productCategory"],
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Type",
                                                value: ["productType"],
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Size/Weight",
                                                value: ["productVariation"],
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            }
                                        ],
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onHomeClick: handleFirstPage,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        dataset: catererProducts,
                                        onRowDoubleClick: handleRowDoubleClick
                                    }} />
                                </div>
                            </div>
                        );
                    })()
                },
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                <div className={"w-100 position-absolute end-0px top-50px start-0px bottom-0px "}>

                                </div>
                            </div>
                        );
                    })()
                },
            ]
        },

    };
}

export default useCatererProductsHook;