import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/button/button.component";
import { BsArrowRight } from "react-icons/bs";
import { TextField } from "../../components/field/textfield.component";

const ResetPasswordWidget = ({ method, observer, onSubmit }) => {

    const [auth, setAuth] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    const formThemes = {
        textField: "height-40px bg-grey-theme border-none",
        button: {
            button: "text-center manrope-bold text-14px height-40px w-100 bg-blue-theme",
            text: "text-center manrope-bold text-14px",
        },
        selectDropDownMenu: {
            button: "height-38px bg-grey-theme border-none",
            menu: {
                item: "p-0",
                title: "text-13px ps-1 pe-2"
            }
        }
    };

    const componentObserver = useRef(null);

    const notifier = useCallback(async (e) => {
        componentObserver.current({
            inProgress: e.inProgress,
            errorOccurred: e.errorOccurred,
            asyncMessage: e.message
        });
    })

    useEffect(() => {
        (async () => {
            if (observer?.current)
                observer.current = await notifier;
        })();
    }, [auth, observer])

    const handleOnChange = (value) => {
        setAuth({
            code: value
        })
    }

    return (
        <form method={method} onSubmit={onSubmit} autoComplete={'false'} className={'needs-validation text-left'} noValidate>
            <div className={'flex-column'}>
                <div className={'margin-bottom-30px'}>
                    <h2 className={'text-33px line-height-36px text-bold'}>Reset password</h2>
                    <div className={'text-14px line-height-18px'}>
                        Enter your email address, and we will send you a code to
                        reset your password.
                    </div>
                </div>

                {/* email field */}
                <div className={'w-100 m-0 mb-3'}>
                    <label className={'w-100 form-label align-left text-bold text-13px line-height-17px margin-bottom-8px'}>
                        Email address:
                    </label>
                    <div className={'input-group has-validation'}>
                        <TextField attributes={{
                            onChange: handleOnChange,
                            readOnly: auth.inProgress,
                            placeholder: "Please enter your email to login.",
                            id: "email-field",
                            style: formThemes.textField,
                            observer: componentObserver
                        }} />
                        <div className={'invalid-feedback'}>
                            Please choose a username.
                        </div>
                    </div>
                </div>

                {/* submit button */}
                <div className={'w-100 m-0 mb-3'}>
                    <Button attributes={{
                        type: 'button',
                        text: 'Send code',
                        icon: <BsArrowRight />,
                        style: formThemes.button,
                        onClick: onSubmit,
                        showProgress: false,
                        isProgress: auth.isProgress,
                        observer: componentObserver
                    }} />
                </div>
            </div>
        </form>
    );
}
export default ResetPasswordWidget;