import { https } from '../../../../../shared/utilities';


const CatererCustomerOrderService = () => {
    const request = https();
    const controller = "/caterer-customer-orders";

    return { 
        getAll: async (data) => await request.post(controller + "/list", data), 
        get: async (id) => await request.post(controller + "/list", id), 
        search: async (data) => await request.post(controller + "/list", data),
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        remove: async (data) => await request.post(controller + "/delete", data),
    }
}

export { CatererCustomerOrderService }