import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import Button from "../button/button.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes/index";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import { DOCKTYPE, MENUALIGNMENTTYPE, MODALACTION, SLIDEDIRECTION, TOOLBARITEMTYPE } from "../../enums/enums";
import { Toolbar } from "../toolbar/toolbar.component";

const CalloutWindow = forwardRef(({ attributes }, ref) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [params, setParams] = useState({
        title: attributes?.title,
        widget: attributes?.widget,
        isNew: attributes?.isNew
    });
    const [inProgress, setInProgress] = useState(false);

    // refs
    const componentRef = useRef();

    const widgetObserver = useRef();
    const widgetNotifier = useRef();

    const close = useCallback(async () => {
        const dialog = componentRef?.current;
        if (dialog) {
            if (dialog.getAttribute('data-slide-position') === SLIDEDIRECTION.LEFT) {
                dialog.classList.remove("end-0px");
                dialog.classList.add("end--1000px");
            } else {
                dialog.classList.remove("start-0px");
                dialog.classList.add("start--1000px");
            }
            dialog.classList.remove("w-100");
            dialog.classList.add("overflow-hidden");
            dialog?.classList.add("opacity-0");
            dialog?.classList.remove("opacity-10");
        }
    }, [])

    useImperativeHandle(attributes?.observer, () => ({
        show(attr) {
            const dialog = componentRef?.current;
            close();

            const modalBody = dialog.querySelector("div[aria-controls=\"modal-body\"]");
            modalBody.scrollTop = 0;

            dialog.querySelector("h1").innerHTML = attr?.title || "";
            dialog?.setAttribute("is-new", attr?.isNew);
            dialog.classList.add("w-100");
            dialog.classList.remove("overflow-hidden");
            dialog?.classList.add("opacity-10");
            dialog?.classList.remove("opacity-0");
            if (dialog.getAttribute('data-slide-position') === SLIDEDIRECTION.LEFT) {
                dialog.classList.add("end-0px");
                dialog.classList.remove("end--1000px");
            } else {
                dialog.classList.add("start-0px");
                dialog.classList.remove("start--1000px");
            }

            if (widgetObserver?.current)
                widgetObserver?.current({
                    data: attr?.data,
                    callback: attr?.callback
                });
        },

        close() {
            // setTimeout(() => {
            close();
            // });
        }
    }))

    const getSlidePosition = () => {
        let slideDirectionStyle = attributes?.slideDirection + " position-absolute top-0px bottom-0px border border-top-0 border-bottom-0 " + ((attributes?.slideDirection === SLIDEDIRECTION.RIGHT)? "border-start-0 border-end-1px box-shadow-right" : "box-shadow-left");
        return slideDirectionStyle;
    }

    const handleStopPropagation = (e) => {
        // e.cancelBubble = true;
        // e.stopPropagation();
    }

    useEffect(() => {
        (async () => {
            // document.body.addEventListener("click", close);
            // return () => {
            //     document.body.removeEventListener("click", close);
            // };

            // console.log(attributes);
        })();
    }, [attributes])

    return (
        <div onClick={handleStopPropagation}
            ref={componentRef}
            aria-owns={attributes?.name}
            aria-controls={"modal-dialog-modal"}
            data-slide-position={attributes?.slideDirection}
            className={getSlidePosition() + " " + attributes?.style?.window + " bg-white-theme overflow-hidden z-index-5 opacity-0"}
            id="modal-dialog">
            <div className={"modal-dialog position-absolute top-0px start-0px bottom-0px end-0px"}>
                <div className={"modal-content position-absolute top-0px start-0px bottom-0px end-0px padding-15px padding-start-22px border-1px transparent-border-blue-dark-theme-"}>
                    <div className={"modal-header border-0px padding-start-20px height-50px  position-absolute top-0px start-0px end-0px"}>
                        <div className={"w-100 d-flex justify-content-between align-items-center"}>
                            <h1 className={"modal-title text-17px text-bold line-height-23px pt-0 " + themes?.modal?.title}>{params?.title}</h1>
                            <div className={"ms-3 me-2 d-flex"}>
                                <div className={"spinner-border text-dark icon icon-14px text-8px " + (inProgress ? "" : "hide")} role="status"></div>
                            </div>
                            <div className={"ms-3 me-0 d-flex"}>
                                <Toolbar attributes={{
                                    items: [
                                        {
                                            name: attributes?.name + "-close-button",
                                            type: TOOLBARITEMTYPE.BUTTON,
                                            menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                            leading: icons?.CloseRoundedIcon,
                                            onClick: close,
                                            style: themes?.toolbar?.iconButton
                                        }
                                    ]
                                }} />
                            </div>
                        </div>
                    </div>
                    <div aria-controls="modal-body" className={"modal-body text-13px padding-20px pt-0 position-absolute top-50px start-0px end-0px " + ((attributes?.buttons && attributes?.buttons?.length > 0) ? "bottom-65px" : "bottom-0px") + " " + (attributes?.dockType === DOCKTYPE.UNDOCK ? "" : "overflow-y-auto")}>
                        {
                            (() => {
                                if (attributes?.widget) {
                                    return attributes?.widget({
                                        attributes: {
                                            observer: widgetObserver,
                                            reactiveFormModule: widgetNotifier,
                                            data: widgetObserver?.data,
                                            callback: attributes?.callback
                                        }
                                    });
                                }
                            })()
                        }
                    </div>
                    <div className={"modal-footer border-top-0px position-absolute start-0px bottom-0px end-0px p-3 bg-white-theme " + ((attributes?.buttons && attributes?.buttons?.length > 0) ? "" : "hide")}>
                        {
                            (() => {
                                return attributes?.buttons?.map((buttonAttributes, i) => {
                                    buttonAttributes["args"] = {
                                        apply: (e, callback) => {
                                            widgetNotifier.current({
                                                action: MODALACTION.APPLY,
                                                data: attributes?.data,
                                                isNew: (() => {
                                                    try {
                                                        return JSON.parse(componentRef?.current?.getAttribute("is-new"));
                                                    } catch (e) {
                                                        return false;
                                                    }
                                                })(),
                                                inProgress: (state) => {
                                                    setInProgress(state);
                                                },
                                                successful: (args) => {
                                                    callback(args);
                                                },
                                                error: () => {
                                                    //
                                                }
                                            });
                                        },
                                        close: e => {
                                            close(e?.currentTarget);
                                        },
                                        submit: (e, callback) => {
                                            const currentTarget = e.currentTarget;
                                            widgetNotifier.current({
                                                action: MODALACTION.SUBMIT,
                                                data: attributes?.data,
                                                isNew: (() => {
                                                    try {
                                                        return JSON.parse(componentRef?.current?.getAttribute("is-new"));
                                                    } catch (e) {
                                                        return false;
                                                    }
                                                })(),
                                                inProgress: (state) => {
                                                    setInProgress(state);
                                                },
                                                successful: (args) => {
                                                    close(currentTarget || e?.currentTarget);
                                                    callback(args);
                                                },
                                                close: () => {
                                                    close(currentTarget || e?.currentTarget);
                                                },
                                                error: () => {
                                                    //
                                                }
                                            });
                                        }
                                    };
                                    return (
                                        <div className={"ms-2"} key={"callout-footer-button-" + i}><Button key={"modal-footer-button-" + i} attributes={buttonAttributes} /></div>
                                    );
                                })
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
})

const useCalloutWindow = (attributes) => {

    const show = (attr) => {
        const dialog = document.body.querySelector("*[aria-owns=\"" + attributes?.name + "\"]");
        const h1 = dialog?.querySelector("h1");
        if (h1)
            h1.innerHTML = attr?.title || "";
        dialog?.classList.add("display-block");
        dialog?.classList.add("opacity-1");
        dialog?.classList.remove("opacity-0");
        console.log("Hey, open");
        if (dialog.getAttribute('data-slide-position') === SLIDEDIRECTION.LEFT) {
            dialog?.classList.add("end-0px");
            dialog?.classList.remove("end--1000px");
            dialog?.classList.remove("start-0px");
        } else {
            dialog?.classList.add("start-0px");
            dialog?.classList.remove("start--1000px");
            dialog?.classList.remove("end-0px");
        }
        dialog?.setAttribute("is-new", attr?.isNew);
    }

    const close = (currentTarget) => {
        if (currentTarget) {
            const dialog = currentTarget?.parentNode?.parentNode?.parentNode?.parentNode;
            if (dialog) {
                dialog.classList.remove("display-block");
                dialog?.classList.add("end--1000px");
                dialog?.classList.remove("end-0px");
                dialog?.classList.add("opacity-0");
                dialog?.classList.remove("opacity-1");
            } else {
                //
            }
        }
    }

    return { show, close }
}
export { CalloutWindow, useCalloutWindow }