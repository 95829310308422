import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/button/button.component";
import { BsArrowRight } from "react-icons/bs";
import { TextField } from "../../components/field/textfield.component";
import Password from "../../components/password/password.component";
import { useNavigate } from "react-router-dom";
import { IoChevronBackOutline } from "react-icons/io5";

const AccountSignUpWidget = ({ method, observer, onSubmit }) => {

    const [auth, setAuth] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        retypePassword: '',
    });
    const formErrorMessages = {
        firstName: "Please enter your first name to continue",
        lastName: "Please enter your last name to continue",
        email: "Please enter your email to continue",
        password: "Please choose a strong password to continue",
        retypePassword: "Please retype your password to continue",
    };

    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    const navigate = useNavigate();

    const componentObserver = useRef(null);
    const alertObserver = useRef(null);

    const notifier = useCallback(async (e) => {
        componentObserver.current({
            inProgress: e.inProgress,
            errorOccurred: e.errorOccurred,
            asyncMessage: e.message
        });

        // 
        alertObserver.current({
            state: e.errorOccurred,
            message: e.message
        })
    }, [])

    useEffect(() => {
        (async () => {
            observer.current = notifier;
        })();
    }, [])

    const handleTextFieldOnChange = async (e) => {
        setAuth({
            ...auth,
            [e.id]: e.value
        })
    }

    const handleRegister = async (e) => {
        //
    }

    const handleGoBack = async (e) => {
        navigate("/.");
    }

    return (
        <form method={method} onSubmit={handleRegister} autoComplete={'false'} className={'needs-validation text-left'} noValidate>
            <div className={'flex-column'}>

                <div className={"position-relative height-60px"}>
                    <Button attributes={{
                        type: "button",
                        className: "ps-1 pe-1 text-center manrope-bold text-14px height-35px bg-light",
                        logo: <IoChevronBackOutline />,
                        text: "Back",
                        onClick: handleGoBack,
                    }}></Button>
                </div>

                <div className={"position-relative height-100px"}>
                    {/* <AlertBox attributes={{
                        observer: alertObserver
                    }} /> */}
                </div>

                <div className={'margin-bottom-30px'}>
                    <h2 className={'text-33px line-height-36px text-bold'}>Create your account</h2>
                    <div className={'text-14px line-height-18px'}>Welcome back</div>
                </div>

                {/* name field */}
                <div className={'w-100 m-0 mb-4'}>
                    <label className={'w-100 form-label align-left text-bold text-13px line-height-17px margin-bottom-6px'}>
                        Name:
                    </label>
                    <div className={'d-flex'}>
                        <div className={'input-group has-validation me-3'}>
                            <TextField attributes={{
                                onChange: handleTextFieldOnChange,
                                readOnly: status.inProgress,
                                placeholder: "First name",
                                id: "firstName",
                                className: "height-45px",
                                observer: componentObserver
                            }} />
                            <div className={'invalid-feedback'}>
                                Please choose a username.
                            </div>
                        </div>

                        <div className={'input-group has-validation'}>
                            <TextField attributes={{
                                onChange: handleTextFieldOnChange,
                                readOnly: status.inProgress,
                                placeholder: "Last name",
                                id: "lastName",
                                className: "height-45px",
                                observer: componentObserver
                            }} />
                            <div className={'invalid-feedback'}>
                                Please choose a username.
                            </div>
                        </div>
                    </div>
                </div>

                {/* email field */}
                <div className={'w-100 m-0 mb-4'}>
                    <label className={'w-100 form-label align-left text-bold text-13px line-height-17px margin-bottom-6px'}>
                        Email address:
                    </label>
                    <div className={'input-group has-validation'}>
                        <TextField attributes={{
                            onChange: handleTextFieldOnChange,
                            readOnly: status.inProgress,
                            placeholder: "Please enter your email to login.",
                            id: "email",
                            className: "height-45px",
                            observer: componentObserver
                        }} />
                        <div className={'invalid-feedback'}>
                            Please choose a username.
                        </div>
                    </div>
                </div>

                {/* password field */}
                <div className={'w-100 m-0 mb-3'}>
                    <div className={'d-flex justify-content-between margin-bottom-6px'}>
                        <label className={'w-50 form-label align-left text-bold text-13px line-height-17px margin-bottom-0px'}>
                            Choose a password:
                        </label>
                    </div>
                    <Password attributes={{
                        onChange: handleTextFieldOnChange,
                        readOnly: status.inProgress,
                        placeholder: "Please enter your password to continue.",
                        id: "password",
                        className: "height-45px",
                        observer: componentObserver,
                        required: true
                    }} />
                    <div className={'invalid-feedback'}>
                        Please provide a valid password.
                    </div>
                </div>

                {/* password field */}
                <div className={'w-100 m-0 mb-3'}>
                    <div className={'d-flex justify-content-between margin-bottom-6px'}>
                        <label className={'w-50 form-label align-left text-bold text-13px line-height-17px margin-bottom-0px'}>
                            Retype your password:
                        </label>
                    </div>
                    <Password attributes={{
                        onChange: handleTextFieldOnChange,
                        readOnly: status.inProgress,
                        placeholder: "Please enter your password to continue.",
                        id: "retypePassword",
                        className: "height-45px",
                        observer: componentObserver,
                        required: true
                    }} />
                    <div className={'invalid-feedback'}>
                        Please provide a valid password.
                    </div>
                </div>

                {/* submit button */}
                <div className={'w-100 m-0 mt-4 mb-3'}>
                    <Button attributes={{
                        type: 'button',
                        text: 'Register',
                        icon: <BsArrowRight />,
                        className: 'w-100 text-center manrope-bold text-14px height-45px bg-blue-theme',
                        onClick: handleRegister,
                        showProgress: false,
                        isProgress: status.isProgress,
                        observer: componentObserver
                    }} />
                </div>

            </div>
        </form>
    );
}
export default AccountSignUpWidget;