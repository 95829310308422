import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import useAppConfig from "../../hooks/app.config.hook";

const ProfilePhotoUploader = forwardRef(({ attributes, model, isImageAvailable }, ref) => {

    // theme
    const icons = useIconsThemes()?.rounded;

    const isInitialized = useRef(0);
    const componentObserver = useRef();
    const appConfig = useAppConfig();

    const [imageSize, setImageSize] = useState();

    useImperativeHandle(attributes?.observer, () => ({
        loadImage(args) {
            const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
            const preview = componentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
            const size = componentObserver.current.clientWidth;
            if (args && attributes?.url !== "") {
                const loadImageUrl = (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + (size + 150);
                hiddenField.value = args[attributes?.key];
                preview.setAttribute("style", "background-image: url('" + loadImageUrl + "')");
            } else {
                preview.setAttribute("style", "background-image: url('')");
            }
        }
    }))

    useEffect(() => {
        const size = componentObserver.current.clientWidth;
        setImageSize(size);

        if (attributes) {
            initializeImage(model);
        }
    }, [attributes, model, componentObserver])

    const initializeImage = async (args) => {
        const size = componentObserver.current.clientWidth;
        setImageSize(size);

        const loader = componentObserver?.current?.querySelector("div[aria-controls=\"loader-controls\"]");
        loader.classList.add("hide");

        const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
        const preview = componentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
        // preview.setAttribute("style", "background-image: url('')");

        if (args && isImageAvailable === true) {
            hiddenField.value = args[attributes?.key];

            if (attributes?.onUploadCompleted)
                attributes?.onUploadCompleted(args);

            // load product image
            preview.setAttribute("style", "background-image: url('" + (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + size + "')");
            isInitialized.current = 0;
        }
    }

    const loadUploadedImage = async (args) => {
        const size = componentObserver.current.clientWidth * 2;

        const fileUploader = componentObserver?.current?.querySelector("input[type=\"file\"]");
        const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
        const preview = componentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
        const loader = componentObserver?.current?.querySelector("div[aria-controls=\"loader-controls\"]");

        if (args) {

            const loadImageUrl = (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + size;
            hiddenField.value = args[attributes?.key];

            if (attributes?.onUploadCompleted)
                attributes?.onUploadCompleted(args);

            preview.setAttribute("style", "");
            setTimeout(() => {
                preview.setAttribute("style", "background-image: url('" + loadImageUrl + "')");

                // hide loader
                loader.classList.add("hide");
            }, 1000);

            fileUploader.value = "";

            isInitialized.current = 0;
        }
    }

    const handleBrowseFile = (e) => {
        const fileInput = componentObserver.current.querySelector("input[type=\"file\"]");
        fileInput.click();
    }

    const handleFileSelectionChange = (e) => {
        const files = e.target.files;

        // Create a new worker thread
        if (files.length > 0) {
            const loader = componentObserver?.current?.querySelector("div[aria-controls=\"loader-controls\"]");
            loader.classList.remove("hide");

            const worker = new Worker(new URL('./uploader.worker', import.meta.url));
            worker.onmessage = ({ data }) => {
                if (data.isUploading) {
                    handleImageUploading(data);
                }

                if (
                    ('isUploadCompleted' in data && data.isUploadCompleted === true) ||
                    ('isUploadCancelled' in data && data.isUploadCancelled === true)
                ) {
                    if (data.response.statusCode === 200) {
                        const uploadResponse = data.response.data;
                        loadUploadedImage(uploadResponse);
                    }

                    // clear file
                    e.target.value = "";
                }
            };

            worker.postMessage({
                url: attributes?.config?.uploadUrl,
                file: files[0],
                ownerId: appConfig?.sessionAuthDetails?.userId
            });
        }
    }

    const handleImageUploading = (args) => {
        //
    }

    return (
        <div ref={componentObserver} className={"text-dark text-decoration-none cursor-pointer"}>
            <div onClick={handleBrowseFile} className={"position-relative d-flex justify-content-center overflow-hidden align-items-center rounded-3 text-white mx-auto " + attributes?.style?.g + " " + attributes?.style?.bgColor + " " + attributes?.size}>
                <input
                    id={attributes?.id || attributes?.key}
                    name={attributes?.id || attributes?.key}
                    aria-owns={attributes?.name}
                    aria-controls={attributes?.formElement}
                    aria-required={attributes?.validate}
                    type={"hidden"} />

                <div className={"position-absolute top--1000px left-0px width-1px height-1px overflow-hidden"}>
                    <input type="file" accept=".png, .jpg, .webp, .mp4"
                        onChange={handleFileSelectionChange} />
                </div>


                {
                    (() => {
                        return (
                            <div className={"d-flex justify-content-center align-items-center "}>
                                <div className={"" + attributes?.style?.textColor}>
                                    <div className={"w-100 mb-2 mx-auto d-flex justify-content-center " + attributes?.style?.placeholder}>{icons?.PersonAddRoundedIcon}</div>
                                </div>
                            </div>
                        )
                    })()
                }


                {
                    ((size) => {
                        return (
                            <div className={""}>
                                <i aria-controls="image-preview-controls" className={"bg-cover bg-center d-flex position-absolute top-0px start-0px bottom-0px end-0px " + ((attributes?.style?.image) ? attributes?.style?.image : "rounded-2 ")}></i>
                            </div>
                        )
                    })(imageSize)
                }


                {/* loader */}
                <div aria-controls="loader-controls" className={"hide transparent-bg-white-theme position-absolute top-0px start-0px bottom-0px end-0px z-index-10 bottom-0px mx-auto d-flex justify-content-center align-items-center"}>
                    <div className={"transparent-bg-white-theme- shadow-lg- mx-auto min-height-50px p-4"}>
                        <div className="spinner-border border-2px text-blue-theme icon-70px" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
})
export { ProfilePhotoUploader };