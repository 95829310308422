import React, { useEffect, useRef } from "react";
import { DOCKTYPE } from "../../enums/enums";

const Tab = ({ attributes }) => {

    // components
    const tab = useTab(attributes);

    // refs
    const tabComponent = useRef();

    useEffect(() => {
        if (attributes?.dockType) {
            tab.resizer();
            window.addEventListener("resize", tab.resizer);

            // garbage collection
            return () => {
                window.removeEventListener("resize", tab.resizer);
            }
        }
    }, [])

    const handleTabClick = (e) => {
        // select tab
        tab.selectTab(e);

        // trigger event handler
        attributes?.onTabClick(e, tab.selectedTab(e));
    }

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return "position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return "position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return "position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return "position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return "position-absolute top-0px start-0px end-0px";
            default:
                return;
        }
    }

    const getTabButton = (tabItem, index) => {
        return (
            <li key={"tab-" + index} aria-selected={(index === 0) ? true : false} aria-posinset={index} aria-owns={tabItem.target} aria-controls={attributes?.name + "-tab-button"} className={"nav-item cursor-pointer border-0px p-0 " + (((tabItem?.className) ? tabItem?.className : ""))} onClick={tabItem?.tabButtonClick}>
                <div aria-posinset={index} className={"nav-link border-0px active position-relative d-flex justify-content-center align-items-center " + ((tabItem?.style?.tab) ? tabItem?.style?.tab : " ps-2 pe-2 height-34px")}>
                    <div className={"d-flex justify-content-center align-items-center text-13px " + (tabItem?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                        <i className={"d-flex justify-content-center align-items-center m-0 " + (tabItem?.leading ? "" : "hide ") + (tabItem?.style?.text ? "me-1 " : "") + " icon " + tabItem?.style?.leading}>
                            {tabItem?.leading}
                        </i>
                        <span className={"d-flex white-space-nowrap " + (tabItem?.style?.text ? tabItem?.style?.text : "hide")}>{tabItem?.text}</span>
                        <i className={"d-flex justify-content-center align-items-center " + ((tabItem?.style?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + tabItem?.style?.trailing}>
                            {tabItem?.trailing}
                        </i>
                    </div>
                </div>
            </li>
        );
    }

    return (
        <div aria-controls="tab" id={attributes?.name} ref={tabComponent} className={"w-100 " + getDockStyle(attributes?.dockType)}>
            {
                (() => {
                    let dockStyle;
                    switch (attributes?.dockType) {
                        case DOCKTYPE.FILL:
                            dockStyle = getDockStyle(DOCKTYPE.TOP)
                            break;
                        default:
                            break;
                    }

                    return (
                        <div aria-controls="tab-button-container" className={"" + dockStyle}>
                            <ul className={"nav nav-tabs w-100 mb-0 padding-bottom-2px border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme " + attributes?.themes?.tab?.tabControl}>
                                {
                                    attributes?.items?.map((item, i) => {
                                        item["key"] = attributes.name + "-button-" + i;
                                        item["tabButtonClick"] = handleTabClick;
                                        return (
                                            getTabButton(item, i)
                                        )
                                    })
                                }
                            </ul>
                        </div>
                    )
                })()
            }
            {
                (() => {
                    let dockStyle;
                    switch (attributes?.dockType) {
                        case DOCKTYPE.FILL:
                            dockStyle = getDockStyle(DOCKTYPE.BOTTOM);
                            break;
                        default:
                            dockStyle = "position-relative";
                            break;
                    }

                    return (
                        <div aria-controls="tab-panel-container" className={dockStyle + " " + (!attributes?.showPanel? "": "hide")}>
                            <div aria-controls={attributes?.name + "-stackable-panel"} className={getDockStyle(DOCKTYPE.FILL)}>
                                {
                                    attributes?.items?.map((item, i) => {
                                        return (
                                            <div aria-controls={attributes?.name + "-panel"} panel-index={i} className={"position-absolute top-0px start-0px bottom-0px end-0px " + ((i === 0) ? "" : "hide")} key={attributes.name + "-stackable-panel-" + i}>
                                                {
                                                    (() => {
                                                        if (item?.widget) {
                                                            return item?.widget
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })()
            }
        </div>
    );
}

const useTab = (attributes) => {

    const themes = attributes?.themes?.tab;

    const addWidget = () => {

    }

    const getButtonContainerHeight = (index) => {
        const _tab = target();
        if (_tab) {
            const buttonContainrHeight = _tab?.querySelector("div[aria-controls=\"tab-button-container\"]");
            return buttonContainrHeight?.offsetHeight;
        }
        return 0;
    }

    const getPanelContainer = () => {
        const _tab = target();
        return _tab.querySelector("div[aria-controls=\"tab-panel-container\"]");
    }

    const getPanels = () => {

    }

    const getPanelByIndex = (index) => {
        const _tab = target();
        if (_tab) {
            const panels = _tab.querySelectorAll("div[aria-controls=\"" + attributes?.name + "-panel\"]");
            const panelArray = [...panels];
            return panelArray[index];
        }
        return null;
    }

    const getPanelByName = (name) => {
        const _tab = target();
        if (_tab) {
            const panels = _tab.querySelectorAll("div[aria-controls=\"" + attributes?.name + "-panel\"]");
            const panelArray = [...panels];
            return panelArray.find(x => x.querySelector("div[aria-owns=\"" + name + "\"]"));
        }
        return null;
    }

    const selectTab = (clickedTab) => {
        const tabs = document.querySelectorAll("*[aria-controls=\"" + attributes?.name + "-tab-button\"]");
        const panels = document.querySelectorAll("div[aria-controls=\"" + attributes?.name + "-panel\"]");

        const selectedTabThemes = themes?.selectedTabButton?.tab?.split(" ");
        const tabThemes = themes?.tabButton?.tab?.split(" ");

        const activeTab = [...tabs].find(x => x.getAttribute("aria-selected") === "true");
        activeTab.setAttribute("aria-selected", "false");
        activeTab.firstChild.classList.remove(...selectedTabThemes);
        activeTab.firstChild.classList.add(...tabThemes);

        const selectedTab = clickedTab.currentTarget;
        selectedTab.setAttribute("aria-selected", "true");
        selectedTab.firstChild.classList.remove(...tabThemes);
        selectedTab.firstChild.classList.add(...selectedTabThemes);

        [...panels].find(x => !x.classList.contains("hide")).classList.add("hide");
        panels[selectedTab.getAttribute("aria-posinset")].classList.remove("hide");
    }

    const selectedTab = (clickedTab) => {
        const currentTarget = clickedTab.currentTarget;
        return {
            selectedTab: currentTarget,
            handle: currentTarget.getAttribute("aria-owns"),
            index: currentTarget.getAttribute("aria-posinset"),
            target: currentTarget.getAttribute("aria-owns")
        }
    }

    const setPanel = (index) => {
        const _tab = target();
        if (_tab) {
            const panels = _tab.querySelectorAll("div[aria-controls=\"" + attributes?.name + "-panel\"]");
            const selectedPanels = _tab.querySelector("div[aria-controls=\"" + attributes?.name + "-panel\"]");
            [...panels].forEach((panel, i) => panel.classList.add("hide"));
            panels[index].classList.remove("hide");
        }
    }

    const target = () => {
        return document.getElementById(attributes?.name);
    }

    const resizer = () => {
        if (target()) {
            const tabButtonHeight = getButtonContainerHeight();
            const panelContainer = getPanelContainer();
            if (panelContainer)
                panelContainer.setAttribute("style", "top: " + tabButtonHeight + "px");
        }
    }

    return {
        name: attributes?.name,
        addWidget,
        getButtonContainerHeight,
        getPanelContainer,
        getPanels,
        getPanelByIndex,
        getPanelByName,
        selectTab,
        selectedTab,
        setPanel,
        target,
        resizer
    }
}

export { Tab, useTab };