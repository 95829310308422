import { https } from "../../../../../shared/utilities";

const ResourceService = () => {
    const request = https();
    const controller = "/resources";
    
    return { 
        getGender: async (data) => await request.get(controller + "/get-gender", data),
        getCountry: async (data) => await request.post(controller + "/get-country", data),
        getState: async (data) => await request.post(controller + "/get-state", data),
        getCity: async (data) => await request.post(controller + "/get-city", data),
    }
}

export { ResourceService }