import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "../../components/button/button.component";
import { BsArrowRight } from "react-icons/bs";
import { TextField } from "../../components/field/textfield.component";

const CodeVerifierWidget = ({ method, observer, onSubmit }) => {

    const [auth, setAuth] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: "",
        code: ""
    });

    const componentObserver = useRef(null);

    const notifier = useCallback((e) => {
        componentObserver.current({
            inProgress: e?.inProgress,
            errorOccurred: e?.errorOccurred,
            asyncMessage: e?.message
        });

        return {
            code: auth.code
        };
    }, [componentObserver, auth]);

    useEffect(() => {
        (async () => {
            observer.widgetobserver.current = await notifier;
        })();
    }, [auth, observer, onSubmit, notifier])

    const handleOnChange = (value) => {
        setAuth({
            code: value
        })
    }

    return (
        <form method={method} onSubmit={onSubmit} autoComplete={'false'} className={'needs-validation text-left'} noValidate>
            <div className={'flex-column'}>
                <div className={'margin-bottom-30px'}>
                    <h2 className={'text-33px line-height-36px text-bold'}>Code verification</h2>
                    <div className={'text-14px line-height-18px'}>
                        Enter the code sent to your email address and click on
                        the verify code button to reset your password.
                    </div>
                </div>

                {/* email field */}
                <div className={'w-100 m-0 mb-3'}>
                    <label className={'w-100 form-label align-left text-bold text-13px line-height-17px margin-bottom-8px'}>
                        Verification code:
                    </label>
                    <div className={'input-group has-validation'}>
                        <TextField attributes={{
                            onChange: handleOnChange,
                            readOnly: auth.inProgress,
                            placeholder: "Please enter the code that was sent to your email.",
                            id: "email-field",
                            className: "height-45px",
                            observer: componentObserver
                        }} />
                        <div className={'invalid-feedback'}>
                            Please enter the code that was sent to your email.
                        </div>
                    </div>
                </div>

                {/* submit button */}
                <div className={'w-100 m-0 mb-3'}>
                    <Button attributes={{
                        type: 'button',
                        text: 'Verify code',
                        icon: <BsArrowRight />,
                        className: 'w-100 text-center manrope-bold text-14px height-45px bg-blue-theme',
                        onClick: onSubmit,
                        showProgress: false,
                        isProgress: auth.isProgress,
                        observer: componentObserver
                    }} />
                </div>
            </div>
        </form>
    );
}
export default CodeVerifierWidget;