import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProductTypeService } from '../product.type.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditProductTypeHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [types, setTypes] = useState([]);
    const [type, setType] = useState({
        typeName: '',
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const typeService = ProductTypeService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);
        } else {
            form.clear();
        }
    }, [form])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setType({
            ...type,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await typeService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await typeService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }


    return {
        type: type,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        typeNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        typeNameField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for your type",
            id: "productTypeName",
            value: type?.typeName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        typeDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        typeDescriptionField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Type description",
            id: "productTypeDescription",
            isMultiline: true,
            value: type?.typeDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditProductTypeHook;