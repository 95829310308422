import React from 'react';
import useCatererCustomersTransactionsHook from './caterer.customers.transactions.hook';
import { DataGrid } from '../../../../../shared/components/datagrid';
import { Grid } from '../../../../../shared/components/grid/grid.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';

const CatererCustomersTransactionsView = ({ attributes }) => {

    const hook = useCatererCustomersTransactionsHook(attributes);

    return (
        <div className={"w-100 height-560px position-relative p-0 pt-0 pb-1"}>
            <div className={"position-absolute end-15px top-0px start-15px bottom-0px "}>
                {/* header view */}
                <div className={"position-absolute end-0px top-0px start-0px height-50px d-flex align-items-center ps-0 pe-0 height-50px bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme"}>
                    <Toolbar attributes={hook?.toolbarAttributes} />
                </div>

                {/* list of selected market datagrid view */}
                <div className={"position-absolute end-0px top-50px start-0px bottom-0px "}>
                    <DataGrid attributes={hook?.datagridAttributes} />
                </div>
            </div>
        </div >
    );
}

export default CatererCustomersTransactionsView;