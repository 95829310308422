import { useCallback, useEffect, useRef, useState } from 'react';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useForm } from '../../../../../shared/components/form/form.component';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { ProductBrandService } from '../../../configuration.settings/settings/product.brands/product.brand.service';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import { ProductService } from '../../../product.management/product/product.service';
import { MarketProductService } from '../market.products/market.product.service';

const useAddMarketProductHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;
    const customUIThemes = {
        // toolbarcollection
        toolbarCollection: "height-50px",
        toolbarCollectionWrapper: "d-flex align-items-center ps-0 pe-0 height-50px bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme",

        // toolbar
        toolbar: {

            // filter category
            filterCategoryMultiSelectDropDownField: {
                g: "height-34px min-width-100px justify-content-start border-1px border transparent-border-blue-dark-theme rounded-1",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-start",
                dropDown: "width-250px"
            },

            // filter brand
            filterBrandMultiSelectDropDownField: {
                g: "height-34px min-width-100px justify-content-start border-1px border transparent-border-blue-dark-theme rounded-1",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-start",
                dropDown: "width-250px"
            },

            // filter availability
            filterAvailabilityMultiSelectDropDownField: {
                g: "height-34px min-width-100px justify-content-start border-1px border transparent-border-blue-dark-theme rounded-1",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-start",
                dropDown: "width-200px"
            },
        },
    };

    // state objects
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [productCategories, setProductCategories] = useState([]);
    const [productBrands, setProductBrands] = useState([]);
    const [marketProducts, setMarketProducts] = useState([]);
    const [marketProduct, setMarketProduct] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const selectedMarketObserver = useRef(null);

    // components
    const productDatagridName = "add-market-product-list-datagrid";
    const datagrid = useDataGrid({
        name: productDatagridName
    });

    const addMarketProductForm = useForm({
        ref: reactiveForm
    });

    const pageToolbarName = "add-market-product-list-page-toolbar";
    const pageToolbar = useToolbar({
        name: pageToolbarName
    });

    // buttons
    const pageButtons = {
        filterButtonName: "add-market-product-filter-button",
        previousButtonName: "add-market-product-previous-button",
        pagesLabelName: "add-market-product-pages-label",
        nextButtonName: "add-market-product-next-button",
    }

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        const isEmptyField = addMarketProductForm.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [])

    const widgetObserver = useCallback(async (args) => {
        // set selected market
        setSelectedMarket(args?.data);

        selectedMarketObserver.current = args?.data;

        await getProductCategories("");
        await getProductBrands("");
        await getProducts({
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 10
        })

        // clear datagrid
        datagrid.clearSelection();
        addMarketProductForm.clear();

    }, [addMarketProductForm])

    // services
    const productCategoryService = ProductCategoryService();
    const productBrandService = ProductBrandService();
    const productService = ProductService();
    const marketProductService = MarketProductService();
    const productImageService = ProductImageService();

    const getProducts = useCallback(async (request) => {
        datagrid.clearSelection();

        await productService.getAll(request)
            .then(response => {
                datagrid.hideLoader();

                // populate datagrid 
                setMarketProducts(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                pageToolbar.button({
                    name: pageButtons.pagesButtonName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid.hideLoader();
            });

    }, []);

    const getProductCategories = useCallback(async (search) => {
        await productCategoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 5
        }).then(response => {
            setProductCategories(response.data.list);
        }).catch(error => {
            //
        });
    }, []);

    const getProductBrands = useCallback(async (search) => {
        await productBrandService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 5
        }).then(response => {
            setProductBrands(response.data.list);
        }).catch(error => {
            // 
        });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleCategorySearchFieldChange = async (e) => {
        getProductCategories(e.value);
    }

    const handleBrandSearchFieldChange = async (e) => {
        getProductBrands(e.value);
    }

    const handleSearchRecords = async (e, args) => {
        getProducts({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = async (e, args) => {
        if (pageNumber > 1)
            getProducts({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = async (e, args) => {
        if (pageNumber < totalPages)
            getProducts({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        args.data["marketId"] = selectedMarketObserver?.current?.marketId;
        await marketProductService.save(args?.data).then((response) => {
            getProducts({
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 10
            });

            args?.successful({
                data: args?.data,
                callback: args?.callback
            });
            args?.inProgress(false);
        }).catch((error) => {
            args?.error(error);
        });
    }

    return {
        reactiveForm: reactiveForm,

        // product category
        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapperNoPadding}>
                    <div className={"w-40"}>
                        <Toolbar attributes={{
                            items: [
                                {
                                    name: pageButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField200px,
                                    onClick: (e, args) => { handleSearchRecords(e, args) }
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-60 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: pageToolbarName,
                            items: [
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: pageButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: pageButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        datagridAttributes: {
            name: productDatagridName,
            formElement: true,
            validate: true,
            key: "productId",
            id: "productIds",
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["productId"],
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                    value: "media1Url",
                    default: "",
                    style: themes?.width35px,
                    image: {
                        id: "media1Id",
                        key: "media1Id",
                        url: "media1Url",
                        size: themes?.size35px,
                        leading: icons?.BrandingWatermarkRoundedIcon,
                        style: themes?.image,
                        config: {
                            downloadUrl: productImageService?.downloadUrl
                        },
                    }
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Product",
                    value: ["productName"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Category",
                    value: ["productCategory"],
                    default: "",
                    style: themes?.width100
                },
                {
                    title: "Brand",
                    value: ["productBrand"],
                    default: "",
                    style: themes?.width100
                },
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: marketProducts
        },

        themes: themes,
    }
}

export default useAddMarketProductHook;