import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { ContentService } from './content.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import MenuList from '../../../../shared/components/menu/menu.list.component';
import CreateOrEditContentView from './createoredit.content.view';

const useContentsHook = (attributes) => {

    // icons
    const icons = useIconsThemes()?.rounded;

    // theme
    const themes = usePrimaryUIThemes();
    themes["menuList"] = {
        item: "height-28px min-width-200px text-blue-theme rounded-2 cursor-pointer",
        leading: "icon-17px text-18px",
        trailing: "icon-17px text-18px",
        title: "text-13px pe-3 text-normal text-nowrap text-blue-theme line-height-22px",
        selection: "bg-light-theme"
    };

    // state objects
    const [contents, setContents] = useState([]);
    const [selectedContent, setSelectedContent] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No content selected!",
            message: "Please select an content from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New content",
            successMessage: {
                title: "Content created successfully!",
                message: "Content was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit content details",
            alertMessage: {
                title: "Multiple contents selected!",
                message: "Oops!, you selected multiple contents. Please select a content from the list to continue"
            },
            successMessage: {
                title: "Content updated successfully!",
                message: "Content was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit content details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these contents?" : "this content?")
                }
            },
            successMessage: {
                title: "Content deleted successfully!",
                message: "Content was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these contents?" : "this content?")
                }
            },
            successMessage: {
                title: "Content published successfully!",
                message: "Content was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these contents?" : "this content?")
                }
            },
            successMessage: {
                title: "Content published successfully!",
                message: "Content was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these contents?" : "this content?")
                }
            },
            successMessage: {
                title: "Content archived successfully!",
                message: "Content was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these contents?" : "this content?")
                }
            },
            successMessage: {
                title: "Content restored successfully!",
                message: "Content was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available contents, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditContentObserver = useRef();

    // components
    const datagridName = "content-contents-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "content-contents-left-pane-toolbar";
    const leftToolbarButtons = {
        addContentButtonName: "add-content-button",
        editContentButtonName: "edit-content-button",
        deleteContentButtonName: "delete-content-button",
        reloadContentButtonName: "reload-content-button",
    }

    const rightPaneToolbarName = "content-contents-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const contentService = ContentService();

    // dialogs
    const createOrEditContentDialogName = "createoredit-content-modal";
    const createOrEditContentDialog = useModalDialog({
        name: createOrEditContentDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getContents = useCallback(async (request) => {

        showPagingInfo(1, 1);

        datagrid?.showLoader("Fetching available contents, please wait...");
        datagrid.clearSelection();

        await contentService.getAll(request)
            .then(response => {
                // pageLoader?.close();

                // populate datagrid 
                setContents(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);

            })
            .catch(error => {
                // pageLoader?.close();
            });

    }, []);

    useEffect(() => {
        getContents({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch(error) {
            // ignore
        }
    }

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedContent({});

        // show dialog
        createOrEditContentDialog.show({
            title: "New content",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditContentObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple contents selected!",
                message: "Oops!, you selected multiple contents. Please select a content from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedContent = contents.find(content => content.contentId === datagrid.getSelectedRowKeyValue());
                setSelectedContent(selectedContent);

                createOrEditContentDialog.show({
                    title: "Edit content",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditContentObserver.current({
                    data: selectedContent,
                    callback: () => {
                        getContents({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No content selected!",
                    message: "Please select a content from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these contents?" : "this content?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    contentService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getContents({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No content selected!",
                message: "Please select a content from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these contents?" : "this content?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    contentService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getContents({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No content selected!",
                message: "Please select a content from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these contents?" : "this content?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    contentService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getContents({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No content selected!",
                message: "Please select a content from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these contents?" : "this content?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    contentService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getContents({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No content selected!",
                message: "Please select a content from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these contents?" : "this content?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    contentService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getContents({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No content selected!",
                message: "Please select a content from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getContents({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getContents({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getContents({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleAddContentToCategory = (e) => {
        // 
    }

    const handleSelectRecord = (e, args) => {
        datagrid.setSelectedRow(args.key);
        const selectedContent = contents.find(content => content.contentId === args.key);
        setSelectedContent(selectedContent);
    }

    const handleDatagridRowMenuItemClick = (args) => {
        switch (args.index) {
            case 7:
                //
                break;
            case 6:
                handleAddContentToCategory();
                break;
            case 5:
                handleRestoreArchive();
                break;
            case 4:
                handleArchive();
                break;
            case 3:
                handleUnpublish();
                break;
            case 2:
                handlePublish();
                break;
            case 1:
                handleDelete();
                break;
            case 0:
            default:
                handleEdit();
                break;
        }
    }

    return {
        componentObserver,
        contents,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add content",
                                        tooltip: "Create",
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: leftToolbarButtons.reloadContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.PublishRoundedIcon,
                                        onClick: (e) => handlePublish(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Unpublish",
                                        leading: icons?.VpnLockRoundedIcon,
                                        onClick: (e) => handleUnpublish(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: leftToolbarButtons.reloadContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DriveFolderUploadRoundedIcon,
                                        onClick: (e) => handleArchive(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Restore",
                                        leading: icons?.ReplyRoundedIcon,
                                        onClick: (e) => handleRestoreArchive(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    // {
                                    //     name: rightToolbarButtons.label,
                                    //     type: TOOLBARITEMTYPE.LABEL,
                                    //     menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    //     text: "25 of 1200 records",
                                    //     trailing: icons?.FilterAltRoundedIcon,
                                    //     observer: componentObserver,
                                    //     style: themes?.toolbar?.label?.label13
                                    // },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["contentId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["contentId"],
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.TOOLBAR,
                    value: ["contentId"],
                    style: themes?.width30px,
                    items: [
                        {
                            type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                            menuPosition: MENUALIGNMENTTYPE.BOTTOMLEFT,
                            leading: icons?.MoreVertRoundedIcon,
                            onClick: (e, args) => { handleSelectRecord(e, args) },
                            style: themes?.toolbar?.iconButton,
                            widget: <MenuList attributes={{
                                items: [
                                    {
                                        leading: icons?.EditRoundedIcon,
                                        title: "Browse content",
                                    },
                                    {
                                        leading: icons?.DeleteRoundedIcon,
                                        title: "Delete",
                                    },
                                    {
                                        type: MENUITEMTYPE.SEPARATOR,
                                    },
                                    {
                                        leading: icons?.PublishRoundedIcon,
                                        title: "Publish",
                                    },
                                    {
                                        leading: null,
                                        title: "Unpublish",
                                    },
                                    {
                                        type: MENUITEMTYPE.SEPARATOR,
                                    },
                                    {
                                        leading: icons?.DriveFolderUploadRoundedIcon,
                                        title: "Archive",
                                    },
                                    {
                                        leading: icons?.ReplyRoundedIcon,
                                        title: "Restore",
                                    },
                                    {
                                        type: MENUITEMTYPE.SEPARATOR,
                                    },
                                    {
                                        leading: icons?.AddRoundedIcon,
                                        title: "Add a new content",
                                    }
                                ],
                                style: themes?.menuList,
                                onItemClick: handleDatagridRowMenuItemClick,
                                isSelectable: false
                            }} />
                        },
                    ]
                },
                {
                    title: "Title",
                    value: ["contentTitle"],
                    default: "",
                    style: themes?.width100
                },
                {
                    title: "Category",
                    value: ["parentContentName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    type: COLUMNTYPE.TOOLBAR,
                    value: ["contentId"],
                    style: themes?.width70px,
                    items: [
                        {
                            type: TOOLBARITEMTYPE.BUTTON,
                            menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                            leading: icons?.VpnLockRoundedIcon,
                            onClick: (e, args) => { handleSelectRecord(e, args) },
                            style: themes?.toolbar?.iconButton
                        },
                        {
                            type: TOOLBARITEMTYPE.BUTTON,
                            menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                            leading: icons?.LockRoundedIcon,
                            onClick: (e, args) => { handleSelectRecord(e, args) },
                            style: themes?.toolbar?.iconButton
                        },
                    ]
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Author",
                    value: ["author"],
                    default: "",
                    style: themes?.width60
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.DATETIME,
                    title: "Date",
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            showPaneOnAction: true,
            paneVisibleColumns: [0, 1, 2, 8],
            pane: {
                isVisible: true,
                widget: CreateOrEditContentView,
                // closeButton: {
                //     leading: icons?.ClearRoundedIcon,
                //     style: themes?.toolbar?.flatIconButton
                // }
            },
            dataset: contents
        }} />,

        createOrEditDialog: {
            name: createOrEditContentDialogName,
            data: selectedContent,
            observer: createOrEditContentObserver,
            widget: CreateOrEditContentView,
            style: {
                window: "max-width-530px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getContents({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useContentsHook;