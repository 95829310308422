import React from 'react';
import useCreateOrEditMarketTypeHook from './createoredit.market.type.hook';
import Label from '../../../../../../shared/components/label/label.component';
import { MultiTextField, TextField } from '../../../../../../shared/components/field';

const CreateOrEditMarketTypeView = ({ attributes }) => {

    const hook = useCreateOrEditMarketTypeHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0"}>
            <div className={"w-100 height-280px position-relative "}>
                <form ref={hook?.reactiveForm} method={attributes.method} autoComplete={"false"} className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>
                        {/* name field */}
                        <div className={"w-100 m-0 mb-4"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.typeNameLabel} />
                            </div>
                            <TextField
                                attributes={hook?.typeNameField} />
                        </div>

                        {/* description field */}
                        <div className={"w-100 m-0"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.typeDescriptionLabel} />
                            </div>
                            <MultiTextField
                                attributes={hook?.typeDescriptionField} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateOrEditMarketTypeView;