import React, { useRef } from "react";

const HorizontalSpace = ({ attributes }) => {

    const componentObserver = useRef();

    return (
        <div ref={componentObserver} className={"w-100 height-6px position-absolute end-0px start-0px z-index-0"}>
            <div className={"position-absolute top-0px start-0px bottom-0px end-0px bg-blue-light-theme"}></div>
        </div>
    );
}
export default HorizontalSpace;