import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Avatar } from '../../../../../shared/components/avatar';
import { CalloutWindow } from '../../../../../shared/components/callout/callout.window.component';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, PAGEDIRECTION, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import useFlags from '../../../../../shared/hooks/flags.hook';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import AddMarketProductView from '../add.market.product/add.market.product.view';
import ManageMarketProductListView from '../manage.market.product.list/manage.market.product.list.view';
import MarketProductDetailsView from '../market.product.details/market.product.details.view';
import { MarketService } from '../market.service';
import { MarketProductService } from './market.product.service';
import CreateOrEditMarketProductView from '../createoredit.market.product/createoredit.market.product.view';
import global from '../../../../../shared/helpers/global.variable.helper';

const useMarketProductsHook = (attributes) => {

    const appConfig = useAppConfig();

    // theme
    const icons = useIconsThemes().rounded;
    const themes = usePrimaryUIThemes();

    // flags
    const flags = useFlags();

    // state objects
    const [selectedMarket, setSelectedMarket] = useState({});
    const [marketProducts, setMarketProducts] = useState([]);
    // const [selectedMarketProduct, setSelectedMarketProduct] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // const [categories, setCategories] = useState([]);


    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No product selected!",
            message: "Please select an market from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New market",
            successMessage: {
                title: "Market created successfully!",
                message: "Market was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit market details",
            alertMessage: {
                title: "Multiple markets selected!",
                message: "Oops!, you selected multiple markets. Please select a market from the list to continue"
            },
            successMessage: {
                title: "Market updated successfully!",
                message: "Market was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit market details",
            confirmMessage: (count) => {
                return {
                    title: (marketProductDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market deleted successfully!",
                message: "Market was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (marketProductDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market published successfully!",
                message: "Market was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (marketProductDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market published successfully!",
                message: "Market was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (marketProductDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market archived successfully!",
                message: "Market was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (marketProductDatagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these markets?" : "this market?")
                }
            },
            successMessage: {
                title: "Market restored successfully!",
                message: "Market was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available markets, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const addMarketProductsObserver = useRef();
    const marketLogoObserver = useRef();
    const manageSelectedMarketProductCalloutWindowObserver = useRef();
    const selectedMarketProductInventoryItemCalloutWindowObserver = useRef();
    const totalRowsCount = useRef(0);
    const lastRowsCount = useRef(0);

    // components
    const stackablePanelComponentName = "market-products-stackable-panel";
    const marketProductDatagridName = "products-datagrid";
    const manageSelectedMarketProductCalloutWindowName = "manage-market-product-callout-window";
    const selectedMarketProductInventoryItemCalloutWindowName = "selected-market-product-inventory-item-callout-window";

    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const marketProductDatagrid = useDataGrid({
        name: marketProductDatagridName
    });

    const leftToolbarButtons = {
        addProductButtonName: "add-market-product-button",
        editProductButtonName: "edit-market-product-button",
        deleteProductButtonName: "delete-market-product-button"
    }

    const rightPaneToolbarName = "products-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // dialogs
    const addMarketProductDialogName = "add-market-product-modal";

    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();
    const addMarketProductDialog = useModalDialog({
        name: addMarketProductDialogName
    });

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();

    // services
    const marketService = MarketService();
    const marketProductService = MarketProductService();
    const categoryService = ProductCategoryService();
    const productImageService = ProductImageService();

    const getMarketProducts = useCallback(async (request) => {
        pageLoader.show();
        await marketProductService.getMarketInventory(request)
            .then(response => {
                pageLoader.close();
                marketProductDatagrid.hideLoader();

                // showing rows
                if (request.direction === PAGEDIRECTION.PREVIOUS) {
                    totalRowsCount.current -= lastRowsCount.current;
                } else if (request.direction === PAGEDIRECTION.NEXT) {
                    totalRowsCount.current += response?.data?.list?.length;
                } else {
                    totalRowsCount.current = response?.data?.list?.length;
                }

                // populate datagrid 
                setMarketProducts(response?.data?.list);
                lastRowsCount.current = response?.data?.list?.length;

                // set page
                setQueryString(request?.queryString);
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);
            })
            .catch(error => {
                pageLoader.close();
                marketProductDatagrid.hideLoader();
            });

    }, []);

    // const getCategories = useCallback(async (request) => {
    //     await categoryService.getAll(request)
    //         .then(response => {
    //             setCategories(response?.data?.list);
    //         })
    //         .catch(error => {
    //             //
    //         });
    // }, []);

    const widgetObserver = useCallback(async (args) => {
        stackablePanelComponent.setPanel(1);

        // productsComponentObserver.current(args);

        // load image
        marketLogoObserver.current.loadImage(args?.data);

        if (args?.data !== null) {

            // selected market
            setSelectedMarket(args?.data);

            // get products in market
            await getMarketProducts({
                marketId: args?.data?.marketId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: rowsPerPage,
                sort: {}
            });

            // // get product categories
            // getCategories({
            //     queryString: "",
            //     pageNumber: 1,
            //     rowsPerPage: 5
            // });

        } else {
            // form.clear();
        }
    }, []);

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;
        })();
    }, []);


    const handleAddProductsToMarket = (e) => {
        // // show dialog
        // addMarketProductDialog.show({
        //     title: "Available Products",
        //     showCloseButton: true,
        //     isNew: true
        // });

        // // listen to dialog for changes
        // addMarketProductsObserver.current({
        //     data: selectedMarket,
        //     callback: () => {
        //         getMarketProducts({
        //             marketId: selectedMarket?.marketId,
        //             queryString: queryString,
        //             pageNumber: pageNumber,
        //             rowsPerPage: rowsPerPage
        //         });
        //     }
        // });

        addMarketProductsObserver.current.show({
            title: "Available products",
            data: selectedMarket,
            isNew: false,
            callback: () => {
                //
            }
        });
    }

    const handleDeleteProductFromMarketInventory = (e) => {
        if (marketProductDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (marketProductDatagrid.selectedRowsCount() + ((marketProductDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((marketProductDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    marketProductService.removeProductFromMarketInventory(marketProductDatagrid.getSelectedRowsKeyValue()).then(response => {
                        getMarketProducts({
                            marketId: selectedMarket?.marketId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                        toastr.success("Product successfully removed from market inventory list");
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleSearchRecords = async (e, args) => {
        await getMarketProducts({
            marketId: selectedMarket?.marketId,
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage,
                direction: PAGEDIRECTION.PREVIOUS
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage,
                direction: PAGEDIRECTION.NEXT
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
    }

    const handlePageNumberChange = (e, args) => {
        getMarketProducts({
            marketId: selectedMarket?.marketId,
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleSelectedMarketProductUpdate = async (args) => {
        await marketProductService.update(args).then(async (response) => {
            // await getMarketProducts({
            //     marketId: selectedMarket?.marketId,
            //     queryString: queryString,
            //     pageNumber: pageNumber,
            //     rowsPerPage: rowsPerPage
            // });
        }).catch((error) => {
            // args?.error(error);
        });
    }

    const handleDatagridRowSelected = async (args, e) => {
        e.cancelBubble = false;
        e.stopPropagation();

        // show product details callout
        selectedMarketProductInventoryItemCalloutWindowObserver.current.show({
            title: args?.selectedRow?.productName,
            data: args,
            isNew: false
        });
    }

    return {

        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                <div className={"w-100 position-absolute end-0px top-50px start-0px bottom-0px "}>

                                </div>
                            </div>
                        );
                    })()
                },
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                {/* add market product */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: addMarketProductDialogName,
                                    data: null,
                                    observer: addMarketProductsObserver,
                                    widget: AddMarketProductView,
                                    callback: (args) => {
                                        getMarketProducts({
                                            marketId: selectedMarket?.marketId,
                                            queryString: queryString,
                                            pageNumber: pageNumber,
                                            rowsPerPage: rowsPerPage
                                        });
                                        pageLoader.hide();
                                        toastr.success("Product successfully removed from market inventory list");
                                    },
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.UNDOCK,
                                    style: {
                                        window: "max-width-710px"
                                    },
                                    buttons: [
                                        {
                                            text: "Cancel",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => winArgs.close(e)
                                        },
                                        {
                                            text: "Apply",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => {
                                                winArgs.apply(e, async (args) => {
                                                    await getMarketProducts({
                                                        marketId: selectedMarket?.marketId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                });
                                            }
                                        },
                                        {
                                            text: "Continue",
                                            style: themes?.button?.primary,
                                            onClick: (e, winArgs) => {
                                                winArgs.submit(e, async (args) => {
                                                    await getMarketProducts({
                                                        marketId: selectedMarket?.marketId,
                                                        queryString: queryString,
                                                        pageNumber: pageNumber,
                                                        rowsPerPage: rowsPerPage
                                                    });
                                                });
                                            }
                                        }
                                    ]
                                }} />

                                {/* manage market product list */}
                                <CalloutWindow attributes={{
                                    title: messages?.handleCreate?.dialogTitle,
                                    name: manageSelectedMarketProductCalloutWindowName,
                                    data: selectedMarket,
                                    observer: manageSelectedMarketProductCalloutWindowObserver,
                                    widget: ManageMarketProductListView,
                                    callback: (args) => {
                                        getMarketProducts({
                                            marketId: selectedMarket?.marketId,
                                            queryString: queryString,
                                            pageNumber: pageNumber,
                                            rowsPerPage: rowsPerPage
                                        });
                                        pageLoader.hide();
                                        toastr.success("Product successfully removed from market inventory list");
                                    },
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.UNDOCK,
                                    style: {
                                        window: "max-width-510px"
                                    }
                                }} />

                                {/* market product inventory item details */}
                                <CalloutWindow attributes={{
                                    name: selectedMarketProductInventoryItemCalloutWindowName,
                                    data: null,
                                    observer: selectedMarketProductInventoryItemCalloutWindowObserver,
                                    widget: CreateOrEditMarketProductView,
                                    slideDirection: SLIDEDIRECTION.LEFT,
                                    dockType: DOCKTYPE.FILL,
                                    style: {
                                        window: "max-width-500px"
                                    },
                                    buttons: [
                                        {
                                            text: "Save changes",
                                            style: themes?.button?.primary,
                                            onClick: (e, winArgs) => {
                                                winArgs.submit(e, args => {
                                                    pageLoader.close();
                                                    // getProducts({
                                                    //     queryString: productQueryString,
                                                    //     pageNumber: productPageNumber,
                                                    //     rowsPerPage: productRowsPerPage
                                                    // });

                                                    // setSelectedProduct({});
                                                    // createOrEditProductObserver.current.close();
                                                });
                                            }
                                        },
                                        {
                                            text: "Cancel",
                                            style: themes?.button?.secondary,
                                            onClick: (e, winArgs) => winArgs.close(e)
                                        }
                                    ]
                                }} />

                                {/* header view */}
                                <div className={themes?.toolbarCollectionWrapperRightPadding}>
                                    <div className={"w-50 d-flex justify-content-start align-items-center mb-1 text-truncate"}>
                                        <div className={"max-width-38px min-width-38px height-38px position-relative me-2"}>
                                            <Avatar attributes={{
                                                name: "caterer-product-logo",
                                                formElement: true,
                                                validate: true,
                                                id: "mediaId",
                                                key: "mediaId",
                                                url: "mediaUrl",
                                                size: themes?.sizeAbsolute,
                                                leading: icons?.PhotoSizeSelectActualIcon,
                                                style: themes?.image38,
                                                model: selectedMarket,
                                                observer: marketLogoObserver,
                                                config: {
                                                    ownerId: appConfig?.sessionAuthDetails?.userId,
                                                    uploadUrl: marketService?.uploadUrl,
                                                    downloadUrl: marketService?.downloadUrl
                                                }
                                            }} />
                                        </div>
                                        <div className={"mt-1"}>
                                            <div className={"d-flex align-items-center text-blue-theme text-13px text-bold mb-1 max-width-200px-"}>
                                                <span className={"me-2 text-truncate line-height-14px height-15px"}>{selectedMarket?.marketName}</span>
                                            </div>
                                            <div className={"d-flex text-12px m-0 opacity-8 text-truncate"}>
                                                <div className={"d-flex align-items-center justify-content-start text-12px me-3 text-truncate"}>
                                                    <span className={"d-flex text-blue-theme text-truncate w-100 me-1"}>Showing {totalRowsCount.current} of {totalRows} inventory items</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"w-70 d-flex justify-content-end"}>
                                        <Toolbar attributes={{
                                            name: rightPaneToolbarName,
                                            items: [
                                                {
                                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                                    name: rightToolbarButtons.filterButtonName,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.SearchRoundedIcon,
                                                    placeholder: "Find a product...",
                                                    trailing: icons?.ArrowForwardRoundedIcon,
                                                    style: themes?.toolbar?.searchField,
                                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: leftToolbarButtons.addProductButtonName,
                                                    type: TOOLBARITEMTYPE.BUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.AddRoundedIcon,
                                                    onClick: handleAddProductsToMarket,
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.button
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: leftToolbarButtons.deleteProductButtonName,
                                                    type: TOOLBARITEMTYPE.BUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.EditRoundedIcon,
                                                    onClick: handleDeleteProductFromMarketInventory,
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.button,
                                                },
                                                {
                                                    type: TOOLBARITEMTYPE.SPACE,
                                                    style: themes?.toolbar?.space?.space4
                                                },
                                                {
                                                    name: leftToolbarButtons.deleteProductButtonName,
                                                    type: TOOLBARITEMTYPE.BUTTON,
                                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                                    leading: icons?.DeleteRoundedIcon,
                                                    onClick: handleDeleteProductFromMarketInventory,
                                                    observer: componentObserver,
                                                    style: themes?.toolbar?.button,
                                                },
                                            ]
                                        }} />
                                    </div>
                                </div>

                                {/* datagrid view market product inventory */}
                                <div className={"position-absolute end-15px top-50px start-0px bottom-0px "}>
                                    <DataGrid attributes={{
                                        name: marketProductDatagridName,
                                        key: "marketProductInventoryId",
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: "marketProductInventoryId",
                                                style: themes?.width30px
                                            },
                                            {
                                                title: "Variation",
                                                value: "productVariation",
                                                default: "",
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                value: "media1Url",
                                                default: "",
                                                style: themes?.width35px,
                                                image: {
                                                    id: "media1Id",
                                                    key: "media1Id",
                                                    url: "media1Url",
                                                    size: themes?.size35px,
                                                    leading: icons?.BrandingWatermarkRoundedIcon,
                                                    style: themes?.image,
                                                    config: {
                                                        downloadUrl: productImageService?.downloadUrl
                                                    },
                                                }
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                title: "Product",
                                                value: "productName",
                                                default: "",
                                                style: themes?.width100
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.TEXTFIELD,
                                                title: "Price",
                                                key: "price",
                                                value: "priceAlias",
                                                default: "",
                                                textAlign: ALIGNMENTTYPE.RIGHT,
                                                style: themes?.width70px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Color",
                                                value: "productColor",
                                                default: "",
                                                style: themes?.width70px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Type",
                                                value: "productType",
                                                default: "",
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.FLAG,
                                                default: "",
                                                style: themes?.width30px,
                                                flags: flags?.marketProductAvailabilityStatusFlags,
                                                label: "title",
                                                value: "availabilityStatus"
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            // {
                                            //     type: COLUMNTYPE.TOOLBAR,
                                            //     style: themes?.width40px,
                                            //     items: [
                                            //         {
                                            //             name: leftToolbarButtons.addProductButtonName,
                                            //             type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                            //             menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                            //             leading: icons?.MoreHorizIcon,
                                            //             onClick: () => { },
                                            //             observer: componentObserver,
                                            //             style: themes?.toolbar?.iconButton,
                                            //             widget: <MenuList attributes={{
                                            //                 style: themes?.menuList,
                                            //                 items: [
                                            //                     {
                                            //                         name: flags.marketProductAvailabilityStatusFlags[0]?.name,
                                            //                         leading: icons.CollectionsIcon,
                                            //                         title: flags.marketProductAvailabilityStatusFlags[0]?.label
                                            //                     },
                                            //                     {
                                            //                         name: flags.marketProductAvailabilityStatusFlags[1]?.name,
                                            //                         leading: icons.CollectionsIcon,
                                            //                         title: flags.marketProductAvailabilityStatusFlags[1]?.label
                                            //                     },
                                            //                     {
                                            //                         name: flags.marketProductAvailabilityStatusFlags[2]?.name,
                                            //                         leading: icons.CollectionsIcon,
                                            //                         title: flags.marketProductAvailabilityStatusFlags[2]?.label
                                            //                     },
                                            //                     {
                                            //                         name: flags.marketProductAvailabilityStatusFlags[3]?.name,
                                            //                         leading: icons.CollectionsIcon,
                                            //                         title: flags.marketProductAvailabilityStatusFlags[3]?.label
                                            //                     }
                                            //                 ],
                                            //                 onItemClick: handleMarketProductAvailabilityStatusMenuItemClick,
                                            //             }} />
                                            //         },
                                            //     ]
                                            // },
                                            // {
                                            //     default: "",
                                            //     style: themes?.width5px
                                            // },
                                        ],
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onHomeClick: handleFirstPage,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        onRowColumnCellLostFocus: handleSelectedMarketProductUpdate,
                                        onRowDoubleClick: handleDatagridRowSelected,
                                        dataset: marketProducts
                                    }} />
                                </div>
                            </div>
                        );
                    })()
                },
            ]
        },

    };
}

export default useMarketProductsHook;