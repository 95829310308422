import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LoginView from '../components/login/login.view';
import ForgotPasswordView from '../components/forgot.password/forgot.password.view';
import VerifyCodeComponent from '../components/forgot.password/verify.code.view';
import RegisterView from '../components/account.signup/account.signup.view';
import AppCore from '../components/app.core/app.core.view';

const LayoutComponent = () => {
    return (
        <div>
            <Routes>
                <Route path='/*' element={<LoginView />} />
                <Route path='/reset-password' element={<ForgotPasswordView />} />
                <Route path='/verify-code' element={<VerifyCodeComponent />} />
                <Route path='/register' element={<RegisterView />} />
                <Route path='/start/*' element={<AppCore />} />
            </Routes>
        </div>
    );
}

export default LayoutComponent;
