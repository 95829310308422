const ALIGNMENTTYPE = {
    LEFT: "left",
    TOP: "top",
    RIGHT: "right",
    BOTTOM: "bottom",
}

const POSITIONTYPE = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical"
}

const ARROWPOSITIONTYPE = {
    SIDEBUTTON: "side-button",
    PAGINATION: "pagination"
}

const VIEWTYPETYPE = {
    SINGLEVIEW: 0,
    CAROUSELVIEW: 1
}

const SLIDERTYPETYPE = {
    SLIDER360: "360-view",
    SIDEARROWS: "side-arrows",
    TOPRIGHTARROWS: "top-right-arrows",
    BOTTOMARROWS: "bottom-arrows"
}

const CAROUSELTYPETYPE = {
    SLIDABLE: "slidable",
    SIDEARROWS: "side-arrows",
    CARDABLE: "cardable"
}

const TOOLBARITEMTYPE = {
    BUTTON: "button",
    PAGEFIELDBUTTON: "page-field-button",
    TOGGGLEBUTTON: "toggle-button",
    DROPDOWNBUTTON: "dropdown-button",
    DROPDOWNMENUBUTTON: "dropdown-menu-button",
    SELECTABLEDROPDOWNLISTFIELD: "selectable-dropdown-list-field",
    SELECTABLEDROPDOWNLISTBUTTON: "selectable-dropdown-list-button",
    LABEL: "label",
    LABELLEDICON: "labellled-icon",
    TEXTFIELD: "text-field",
    SEARCHFIELD: "search-field",
    SPACE: "space-field",
    SEPARATOR: "separator-field",
    UPLOADBUTTON: "upload-button"
}

const COLUMNTYPE = {
    AUTO: "auto",
    BUTTON: "button",
    CHECKBOX: "checkbox",
    READONLYCHECKBOX: "readonly-checkbox",
    DATETIME: "datetime",
    DROPDOWNLIST: "dropdown-list",
    LABEL: "label",
    TEXTFIELD: "text-field",
    VIDEOTHUMBNAIL: "video-thumbnail",
    IMAGETHUMBNAIL: "image-thumbnail",
    ICON: "icon",
    INDICATOR: "indicator",
    FLAG: "flag",
    STATUS: "status",
    TOOLBAR: "toolbar"
}

const COMPONENTYPE = {
    AUTO: "auto",
    BUTTON: "button",
    CHECKBOX: "checkbox",
    DATAGRID: "datagrid",
    DATETIME: "datetime",
    DROPDOWNLIST: "dropdown-list",
    IMAGETHUMBNAIL: "image-thumbnail",
    INDICATOR: "indicator",
    LABEL: "label",
    READONLYCHECKBOX: "readonly-checkbox",
    STATUS: "status",
    SINGLESELECTDROPDOWNFIELD: "single-select-dropdown-field",
    DOBSELECTDROPDOWNFIELD: "dob-select-dropdown-field",
    TEXTFIELD: "text-field",
    TOKENBAR: "tokenbar",
    TOOLBAR: "toolbar",
    VIDEOTHUMBNAIL: "video-thumbnail"
}

const DOCKTYPE = {
    FILL: "dock-fill",
    LEFT: "dock-left",
    RIGHT: "dock-right",
    TOP: "dock-top",
    BOTTOM: "dock-bottom",
    UNDOCK: "undock"
}

const GRIDPANE = {
    LEFT: "dock-left",
    RIGHT: "dock-right",
    NONE: "dock-none",
}

const PAGEDIRECTION = {
    NEXT: "next",
    PREVIOUS: "previous",
}

const SLIDEDIRECTION = {
    LEFT: "slide-left",
    RIGHT: "slide-right",
    TOP: "slide-top",
    TOPLEFT: "slide-top-left",
    TOPRIGHT: "slide-top-right",
    BOTTOM: "slide-bottom",
    BOTTOMLEFT: "slide-bottom-left",
    BOTTOMRIGHT: "slide-bottom-right",
}

const MENUITEMTYPE = {
    MENUITEM: "menu-item",
    SEPARATOR: "separator",
    LABEL: "label",
    TEXTFIELD: "text-field",
    TOOLBAR: "toolbar"
}
const MENUALIGNMENTTYPE = {
    TOPLEFT: "top-left",
    TOPRIGHT: "top-right",
    BOTTOMLEFT: "bottom-left",
    BOTTOMRIGHT: "bottom-right",
}

const TABLEDESIGNS = {
    DEFAULT: "",
    STRIPEDROW: "table-striped",
    STRIPEDCOLUMN: "table-striped-columns",
    BORDERED: "table-bordered",
    BORDERLESS: "table-borderless",
    SMALLTABLE: "table-sm",
    NONE: "none",
}

const COLUMNSTATUSFLAG = {
    STOPPED: 0,
    RUNNING: 1,
}

const STATE = {
    STOPPED: 0,
    RUNNING: 1,
}

const MODALACTION = {
    APPLY: 0,
    CANCEL: 1,
    SAVE: 2,
    SUBMIT: 3,
}

const SORTENUM = {
    ASCENDING: "asc",
    DESCENDING: "desc",
}

const GRIDVIEWTYPE = {
    TABLE: "table",
    CARD: "card",
}

const ACTIONSTATUS = {
    SUCCESS: 0,
    ERROR: 1,
    INFO: 2,
    WARNING: 3,
}

export {
    ALIGNMENTTYPE,
    ARROWPOSITIONTYPE,
    CAROUSELTYPETYPE,
    COLUMNSTATUSFLAG,
    COLUMNTYPE,
    COMPONENTYPE,
    DOCKTYPE,
    GRIDPANE,
    MENUITEMTYPE,
    MENUALIGNMENTTYPE,
    POSITIONTYPE,
    SLIDERTYPETYPE,
    STATE,
    SORTENUM,
    TABLEDESIGNS,
    TOOLBARITEMTYPE,
    VIEWTYPETYPE,
    MODALACTION,
    GRIDVIEWTYPE,
    SLIDEDIRECTION,
    ACTIONSTATUS,
    PAGEDIRECTION
}