import React, { useCallback, useEffect, useRef, useState } from "react";
import { ALIGNMENTTYPE, MENUALIGNMENTTYPE } from "../../enums/enums";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import MenuList from "../menu/menu.list.component";

const DropDownMenuButton = ({ attributes }) => {
    const [button, setProperties] = useState({
        inProgress: false,
        message: ""
    });

    const componentObserver = useRef();

    const collapseAll = useCallback((e) => {
        try {
            [...componentObserver.current.querySelectorAll("*[aria-owns=\"dropdown\"]")].forEach(item => {
                item.classList.add("hide");
            });
        } catch (e) { }
    }, []);

    useEffect(() => {
        (async () => {
            if (attributes.observer)
                attributes.observer.current = await notifier;

            document.body.addEventListener("click", collapseAll);

            // garbage collection
            return () => {
                document.body.removeEventListener("click", collapseAll);
            }
        })();
    }, [])

    const notifier = (e) => {
        setProperties({
            inProgress: e.inProgress,
            message: e.message
        });
    }

    const getMenuAlignment = (e) => {
        switch (e) {
            case MENUALIGNMENTTYPE.TOPLEFT:
                return "top-37px start-0px";
            case MENUALIGNMENTTYPE.TOPRIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.BOTTOMRIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.BOTTOMLEFT:
            default:
                return "top-37px start-0px";
        }
    }

    const handleOnClick = (e) => {
        const dropdown = componentObserver.current.querySelector("*[aria-owns=\"dropdown\"]");
        if (dropdown.classList.contains("hide")) {
            collapseAll(e);
            dropdown.classList.remove("hide");
        } else {
            dropdown.classList.add("hide");
        }
        e.stopPropagation();


        e.stopPropagation();
    }

    const handleDropdownStopPropagation = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();
    }

    return (
        <div id={attributes?.name} ref={componentObserver} className={"d-flex position-relative"}>
            <button key={attributes?.name + "-dropdown-button"} type={attributes?.type} className={"d-flex justify-content-start align-items-center btn border-0px- " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.g) ? attributes?.style?.g : "shadow-sm- border-0px height-34px") + " " + ((attributes?.style?.bgColor) ? attributes?.style?.bgColor : "btn-grey-theme"))} onClick={handleOnClick}>
                <div className={"w-100 padding-end-16px"}>
                    <div className={"position-relative d-flex justify-content-center align-items-center"}>
                        <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-1 pe-2" : " ps-0 pe-0")}>
                            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-1 " : "") + " icon " + attributes?.style?.leading}>
                                {attributes?.leading}
                            </i>
                            <span aria-controls="label" className={"white-space-nowrap " + (attributes?.style?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.text}</span>
                            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                                {button?.trailing}
                            </i>
                        </div>
                        <div className={"position-absolute top-0px end-0px bottom-0px margin-auto d-flex icon icon-20px " + ((attributes.showProgress) ? "show" : "hide")}>
                            <div className={"spinner-border icon icon-20px " + ((attributes.showProgress || attributes.inProgress) ? "show" : "hide")} role="status">
                                <span className={"visually-hidden"}>Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"width-20px border border-top-0px border-bottom-0px border-end-0px position-absolute top-2px bottom-2px end-3px"}>
                    <BiChevronDown />
                </div>
            </button>

            <div onClick={handleDropdownStopPropagation} aria-owns="dropdown" className={"select-dropdown-menu hide position-absolute z-index-10 min-width-100- " + getMenuAlignment(attributes?.menuPosition)}>
                <div className={"bg-white border border-1px transparent-border-blue-dark-theme shadow padding-8px pt-1 pb-1 rounded-3 w-100 min-width-100px min-height-50px display-inline-block float-start"}>
                    {
                        (() => {
                            if (typeof (attributes?.widget) == "function") {
                                return attributes?.widget({
                                    attributes: {}
                                });
                            } else {
                                return attributes?.widget
                            }
                        })()
                    }
                </div>
            </div>
        </div>
    );
}
export default DropDownMenuButton;