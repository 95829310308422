import React from 'react';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import useOrdersHook from './orders.hook';

const OrdersView = () => {

    const hook = useOrdersHook();

    return (
        <div>
            {hook?.toolbar}
            {hook?.gridArea}

            {/* create or edit market modal */}
            <ModalDialog attributes={hook?.createOrEditDialog} />
        </div >
    );

}

export default OrdersView;