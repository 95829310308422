import React, { useCallback, useEffect, useRef, useState } from 'react';
import { StateService } from './state.service';
import CreateOrEditStateView from './createoredit.state/createoredit.state.view';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../../shared/components/datagrid';
import { Toolbar, useToolbar } from '../../../../../../shared/components/toolbar/toolbar.component';
import { useModalDialog } from '../../../../../../shared/components/dialog/modal.dialog.component';
import { useAlertDialog } from '../../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../../../shared/components/loader/page.loader.component';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../../shared/enums/enums';

const useStatesHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [states, setStates] = useState([]);
    const [selectedState, setSelectedState] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No state selected!",
            message: "Please select an state from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New state",
            successMessage: {
                title: "State created successfully!",
                message: "State was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit state details",
            alertMessage: {
                title: "Multiple states selected!",
                message: "Oops!, you selected multiple states. Please select a state from the list to continue"
            },
            successMessage: {
                title: "State updated successfully!",
                message: "State was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit state details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these states?" : "this state?")
                }
            },
            successMessage: {
                title: "State deleted successfully!",
                message: "State was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these states?" : "this state?")
                }
            },
            successMessage: {
                title: "State published successfully!",
                message: "State was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these states?" : "this state?")
                }
            },
            successMessage: {
                title: "State published successfully!",
                message: "State was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these states?" : "this state?")
                }
            },
            successMessage: {
                title: "State archived successfully!",
                message: "State was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these states?" : "this state?")
                }
            },
            successMessage: {
                title: "State restored successfully!",
                message: "State was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available states, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditStateObserver = useRef();
    const stateDetailsViewObserver = useRef();

    // components
    const datagridName = "state-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "state-left-pane-toolbar";
    const leftToolbarButtons = {
        addStateButtonName: "add-state-button",
        editStateButtonName: "edit-state-button",
        deleteStateButtonName: "delete-state-button",
        reloadStateButtonName: "reload-state-button",
    }

    const rightPaneToolbarName = "state-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // dialogs
    const createOrEditStateDialogName = "createoredit-state-modal";
    const createOrEditStateDialog = useModalDialog({
        name: createOrEditStateDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const stateService = StateService();

    const getState = useCallback(async (request) => {

        showPagingInfo(1, 1);

        datagrid?.showLoader("Fetching available state, please wait...");
        datagrid?.clearSelection();

        await stateService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setStates(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);
            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getState({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch(error) {
            // ignore
        }
    }

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedState({});

        // show dialog
        createOrEditStateDialog.show({
            title: "New state",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditStateObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple state selected!",
                message: "Oops!, you selected multiple state. Please select a state from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedState = states.find(state => state.stateId === datagrid.getSelectedRowKeyValue());
                setSelectedState(selectedState);

                createOrEditStateDialog.show({
                    title: "Edit state",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditStateObserver.current({
                    data: selectedState,
                    callback: () => {
                        getState({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No state selected!",
                    message: "Please select a state from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these states?" : "this state?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    stateService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getState({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No state selected!",
                message: "Please select a state from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these states?" : "this state?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    stateService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getState({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No state selected!",
                message: "Please select a state from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these states?" : "this state?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    stateService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getState({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No state selected!",
                message: "Please select a state from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these states?" : "this state?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    stateService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getState({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No state selected!",
                message: "Please select a state from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these states?" : "this state?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    stateService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getState({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No state selected!",
                message: "Please select a state from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getState({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getState({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getState({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleDatagridRowSelected = (args) => {
        stateDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    return {
        componentObserver,
        states,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addStateButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add a state",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editStateButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteStateButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["stateId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["stateId"],
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width5px
                },
                {
                    title: "Code",
                    value: ["stateCode"],
                    default: "",
                    style: themes?.width80px,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Name",
                    value: ["stateName"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Country",
                    value: ["countryName"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    title: "Latitude",
                    value: ["latitude"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    title: "Longitude",
                    value: ["longitude"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width40px
                },
            ],
            rowHeight: 35,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            onRowSelected: handleDatagridRowSelected,
            dataset: states
        }} />,

        createOrEditDialog: {
            name: createOrEditStateDialogName,
            data: selectedState,
            observer: createOrEditStateObserver,
            widget: CreateOrEditStateView,
            style: {
                window: "max-width-400px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getState({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useStatesHook;