import React, { useCallback, useEffect, useRef } from "react";
import { MENUITEMTYPE } from "../../enums/enums";

const MenuList = ({ attributes }) => {

    const componentObserver = useRef()

    const selectListItem = useCallback((selectedItem) => {
        let listContainer = (attributes?.componentObserver?.current) ? attributes?.componentObserver?.current : componentObserver.current;
        const selectedItems = listContainer.querySelectorAll("div[aria-controls=\"selector\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove("bg-light-theme");
        });
        selectedItem?.querySelector("div[aria-controls=\"selector\"]")?.classList.add("bg-light-theme");
    }, [attributes])

    const collapseAll = useCallback((e) => {
        [...document.querySelectorAll("div[aria-owns=\"dropdown\"]")].forEach((item, i) => {
            item.classList.add("hide");
        });
    }, []);

    useEffect(() => {
    }, [attributes])

    const handleOnClick = (e, args) => {
        attributes?.onItemClick(args);
        collapseAll();
        if (attributes.isSelectable)
            selectListItem(e.currentTarget);
    }

    const getMenuItem = (item) => {
        return (
            <div
                aria-owns={item.value}
                aria-rowindex={item.index}
                onClick={(e) => handleOnClick(e, item)}
                className={"d-flex align-items-center position-relative " + (attributes?.style?.item) + " p-1 margin-top-0px margin-bottom-1px bg-light-"}>
                <div className={"d-flex align-items-center me-2 " + ((item?.transparent) ? "opacity-0 " : " ") + ((item?.leading || item?.leading === null) ? attributes?.style?.leading : " hide")}>
                    {item?.leading}
                </div>
                <div className={(attributes?.style) ? attributes?.style?.title + " mb-0" : "text-13px mb-0"}>{item?.title}</div>
            </div>
        )
    }

    const getMenuItemSeparator = () => {
        return (
            <div className={"position-relative height-1px bg-grey-theme p-0 margin-top-3px margin-bottom-3px"}></div>
        )
    }

    return (
        <div ref={componentObserver} className={"w-100 mb-0 p-0 mt-1 mb-1 position-relative"}>
            {
                attributes?.items?.map((item, i) => {
                    item["index"] = i;
                    item["componentObserver"] = componentObserver;
                    if (!item.onClick)
                        item["onClick"] = handleOnClick;
                    return (
                        <div key={i} className={"w-100 text-decoration-none overflow-hidden- hover-bg-blue-light-theme"} to={item.href}>
                            {
                                (() => {
                                    switch (item?.type) {
                                        case MENUITEMTYPE.SEPARATOR:
                                            return getMenuItemSeparator()
                                        default:
                                            return getMenuItem(item)
                                    }
                                })()
                            }
                        </div>
                    )
                })
            }
        </div>
    )
}
export default MenuList;