import React, { useCallback, useEffect, useRef } from "react";
import { DOCKTYPE } from "../../enums/enums";
import { OffCanvasCallout } from "./offcanvas.callout.component";

const OffCanvasCalloutCollection = ({ attributes }) => {

    // refs
    const offCanvasCalloutCollectionComponent = useRef()

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return attributes?.style + " position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return attributes?.style + " position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return attributes?.style + " position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return attributes?.style + " position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return attributes?.style + " position-absolute top-0px start-0px end-0px";
            default:
                return (attributes?.style || "") + " position-absolute";
        }
    }

    return (
        <div aria-controls="offcanvas-callout-collection" id={attributes?.name} ref={offCanvasCalloutCollectionComponent} className={"w-100 " + getDockStyle(attributes?.dockType)}>
            {
                (() => {
                    return (
                        <div aria-controls="offcanvas-callout-collection-panel-container">
                            <div aria-controls="stackable-panel">
                                {
                                    attributes?.items?.map((item, i) => {
                                        return (
                                            <div aria-controls="panel" panel-index={i} className={"position-absolute top-0px start-0px " + ((i === 0) ? "" : "hide")} key={attributes.name + "-stackable-panel-" + i}>
                                                {
                                                    (() => {
                                                        if (item?.widget) {
                                                            return (<OffCanvasCallout attributes={item} />)
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })()
            }
        </div>
    );
}

const useOffCanvasCalloutCollection = (attributes) => {

    const themes = attributes?.themes?.offCanvasCalloutCollection;

    const addWidget = () => {

    }

    const getOffCanvasCalloutContainer = () => {
        const _offCanvasCalloutCollection = target();
        return _offCanvasCalloutCollection?.querySelector("div[aria-controls=\"offcanvas-callout-collection-panel-container\"]");
    }

    const getOffCanvasCallouts = () => {

    }

    const getOffCanvasCalloutByIndex = (index) => {
        const _offCanvasCalloutCollection = target();
        if (_offCanvasCalloutCollection) {
            const offCanvasCallouts = _offCanvasCalloutCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            const offCanvasCalloutArray = [...offCanvasCallouts];
            return offCanvasCalloutArray[index];
        }
        return null;
    }

    const getOffCanvasCalloutByName = (name) => {
        const _offCanvasCalloutCollection = target();
        if (_offCanvasCalloutCollection) {
            const offCanvasCallouts = _offCanvasCalloutCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            const offCanvasCalloutArray = [...offCanvasCallouts];
            return offCanvasCalloutArray.find(x => x.querySelector("div[aria-owns=\"" + name + "\"]"));
        }
        return null;
    }

    const target = () => {
        return document.getElementById(attributes?.name);
    }

    const setOffCanvasCallout = (index) => {
        const _offCanvasCalloutCollection = target();
        if (_offCanvasCalloutCollection) {
            const offCanvasCallouts = _offCanvasCalloutCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            [...offCanvasCallouts].forEach((offCanvasCallout, i) => {
                offCanvasCallout.classList.add("hide")
            });
            const selectedOffCanvasCallout = _offCanvasCalloutCollection.querySelector("div[panel-index=\"" + index + "\"]");
            selectedOffCanvasCallout.classList.remove("hide");
        }
    }

    return {
        addWidget,
        getOffCanvasCalloutContainer,
        getOffCanvasCallouts,
        getOffCanvasCalloutByIndex,
        getOffCanvasCalloutByName,
        name: attributes?.name,
        setOffCanvasCallout,
        target
    }
}

export { OffCanvasCalloutCollection, useOffCanvasCalloutCollection };