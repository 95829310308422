import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { OrderService } from '../order.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';


const useOrderDetailsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [order, setOrder] = useState([]);
    const [orderProducts, setOrderProducts] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const actionButtonsMenuItems = {
        fundOrderMenuItem: "fund-order-menuitem",
        orderToOrderTransferMenuItem: "order-to-order-transfer-menuitem",
        bankTransferMenuItem: "bank-transfer-menuitem",
        placeLienOnOrderMenuItem: "place-lien-on-order-menuitem",
        lockOrderAccountMenuItem: "lock-order-account-menuitem",
        sendMessageMenuItem: "send-message-menuitem",
        printAccountStatmentMenuItem: "print-account-statment-menuitem",
        downloadAccountStatmentPdfMenuItem: "download-account-statment-pdf-menuitem",
        shareAccountStatmentPdfMenuItem: "share-account-statment-pdf-menuitem",
    };

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    const stackablePanelComponentName = "order-stackable-panel";
    const orderOptionToolbarName = "order-option-toolbar";
    const orderProductsDatagridName = "order-products-datagrid";
    const orderProductsToolbarName = "order-products-toolbar";

    // components
    const form = useForm({
        ref: reactiveForm
    });
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const orderOptionToolbarButtons = {
        actionsButtonName: "add-market-product-button",
    };
    const orderProductsDatagrid = useDataGrid({
        name: orderProductsDatagridName
    });

    const orderProductsToolbar = useToolbar({
        name: orderProductsToolbarName
    });
    const orderProductsToolbarButtons = {
        previousButtonName: "ops-previous-button",
        pagesLabelName: "ops-pages-info-label",
        nextButtonName: "ops-next-button",
    }

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        // handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setOrder(args?.data);

        // get order recent transactionns
        getOrderProducts({
            customerOrderId: args?.data?.customerOrderId,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 100
        });
    }, [])

    // services
    const orderService = OrderService();
    const productImageService = ProductImageService();

    const getOrderProducts = useCallback(async (request) => {
        orderProductsDatagrid?.showLoader();
        orderProductsDatagrid.clearSelection();

        await orderService.getOrderProducts(request)
            .then(response => {
                orderProductsDatagrid?.hideLoader();

                setOrderProducts(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

            })
            .catch(error => {
                orderProductsDatagrid?.hideLoader();
            });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);


    // methods

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getOrderProducts({
                customerOrderId: order?.customerOrderId,
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getOrderProducts({
                customerOrderId: order?.customerOrderId,
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleOrderToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.lockOrderAccountMenuItem:
                // updateMarketWithNewProducts();
                break;
            case actionButtonsMenuItems?.placeLienOnOrderMenuItem:
                // addProductsToMarket();
                break;
            case actionButtonsMenuItems?.bankTransferMenuItem:
                //
                break;
            case actionButtonsMenuItems?.orderToOrderTransferMenuItem:
                //
                break;
            case actionButtonsMenuItems.fundOrderMenuItem:
            default:
                //
                break;
        }
    }

    return {
        order: order,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // profile photo
        profilePhotoAttributes: {
            formElement: true,
            id: "media",
            value: order?.media,
            placeholder: icons?.PersonIcon,
            style: themes?.avatar280px,
            observer: componentObserver
        },

        // actions toolbar
        actionsMenuButton: {
            name: orderOptionToolbarName,
            items: [
                {
                    name: orderOptionToolbarButtons.actionsButtonName,
                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.MenuRoundedIcon,
                    onClick: (e) => { },
                    observer: componentObserver,
                    style: themes?.toolbar?.button,
                    widget: <MenuList attributes={{
                        items: [
                            {
                                leading: icons?.DataSaverOnIcon,
                                name: actionButtonsMenuItems.fundOrderMenuItem,
                                title: "Reorder",
                            },
                            {
                                leading: icons?.AccessTimeIcon,
                                name: actionButtonsMenuItems.orderToOrderTransferMenuItem,
                                title: "Set as recurring ",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.MailIcon,
                                name: actionButtonsMenuItems?.sendMessageMenuItem,
                                title: "Send message",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.LocalPrintshopIcon,
                                name: actionButtonsMenuItems?.printAccountStatmentMenuItem,
                                title: "Print statment",
                            },
                            {
                                leading: icons?.ShareIcon,
                                name: actionButtonsMenuItems?.shareAccountStatmentPdfMenuItem,
                                title: "Share statment as PDF",
                            },
                        ],
                        style: themes?.menuList,
                        onItemClick: handleOrderToolbarOptionsMenuItemClick,
                        isSelectable: false
                    }} />
                }
            ]
        },

        // status flag
        statusFlagAttributes: {
            value: parseInt((() => order?.status)()),
            flags: [
                {
                    title: "Completed",
                    color: "bg-success-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-success-info-border-theme text-success-info-dark-theme",
                    icon: icons?.CheckRoundedIcon
                },
                {
                    title: "Cancelled",
                    color: "bg-danger-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-danger-info-theme text-danger-info-dark-theme",
                    icon: icons?.ClearRoundedIcon
                },
                {
                    title: "Discontinued",
                    color: "bg-disabled-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-disabled-info-theme text-disabled-info-dark-theme",
                    icon: icons?.DoDisturbRoundedIcon
                },
                {
                    title: "Low On Stock",
                    color: "bg-warning-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-warning-info-theme text-warning-info-dark-theme",
                    icon: icons?.AccessTimeRoundedIcon
                }
            ]
        },

        // 
        shopperPhotoAttributes: {
            formElement: true,
            id: "media",
            value: order?.media,
            placeholder: icons?.PersonIcon,
            style: themes?.avatar100px,
            observer: componentObserver
        },

        // 
        marketAddressIconAttributes: {
            formElement: true,
            id: "media",
            value: order?.media,
            placeholder: icons?.StorefrontRoundedIcon,
            style: themes?.avatar100px,
            observer: componentObserver
        },

        // 
        dropOffAddressIconAttributes: {
            formElement: true,
            id: "media",
            value: order?.media,
            placeholder: icons?.HouseIcon,
            style: themes?.avatar100px,
            observer: componentObserver
        },

        // 
        orderToolbarAttributes: {
            name: orderProductsToolbarName,
            items: [
                {
                    name: orderProductsToolbarButtons.previousButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Previous",
                    leading: icons?.ArrowBackIosRoundedIcon,
                    onClick: (e, args) => { handlePreviousPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.startDropDownButton
                },
                {
                    name: orderProductsToolbarButtons.pagesLabelName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    observer: componentObserver,
                    style: themes?.toolbar?.menuButton
                },
                {
                    name: orderProductsToolbarButtons.nextButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Next",
                    leading: icons?.ArrowForwardIosRoundedIcon,
                    onClick: (e, args) => { handleNextPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.endDropDownButton
                },
            ]
        },

        // 
        orderProductsDatagrid: {
            name: orderProductsDatagridName,
            key: ["customerOrderProductId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Qty",
                    value: ["quantity"],
                    default: "",
                    style: themes?.width50px,
                    widget: null
                },
                {
                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                    value: ["productMediaUrl"],
                    default: "",
                    style: themes?.width35px,
                    image: {
                        id: "media1Id",
                        key: "media1Id",
                        url: "media1Url",
                        size: themes?.size35px,
                        leading: icons?.PhotoSizeSelectActualRoundedIcon,
                        style: themes?.image,
                        config: {
                            downloadUrl: productImageService?.downloadUrl
                        }
                    }
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Product",
                    value: ["productName"],
                    default: "",
                    style: themes?.width100,
                    widget: null
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Amount",
                    value: ["price"],
                    default: "",
                    style: themes?.width90px
                },
            ],
            rowHeight: 34,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.UNDOCK,
            placeholder: {
                showImage: false,
                text: "No item available yet."
            },
            dataset: orderProducts
        },

        themes: themes,
        icons: icons,

    }
}

export default useOrderDetailsHook;