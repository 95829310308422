import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, MENUITEMTYPE, SLIDEDIRECTION, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import CreateOrEditPharmacyView from './createoredit.pharmacy/createoredit.pharmacy.view';
import { PharmacyService } from './pharmacy.service';
import { Grid } from '../../../../shared/components/grid/grid.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';
import PharmacyProductsView from './pharmacy.products/pharmacy.products.view';

const usePharmaciesHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [pharmacies, setPharmacies] = useState([]);
    const [selectedPharmacy, setSelectedPharmacy] = useState({});
    const [pharmacyQueryString, setPharmacyQueryString] = useState("");
    const [pharmacyPageNumber, setPharmacyPageNumber] = useState(1);
    const [pharmacyRowsPerPage, setPharmacyRowsPerPage] = useState(25);
    const [pharmacyTotalPages, setPharmacyTotalPages] = useState(0);
    const [pharmacyTotalRows, setPharmacyTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No pharmacy selected!",
            message: "Please select an pharmacy from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New pharmacy",
            successMessage: {
                title: "Pharmacy created successfully!",
                message: "Pharmacy was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit pharmacy details",
            alertMessage: {
                title: "Multiple pharmacies selected!",
                message: "Oops!, you selected multiple pharmacies. Please select a pharmacy from the list to continue"
            },
            successMessage: {
                title: "Pharmacy updated successfully!",
                message: "Pharmacy was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit pharmacy details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these pharmacies?" : "this pharmacy?")
                }
            },
            successMessage: {
                title: "Pharmacy deleted successfully!",
                message: "Pharmacy was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these pharmacies?" : "this pharmacy?")
                }
            },
            successMessage: {
                title: "Pharmacy published successfully!",
                message: "Pharmacy was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these pharmacies?" : "this pharmacy?")
                }
            },
            successMessage: {
                title: "Pharmacy published successfully!",
                message: "Pharmacy was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these pharmacies?" : "this pharmacy?")
                }
            },
            successMessage: {
                title: "Pharmacy archived successfully!",
                message: "Pharmacy was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these pharmacies?" : "this pharmacy?")
                }
            },
            successMessage: {
                title: "Pharmacy restored successfully!",
                message: "Pharmacy was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available pharmacies, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditPharmacyObserver = useRef();
    const pharmacyDetailsViewObserver = useRef();
    const pharmaciesDatagridObserver = useRef([]);

    // components
    const datagridName = "product-pharmacies-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-pharmacies-left-pane-toolbar";
    const leftToolbarButtons = {
        addPharmacyButtonName: "add-pharmacy-button",
        editPharmacyButtonName: "edit-pharmacy-button",
        deletePharmacyButtonName: "delete-pharmacy-button",
        reloadPharmacyButtonName: "reload-pharmacy-button",
    }

    const rightPaneToolbarName = "product-pharmacies-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const pharmacyService = PharmacyService();

    // dialogs & callouts
    const createOrEditPharmacyDialogName = "createoredit-pharmacy-modal";
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getPharmacies = useCallback(async (request) => {
        datagrid?.showLoader(messages?.loader?.fetchingMessage);

        await pharmacyService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setPharmacies(response?.data?.list);
                pharmaciesDatagridObserver.current?.selectDefault();

                // set page
                setPharmacyPageNumber(response?.data?.page?.pageNumber);
                setPharmacyRowsPerPage(response?.data?.page?.rowsPerPage);
                setPharmacyTotalPages(response?.data?.page?.totalPages);
                setPharmacyTotalRows(response?.data?.page?.totalRows);

                // 
                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

                // // 
                // pharmaciesDatagridObserver.current.selectDefault();

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        (async () => {
            await getPharmacies({
                queryString: pharmacyQueryString,
                pageNumber: pharmacyPageNumber,
                rowsPerPage: pharmacyRowsPerPage
            });
        })();
    }, []);

    // pharmacy
    const handleCreate = (e) => {
        createOrEditPharmacyObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            data: {},
            isNew: true
        });
    }

    const handleUpload = (e) => {
        // createOrEditPharmacyObserver.current.show({
        //     title: messages?.handleCreate?.dialogTitle,
        //     data: {},
        //     isNew: true
        // });
    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show(messages?.handleEdit?.alertMessage);
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedPharmacy = pharmacies.find(pharmacy => pharmacy.pharmacyId === datagrid.getSelectedRowKeyValue());
                setSelectedPharmacy(selectedPharmacy);

                // listen to dialog for changes
                createOrEditPharmacyObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedPharmacy,
                    isNew: false,
                    callback: () => {
                        getPharmacies({
                            queryString: pharmacyQueryString,
                            pageNumber: pharmacyPageNumber,
                            rowsPerPage: pharmacyRowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show(messages?.noRecordSelectedErrorMessage);
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handleDelete?.confirmMessage()?.title,
                message: messages?.handleDelete?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    pharmacyService.remove(datagrid.getSelectedRowsKeyValue()).then(async (response) => {
                        await getPharmacies({
                            queryString: pharmacyQueryString,
                            pageNumber: pharmacyPageNumber,
                            rowsPerPage: pharmacyRowsPerPage
                        });
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handlePublish?.confirmMessage()?.title,
                message: messages?.handlePublish?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    pageLoader.show(messages?.loader?.loadingMessage);
                    pharmacyService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getPharmacies({
                            queryString: pharmacyQueryString,
                            pageNumber: pharmacyPageNumber,
                            rowsPerPage: pharmacyRowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: messages?.handleUnpublish?.confirmMessage()?.title,
                message: messages?.handleUnpublish?.confirmMessage(datagrid.selectedRowsCount())?.message,
                onConfirmed: (e) => {
                    pageLoader.show(messages?.loader?.loadingMessage);
                    pharmacyService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getPharmacies({
                            queryString: pharmacyQueryString,
                            pageNumber: pharmacyPageNumber,
                            rowsPerPage: pharmacyRowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show(messages?.noRecordSelectedErrorMessage);
        }
    }

    const handleSearchRecords = (e, args) => {
        getPharmacies({
            queryString: args?.value,
            pageNumber: pharmacyPageNumber,
            rowsPerPage: pharmacyRowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pharmacyPageNumber > 1)
            getPharmacies({
                queryString: pharmacyQueryString,
                pageNumber: pharmacyPageNumber - 1,
                rowsPerPage: pharmacyRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pharmacyPageNumber < pharmacyTotalPages)
            getPharmacies({
                queryString: pharmacyQueryString,
                pageNumber: pharmacyPageNumber + 1,
                rowsPerPage: pharmacyRowsPerPage
            });
    }

    const handleDatagridRowSelected = (args) => {
        pharmacyDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    return {
        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addPharmacyButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add new",

                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.addPharmacyButtonName,
                                    type: TOOLBARITEMTYPE.UPLOADBUTTON,
                                    leading: icons?.FileUploadRoundedIcon,
                                    onUpload: (e) => handleUpload(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.editPharmacyButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deletePharmacyButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                // {
                                //     name: leftToolbarButtons.reloadPharmacyButtonName,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     leading: icons?.PublishRoundedIcon,
                                //     onClick: (e) => handlePublish(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                                // {
                                //     name: leftToolbarButtons.reloadPharmacyButtonName,
                                //     type: TOOLBARITEMTYPE.BUTTON,
                                //     text: "Unpublish",
                                //     leading: icons?.VpnLockRoundedIcon,
                                //     onClick: (e) => handleUnpublish(e),
                                //     observer: componentObserver,
                                //     style: themes?.toolbar?.button
                                // },
                                // {
                                //     type: TOOLBARITEMTYPE.SPACE,
                                //     style: themes?.toolbar?.space?.space15
                                // },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField,
                                    onClick: (e, args) => { handleSearchRecords(e, args) }
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        // datagrid,
        gridViewAttributes: {
            dockType: DOCKTYPE.FILL,
            fixedPane: GRIDPANE?.LEFT,
            paneWidth: themes?.width380px,
            allowAutoScroll: true,
            pane: {
                left: (
                    <div className='position-absolute top-10px start-15px bottom-15px end-10px'>
                        <DataGrid attributes={{
                            name: datagridName,
                            observer: pharmaciesDatagridObserver,
                            key: ["pharmacyId"],
                            columns: [
                                {
                                    default: "",
                                    style: themes?.width15px
                                },
                                {
                                    type: COLUMNTYPE.CHECKBOX,
                                    value: "pharmacyId",
                                    style: themes?.width30px
                                },
                                {
                                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                                    value: "mediaUrl",
                                    default: "",
                                    style: themes?.width35px,
                                    image: {
                                        id: "mediaId",
                                        key: "mediaId",
                                        url: "mediaUrl",
                                        size: themes?.size35px,
                                        leading: icons?.StorefrontRoundedIcon,
                                        style: themes?.image,
                                        config: {
                                            downloadUrl: pharmacyService?.downloadUrl
                                        }
                                    }
                                },
                                {
                                    default: "",
                                    style: themes?.width15px
                                },
                                {
                                    title: "Name",
                                    value: "pharmacyName",
                                    default: "",
                                    style: themes?.width100
                                }
                            ],
                            rowHeight: 35,
                            isStripped: true,
                            isSelectable: true,
                            dockType: DOCKTYPE.FILL,
                            onRowSelected: handleDatagridRowSelected,
                            dataset: pharmacies
                        }} />
                    </div>
                ),

                right: (
                    <div className='position-absolute top-3px start-15px bottom-0px end-0px'>
                        <PharmacyProductsView attributes={{
                            observer: pharmacyDetailsViewObserver
                        }} />
                    </div>
                )
            }
        },

        calloutWindowAttributes: {
            title: messages?.handleCreate?.dialogTitle,
            name: createOrEditPharmacyDialogName,
            data: selectedPharmacy,
            observer: createOrEditPharmacyObserver,
            widget: CreateOrEditPharmacyView,
            style: {
                window: "max-width-430px"
            },
            slideDirection: SLIDEDIRECTION.RIGHT,
            buttons: [
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            pageLoader.close();
                            getPharmacies({
                                queryString: pharmacyQueryString,
                                pageNumber: pharmacyPageNumber,
                                rowsPerPage: pharmacyRowsPerPage
                            });

                            setSelectedPharmacy({});
                            createOrEditPharmacyObserver.current.close();
                        });
                    }
                },
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                }
            ]
        }

    };
}

export default usePharmaciesHook;