import React from "react";

const LabelledIcon = ({ attributes }) => {
    return (
        <div className={"text-dark text-decoration-none d-flex justify-content-start align-items-center " + ((attributes?.style?.g) ? attributes?.style?.g : " border-0px")}>
            <div className={"icon d-flex " + (attributes?.style?.leading) + (attributes?.leading ? " me-2-" : " hide")}>{attributes?.leading}</div>
            <div className={"white-space-nowrap " + (attributes?.style?.text) + " " + (attributes?.text ? "" : " hide")}>{attributes?.text}</div>
        </div>
    );
}
export default LabelledIcon;