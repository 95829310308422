import React, { useEffect, useRef, useState } from "react";

const StatusBar = ({ attributes }) => {
    const [button, setProperties] = useState({
        inProgress: false,
        message: ""
    });

    const buttonElement = useRef();

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const notifier = (e) => {
        setProperties({
            inProgress: e.inProgress,
            message: e.message
        });
    }

    const noAction = () => {
        // 
    }

    const handleOnClick = (e) => {
        if (attributes?.args) {
            attributes.onClick(e, attributes?.args);
        } else {
            attributes.onClick(e);
        }

        e.stopPropagation();
    }

    const getStatusBarState = (status) => {
        return status? "btn-green-theme" : "btn-red-theme";
    }

    return (
        <div className={"d-flex"} onClick={attributes?.onClick ? handleOnClick : () => noAction()}>
            <div type={attributes?.type} className={"position-relative border-0px- p-0 " + (((attributes?.style?.button) ? attributes?.style?.button : " border-0px w-100 height-30px ") + getStatusBarState(attributes?.status))}>
                <div className={"d-flex justify-content-center align-items-center"}>
                    <div className={"d-flex justify-content-center align-items-center position-absolute top-0px end-0px bottom-0px start-0px text-center margin-auto"}>
                        <span className={"d-flex justify-content-center"}>{attributes?.status? "Running" : "Stopped"}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StatusBar;