import React from 'react';
import AuthenticationWidget from '../../shared/widgets/authentication/authentication.widget';
import useLoginHook from './login.hook';

const LoginView = () => {

    const hook = useLoginHook();

    return (
        <div>
            <div className={'position-relative height-' + hook?.viewHeight + 'px d-flex align-items-center'}>
                <div className={'position-absolute end-0px start-0px top-0px bottom-0px width-40- d-flex justify-content-center align-items-start mx-auto bg-white'}>
                    <div className={'max-width-570px'}>
                        <div className={'width-360px height-500px mt-3'}>
                            <AuthenticationWidget attributes={hook?.authenticationWidgetAttributes} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginView;