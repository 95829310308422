import { DOCKTYPE, GRIDPANE } from '../../../../shared/enums/enums';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { ToolbarCollection, useToolbarCollection } from '../../../../shared/components/toolbar/toolbar.collection.component';
import { useModalDialogCollection } from '../../../../shared/components/dialog/modal.dialog.collection.component';
import { Tab } from '../../../../shared/components/tab/tab.component';
import { useStackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';
import { useCallback, useState } from 'react';
import { Grid } from '../../../../shared/components/grid/grid.component';
import useInvestorsPortfolioHook from './investor.portfolio/investors.portfolio.hook';
import useInvestorReportDashboardHook from './investment.reports/investor.report.dashboard.hook';

const useInvestorManagementHook = () => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const stackablePanelComponentName = "wallet-management-stackable-panel";
    const tabComponentName = "wallet-management-tab";
    const toolbarCollectionComponentName = "wallet-management-toolbar";
    const modalDialogCollectionComponentName = "wallet-management-modal";

    // custom hooks
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });
    const toolbarCollectionComponent = useToolbarCollection({
        name: toolbarCollectionComponentName
    });
    const modalDialogCollectionComponent = useModalDialogCollection({
        name: modalDialogCollectionComponentName
    });

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // callbacks
    const handleStackablePanelToggle = useCallback((tabIndex) => {
        stackablePanelComponent.setPanel(1);
    }, [])

    // 
    // component hooks
    const investorsPortfolioComponentHook = useInvestorsPortfolioHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });
    const reportDashboardComponentHook = useInvestorReportDashboardHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });

    // 
    const handleTabButtonClick = (e, args) => {
        // select toolbar
        toolbarCollectionComponent.setToolbar(args.index);

        // select modal dialog
        modalDialogCollectionComponent.setDialog(args.index);

        // set selected tab
        setSelectedTabIndex(args.index);

    }

    // toolbar for managing dataset
    const manageToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                investorsPortfolioComponentHook?.toolbar,
                reportDashboardComponentHook?.toolbar,
            ]
        }
    }

    const manageTabAttributes = () => {
        return {
            name: tabComponentName,
            themes: themes,
            dockType: DOCKTYPE.FILL,
            onTabClick: (e, args) => handleTabButtonClick(e, args),
            items: [
                {
                    leading: icons?.WalletRoundedIcon,
                    text: "Wallets",
                    target: "investorsPortfolio-tab-button",
                    style: themes?.tab?.selectedTabButton,
                    widget: (() => {
                        return (
                            <Grid attributes={{
                                dockType: DOCKTYPE.FILL,
                                fixedPane: GRIDPANE?.RIGHT,
                                paneWidth: themes?.width500px,
                                pane: {
                                    left: investorsPortfolioComponentHook?.datagridAttributes,
                                    right: investorsPortfolioComponentHook?.detailsComponent
                                }
                            }} />
                        )

                    })()
                },
                {
                    leading: icons?.ReceiptLongRoundedIcon,
                    text: "Wallets Transactions",
                    target: "wallets-transactions-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: null
                },
                {
                    leading: icons?.AdminPanelSettingsIcon,
                    text: "Cash Safe Management",
                    target: "cash-safe-management-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: null
                },
                {
                    leading: icons?.AssessmentRoundedIcon,
                    text: "Reports",
                    target: "reports-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: null
                },
            ]
        }
    }

    // toolbar for creating or editing
    const createOrEditToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                investorsPortfolioComponentHook?.toolbar,
                reportDashboardComponentHook?.toolbar,
            ]
        }
    }

    return {
        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 bcustomerWallet-solid bcustomerWallet-top-0px bcustomerWallet-bottom-0px bcustomerWallet-start-0px bcustomerWallet-end-0px"}>
                                {/* toolbar */}
                                <ToolbarCollection attributes={manageToolbarCollectionAttributes()} />


                                {/* datagrid view */}
                                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                                    <Tab attributes={manageTabAttributes()} />
                                </div>
                            </div>
                        );
                    })()
                },

                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 bcustomerWallet-solid bcustomerWallet-top-0px bcustomerWallet-bottom-0px bcustomerWallet-start-0px bcustomerWallet-end-0px"}>
                                {/* toolbar */}
                                <ToolbarCollection attributes={createOrEditToolbarCollectionAttributes()} />


                                {/* datagrid view */}
                                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                                    {/* <Tab attributes={tabAttributes()} /> */}
                                </div>
                            </div>
                        );
                    })()
                }
            ]
        },

        toolbarCollectionAttributes: manageToolbarCollectionAttributes,

        tabAttributes: manageTabAttributes,

        modalDialogCollectionAttributes: {
            name: modalDialogCollectionComponentName,
            items: [
                investorsPortfolioComponentHook?.createOrEditDialog,
                // donationRequestsComponentHook?.createOrEditDialog,
            ]
        },

    };
}

export default useInvestorManagementHook;