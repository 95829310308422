import { useCallback, useEffect, useRef, useState } from 'react';
import { BankService } from '../bank.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../../shared/components/datagrid';
import { TOOLBARITEMTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditBankHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [wallets, setBanks] = useState([]);
    const [wallet, setBank] = useState([]);
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const categoryDgridName = "createoredit-parent-category-datagrid";
    const parentCategoriesDatagrid = useDataGrid({
        name: categoryDgridName
    });
    const categoryTokenSearchFieldName = "createoredit-parent-category-token-search-field";
    const parentCategoriesTokenSearchField = useTokenSearchField({
        name: categoryTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const walletService = BankService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setBank({
            ...wallet,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        //
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await walletService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await walletService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleCancelSelectedParent = (args) => {
        //
    }

    return {
        wallet: wallet,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // media
        mediaHeaderLabelAttributes: {
            text: "Bank Media Gallery:",
            style: themes?.label?.textUpperCaseBold13px
        },
        mediaUploaderAttributes: {
            formElement: true,
            id: "media",
            value: wallet?.media,
            style: themes?.mediaGallery?.card180px,
            observer: componentObserver
        },

        // wallet details header label
        walletDetailsHeaderLabelAttributes: {
            text: "Bank Basic Details",
            style: themes?.label?.textUpperCaseBold13px
        },

        // wallet name
        walletNameLabelAttributes: {
            text: "Bank name:",
            style: themes?.label?.text13px
        },
        walletNameFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for this wallet",
            id: "walletName",
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // wallet description
        walletDescriptionLabelAttributes: {
            text: "Description:",
            style: themes?.label?.text13px
        },
        walletDescriptionFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Briefly describe this wallet",
            id: "walletDescription",
            isMultiline: true,
            value: wallet?.walletDescription,
            style: themes?.field?.WYSIWYGField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // wallet category
        walletLabelAttributes: {
            text: "Choose a category:",
            style: themes?.label?.text13px
        },
        walletMultiSelectDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.textField,
            formElement: true,
            key: "categoryId",
            title: ["categoryName"],
            value: ["categoryId"],
            isStripped: false,
            isSelectable: true,
            isSingleSelect: true,
            dataset: wallets,
            dataObject: wallet,
            onChange: handleSearchFieldChange,
            onTokenCancelButtonClick: handleCancelSelectedParent,
            tokenButton: {
                trailing: icons?.ClearRoundedIcon,
            },
        },

        // wallet universal code
        walletBarcodeLabelAttributes: {
            text: "Barcode:",
            style: themes?.label?.text13px
        },
        walletBarcodeFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "XXXX-XXXX-XXXX",
            id: "walletBarcode",
            value: wallet?.BankBarcode,
            leading: icons?.QrCode2RoundedIcon,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },


        // wallet attributes
        walletOptionsHeaderLabelAttributes: {
            text: "Bank Attributes:",
            style: themes?.label?.textUpperCaseBold13px
        },
        walletOptionsDescriptionLabelAttributes: {
            text: "Does your wallet come in different options, like size, color or material? Add them here.",
            style: themes?.label?.text13px
        },

        themes: themes,
    }
}

export default useCreateOrEditBankHook;