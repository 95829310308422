import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';
import { faker } from '@faker-js/faker';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useForm } from '../../../../../shared/components/form/form.component';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { CourierService } from '../courier.service';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import useFlags from '../../../../../shared/hooks/flags.hook';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const useCourierDetailsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [courier, setCourier] = useState([]);
    const [courierTripRequests, setCourierTripRequests] = useState([]);
    const [courierTripRequestQueryString, setCourierTripRequestQueryString] = useState("");
    const [courierTripRequestPageNumber, setCourierTripRequestPageNumber] = useState(1);
    const [courierTripRequestRowsPerPage, setCourierTripRequestRowsPerPage] = useState(35);
    const [courierTripRequestTotalPages, setCourierTripRequestTotalPages] = useState(0);
    const [courierTripRequestTotalRows, setCourierTripRequestTotalRows] = useState(0);

    const actionButtonsMenuItems = {
        fundCourierMenuItem: "fund-courier-menuitem",
        courierToCourierTransferMenuItem: "courier-to-courier-transfer-menuitem",
        bankTransferMenuItem: "bank-transfer-menuitem",
        placeLienOnCourierMenuItem: "place-lien-on-courier-menuitem",
        lockCourierAccountMenuItem: "lock-courier-account-menuitem",
        sendMessageMenuItem: "send-message-menuitem",
        printAccountStatmentMenuItem: "print-account-statment-menuitem",
        downloadAccountStatmentPdfMenuItem: "download-account-statment-pdf-menuitem",
        shareAccountStatmentPdfMenuItem: "share-account-statment-pdf-menuitem",
    };

    // flags
    const flags = useFlags();

    // obervers
    const courierObserver = useRef();
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const fundCourierObserver = useRef(null);
    const courierTransferObserver = useRef(null);
    const bankTransferObserver = useRef(null);
    const profilePhotoObserver = useRef();

    const stackablePanelComponentName = "courier-stackable-panel";
    const courierOptionToolbarName = "courier-option-toolbar";
    const shoperOrdersDatagridName = "courier-order-requests-datagrid";
    const courierTransferModalDialogName = "courier-transfer-modal-dialog";
    const fundCourierModalDialogName = "fund-courier-modal-dialog";
    const bankTransferModalDialogName = "bank-transfer-modal-dialog";
    const ordersToolbarName = "orders-toolbar";

    // components
    const form = useForm({
        ref: reactiveForm
    });

    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const courierOptionToolbarButtons = {
        actionsButtonName: "add-market-product-button",
    };

    const courierTripsDatagrid = useDataGrid({
        name: shoperOrdersDatagridName
    });

    const fundCourierModalDialog = useModalDialog({
        name: fundCourierModalDialogName
    });

    const courierTransferModalDialog = useModalDialog({
        name: courierTransferModalDialogName
    });

    const bankTransferModalDialog = useModalDialog({
        name: bankTransferModalDialogName
    });

    const ordersToolbar = useToolbar({
        name: ordersToolbarName
    });

    const ordersToolbarButtons = {
        previousButtonName: "ops-previous-button",
        pagesLabelName: "ops-pages-info-label",
        nextButtonName: "ops-next-button",
    }

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        // handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setCourier(args?.data);
        courierObserver.current = await args?.data;


        profilePhotoObserver.current.loadImage(args?.data);

        // get courier recent transactionns
        getCourierTripRequests({
            courierId: args?.data?.courierId,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 10
        });
    }, [])

    // services
    const labels = useMemo(() => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], [])

    const courierService = CourierService();

    const chartOptions = () => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                title: {
                    display: true,
                    text: 'This bot has viewed 200 videos since commissioned',
                },
            },
        }
    }

    const options = useMemo(() => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                // title: {
                //     display: true,
                //     text: 'Monthly Withdrawals',
                // },
            },
        }
    }, []);

    const dummyData = () => {
        return {
            labels,
            datasets: [
                {
                    label: "Withdrawal",
                    data: labels.map(() => faker.number.int({ min: 0, max: 200000 })),
                    borderColor: "rgb(184, 24, 0)",
                    backgroundColor: 'rgba(250, 188, 179)',
                    borderWidth: 2
                },
                {
                    label: "Deposit",
                    data: labels.map(() => faker.number.int({ min: 0, max: 200000 })),
                    borderColor: "rgb(28, 108, 9, 0.7)",
                    backgroundColor: 'rgba(217, 251, 208)',
                    borderWidth: 2
                }
            ],
        }
    }

    const data = useMemo(() => dummyData());

    const getCourierTripRequests = useCallback(async (request) => {
        courierTripsDatagrid.clearSelection();
        await courierService.getCourierTrips(request)
            .then(response => {
                courierTripsDatagrid.hideLoader();
                setCourierTripRequests(response?.data?.list);

                setCourierTripRequestPageNumber(response?.data?.page?.pageNumber);
                setCourierTripRequestRowsPerPage(response?.data?.page?.rowsPerPage);
                setCourierTripRequestTotalPages(response?.data?.page?.totalPages);
                setCourierTripRequestTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                courierTripsDatagrid.hideLoader();
            });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handlePreviousPage = (e, args) => {
        if (courierTripRequestPageNumber > 1)
            getCourierTripRequests({
                courierId: courier?.courierId,
                queryString: courierTripRequestQueryString,
                pageNumber: courierTripRequestPageNumber - 1,
                rowsPerPage: courierTripRequestRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (courierTripRequestPageNumber < courierTripRequestTotalPages)
            getCourierTripRequests({
                courierId: courier?.courierId,
                queryString: courierTripRequestQueryString,
                pageNumber: courierTripRequestPageNumber + 1,
                rowsPerPage: courierTripRequestRowsPerPage
            });
    }

    const handleFundCourier = (args) => {
        fundCourierModalDialog.show({
            title: "Fund courier"
        });
        fundCourierObserver.current({
            data: courier
        })
    }

    const handleCourierTransfer = (args) => {
        courierTransferModalDialog.show({
            title: "Transfer to courier"
        });
        courierTransferObserver.current({
            data: courier
        })
    }

    const handleBankTransfer = (args) => {
        bankTransferModalDialog.show({
            title: "Bank transfer"
        });
        bankTransferObserver.current({
            data: courier
        })
    }

    const handleActionButtonMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.lockCourierAccountMenuItem:
                // updateMarketWithNewProducts();
                break;
            case actionButtonsMenuItems?.placeLienOnCourierMenuItem:
                // addProductsToMarket();
                break;
            case actionButtonsMenuItems?.bankTransferMenuItem:
                handleBankTransfer();
                break;
            case actionButtonsMenuItems?.courierToCourierTransferMenuItem:
                handleCourierTransfer();
                break;
            case actionButtonsMenuItems.fundCourierMenuItem:
            default:
                handleFundCourier();
                break;
        }
    }

    return {
        courier: courier,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // profile photo
        profilePhotoAttributes: {
            formElement: true,
            leading: icons?.PersonIcon,
            observer: profilePhotoObserver,
            style: themes?.avatar280px,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            config: {
                downloadUrl: courierService?.downloadUrl
            }
        },

        // 
        marketAddressAttributes: {
            leading: {
                formElement: true,
                id: "media",
                placeholder: icons?.StorefrontRoundedIcon,
                style: themes?.avatar100px,
                observer: componentObserver
            },
            name: (() => courier?.marketName)(),
            address: (() => courier?.marketAddress)()
        },

        // suporting documents
        supportingDocuments: [{}, {}, {}, {}],

        // transaction chart
        chartAttributes: {
            options: options,
            data: data
        },

        // 
        ordersToolbarAttributes: {
            name: ordersToolbarName,
            items: [
                {
                    name: ordersToolbarButtons.previousButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Previous",
                    leading: icons?.ArrowBackIosRoundedIcon,
                    onClick: (e, args) => { handlePreviousPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.startDropDownButton
                },
                {
                    name: ordersToolbarButtons.pagesLabelName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    observer: componentObserver,
                    style: themes?.toolbar?.menuButton
                },
                {
                    name: ordersToolbarButtons.nextButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Next",
                    leading: icons?.ArrowForwardIosRoundedIcon,
                    onClick: (e, args) => { handleNextPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.endDropDownButton
                },
            ]
        },

        // 
        purchaseHistoryDatagridAttributes: {
            name: shoperOrdersDatagridName,
            key: ["customerOrderId"],
            columns: [
                {
                    default: "",
                    style: themes?.width5px
                },
                {
                    type: COLUMNTYPE.FLAG,
                    value: ["status"],
                    title: "",
                    default: "",
                    style: themes?.width30px,
                    flags: flags?.orderFlags
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Order",
                    value: ["orderId"],
                    default: "",
                    style: themes?.width100,
                    widget: null
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Amount",
                    value: ["price"],
                    default: "",
                    style: themes?.width90px
                },
            ],
            rowHeight: 34,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.UNDOCK,
            placeholder: {
                showImage: false,
                text: "No purchase history available yet."
            },
            dataset: courierTripRequests
        },

        themes: themes,
        icons: icons,

    }
}

export default useCourierDetailsHook;