import React, { useCallback, useEffect, useRef, useState } from "react";
import { ALIGNMENTTYPE, COLUMNTYPE, COMPONENTYPE, DOCKTYPE, MENUALIGNMENTTYPE } from "../../enums/enums";
import ToggleIcon from "../toggle.icon/toggle.icon.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import Button from "./button.component";

const SelectableDropDownListButton = ({ attributes }) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [columns, setColumns] = useState([]);

    const componentObserver = useRef();
    const selectableDropDownList = useSelectableDropDownListButton(attributes);
    const selectedRowTheme = "bg-blue-theme";

    const widgetObserver = useRef();
    const widgetNotifier = useRef();

    const close = useCallback(async () => {
        // const dialog = componentRef?.current;
        // if (dialog) {
        //     dialog?.classList.remove("w-100");
        //     dialog.classList.add("width-0px");
        // }
    }, [])

    const collapseAll = useCallback((e) => {
        [...document.querySelectorAll("div[aria-owns=\"dropdown\"]")].forEach((item, i) => {
            item.classList.add("hide");
        });
    }, []);

    useEffect(() => {
        setColumns([
            {
                style: themes?.width10px
            },
            {
                value: attributes?.title,
                default: "",
                style: themes?.width100
            },
            {
                type: COLUMNTYPE.CHECKBOX,
                value: attributes?.value,
                style: themes?.width20px
            },
            {
                style: themes?.width6px
            }
        ]);
    }, [attributes])

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return "position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return "position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return "position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return "position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return "position-absolute top-0px start-0px end-0px";
            default:
                return;
        }
    }

    const getMenuAlignment = (e) => {
        switch (e) {
            case MENUALIGNMENTTYPE.LEFT:
                return "top-37px start-0px";
            case MENUALIGNMENTTYPE.RIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.TOP:
                return;
            case MENUALIGNMENTTYPE.BOTTOMLEFT:
                return "top-37px start-0px";
            default:
                return "top-37px end-0px";
        }
    }

    const handleCheckBoxClick = (e, dataObject) => {
        const tr = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
        const tbody = tr.parentNode;
        const rows = tbody.querySelectorAll("*[aria-controls=\"table-row\"]");
        const selectedCheckboxes = [];
        if (attributes?.isSingleSelect) {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                checkbox.checked = false;
            });

            const selectedCheckedBox = e.target;
            selectedCheckedBox.checked = true;

            // hidden field
            const field = tbody.parentNode.parentNode.querySelector("input[type=\"hidden\"]");
            field.value = selectedCheckedBox.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("id");

            selectableDropDownList.selectRow(e);
        } else {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (!checkbox.checked) {
                    const rowTheme = row.querySelector("*[aria-controls=\"table-row-header\"]");
                    rowTheme?.classList?.remove(selectedRowTheme);
                    row?.classList?.remove(selectedRowTheme);
                    row?.removeAttribute("aria-selected");
                }

                // build selected checkedbox array
                if (checkbox.checked) {
                    selectedCheckboxes.push(row.getAttribute("id"));
                }
            });

            // hidden field
            const field = tbody.parentNode.parentNode.querySelector("input[type=\"hidden\"]");
            field.value = JSON.stringify(selectedCheckboxes);
        }

        if (attributes?.onSelected) {
            attributes?.onSelected({
                selectedRow: dataObject
            });
        }

        (e.currentTarget.checked) ? tr?.classList?.add(selectedRowTheme) : tr?.classList?.remove(selectedRowTheme);
        e.stopPropagation();
    }

    const handleOnClick = (e) => {
        try {
            selectableDropDownList?.clearSelection();
            const dropdown = componentObserver.current.querySelector("*[aria-owns=\"dropdown\"]");
            if (dropdown.classList.contains("hide")) {
                collapseAll(e);
                dropdown.classList.remove("hide");
                if (attributes.onClick) {
                    attributes.onClick(e, {
                        key: ((e) => componentObserver.current.parentNode.parentNode.parentNode.getAttribute("aria-rowindex"))()
                    });
                }
            } else {
                dropdown.classList.add("hide");
            }
            e.stopPropagation();
        } catch (e) { }
    }

    const handleDropdownStopPropagation = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();
    }

    const handleOnFilter = (e) => {
        if (attributes?.onFilter && e.keyCode === 13)
            attributes?.onFilter({
                value: e?.target?.value
            });
    }

    const handleRowClick = (e, dataObject, evt) => {
        if (attributes?.widget) {
            // selectableDropDownList.toggleRowPanel(e);
        } else {
            selectableDropDownList.selectRow(e);
        }

        const tr = e;
        const tbody = tr.parentNode;
        const rows = tbody.querySelectorAll("*[aria-controls=\"table-row\"]");
        const selectedCheckboxes = [];
        if (attributes?.isSingleSelect) {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                checkbox.checked = false;
            });

            const selectedCheckedBox = e.querySelector("input[type=\"checkbox\"]");
            selectedCheckedBox.checked = true;

            // hidden field
            const field = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
            field.value = selectedCheckedBox.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("id");
        } else {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (!checkbox.checked) {
                    const rowTheme = row.querySelector("*[aria-controls=\"table-row-header\"]");
                    rowTheme?.classList?.remove(selectedRowTheme);
                    row?.classList?.remove(selectedRowTheme);
                    row?.removeAttribute("aria-selected");
                }

                // build selected checkedbox array
                if (checkbox.checked) {
                    selectedCheckboxes.push(row.getAttribute("id"));
                }
            });

            // hidden field
            const field = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
            field.value = JSON.stringify(selectedCheckboxes);
        }

        if (attributes?.onSelected) {
            attributes?.onSelected({
                selectedRow: dataObject
            });
        }

        if (!attributes?.buttons) {
            collapseAll();
        }

        evt.cancelBubble = true;
        evt.stopPropagation();
    }

    const listItem = ({ auto, attributes, dataObject, isFirstOrDefault, isExpanded }) => {
        if (dataObject[attributes.key]) {
            return (
                <tr
                    aria-controls="table-row"
                    key={dataObject[attributes.key]}
                    aria-rowindex={auto}
                    id={"" + dataObject[attributes.key]}
                    aria-selected={(isFirstOrDefault ? "true" : "")}
                    className={"w-100"}>
                    <td className={"border-0px p-0"}>
                        {/* row header */}
                        <div
                            onClick={(e) => handleRowClick(e.currentTarget.parentNode.parentNode, dataObject, e)}
                            aria-controls="table-row-header"
                            aria-atomic={((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme" : "bg-white-theme")}
                            className={"w-100 padding-top-1px padding-bottom-1px ps-0 rounded-0 box-sizing-border d-flex justify-content-start align-items-center cursor-pointer " + (isFirstOrDefault ? "" : "") + ((attributes?.style?.row) ? attributes?.style?.row : "") + ((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme" : "")}>
                            {
                                (() => {
                                    return columns?.map((column, i) => {
                                        return (
                                            <div
                                                aria-controls={"row-column"}
                                                aria-colindex={"row-column-" + i}
                                                key={dataObject[attributes.key.column] + "-column-" + i}
                                                className={"d-flex align-items-center box-sizing-border " + column?.style + " min-height-" + (attributes?.listItemHeight ? attributes?.listItemHeight : 25) + "px"}>
                                                {
                                                    (() => {
                                                        let columnText = dataObject[column?.value]?.toString() || "";

                                                        if ((column?.type === COLUMNTYPE.CHECKBOX)) {
                                                            const primaryKey = attributes?.key;
                                                            return (
                                                                <input
                                                                    onClick={(e) => handleCheckBoxClick(e, dataObject)}
                                                                    className={"form-check-input rounded-1 width-15px height-15px cursor-pointer m-0"}
                                                                    type={"checkbox"}
                                                                    disabled={"disabled"}
                                                                    value={dataObject[primaryKey]}
                                                                    id={dataObject[primaryKey]}
                                                                    name={dataObject[primaryKey]}
                                                                />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.AUTO) {
                                                            return (
                                                                <div className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 " + column?.style.value}>{auto + 1}</div>
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.ICON) {
                                                            return (
                                                                <ToggleIcon attributes={{
                                                                    leading: column?.leading
                                                                }} />
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className={"w-100 d-flex justify-content-between"}>
                                                                    <div className={"w-100 flex-column"}>
                                                                        <div className={"text-truncate pt-1 pb-1 w-100- box-sizing-border " + ((column?.textAlign === ALIGNMENTTYPE.RIGHT) ? "text-end" : "")}>{columnText}</div>
                                                                        {
                                                                            (() => {
                                                                                if (column?.widget) {
                                                                                    return (
                                                                                        <div className={"white-space-nowrap text-overflow-ellipsis text-11px opacity-8 pt-0 pb-1 w-100 box-sizing-border " + (column?.widget ? "" : "hide")}>
                                                                                            {column?.widget}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                    <div className={"width-3px me-1 "}></div>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        );
                                    })
                                })()
                            }
                        </div>
                    </td>
                </tr>
            );
        }
    }

    return (
        <div id={attributes?.name} ref={componentObserver} className={"d-flex position-relative"}>
            <div onClick={handleOnClick} type={attributes?.type} className={"btn d-flex justify-content-center- align-items-center border-0px- " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.g) ? attributes?.style?.g : "shadow-sm- border-0px height-34px") + " " + ((attributes?.style?.bgColor) ? attributes?.style?.bgColor : "btn-grey-theme"))}>
                <div className={"position-relative w-100 d-flex- justify-content-center- align-items-center"}>
                    <div className={"d-flex align-items-center text-13px w-100 " + ((attributes?.text || attributes?.placeholder) ? "ps-2 pe-2" : " ps-0 pe-0")}>
                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                            {attributes?.leading}
                        </i>
                        <span aria-controls="label" className={"w-100 white-space-nowrap " + (attributes?.style?.text)}>{attributes?.text || attributes?.placeholder}</span>
                        <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center m-0 ms-1 icon " + attributes?.style?.trailing}>
                            {icons?.ExpandMoreRoundedIcon}
                        </i>
                    </div>
                </div>
            </div>
            <div onClick={handleDropdownStopPropagation} aria-owns="dropdown" className={"select-dropdown-menu hide position-absolute z-index-10 min-width-100- " + attributes?.dropdown?.width + " " + getMenuAlignment(attributes?.menuPosition)}>
                <div className={"bg-white border border-1px transparent-border-blue-dark-theme shadow padding-8px rounded-3 w-100 min-width-100px min-height-50px display-inline-block float-start"}>
                    <div id={attributes?.name} className={"d-flex align-items-center position-relative form-control ps-2 text-14px margin-bottom-8px " + ((attributes?.style) ? attributes?.style?.textField : " rounded-pill padding-top-2px padding-bottom-2px padding-start-1px padding-end-1px")}>
                        <div className={"hide"}>
                            <button aria-controls="button" type={attributes?.type} className={"btn border-0px- " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.token?.g) ? attributes?.style?.token?.g : "shadow-sm- border-0px height-34px") + " " + ((attributes?.style?.token?.bgColor) ? attributes?.style?.token?.bgColor : "btn-grey-theme"))}>
                                <div className={"position-relative d-flex justify-content-center align-items-center"}>
                                    <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")} onClick={attributes?.onClick ? handleOnClick : () => { }}>
                                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.token?.leading}>
                                            {attributes?.leading}
                                        </i>
                                        <span aria-controls="label" className={"white-space-nowrap " + (attributes?.style?.token?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.text}</span>
                                    </div>

                                    <div aria-controls="cancel-button" className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                                        <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.tokenButton?.trailing) ? "" : "hide- ") + "m-0 ms-1 icon " + attributes?.style?.token?.trailing}>
                                            {attributes?.tokenButton?.trailing}
                                        </i>
                                    </div>
                                </div>
                            </button>

                            <div aria-controls="placeholder" type={attributes?.type} className={"border-0px " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.text) ? attributes?.style?.text : "shadow-sm- border-0px height-34px"))}>
                                <div className={"position-relative d-flex justify-content-center align-items-center"}>
                                    <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                            {attributes?.leading}
                                        </i>
                                        <span aria-controls="label" className={"white-space-nowrap text-italic opacity-6 " + (attributes?.style?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.placeholder}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-controls="field-tokenbar"></div>
                        <div className={"d-flex align-items-center position-relative w-100"}>
                            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center text-blue-theme m-0 me-2 " + ((attributes?.dropdown?.search?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                {attributes?.dropdown?.search?.leading}
                            </i>
                            <input
                                aria-controls={attributes?.formElement}
                                aria-roledescription={COMPONENTYPE.TEXTFIELD}
                                aria-required={attributes?.validate}
                                onKeyDown={handleOnFilter}
                                id={attributes?.key}
                                name={attributes?.key}
                                placeholder={attributes?.placeholder || "Search..."}
                                // defaultValue={attributes?.value || ""}
                                type={'text'}
                                className={"w-100 form-control text-13px text-blue-theme rounded-3px border-0px p-0 bg-transparent"} />
                            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                                {attributes?.trailing}
                            </i>
                        </div>
                    </div>

                    <div aria-controls={"table-grid"} ref={componentObserver} aria-posinset={attributes?.isStripped} aria-multiselectable={attributes?.isMultiSelectable} id={"dg-" + attributes?.name} className={"" + getDockStyle(attributes?.dockType) + " " + attributes?.style?.dropdown}>
                        <div aria-owns={attributes?.name} className={((attributes?.dockType === DOCKTYPE.FILL) ? ((!attributes.hasOwnProperty("showHeader") || attributes?.showHeader) ? "top-37px " : "top-7px ") + "position-absolute start-0px end-0px bottom-0px overflow-x-hidden- " + ((attributes?.hasOwnProperty("allowScrolling") && attributes?.allowScrolling === false) ? "" : "overflow-y-auto") : "")}>
                            <input
                                name={attributes?.key}
                                id={attributes?.key}
                                aria-owns={attributes?.name}
                                aria-controls={attributes?.formElement}
                                aria-roledescription={COMPONENTYPE.DATAGRID}
                                aria-required={attributes?.validate}
                                type={"hidden"} />
                            <table className={"table table-hover- w-100 mb-0 text-13px box-sizing-border table-layout-fixed"}>
                                <tbody>
                                    {
                                        (() => {
                                            if (attributes?.dataset?.length > 0) {
                                                return attributes?.dataset?.map((data, i) => {
                                                    return listItem({
                                                        isFirstOrDefault: (i === 0),
                                                        auto: i,
                                                        key: (data[attributes.key.column] + "-" + i),
                                                        attributes: attributes,
                                                        dataObject: data
                                                    });
                                                })
                                            }
                                        })()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div >

                    <div className={"w-100 d-flex justify-content-start align-items-center mt-3"}>
                        {
                            (() => {
                                return attributes?.buttons?.map((buttonAttributes, i) => {
                                    buttonAttributes["args"] = {
                                        apply: (e, callback) => {
                                            widgetNotifier.current({
                                                data: attributes?.data,
                                                successful: (args) => {
                                                    callback(args);
                                                },
                                                error: () => {
                                                    //
                                                }
                                            });
                                        },
                                        close: e => {
                                            close(e?.currentTarget);
                                        },
                                        submit: (e, callback) => {
                                            const field = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
                                            callback({
                                                data: JSON.parse(field.value)
                                            });
                                            collapseAll(e);
                                        }
                                    };
                                    return (
                                        <div className={"me-2"} key={"callout-footer-button-" + i}><Button key={"modal-footer-button-" + i} attributes={buttonAttributes} /></div>
                                    );
                                })
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    )

}

const useSelectableDropDownListButton = (attributes) => {

    const selectedRowTheme = "bg-blue-lighter-theme";

    const addSelectedRow = () => {

    }

    const addSelectedRows = () => {

    }

    const clearSelection = () => {
        const dgrid = target();
        if (dgrid) {
            const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            [...selectedRows].forEach(row => {
                row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRowTheme);
                row.classList.remove(selectedRowTheme);
                row?.removeAttribute("aria-selected");
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }
    }

    const getPaging = () => {
        const _target = target();
        return {
            pageNumber: parseInt(_target.getAttribute("pagenumber")) || attributes.page.pageNumber,
            rowsPerPage: parseInt(_target.getAttribute("rowsperpage")) || attributes.page.rowsPerPage,
            totalPages: parseInt(_target.getAttribute("totalpages")),
            totalRows: parseInt(_target.getAttribute("totalrows"))
        };
    }

    const getSelectedRow = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.find(x => x.checked);
        }
    }

    const getSelectedRowIndex = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const selectedRow = tbody.querySelector("tr[aria-selected=\"true\"]");
        if (selectedRow) {
            const selectedIndex = selectedRow.getAttribute("aria-rowindex");
            return selectedIndex;
        } else {
            return 0;
        }
    }

    const getSelectedRowKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked)[0].value;
        }
    }

    const getSelectedRowsKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).map(item => item.value);
        }
    }

    const getSelectedRows = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            [...checkboxes].forEach(checkbox => {
                //
            });
        }
    }

    const headerColumns = () => {
        const dgrid = target();
        const thead = dgrid?.querySelector("thead");
        if (thead) {
            const columns = thead.querySelectorAll("*[aria-controls=\"header-column\"]");
            return [...columns];
        } else {
            return [];
        }
    }

    const isRowSelected = () => {

    }

    const itemsCount = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid.querySelector("tbody");
            const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
            return checkboxes.length;
        }
        return 0;
    }

    const nextPage = () => {
        const _target = target();
        const currentPageNumber = parseInt(_target.getAttribute("pagenumber"));
        const totalPages = parseInt(_target.getAttribute("totalpages"));
        if (currentPageNumber < totalPages) {
            _target.setAttribute("pagenumber", currentPageNumber + 1);
        }
    }

    const previousPage = () => {
        const _target = target();
        const currentPageNumber = parseInt(_target.getAttribute("pagenumber"));
        if ((currentPageNumber - 1) > 0) {
            _target.setAttribute("pagenumber", currentPageNumber - 1);
        }
    }

    const removeSelectedRow = () => {

    }

    const removeSelectedRows = () => {

    }

    const resize = () => {
        const datagridHeaderColumns = headerColumns();
        const datagridRows = rows();
        datagridRows?.forEach((row, i) => {
            const columns = row.querySelectorAll("*[aria-controls=\"row-column\"]");
            datagridHeaderColumns?.forEach((column, i) => {
                columns[i]?.setAttribute("style", "max-width:" + column?.offsetWidth + "px");
            });
        });
    }

    const resizeRowPanelGrid = () => {
        const dgrids = document.querySelectorAll("*[aria-controls=\"table-grid\"]");
        [...dgrids].forEach(dgrid => {
            const thead = dgrid?.querySelector("thead");
            if (thead) {
                const dgridHeaderColumns = [...thead.querySelectorAll("*[aria-controls=\"header-column\"]")];

                // // get rows
                // const tbody = dgrid?.querySelector("tbody");
                // const dgridRows = [...tbody?.querySelectorAll("*[aria-controls=\"table-row\"]")];
                // dgridRows?.forEach((row, i) => {
                //     const columns = row.querySelectorAll("*[aria-controls=\"row-column\"]");
                //     dgridHeaderColumns?.forEach((column, i) => {
                //         columns[i].setAttribute("style", "max-width:" + column.offsetWidth + "px");
                //     });
                // })
            }
        });
    }

    const rows = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid?.querySelector("tbody");
            const rows = tbody?.querySelectorAll("*[aria-controls=\"table-row\"]");
            return [...rows];
        }
        return [];
    }

    const rowColumns = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const columns = tbody.querySelectorAll("*[aria-controls=\"row-column\"]");
        return [...columns];
    }

    const selectAllRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.add(selectedRowTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = true;
        });
    }

    const selectDefaultRow = (attributes) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove(selectedRowTheme);
            const checkbox = item.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });
        if (selectedRows.length > 0) {
            selectedRows[0]?.classList.add(selectedRowTheme);
            const selectedCheckbox = selectedRows[0].querySelector("input[type=\"checkbox\"]");
            if (selectedCheckbox)
                selectedCheckbox.checked = true;
        }
    }

    const selectRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        const isMultiSelectable = JSON.parse(dgrid.getAttribute("aria-multiselectable"));

        if (!isMultiSelectable) {
            [...selectedRows].forEach(row => {
                const rowHeader = row.querySelector("*[aria-controls=\"table-row-header\"]");
                rowHeader.classList.remove(selectedRowTheme);
                rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
                if (selectedRow === row) {
                    rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
                }
                row?.removeAttribute("aria-selected");
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }

        if (selectedRow && isMultiSelectable) {
            const selectedRowCheckbox = selectedRow.querySelector("input[type=\"checkbox\"]");
            if (selectedRowCheckbox && selectedRowCheckbox.checked !== true) {
                selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
                selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRow.getAttribute("aria-atomic"));
                selectedRow?.setAttribute("aria-selected", true);
                selectedRowCheckbox.checked = true;
            } else {
                selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRowTheme);
                selectedRow?.setAttribute("aria-selected", false);
                selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRow.getAttribute("aria-atomic"));
                selectedRowCheckbox.checked = false;
            }
        } else {
            if (!isMultiSelectable) {
                const selectedRowCheckbox = selectedRow.querySelector("input[type=\"checkbox\"]");
                if (selectedRowCheckbox && selectedRowCheckbox.checked !== true) {
                    selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
                    selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRow.getAttribute("aria-atomic"));
                    selectedRow?.setAttribute("aria-selected", true);
                    selectedRowCheckbox.checked = true;
                }
            }
        }
    }

    const selectedRowsCount = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).length;
        }
        return 0;
    }

    const setPaging = () => {
        return attributes.page;
    }

    const setSelectedRow = (key) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        const isStripped = dgrid.getAttribute("aria-posinset");
        const isMultiSelectable = dgrid.getAttribute("aria-multiselectable");
        [...selectedRows].forEach(row => {
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        const selectedRow = dgrid.querySelector("*[id=\"" + key + "\"]");
        const checkbox = selectedRow.querySelector("input[type=\"checkbox\"]");
        if (checkbox && checkbox.value === key) {
            checkbox.checked = true;
            selectedRow?.setAttribute("aria-selected", true);
        }
    }

    const target = () => {
        return document.getElementById("dg-" + attributes?.name);
    }

    const toggleRowPanel = (selectedRow) => {
        if (selectedRow) {
            const dgrid = target();
            const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            const isStripped = dgrid.getAttribute("aria-posinset");
            const isMultiSelectable = dgrid.getAttribute("aria-multiselectable");
            [...selectedRows].forEach(row => {
                row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRowTheme);
                row?.removeAttribute("aria-selected");

                const rowPanel = row.querySelector("*[aria-controls=\"table-row-panel\"]");
                if (rowPanel?.getAttribute("id") !== selectedRow?.querySelector("*[aria-controls=\"table-row-panel\"]")?.getAttribute("id"))
                    rowPanel.classList.add("hide");
            });

            [...selectedRows].forEach((row, i) => {
                if (isStripped && (i % 2) === 1)
                    row.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
            });

            if (isMultiSelectable) {
                // selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
                // selectedRow?.setAttribute("aria-selected", true);
            }

            const selectedRowPanel = selectedRow.querySelector("*[aria-controls=\"table-row-panel\"]");
            if (selectedRowPanel?.classList.contains("hide")) {
                selectedRowPanel?.classList.remove("hide");

                // reset/resize table
                const dgrid = selectedRowPanel?.querySelector("*[aria-controls=\"table-grid\"]");
                if (dgrid) {
                    const thead = dgrid?.querySelector("thead");
                    if (thead) {
                        const dgridHeaderColumns = [...thead.querySelectorAll("*[aria-controls=\"header-column\"]")];
                        const columnsWidth = [];
                        dgridHeaderColumns?.forEach((column, i) => {
                            columnsWidth.push(column.offsetWidth);
                        });

                        // get rows
                        const tbody = dgrid?.querySelector("tbody");
                        const dgridRows = [...tbody?.querySelectorAll("*[aria-controls=\"table-row\"]")];
                        dgridRows?.forEach((row, i) => {
                            const columns = [...row.querySelectorAll("*[aria-controls=\"row-column\"]")];
                            columnsWidth.forEach((width, i) => {
                                columns[i].setAttribute("style", "max-width:" + width + "px;");
                            });
                        })
                    }
                }
            } else {
                selectedRowPanel?.classList.add("hide");
            }
        }
    }

    const unselectAllRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove(selectedRowTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = false;
        });
        if (selectedRows.length > 0) {
            selectedRows[0]?.classList.add(selectedRowTheme);
            selectedRows[0].querySelector("input[type=\"checkbox\"]").checked = true;
        }
    }

    const unselectCheckedRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove("bg-light-theme");
        });
        selectedRow?.classList.add("bg-light-theme");
    }

    return {
        addSelectedRow,
        addSelectedRows,
        clearSelection,
        target,
        getPaging,
        getSelectedRow,
        getSelectedRowIndex,
        getSelectedRowKeyValue,
        getSelectedRows,
        getSelectedRowsKeyValue,
        headerColumns,
        isRowSelected,
        itemsCount,
        nextPage,
        previousPage,
        removeSelectedRow,
        removeSelectedRows,
        resize,
        resizeRowPanelGrid,
        rows,
        rowColumns,
        selectAllRow,
        selectDefaultRow,
        selectRow,
        setPaging,
        setSelectedRow,
        selectedRowsCount,
        toggleRowPanel,
        unselectAllRow,
        unselectCheckedRow
    }

}

export { SelectableDropDownListButton, useSelectableDropDownListButton };