import React from 'react';
import { TextField } from '../../../../../../shared/components/field/textfield.component';
import useFundCustomerWalletHook from './fund.wallet.hook';
import Label from '../../../../../../shared/components/label/label.component';
import { MultiSelectDropDownField } from '../../../../../../shared/components/field/multi.select.dropdown.field.component';
import MediaUploader from '../../../../../../shared/components/media.uploader/media.uploader.component';

const FundCustomerWalletView = ({ attributes }) => {

    const hook = useFundCustomerWalletHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0 pb-5"}>
            <form ref={hook?.reactiveForm}
                method={attributes.method}
                autoComplete={"false"}
                className={"w-100 text-left pt-2"} noValidate>
                <div className={"w-100 min-height-160px d-flex"}>
                    <div className={"w-100 flex-column"}>

                        {/* amount field */}
                        <div className={"w-100 mb-3"}>
                            <div className={"mb-2"}>Amount:</div>
                            <TextField
                                attributes={hook?.amountFieldAttributes} />
                        </div>

                        {/* narration field */}
                        <div className={"w-100 mb-3"}>
                            <div className={"mb-2"}>Narration:</div>
                            <TextField
                                attributes={hook?.narrationFieldAttributes} />
                        </div>

                    </div>
                </div>
            </form>
        </div>
    );
}

export default FundCustomerWalletView;