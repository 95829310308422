import React, { useEffect } from "react";
import { COMPONENTYPE } from "../../enums/enums";

const TokenSearchField = ({ attributes, onChange }) => {

    useEffect(() => {
        //
    }, [attributes])

    const handleOnChange = (e) => {
        if (onChange)
            onChange(e.target);
    }

    const handleOnClick = (e) => {
        if (attributes?.args) {
            attributes.onClick(e, attributes.args);
        } else {
            attributes.onClick(e);
        }
        e.stopPropagation();
    }

    return (
        <div id={attributes?.name} className={"d-flex align-items-center position-relative form-control ps-2 text-14px " + ((attributes?.style) ? attributes?.style?.textField : " rounded-pill padding-top-2px padding-bottom-2px padding-start-1px padding-end-1px")}>
            <div className={"hide"}>
                {/* token */}
                <button aria-controls="button" type={attributes?.type} className={"btn border-0px- " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.token?.g) ? attributes?.style?.token?.g : "shadow-sm- border-0px height-34px") + " " + ((attributes?.style?.token?.bgColor) ? attributes?.style?.token?.bgColor : "btn-grey-theme"))}>
                    <div className={"position-relative d-flex justify-content-center align-items-center"}>
                        <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")} onClick={attributes?.onClick ? handleOnClick : () => { }}>
                            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.token?.leading}>
                                {attributes?.leading}
                            </i>
                            <span aria-controls="label" className={"white-space-nowrap " + (attributes?.style?.token?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.text}</span>
                        </div>

                        <div aria-controls="cancel-button" className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.tokenButton?.trailing) ? "" : "hide- ") + "m-0 ms-1 icon " + attributes?.style?.token?.trailing}>
                                {attributes?.tokenButton?.trailing}
                            </i>
                        </div>
                    </div>
                </button>

                {/* placeholder */}
                <div aria-controls="placeholder" type={attributes?.type} className={"border-0px " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.text) ? attributes?.style?.text : "shadow-sm- border-0px height-34px"))}>
                    <div className={"position-relative d-flex justify-content-center align-items-center"}>
                        <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                {attributes?.leading}
                            </i>
                            <span aria-controls="label" className={"white-space-nowrap text-italic opacity-6 " + (attributes?.style?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.placeholder}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div aria-controls="field-tokenbar"></div>
            <div className={"d-flex align-items-center position-relative w-100"}>
                <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 me-2 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                    {attributes?.leading}
                </i>
                <input
                    aria-controls={attributes?.formElement}
                    aria-roledescription={COMPONENTYPE.TEXTFIELD}
                    aria-required={attributes?.validate}
                    onChange={handleOnChange}
                    id={attributes?.id}
                    placeholder={attributes?.placeholder}
                    defaultValue={attributes?.value || ""}
                    type={'text'}
                    className={"w-100 form-control text-13px rounded-3px border-0px p-0 bg-transparent"} />
                <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                    {attributes?.trailing}
                </i>
            </div>
        </div>
    );

}

const useTokenSearchField = (tokenFieldAttr) => {

    const target = () => {
        return document.getElementById(tokenFieldAttr.name);
    }

    const addToken = (args) => {
        clear();
        
        const _tokenSearchField = target();
        if (_tokenSearchField) {
            const fieldTokenBar = _tokenSearchField?.querySelector("*[aria-controls=\"field-tokenbar\"]");
            if (fieldTokenBar)
                fieldTokenBar.innerHTML = "";

            // add token item
            if (_tokenSearchField.querySelector("*[id=\"" + args.value + "\"]") === null) {
                // get token id
                const tokenId = tokenFieldAttr?.name + "-token-" + args.value;
                const token = _tokenSearchField?.firstChild?.firstChild?.cloneNode(true);
                token.setAttribute("id", tokenId);
                token.querySelector("*[aria-controls=\"label\"]").innerHTML = args?.text;
                fieldTokenBar?.appendChild(token);
            }
        }
    }

    const clear = () => {
        const fieldTokenBar = target()?.querySelector("*[aria-controls=\"field-tokenbar\"]");
        if (fieldTokenBar?.childNodes?.length > 0) {
            fieldTokenBar.innerHTML = "";
        }
    }

    return {
        addToken,
        clear
    };
}

export { TokenSearchField, useTokenSearchField }