const global = {
    variables: {
        rowsPerPageList: [
            { rowValue: 15, rowTitle: "15" },
            { rowValue: 20, rowTitle: "20", default: true },
            { rowValue: 35, rowTitle: "35" },
            { rowValue: 50, rowTitle: "50" },
            { rowValue: 75, rowTitle: "75" },
            { rowValue: 100, rowTitle: "100" }
        ],
    }
}

export default global;