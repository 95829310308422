import React, { useEffect, useRef } from "react";
import { COMPONENTYPE } from "../../enums/enums";

const DatagridColumnCellField = ({ attributes }) => {

    const componentRef = useRef();

    const defaultClass = "transparent-border-theme";
    const classArray = ["border", "border-1px", "border-blue-theme", "bg-white-theme", "ps-2"];

    useEffect(() => {
        //
    }, [attributes]);

    const handleOnChange = (e) => {
        if (attributes?.onChange)
            attributes?.onChange(e.target);
    }

    const handleOnFocus = (e) => {
        e.target.parentNode.classList.remove(defaultClass);
        classArray.forEach(classItem => {
            e.target.parentNode.classList.add(classItem);
        })
        if (attributes?.onFocus)
            attributes?.onFocus(e.target);
    }

    const handleOnLostFocus = (e) => {
        e.target.parentNode.classList.add(defaultClass);
        classArray.forEach(classItem => {
            e.target.parentNode.classList.remove(classItem);
        });
        if (attributes?.onLostFocus)
            attributes?.onLostFocus(e.target.innerHTML);
    }

    const handleOnKeyDown = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();

        if (e.keyCode === 13)
            e.target.blur();
    }

    const handleOnKeyUp = (e) => {
        if (e.keyCode === 13)
            e.target.blur();
    }

    const handleOnClick = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();
    }

    return (
        <div ref={componentRef} className={"d-flex align-items-center w-100 position-relative form-control ps-0 pe-0 " + defaultClass + " " + (attributes?.style?.g + " " + attributes?.style?.bgColor)}>
            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 me-2 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                {attributes?.leading}
            </i>
            <div
                aria-controls={attributes?.formElement}
                aria-roledescription={COMPONENTYPE.TEXTFIELD}
                aria-required={attributes?.validate}
                aria-label={attributes?.ariaLabel}
                data-defaultvalue={attributes?.defaultValue}
                onChange={handleOnChange}
                onBlur={handleOnLostFocus}
                onFocus={handleOnFocus}
                onKeyDown={handleOnKeyDown}
                onKeyUp={handleOnKeyUp}
                onClick={handleOnClick}
                onMouseDown={handleOnClick}
                id={attributes?.id}
                name={attributes?.id}
                placeholder={attributes?.placeholder}
                contentEditable={true}
                suppressContentEditableWarning={true}
                type={'text'}
                className={"w-100 form-control text-13px rounded-3px border-0px p-0 bg-transparent " + attributes?.style?.text}>{attributes?.value?.toString() || ""}</div>
            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                {attributes?.trailing}
            </i>
        </div>
    );
}
export { DatagridColumnCellField };