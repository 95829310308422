import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { RiUser6Fill } from "react-icons/ri";
import { Toolbar } from "../toolbar/toolbar.component";
import { TOOLBARITEMTYPE } from "../../enums/enums";
import { FaImages } from "react-icons/fa";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const Image = forwardRef(({ attributes, model, isImageAvailable }, ref) => {


    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const isInitialized = useRef(0);
    const componentObserver = useRef();

    const [imageSize, setImageSize] = useState();

    useImperativeHandle(attributes?.observer, () => ({
        loadImage(args) {
            attributes.model = model = args?.model;

            const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
            const preview = componentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
            const size = componentObserver.current.clientWidth + 50;
            if (args && attributes?.url !== "") {
                const loadImageUrl = (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + (attributes?.config?.size || size);
                hiddenField.value = args[attributes?.key];
                preview.setAttribute("style", "background-image: url('" + loadImageUrl + "')");
            } else {
                preview.setAttribute("style", "background-image: url('')");
            }
        }
    }))

    useEffect(() => {
        if (isInitialized.current <= 0) {
            isInitialized.current = 1;
        }

        const size = componentObserver.current.clientWidth;
        setImageSize(size);

        if (attributes) {
            initializeImage(model);
        }
    }, [attributes, model])

    const initializeImage = async (args) => {
        const size = componentObserver.current.clientWidth;
        setImageSize(size);

        const loader = componentObserver?.current?.querySelector("div[aria-controls=\"loader-controls\"]");
        loader.classList.add("hide");

        const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
        const preview = componentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
        // preview.setAttribute("style", "background-image: url('')");

        if (args && isImageAvailable === true) {
            hiddenField.value = args[attributes?.key];

            if (attributes?.onUploadCompleted)
                attributes?.onUploadCompleted(args);

            // load product image
            preview.setAttribute("style", "background-image: url('" + (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + size + "')");
            isInitialized.current = 0;
        }
    }

    return (
        <div ref={componentObserver} className={"text-dark text-decoration-none d-flex justify-content-start align-items-center position-absolute top-0px start-0px bottom-0px end-0px"}>
            <div className={attributes?.style?.g + " " + attributes?.style?.bgColor + " " + (attributes?.style?.avatar ? attributes?.style?.avatar : "rounded-circle-") + " mx-auto position-absolute top-0px start-0px bottom-0px end-0px d-flex justify-content-center align-items-center"}>
                <input
                    name={attributes?.id}
                    id={attributes?.id || attributes?.key}
                    aria-owns={attributes?.name}
                    aria-controls={attributes?.formElement}
                    aria-required={attributes?.validate}
                    type={"hidden"} />

                {
                    (() => {
                        if (!attributes?.url) {
                            return <i className={"d-flex justify-content-center align-items-center mx-auto icon-20px " + attributes?.style?.placeholder}>
                                {attributes?.leading}
                            </i>
                        }
                    })()
                }
                {
                    ((size) => {
                        return <i aria-controls="image-preview-controls" className={"bg-cover bg-center d-flex position-absolute top-0px start-0px bottom-0px end-0px " + ((attributes?.style?.image) ? attributes?.style?.image : "rounded-2 ")}></i>
                    })(imageSize)
                }


                {/* loader */}
                <div aria-controls="loader-controls" className={"hide transparent-bg-white-theme position-absolute top-0px start-0px bottom-0px end-0px z-index-10 bottom-0px mx-auto d-flex justify-content-center align-items-center"}>
                    <div className={"transparent-bg-white-theme- shadow-lg- mx-auto min-height-50px p-4"}>
                        <div className="spinner-border border-2px text-blue-theme icon-70px" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
})
export default Image;