import React from 'react';
import { TextField } from '../../../../../shared/components/field/textfield.component';
import useCreateOrEditCatererHook from './createoredit.caterer.hook';
import Label from '../../../../../shared/components/label/label.component';
import { MultiTextField } from '../../../../../shared/components/field/multi.textfield.component';
import { MultiSelectDropDownField } from '../../../../../shared/components/field/multi.select.dropdown.field.component';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { ProfilePhotoUploader } from '../../../../../shared/components/image/profile.photo.uploader.component';
import { DateOfBirthDropDownFieldAttributes } from '../../../../../shared/components/field/dob.selection.field.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';

const CreateOrEditCatererView = ({ attributes }) => {

    const hook = useCreateOrEditCatererHook(attributes);

    return (
        <div className={"w-100 position-relative"}>
            <form ref={hook?.reactiveForm} method={attributes.method} autoComplete={"false"} className={"w-100 text-left pt-2"} noValidate>
                <div className={"w-100 flex-column"}>
                    {/* media upload */}
                    <div className={"mb-0 mb-4 d-flex justify-content-between align-items-start"}>
                        <div className={"position-relative mb-4 rounded-5px max-width-90px width-90px height-90px"}>
                            <ProfilePhotoUploader attributes={hook?.profilePhotoUploaderAttributes} />
                        </div>

                        <div className={"d-flex align-items-start"}>
                            <Toolbar attributes={hook?.toggleToolbarAttributes} />
                        </div>
                    </div>

                    {/* business name field */}
                    <div className={"w-100 m-0 mb-4"}>
                        <div className={"mb-2"}>
                            <Label attributes={hook?.catererNameLabel} />
                        </div>
                        <TextField
                            attributes={hook?.catererNameField} />
                    </div>

                    {/* about field */}
                    <div className={"w-100 m-0 mb-4"}>
                        <div className={"mb-2"}>
                            <Label attributes={hook?.catererDescriptionLabel} />
                        </div>
                        <MultiTextField
                            attributes={hook?.catererDescriptionField} />
                    </div>

                    {/* contact person name field */}
                    <div className={"w-100 mb-4"}>
                        {/*  */}
                        <div className={"mb-2 text-blue-theme"}>Contact person:</div>

                        <div className={"w-100 d-flex"}>
                            {/* first name */}
                            <div className={"w-100 me-1"}>
                                <TextField
                                    attributes={hook?.firstNameFieldAttributes} />
                            </div>

                            {/* last name */}
                            <div className={"w-100 ms-2"}>
                                <TextField
                                    attributes={hook?.lastNameFieldAttributes} />
                            </div>
                        </div>
                    </div>

                    {/* gender and dob */}
                    <div className={"w-100 d-flex mb-4"}>
                        {/* gender field */}
                        <div className={"w-30 m-0 me-1"}>
                            <div className={"mb-2 text-blue-theme"}>
                                Gender:
                            </div>
                            <SingleSelectDropDownField
                                attributes={hook?.genderSelectDropDownFieldAttributes} />
                        </div>

                        {/* dob field */}
                        <div className={"w-70 m-0 ms-2"}>
                            <div className={"mb-2 text-blue-theme"}>
                                Date of birth:
                            </div>
                            <div className={"m-0"}>
                                <DateOfBirthDropDownFieldAttributes attributes={hook?.dobDropDownFieldAttributes} />
                            </div>
                        </div>
                    </div>

                    {/* contact address field */}
                    <div className={"w-100 m-0 mb-2"}>
                        <div className={"mb-2"}>
                            <Label attributes={hook?.contactAddressLabel} />
                        </div>
                        <TextField
                            attributes={hook?.contactAddressField} />
                    </div>

                    <div className={"d-flex justify-content-center mb-2"}>
                        {/* caterer country field */}
                        <div className={"w-100 m-0 me-2"}>
                            <SingleSelectDropDownField
                                attributes={hook?.countrySelectDropDownFieldAttributes} />
                        </div>

                        {/* caterer state field */}
                        <div className={"w-100 m-0 mb-0"}>
                            <SingleSelectDropDownField
                                attributes={hook?.stateSelectDropDownFieldAttributes} />
                        </div>
                    </div>

                    <div className={"d-flex justify-content-center mb-4"}>
                        {/* caterer city field */}
                        <div className={"w-100 m-0 me-2"}>
                            <SingleSelectDropDownField
                                attributes={hook?.citySelectDropDownFieldAttributes} />
                        </div>

                        {/* caterer postal code field */}
                        <div className={"w-100 max-width-150px m-0 mb-0"}>
                            <TextField
                                attributes={hook?.postalCodeField} />
                        </div>
                    </div>

                    {/* email and phone number */}
                    <div className={"w-100 d-flex mb-4"}>
                        <div className={"w-60 m-0 me-0"}>
                            <div className={"mb-2 text-blue-theme"}>
                                Email address:
                            </div>
                            <TextField
                                attributes={hook?.emailAddressFieldAttributes} />
                        </div>

                        <div className={"w-40 m-0 ms-2"}>
                            <div className={"mb-2 text-blue-theme"}>
                                Phone number:
                            </div>
                            <TextField
                                attributes={hook?.phoneNumberFieldAttributes} />
                        </div>
                    </div>

                    {/* national id field */}
                    <div className={"w-100 m-0 mb-4"}>
                        <div className={"mb-2"}>
                            <Label attributes={hook?.nationalIdLabel} />
                        </div>
                        <TextField
                            attributes={hook?.nationalIdField} />
                    </div>

                </div>
            </form>
        </div>
    );
}

export default CreateOrEditCatererView;