import { useCallback, useEffect, useRef, useState } from 'react';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { PharmacyProductCategoryService } from '../pharmacy.product.categories/pharmacy.product.category.service';
import { PharmacyProductVariationService } from '../pharmacy.product.variations/pharmacy.product.variation.service';
import { PharmacyProductColorService } from '../pharmacy.product.colors/pharmacy.product.color.service';
import { PharmacyProductTypeService } from '../pharmacy.product.types/pharmacy.product.type.service';
import { PharmacyProductService } from '../pharmacy.products/pharmacy.product.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';

const useCreateOrEditPharmacyHook = (attributes) => {
    const appConfig = useAppConfig();

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes().rounded;
    const customUIThemes = {

        // image
        image: {
            g: "bg-cover bg-center text-white cursor-pointer rounded-6px",
            bgColor: "bg-cover bg-center bg-blue-light-theme transparent-border-blue-light-theme",
            textColor: "text-center manrope-bold text-13px text-blue-theme",
            placeholder: "bg-cover bg-center opacity-9 text-blue-dark-theme icon-35px text-480px"
        },


        // 
        singleSelectDropDownField: {
            g: "height-37px w-100 justify-content-start border-1px border transparent-border-blue-dark-theme shadow-sm-",
            bgColor: "bg-blue-light-theme-",
            leading: "icon-16px text-16px",
            trailing: "icon-16px text-16px",
            text: "text-13px text-dark-theme text-start max-width-100px- text-truncate",
            dropdown: "max-height-180px overflow-hidden overflow-y-auto"
        },

    };

    // state objects
    const [selectedPharmacy, setSelectedPharmacy] = useState();
    const [product, setProduct] = useState();


    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No images selected!",
            message: "Please select an images from the list to continue"
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available images, please wait..."
        }
    };

    // observers
    const pharmacyProductReactiveForm = useRef();
    const componentObserver = useRef();
    const imageUploaderObserver = useRef();
    const pharmacyProductObserver = useRef();
    const categorySingleSelectDropDownFieldObserver = useRef();
    const colorSingleSelectDropDownFieldObserver = useRef();
    const typeSingleSelectDropDownFieldObserver = useRef();
    const variationSingleSelectDropDownFieldObserver = useRef();

    const productImages = useRef([]);
    const productCategories = useRef([]);
    const productVariations = useRef([]);
    const productColors = useRef([]);
    const productTypes = useRef([]);


    // components
    const form = useForm({
        ref: pharmacyProductReactiveForm
    });


    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();
    const pageLoader = usePageLoader();


    // services
    const productCategoryService = PharmacyProductCategoryService();
    const productVariationService = PharmacyProductVariationService();
    const productColorService = PharmacyProductColorService();
    const productTypeService = PharmacyProductTypeService();
    const pharmacyProductService = PharmacyProductService();
    const productImageService = ProductImageService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;

        args.data["pharmacyId"] = pharmacyProductObserver?.current?.pharmacyId;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setProduct(args?.data);
        pharmacyProductObserver.current = args?.data;

        // create a promise list of product attributes
        const promiseList = [
            productCategoryService.getAllByPharmacy({
                pharmacyId: pharmacyProductObserver?.current?.pharmacyId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list),

            productVariationService.getAllByPharmacy({
                pharmacyId: pharmacyProductObserver?.current?.pharmacyId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list),

            productColorService.getAllByPharmacy({
                pharmacyId: pharmacyProductObserver?.current?.pharmacyId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list),

            productTypeService.getAllByPharmacy({
                pharmacyId: pharmacyProductObserver?.current?.pharmacyId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list)
        ];

        // get all product details attributes
        Promise.all(promiseList).then(response => {
            response.forEach(list => {
                if (Array.isArray(list) && list.length > 0) {
                    // get product selected brands
                    if (list[0].hasOwnProperty("productCategoryId")) {
                        productCategories.current = list;
                        categorySingleSelectDropDownFieldObserver.current?.updateList(list);
                    }

                    // get product selected colors
                    if (list[0].hasOwnProperty("productColorId")) {
                        productColors.current = list;
                        colorSingleSelectDropDownFieldObserver.current?.updateList(list);
                    }

                    // get product selected types
                    if (list[0].hasOwnProperty("productTypeId")) {
                        productTypes.current = list;
                        typeSingleSelectDropDownFieldObserver.current?.updateList(list);
                    }

                    // get product selected variations
                    if (list[0].hasOwnProperty("productVariationId")) {
                        productVariations.current = list;
                        variationSingleSelectDropDownFieldObserver.current?.updateList(list);
                    }
                }
            });
        }).catch(error => {
            //
        });

        // load image
        imageUploaderObserver.current.loadImage(args?.data);

        if (args?.data !== null) {
            // set form data
            form.setData(pharmacyProductObserver.current);
        } else {
            form.clear();
            pharmacyProductObserver.current = await {};
        }
    }, [form])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleProductImageUpload = (args) => {
        // console.log(args);
    }

    const handleSaveOrUpdate = async (args) => {
        pageLoader.show({
            message: "Loading, please wait...",
        });
        if (args?.isNew) {
            await pharmacyProductService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader?.hide();
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await pharmacyProductService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader?.hide();
            }).catch((error) => {
                args?.error(error);
            });
        }
    }


    return {
        product: product,
        media: pharmacyProductObserver.current,
        reactiveForm: pharmacyProductReactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // media
        frontImageUploaderAttributes: {
            name: "createoredit-pharmacy-product-image-uploader",
            formElement: true,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            size: themes?.sizeAbsolute,
            leading: icons?.PhotoSizeSelectActualIcon,
            // style: customUIThemes?.image,
            style: themes?.image,
            model: pharmacyProductObserver.current,
            onUploadCompleted: handleProductImageUpload,
            observer: imageUploaderObserver,
            config: {
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: pharmacyProductService?.uploadUrl,
                downloadUrl: pharmacyProductService?.downloadUrl
            }
        },

        // product name
        productNameFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Choose a name for this product",
            id: "productName",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // product description
        productDescriptionFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "Briefly describe this product",
            id: "productDescription",
            isMultiline: true,
            value: product?.productDescription,
            style: themes?.field?.multiTextField200Hpx,
            observer: componentObserver
        },

        // price
        productPriceFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "0.00",
            id: "serviceFee",
            value: product?.serviceFee,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // discount
        productDiscountFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "0%",
            id: "discount",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // product category
        categorySingleSelectDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            onClick: (e, args) => { },
            observer: categorySingleSelectDropDownFieldObserver,
            style: customUIThemes?.singleSelectDropDownField,
            formElement: true,
            key: "productCategoryId",
            title: "productCategoryName",
            value: "productCategoryId",
            items: productCategories.current,
            model: product,
        },

        // colors
        colorSingleSelectDropDownFieldAttributes: {
            name: "color-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select color",
            onClick: (e, args) => { },
            observer: colorSingleSelectDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productColorId",
            title: "productColorName",
            value: "productColorId",
            isStripped: false,
            isSelectable: true,
            items: productColors.current,
            model: product,
        },

        // types
        typeSingleSelectDropDownFieldAttributes: {
            name: "type-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select types",
            onClick: (e, args) => { },
            observer: typeSingleSelectDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productTypeId",
            title: "productTypeName",
            value: "productTypeId",
            isStripped: false,
            isSelectable: true,
            items: productTypes.current,
            model: product,
        },

        // variations
        variationSingleSelectDropDownFieldAttributes: {
            name: "variation-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select size/weight",
            onClick: (e, args) => { },
            observer: variationSingleSelectDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productVariationId",
            title: "productVariationName",
            value: "productVariationId",
            isStripped: false,
            isSelectable: true,
            items: productVariations.current,
            model: product,
        },

        // manufactued date
        manufacturedDateFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "DD/MM/YYYY",
            id: "manufacturedDate",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // expiry date
        expiryDateFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "DD/MM/YYYY",
            id: "expiryDate",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // batch number
        batchNumberFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "XXXXXXXXXXXXX",
            id: "batchNumber",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // service fee
        serviceFeeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "0.00",
            id: "serviceFee",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        themes: themes,
    }
}

export default useCreateOrEditPharmacyHook;