import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Toolbar } from "../toolbar/toolbar.component";
import { MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from "../../enums/enums";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";

const DatagridImage = forwardRef(({ attributes, model }, ref) => {

    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const componentObserver = useRef();

    const [imageSize, setImageSize] = useState();

    useImperativeHandle(attributes?.observer, () => ({
        loadImage(args) {
            const preview = componentObserver?.current?.querySelector("i[aria-controls=\"image-preview-controls\"]");
            const size = componentObserver.current.clientWidth;
            if (args && attributes?.url !== "") {
                const loadImageUrl = (attributes?.config?.downloadUrl + "/" + args[attributes?.url]) + "?size=" + (attributes?.config?.size || size);
                preview.setAttribute("style", "background-image: url('" + loadImageUrl + "')");
            } else {
                preview.setAttribute("style", "background-image: url('')");
            }
        }
    }))

    useEffect(() => {
        setImageSize(componentObserver.current.clientWidth);
    }, [attributes])

    const handleBrowseAction = () => {
        if (attributes?.buttonClick)
            attributes?.buttonClick({});
    }

    return (
        <div ref={componentObserver} className={"d-flex justify-content-center " + attributes?.style?.g}>
            <div className={"position-relative d-flex justify-content-center align-items-center rounded-2- text-white bg-light- mx-auto " + attributes?.size}>
                {
                    (() => {
                        if (!attributes?.config) {
                            return <i className={"d-flex justify-content-center align-items-center mx-auto icon-20px " + attributes?.style?.placeholder}>
                                {attributes?.leading || attributes?.image?.leading}
                            </i>
                        }
                    })()
                }
                {
                    ((model, size) => {
                        if (model && attributes?.url) {
                            return <i className={"bg-cover bg-center d-flex position-absolute top-0px start-0px bottom-0px end-0px " + ((attributes?.style?.image) ? attributes?.style?.image : "rounded-2 ") + " " + model[attributes?.src]} style={{ backgroundImage: "url('" + (attributes?.config?.downloadUrl + "/" + model[attributes?.url]) + "?size=" + (attributes?.config?.size || size) + "')" }}></i>
                        }
                    })(attributes?.model || model, imageSize)
                }
            </div>
        </div>
    );
})
export default DatagridImage;