import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import useCustomerDetailsHook from './customer.details.hook';
import { Avatar } from '../../../../../shared/components/avatar';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import HorizontalSpace from '../../../../../shared/components/separator/horizontal.space.component';
import { DataGrid } from '../../../../../shared/components/datagrid';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';

const CustomerDetailsView = ({ attributes }) => {

    const hook = useCustomerDetailsHook(attributes);

    return (
        <div className={"w-100 position-relative p-0 pt-0 pb-3"}>
            <div className={"w-100 position-relative pb-0"}>
                <div className={"w-100 d-flex"}>
                    <div className={"w-100 flex-column"}>
                        {/* media upload */}
                        <div className={"mb-0 p-3 pb-0 rounded-5px min-height-80px"}>
                            <div className={"d-flex w-100 mb-0"}>
                                <div className={"me-3 p-0 max-width-100px min-width-100px height-100px position-relative"}>
                                    <Avatar attributes={hook?.profilePhotoAttributes} />
                                </div>
                                <div className={"w-100"}>
                                    <div className={"d-flex"}>
                                        <div className={"w-100"}>
                                            <div className={"d-flex align-items-center text-16px text-bold mb-1 max-width-200px"}>
                                                <span className={"me-1 text-truncate"}>{hook?.customer?.name}</span>
                                                <i className={"d-flex icon-19px text-green-theme"}>
                                                    {hook?.icons?.VerifiedIcon}
                                                </i>
                                            </div>
                                            <div className={"d-flex text-12px mb-3 opacity-8"}>
                                                <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                                                    <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.LocalPhoneIcon}</i>
                                                    <span className={"d-flex text-truncate max-width-80px"}>{hook?.customer?.phoneNumber}</span>
                                                </div>

                                                <div className={"d-flex align-items-center w-100 justify-content-start text-12px"}>
                                                    <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.ContactMailRoundedIcon}</i>
                                                    <span className={"d-flex text-truncate max-width-100px"}>{hook?.customer?.emailAddress}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"width-60px d-flex justify-content-end align-items-start"}>
                                            <Toolbar attributes={hook?.actionsMenuButton} />
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start text-12px"}>
                                        <div className={"d-flex w-100- min-height-38px border border-1px border-dashed align-items-center justify-content-start text-12px me-3 p-3 pt-1 pb-1 rounded-3px"}>
                                            <i className={"d-flex me-1 icon-17px text-12px text-info-theme"}>{hook?.icons?.WalletRoundedIcon}</i>
                                            <span className={"text-truncate text-14px text-bold"}>NGN76,000</span>
                                        </div>
                                        <div className={"d-flex w-100- min-height-38px border border-1px border-dashed align-items-center justify-content-start text-12px me-3 p-3 pt-1 pb-1 rounded-3px"}>
                                            <i className={"d-flex me-1 icon-17px text-12px text-red-theme"}>{hook?.icons?.ShoppingBasketRoundedIcon}</i>
                                            <span className={"text-truncate text-14px text-bold"}>18 orders</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* details */}
                        <div className={"mb-0 p-3 position-relative"}>
                            <div className={"bg-blue-light-theme min-height-100px rounded-3px mb-5 mt-1 p-3"}>
                                <div className={"d-flex position-relative w-100 height-45px mb-2"}>
                                    <div className={"w-100 me-2"}>
                                        <div>Contact Address</div>
                                        <div className={"text-bold"}>{hook?.customer?.contactAddress}</div>
                                    </div>
                                    <div className={"w-100 max-width-114px"}>
                                        <div>National ID.</div>
                                        <div className={"text-bold"}>{hook?.customer?.identificationNumber}</div>
                                    </div>
                                </div>
                                <div className={"d-flex position-relative height-45px mb-0"}>
                                    <div className={"w-100 me-2"}>
                                        <div>LGA. of Residence</div>
                                        <div className={"text-bold"}>NGN0.00</div>
                                    </div>
                                    <div className={"w-100 me-2"}>
                                        <div>City</div>
                                        <div className={"text-bold"}>0.00%</div>
                                    </div>
                                    <div className={"w-100"}>
                                        <div>State</div>
                                        <div className={"text-bold"}>Every month</div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className={"d-flex align-items-center justify-content-start bg-blue-light-theme min-height-100px rounded-3px mb-5 mt-0 p-3"}>
                                <div className={"w-100 me-0 border border-0px border-red-theme"}>
                                    <div className={"mb-3 text-bold line-height-14px"}>Supporting Documents</div>
                                    <div className={"w-100 height-80px position-relative overflow-hidden"}>
                                        <div className={"w-100 d-flex"}>
                                            {
                                                hook?.supportingDocuments?.map(document => {
                                                    return (
                                                        <div className={"width-65px height-80px bg-white me-3"}></div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* activity graph */}
                            {/* <div className={"m-0 mb-3"}>
                                <div className={"w-100 height-260px- bg-white-theme position-relative pb-0"}>
                                    <Line options={hook?.chartAttributes?.options} data={hook?.chartAttributes?.data} />
                                </div>
                            </div> */}

                            {/* shopping list */}
                            <div className={"m-0 mb-4"}>
                                <div className={"d-flex align-items-center justify-content-start text-18px mb-3"}>
                                    <div className={"w-100 d-flex align-items-center justify-content-start text-18px me-2"}>
                                        <div className={"d-flex align-items-center justify-content-start me-2 line-height-20px"}>
                                            <span className={"text-bold"}>Shopping List</span>
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start"}>
                                        <Toolbar attributes={hook?.shoppingListToolbarAttributes} />
                                    </div>
                                </div>
                                <div className={"min-height-160px position-relative"}>
                                    <DataGrid attributes={hook?.shoppingListDatagridAttributes} />
                                </div>
                            </div>

                            {/* order history */}
                            <div className={"m-0"}>
                                <div className={"d-flex align-items-center justify-content-start text-18px mb-3"}>
                                    <div className={"w-100 d-flex align-items-center justify-content-start text-18px me-2"}>
                                        <div className={"d-flex align-items-center justify-content-start me-2 line-height-20px"}>
                                            <span className={"text-bold"}>Order History</span>
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start"}>
                                        <Toolbar attributes={hook?.ordersToolbarAttributes} />
                                    </div>
                                </div>
                                <div className={"min-height-160px position-relative"}>
                                    <DataGrid attributes={hook?.ordersDatagridAttributes} />
                                </div>
                            </div>
                        </div>

                        {/* fund customer */}
                        <ModalDialog attributes={hook?.fundCustomerModalDialogAttributes} />

                        {/* customer transfer */}
                        <ModalDialog attributes={hook?.customerTransferModalDialogAttributes} />

                        {/* bank transfer */}
                        <ModalDialog attributes={hook?.bankTransferModalDialogAttributes} />

                    </div>
                </div>
            </div >
        </div >
    );
}

export default CustomerDetailsView;