import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { ALIGNMENTTYPE, COLUMNTYPE, COMPONENTYPE, DOCKTYPE, MENUALIGNMENTTYPE } from "../../enums/enums";
import ToggleIcon from "../toggle.icon/toggle.icon.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const MultiSelectDropDownField = forwardRef(({ attributes }, ref) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [columns, setColumns] = useState([]);
    const [items, setItems] = useState([]);

    const componentObserver = useRef();
    const selectableDropDownList = useMultiSelectDropDownField(attributes);
    const selectedItemTheme = "bg-blue-theme";

    const collapseAll = useCallback((e) => {
        [...document.querySelectorAll("div[aria-owns=\"dropdown\"]")].forEach((item, i) => {
            item.classList.add("hide");
        });
    }, []);

    useImperativeHandle(attributes?.observer, () => ({
        addToStore(items) {
            if (!componentObserver?.current?.hasAttribute("items-store")) {
                componentObserver?.current?.setAttribute("items-store", JSON.stringify(items));
            }
        },

        clearItems() {
            componentObserver?.current?.removeAttribute("selected-items-store");
            componentObserver?.current?.removeAttribute("selected-items");
        },

        selectItems(modelList) {

            if (!componentObserver?.current?.hasAttribute("selected-items")) {
                componentObserver?.current?.setAttribute("selected-items", JSON.stringify(modelList));
            } else {
                modelList = JSON.parse(componentObserver?.current?.getAttribute("selected-items"));
                componentObserver?.current?.setAttribute("selected-items-store", JSON.stringify(modelList));
            }

            const label = componentObserver?.current?.querySelector("div[aria-controls=\"label\"]");
            const field = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
            setTimeout(() => {
                const values = [];
                const titles = [];
                modelList.filter((x) => {
                    values.push(x[attributes?.value]);
                    titles.push(x[attributes?.title]);
                    const itemTableGrid = componentObserver?.current?.querySelector("div[aria-controls=\"table-grid\"]").querySelector("table");
                    const selectedItem = itemTableGrid.querySelector("tr[id=\"" + x[attributes?.value] + "\"]");
                    selectableDropDownList.toggleItem(selectedItem);
                    return null;
                });

                label.innerHTML = titles.join(',');
                field.value = JSON.stringify(values);
            }, 1000);

        }
    }))

    useEffect(() => {
        setColumns([
            {
                style: themes?.width1px
            },
            {
                type: COLUMNTYPE.CHECKBOX,
                value: attributes?.value,
                style: themes?.width15px
            },
            {
                style: themes?.width5px
            },
            {
                value: attributes?.title,
                default: "",
                style: themes?.width100
            }
        ]);

        setItems(attributes?.items);

        if (componentObserver?.current?.hasAttribute("selected-items")) {
            componentObserver?.current?.removeAttribute("selected-items");

            // 
            if (componentObserver?.current?.hasAttribute("selected-items-store")) {
                componentObserver?.current?.setAttribute("selected-items", componentObserver?.current?.getAttribute("selected-items-store"));
            }
        }

        selectableDropDownList.resize();
        window.addEventListener("resize", selectableDropDownList.resize);
        document.body.addEventListener("click", collapseAll);

        const hiddenField = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
        if (hiddenField.value.length > 0 && hiddenField.value !== attributes?.model[attributes?.key]) {
            return;
        }

        const label = componentObserver?.current?.querySelector("div[aria-controls=\"label\"]");
        label.innerHTML = "" || attributes?.placeholder;
        if (attributes?.model && attributes?.model.length > 0 && attributes?.model.filter(x => x !== null).length > 0) {
            const model = attributes?.model.find(x => x.hasOwnProperty(attributes?.key));
            if (model) {
                label.innerHTML = (model ? model[attributes?.title] : null) || attributes?.placeholder;
                hiddenField.value = attributes?.model[attributes?.key];
            }
        }

        // resize component
        componentObserver?.current?.classList.add("max-width-" + componentObserver?.current?.parentNode.offsetWidth + "px");

        // garbage collection
        return () => {
            window.removeEventListener("resize", selectableDropDownList.resize);
            document.body.removeEventListener("click", collapseAll);
        }

    }, [attributes])

    const getMenuAlignment = (e) => {
        switch (e) {
            case MENUALIGNMENTTYPE.LEFT:
                return "top-37px start-0px";
            case MENUALIGNMENTTYPE.RIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.TOP:
                return;
            case MENUALIGNMENTTYPE.BOTTOMLEFT:
                return "top-37px start-0px";
            default:
                return "top-37px end-0px";
        }
    }

    const handleCheckBoxClick = (e, dataObject) => {
        const tr = e.currentTarget.parentNode.parentNode.parentNode.parentNode.parentNode;
        const tbody = tr.parentNode;
        const rows = tbody.querySelectorAll("*[aria-controls=\"table-row\"]");
        const selectedCheckboxes = [];
        if (attributes?.isSingleSelect) {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                checkbox.checked = false;
            });

            const selectedCheckedBox = e.target;
            selectedCheckedBox.checked = true;

            // hidden field
            const hiddenField = tbody.parentNode.parentNode.querySelector("input[type=\"hidden\"]");
            hiddenField.value = selectedCheckedBox.parentNode.parentNode.parentNode.parentNode.parentNode.getAttribute("id");
        } else {
            [...rows].forEach(row => {
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (!checkbox.checked) {
                    const rowTheme = row.querySelector("*[aria-controls=\"table-row-header\"]");
                    rowTheme?.classList?.remove(selectedItemTheme);
                    row?.classList?.remove(selectedItemTheme);
                    row?.removeAttribute("aria-selected");
                }

                // build selected checkedbox array
                if (checkbox.checked) {
                    selectedCheckboxes.push(row.getAttribute("id"));
                }
            });

            // hidden field
            const hiddenField = tbody.parentNode.parentNode.querySelector("input[type=\"hidden\"]");
            hiddenField.value = JSON.stringify(selectedCheckboxes);
        }

        if (attributes?.onSelected) {
            attributes?.onSelected({
                selectedItem: dataObject
            });
        }

        (e.currentTarget.checked) ? tr?.classList?.add(selectedItemTheme) : tr?.classList?.remove(selectedItemTheme);
        e.stopPropagation();
    }

    const handleOnClick = (e) => {
        try {
            const searchField = componentObserver?.current?.querySelector("input[type=\"text\"]");
            const dropdown = componentObserver.current.querySelector("*[aria-owns=\"dropdown\"]");
            if (dropdown.classList.contains("hide")) {
                collapseAll(e);
                dropdown.classList.remove("hide");
                if (attributes.onClick) {
                    attributes.onClick(e, {
                        key: ((e) => componentObserver.current.parentNode.parentNode.parentNode.getAttribute("aria-rowindex"))()
                    });
                }

                // clear search field and set focus
                searchField.value = "";
                searchField.focus();

                // reset list
                attributes.items = JSON.parse(componentObserver?.current?.getAttribute("items-store"));
                setItems(attributes.items);

                // set selected items
                setTimeout(() => {
                    const values = [];
                    const titles = [];
                    const selectedItems = JSON.parse(componentObserver?.current?.getAttribute("selected-items")) || [];
                    selectedItems.filter((x) => {
                        values.push(x[attributes?.value]);
                        titles.push(x[attributes?.title]);
                        const itemTableGrid = componentObserver?.current?.querySelector("div[aria-controls=\"table-grid\"]").querySelector("table");
                        const selectedItem = itemTableGrid.querySelector("tr[id=\"" + x[attributes?.value] + "\"]");
                        selectableDropDownList.selectItem(selectedItem);
                        return null;
                    });
                });

            } else {
                dropdown.classList.add("hide");
            }
            e.stopPropagation();
        } catch (e) { }
    }

    const handleDropdownStopPropagation = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();
    }

    const handleOnFilter = (e) => {
        if (componentObserver?.current?.hasAttribute("selected-items")) {
            componentObserver?.current?.setAttribute("selected-items", componentObserver?.current?.getAttribute("selected-items"));
        }

        if (attributes?.onLookupChange)
            attributes?.onLookupChange(e.target);
    }

    const handleItemClick = (e, dataObject, evt) => {

        // get items in store
        let selectedItems = JSON.parse(componentObserver?.current?.getAttribute("selected-items")) || [];

        // toggle selected item
        selectableDropDownList.toggleItem(e);

        const tr = e;
        const tbody = tr.parentNode;
        const rows = tbody.querySelectorAll("*[aria-controls=\"table-row\"]");
        let selectedCheckboxes = [];
        let selectedItemTitles = [];

        selectedItems.forEach(item => {
            selectedCheckboxes.push(item[attributes?.value]);
            selectedItemTitles.push(item[attributes?.title]);
        });

        [...rows].forEach(row => {
            const id = row.getAttribute("id");
            const title = row.getAttribute("data-title");

            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (!checkbox.checked) {
                const rowTheme = row.querySelector("*[aria-controls=\"table-row-header\"]");
                rowTheme?.classList?.remove(selectedItemTheme);
                row?.classList?.remove(selectedItemTheme);
                row?.removeAttribute("aria-selected");

                if (selectedItemTitles.find(x => x === title)) {
                    selectedItemTitles = selectedItemTitles.filter((selectedTitle) => {
                        return selectedTitle !== title
                    })
                }

                if (selectedCheckboxes.find(x => x === id)) {
                    selectedCheckboxes = selectedCheckboxes.filter((selectedId) => {
                        return selectedId !== id
                    })
                }

                // remove item from list
                if (selectedItems.find(x => x[attributes?.value] === id)) {
                    selectedItems = selectedItems.filter((selectedItem) => {
                        return selectedItem[attributes?.value] !== id
                    })
                }
            }

            // build selected checkedbox array
            if (checkbox.checked) {
                if (!selectedCheckboxes.find(x => x === id))
                    selectedCheckboxes.push(id);

                if (!selectedItemTitles.find(x => x === title))
                    selectedItemTitles.push(title);

                if (!selectedItems.find(x => x[attributes?.value] === id)) {
                    const newItem = {};
                    newItem[attributes?.value] = id;
                    newItem[attributes?.title] = title;
                    selectedItems.push(newItem);
                }
            }
        });

        // update selected items store
        componentObserver?.current?.setAttribute("selected-items", JSON.stringify(selectedItems));
        componentObserver?.current?.setAttribute("selected-items-store", JSON.stringify(selectedItems));

        // set hidden field value
        const field = componentObserver?.current?.querySelector("input[type=\"hidden\"]");
        field.value = JSON.stringify(selectedCheckboxes);

        // set label text
        const label = componentObserver?.current?.querySelector("div[aria-controls=\"label\"]");
        label.innerHTML = selectedItemTitles.join(',');

        // trigger on selected event handler
        if (attributes?.onSelected) {
            attributes?.onSelected({
                selectedItem: dataObject
            });
        }

        // clear search field and set focus
        const searchField = componentObserver?.current?.querySelector("input[type=\"text\"]");
        searchField.value = "";
        searchField.focus();

        // get default list items
        attributes.items = JSON.parse(componentObserver?.current?.getAttribute("items-store"));
        setItems(attributes.items);

        // set selected items
        setTimeout(() => {
            const values = [];
            const titles = [];
            const selectedItems = JSON.parse(componentObserver?.current?.getAttribute("selected-items")) || [];
            selectedItems.filter((x) => {
                values.push(x[attributes?.value]);
                titles.push(x[attributes?.title]);
                const itemTableGrid = componentObserver?.current?.querySelector("div[aria-controls=\"table-grid\"]").querySelector("table");
                const selectedItem = itemTableGrid.querySelector("tr[id=\"" + x[attributes?.value] + "\"]");
                selectableDropDownList.selectItem(selectedItem);
                return null;
            });
        }, 100);

        evt.cancelBubble = true;
        evt.stopPropagation();
    }

    const listItem = ({ auto, attributes, dataObject, isFirstOrDefault }) => {
        if (dataObject[attributes.key]) {
            return (
                <tr
                    aria-controls="table-row"
                    key={dataObject[attributes.key]}
                    aria-rowindex={auto}
                    id={"" + dataObject[attributes.key]}
                    data-title={"" + dataObject[attributes.title]}
                    aria-selected={(isFirstOrDefault ? "true" : "")}
                    className={"w-100"}>
                    <td className={"border-0px p-0"}>
                        {/* row header */}
                        <div
                            onClick={(e) => handleItemClick(e.currentTarget.parentNode.parentNode, dataObject, e)}
                            aria-controls="table-row-header"
                            aria-atomic={((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme" : "bg-white-theme")}
                            className={"w-100 padding-top-1px padding-bottom-1px ps-0 rounded-0 box-sizing-border d-flex justify-content-start align-items-center cursor-pointer " + (isFirstOrDefault ? "" : "") + ((attributes?.style?.row) ? attributes?.style?.row : "") + ((attributes?.isStripped && (auto % 2 === 1)) ? "bg-blue-light-theme" : "")}>
                            {
                                (() => {
                                    return columns?.map((column, i) => {
                                        return (
                                            <div
                                                aria-controls={"row-column"}
                                                aria-colindex={"row-column-" + i}
                                                key={dataObject[attributes.key.column] + "-column-" + i}
                                                className={"d-flex align-items-center box-sizing-border " + column?.style + " min-height-" + (attributes?.listItemHeight ? attributes?.listItemHeight : 25) + "px"}>
                                                {
                                                    (() => {
                                                        let columnText = dataObject[column?.value]?.toString() || "";

                                                        if ((column?.type === COLUMNTYPE.CHECKBOX)) {
                                                            const primaryKey = attributes?.key;
                                                            return (
                                                                <input
                                                                    onClick={(e) => handleCheckBoxClick(e, dataObject)}
                                                                    className={"form-check-input rounded-1 width-15px height-15px cursor-pointer m-0"}
                                                                    type={"checkbox"}
                                                                    disabled={true}
                                                                    value={dataObject[primaryKey]}
                                                                    id={dataObject[primaryKey]}
                                                                    name={dataObject[primaryKey]}
                                                                />
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.AUTO) {
                                                            return (
                                                                <div className={"white-space-nowrap text-overflow-ellipsis pt-1 pb-1 " + column?.style.value}>{auto + 1}</div>
                                                            )
                                                        }
                                                        else if (column.type === COLUMNTYPE.ICON) {
                                                            return (
                                                                <ToggleIcon attributes={{
                                                                    leading: column?.leading
                                                                }} />
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <div className={"w-100 d-flex justify-content-between"}>
                                                                    <div className={"w-100 flex-column"}>
                                                                        <div className={"text-truncate pt-1 pb-1 w-100- box-sizing-border " + ((column?.textAlign === ALIGNMENTTYPE.RIGHT) ? "text-end" : "")}>{columnText}</div>
                                                                        {
                                                                            (() => {
                                                                                if (column?.widget) {
                                                                                    return (
                                                                                        <div className={"white-space-nowrap text-overflow-ellipsis text-11px opacity-8 pt-0 pb-1 w-100 box-sizing-border " + (column?.widget ? "" : "hide")}>
                                                                                            {column?.widget}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })()
                                                                        }
                                                                    </div>
                                                                    <div className={"width-3px me-1 "}></div>
                                                                </div>
                                                            )
                                                        }
                                                    })()
                                                }
                                            </div>
                                        );
                                    })
                                })()
                            }
                        </div>
                    </td>
                </tr>
            );
        }
    }

    const noRecordMessageLabel = () => {
        return (
            <tr className={"w-100"}>
                <td className={"border-0px p-0"}>
                    <div className={"d-flex justify-content-center align-items-center mx-auto max-width-350px pt-2 pb-2 mt-0"}>Oops! no records found.</div>
                </td>
            </tr>
        );
    }

    return (
        <div aria-controls={"selectable-dropdown-list-field"} id={attributes?.name} ref={componentObserver} className={"w-100 d-flex position-relative"}>
            <div type={attributes?.type} className={"position-relative form-control ps-2 d-flex justify-content-center align-items-center " + (((attributes?.style?.g) ? attributes?.style?.g : " border-0px height-34px") + ((attributes?.style?.bgColor) ? attributes?.style?.bgColor : "btn-grey-theme"))} onClick={handleOnClick}>
                <div aria-controls="label" className={"text-truncate d-grid " + (attributes?.style?.text)}>{attributes?.text || attributes?.placeholder}</div>
                <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center m-0 ms-1 icon " + attributes?.style?.trailing}>
                    {icons?.ExpandMoreRoundedIcon}
                </i>
            </div>
            <div onClick={handleDropdownStopPropagation} aria-owns="dropdown" className={"select-dropdown-menu hide position-absolute z-index-10 min-width-100- " + getMenuAlignment(attributes?.menuPosition)}>
                <div className={"bg-white border border-1px transparent-border-blue-dark-theme shadow padding-8px rounded-3 w-100 min-width-50px min-height-50px display-inline-block float-start"}>
                    <div id={attributes?.name} className={"d-flex align-items-center position-relative form-control ps-2 text-14px margin-bottom-8px " + ((attributes?.style) ? attributes?.style?.textField : " rounded-pill padding-top-2px padding-bottom-2px padding-start-1px padding-end-1px")}>
                        <div className={"hide"}>
                            <div aria-controls="placeholder" type={attributes?.type} className={"border-0px cursor-pointer " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.text) ? attributes?.style?.text : "shadow-sm- border-0px height-34px"))}>
                                <div className={"position-relative d-flex justify-content-center align-items-center"}>
                                    <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-0 pe-0" : " ps-0 pe-0")}>
                                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                            {attributes?.leading}
                                        </i>
                                        <span aria-controls="label" className={"white-space-nowrap text-italic opacity-6 " + (attributes?.style?.text) + " " + (attributes?.leading ? "" : "ms-1 me-1")}>{attributes?.placeholder}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div aria-controls="field-tokenbar"></div>
                        <div className={"d-flex align-items-center position-relative w-100"}>
                            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 me-2 " + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                                {icons.SearchRoundedIcon}
                            </i>
                            <input
                                onChange={handleOnFilter}
                                placeholder={attributes?.placeholder || "Search..."}
                                type={'text'}
                                className={"w-100 form-control text-13px rounded-3px border-0px p-0 bg-transparent"} />
                            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                                {attributes?.trailing}
                            </i>
                        </div>
                    </div>

                    <div aria-controls={"table-grid"} ref={componentObserver} id={"dg-" + attributes?.name} className={attributes?.style?.dropdown}>
                        <div aria-owns={attributes?.name} className={((attributes?.dockType === DOCKTYPE.FILL) ? ((!attributes.hasOwnProperty("showHeader") || attributes?.showHeader) ? "top-37px " : "top-7px ") + "position-absolute start-0px end-0px bottom-0px overflow-x-hidden- " + ((attributes?.hasOwnProperty("allowScrolling") && attributes?.allowScrolling === false) ? "" : "overflow-y-auto") : "")}>
                            <input
                                id={attributes?.key}
                                name={attributes?.key}
                                aria-owns={attributes?.name}
                                aria-controls={attributes?.formElement}
                                aria-roledescription={COMPONENTYPE.DATAGRID}
                                aria-required={attributes?.validate}
                                type={"hidden"} />
                            <table className={"table table-hover- w-100 mb-0 text-13px box-sizing-border table-layout-fixed"}>
                                <tbody>
                                    {
                                        (() => {
                                            if (items?.length > 0) {
                                                return items?.map((data, i) => {
                                                    return listItem({
                                                        isFirstOrDefault: (i === 0),
                                                        auto: i,
                                                        key: (data[attributes.key.column] + "-" + i),
                                                        attributes: attributes,
                                                        dataObject: data
                                                    });
                                                })
                                            } else {
                                                return noRecordMessageLabel();
                                            }
                                        })()
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div >
                </div>
            </div>
        </div>
    )

})

const useMultiSelectDropDownField = (attributes) => {

    const selectedItemTheme = "bg-blue-lighter-theme";

    const addSelectedItem = () => {

    }

    const addSelectedItems = () => {

    }

    const clearSelection = () => {
        const dgrid = target();
        if (dgrid) {
            const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            [...selectedItems].forEach(row => {
                row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedItemTheme);
                row.classList.remove(selectedItemTheme);
                row?.removeAttribute("aria-selected");
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }
    }

    const getPaging = () => {
        const _target = target();
        return {
            pageNumber: parseInt(_target.getAttribute("pagenumber")) || attributes.page.pageNumber,
            rowsPerPage: parseInt(_target.getAttribute("rowsperpage")) || attributes.page.rowsPerPage,
            totalPages: parseInt(_target.getAttribute("totalpages")),
            totalItems: parseInt(_target.getAttribute("totalrows"))
        };
    }

    const getSelectedItem = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.find(x => x.checked);
        }
    }

    const getSelectedItemIndex = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const selectedItem = tbody.querySelector("tr[aria-selected=\"true\"]");
        if (selectedItem) {
            const selectedIndex = selectedItem.getAttribute("aria-rowindex");
            return selectedIndex;
        } else {
            return 0;
        }
    }

    const getSelectedItemKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked)[0].value;
        }
    }

    const getSelectedItemsKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).map(item => item.value);
        }
    }

    const getSelectedItems = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            [...checkboxes].forEach(checkbox => {
                //
            });
        }
    }

    const headerColumns = () => {
        const dgrid = target();
        const thead = dgrid?.querySelector("thead");
        if (thead) {
            const columns = thead.querySelectorAll("*[aria-controls=\"header-column\"]");
            return [...columns];
        } else {
            return [];
        }
    }

    const isItemSelected = () => {

    }

    const itemsCount = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid.querySelector("tbody");
            const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
            return checkboxes.length;
        }
        return 0;
    }

    const resize = () => {
        const datagridHeaderColumns = headerColumns();
        const datagridItems = rows();
        datagridItems?.forEach((row, i) => {
            const columns = row.querySelectorAll("*[aria-controls=\"row-column\"]");
            datagridHeaderColumns?.forEach((column, i) => {
                columns[i]?.setAttribute("style", "max-width:" + column?.offsetWidth + "px");
            });
        });
    }

    const rows = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid?.querySelector("tbody");
            const rows = tbody?.querySelectorAll("*[aria-controls=\"table-row\"]");
            return [...rows];
        }
        return [];
    }

    const rowColumns = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const columns = tbody.querySelectorAll("*[aria-controls=\"row-column\"]");
        return [...columns];
    }

    const selectDefaultItem = (attributes) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove(selectedItemTheme);
            const checkbox = item.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });
        if (selectedItems.length > 0) {
            selectedItems[0]?.classList.add(selectedItemTheme);
            const selectedCheckbox = selectedItems[0].querySelector("input[type=\"checkbox\"]");
            if (selectedCheckbox)
                selectedCheckbox.checked = true;
        }
    }

    const selectItem = (selectedItem) => {
        const selectedItemCheckbox = selectedItem?.querySelector("input[type=\"checkbox\"]");
        if (selectedItemCheckbox) {
            selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedItemTheme);
            selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedItem.getAttribute("aria-atomic"));
            selectedItem?.setAttribute("aria-selected", true);
            selectedItemCheckbox.checked = true;
        }
    }

    const unselectItem = (selectedItem) => {
        const selectedItemCheckbox = selectedItem?.querySelector("input[type=\"checkbox\"]");
        if (selectedItemCheckbox) {
            selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedItemTheme);
            selectedItem?.setAttribute("aria-selected", false);
            selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedItem.getAttribute("aria-atomic"));
            selectedItemCheckbox.checked = false;
        }
    }

    const toggleItem = (selectedItem) => {
        const selectedItemCheckbox = selectedItem?.querySelector("input[type=\"checkbox\"]");
        if (selectedItemCheckbox)
            if (selectedItemCheckbox && selectedItemCheckbox.checked !== true) {
                selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedItemTheme);
                selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedItem.getAttribute("aria-atomic"));
                selectedItem?.setAttribute("aria-selected", true);
                selectedItemCheckbox.checked = true;
            } else {
                selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedItemTheme);
                selectedItem?.setAttribute("aria-selected", false);
                selectedItem?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedItem.getAttribute("aria-atomic"));
                selectedItemCheckbox.checked = false;
            }
    }

    const selectedItemsCount = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).length;
        }
        return 0;
    }

    const setSelectedItem = (key) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(row => {
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        const selectedItem = dgrid.querySelector("*[id=\"" + key + "\"]");
        const checkbox = selectedItem.querySelector("input[type=\"checkbox\"]");
        if (checkbox && checkbox.value === key) {
            checkbox.checked = true;
            selectedItem?.setAttribute("aria-selected", true);
        }
    }

    const target = () => {
        return document.getElementById("dg-" + attributes?.name);
    }

    const unselectAllItem = (selectedItem) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove(selectedItemTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = false;
        });
        if (selectedItems.length > 0) {
            selectedItems[0]?.classList.add(selectedItemTheme);
            selectedItems[0].querySelector("input[type=\"checkbox\"]").checked = true;
        }
    }

    const unselectCheckedItem = (selectedItem) => {
        const dgrid = target();
        const selectedItems = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedItems].forEach(item => {
            item.classList.remove("bg-light-theme");
        });
        selectedItem?.classList.add("bg-light-theme");
    }

    return {
        addSelectedItem,
        addSelectedItems,
        clearSelection,
        target,
        getPaging,
        getSelectedItem,
        getSelectedItemIndex,
        getSelectedItemKeyValue,
        getSelectedItems,
        getSelectedItemsKeyValue,
        headerColumns,
        isItemSelected,
        itemsCount,
        resize,
        rows,
        rowColumns,
        selectDefaultItem,
        selectItem,
        setSelectedItem,
        selectedItemsCount,
        unselectItem,
        unselectAllItem,
        unselectCheckedItem,
        toggleItem
    }

}

export { MultiSelectDropDownField, useMultiSelectDropDownField };