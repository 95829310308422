import React from 'react';
import useMarketsHook from './markets.hook';
import { Grid } from '../../../../shared/components/grid/grid.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';

const MarketsView = () => {

    const hook = useMarketsHook();

    return (
        <div>
            {hook?.toolbar}
            <div className={"w-100 position-absolute end-0px top-50px start-0px bottom-0px "}>
                <Grid attributes={hook?.gridViewAttributes} />
            </div>

            <CalloutWindow attributes={hook?.calloutWindowAttributes} />
        </div >
    );
}

export default MarketsView;