import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const BlogCard = (props) => {
    const { attributes } = props;
    const wdgMediaClassName = (attributes?.themes) ? attributes?.themes?.media : "mx-auto min-width-100px min-height-200px";
    const wdgCaptionClassName = (attributes?.themes) ? attributes?.themes?.caption : "text-60px text-bold";
    const wdgTitleClassName = (attributes?.themes) ? attributes?.themes?.title : "text-25px text-bold mb-3";
    const wdgDescriptionClassName = (attributes?.themes) ? attributes?.themes?.description : "text-16px line-height-20px mb-4";

    useEffect(() => {
        // 
    }, [attributes])

    return (
        <div className={""}>
            <div className={"position-relative mb-3 " + ((attributes?.data?.media) ? "show" : "hide")}>
                <div className={wdgMediaClassName}>
                    <div className={"position-absolute top-0px bottom-0px start-0px end-0px"}></div>
                    <div className={attributes?.data?.media?.banner + " position-absolute top-0px bottom-0px start-0px end-0px z-index-1"}></div>
                </div>
                <div className={"position-absolute bottom-0px z-index-1 p-3 pt-5 bg-dark-theme-fade-top"}>
                    <div className={((attributes?.data?.caption) ? "show" : "hide")}>
                        <h1 className={wdgCaptionClassName}>{attributes?.data?.caption}</h1>
                    </div>
                    <h1 className={wdgTitleClassName + ""}>{attributes?.data?.title}</h1>
                    <div className={wdgDescriptionClassName + ""}>{attributes?.data?.description}</div>
                    <div className={attributes?.data.buttons?.className + " d-flex " + (attributes?.data.buttons?.items ? "" : "")}>
                        {
                            attributes?.data.buttons?.items?.map((button, i) => {
                                return (
                                    <div key={i} className={button.className + " d-flex text-14px p-2 ps-3 pe-3 rounded-3px"}>
                                        <span className={"text-bold"}>{button?.text}</span>
                                        <i className={""}></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={" d-flex "}>
                        {
                            attributes?.data.links?.map((link, i) => {
                                return (
                                    <div key={i} className={"d-flex text-14px p-2 ps-0 pe-3 rounded-3px"}>
                                        <Link to={""} className={link.className + " text-decoration-none text-blue-theme"}>
                                            <span className={"text-bold"}>{link?.text}</span>
                                            <i className={""}></i>
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogCard