import React from 'react';
import HorizontalSpace from '../../../../../shared/components/separator/horizontal.space.component';
import { Tab } from '../../../../../shared/components/tab/tab.component';
import { Avatar } from '../../../../../shared/components/avatar';
import { StackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import useTransactionDetailsHook from './transaction.details.hook';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { DataGrid } from '../../../../../shared/components/datagrid';
import { Line } from 'react-chartjs-2';
import Flag from '../../../../../shared/components/flag/flag.component';
import DispatchMap from '../../../../../shared/components/map/dispatch.map';

const TransactionDetailsView = ({ attributes }) => {

    const hook = useTransactionDetailsHook(attributes);

    return (
        <div className={"w-100 position-relative p-0 pt-0 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <div className={"w-100 d-flex"}>
                    <div className={"w-100 flex-column"}>
                        {/* media upload */}
                        <div className={"mb-0 p-3 pb-0 rounded-5px min-height-80px"}>
                            <div className={"d-flex w-100 mb-3"}>
                                <div className={"me-3 p-0 max-width-100px min-width-100px height-100px position-relative"}>
                                    <Avatar attributes={hook?.profilePhotoAttributes} />
                                </div>
                                <div className={"w-100"}>
                                    <div className={"d-flex"}>
                                        <div className={"w-100"}>
                                            <div className={"d-flex align-items-center text-16px text-bold mb-1 max-width-200px-"}>
                                                <span className={"me-2 text-truncate"}>{hook?.transaction?.customerName}</span>
                                                <Flag attributes={hook?.statusFlagAttributes} />
                                            </div>
                                            <div className={"d-flex text-12px mb-3 opacity-8"}>
                                                <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                                                    <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.LocalPhoneIcon}</i>
                                                    <span className={"d-flex text-truncate max-width-80px"}>{hook?.transaction?.phoneNumber}</span>
                                                </div>

                                                <div className={"d-flex align-items-center w-100 justify-content-start text-12px"}>
                                                    <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.ContactMailRoundedIcon}</i>
                                                    <span className={"d-flex text-truncate max-width-150px"}>{hook?.transaction?.emailAddress}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"width-35px d-flex justify-content-end align-items-start"}>
                                            <Toolbar attributes={hook?.actionsMenuButton} />
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start text-12px"}>
                                        <div className={"d-flex w-100- max-width-160px min-height-38px btransaction btransaction-1px btransaction-dashed align-items-center justify-content-start text-12px me-3 p-3 pt-1 pb-1 rounded-3px"}>
                                            <i className={"d-flex me-1 icon-17px text-12px"}>{hook?.icons?.CreditCardOffRoundedIcon}</i>
                                            <span className={"text-truncate text-14px text-bold"}>NGN 76,000</span>
                                        </div>
                                        <div className={"d-flex w-100- max-width-120px min-height-38px btransaction btransaction-1px btransaction-dashed align-items-center justify-content-start text-12px me-3 p-3 pt-1 pb-1 rounded-3px"}>
                                            <i className={"d-flex me-1 icon-17px text-12px text-warning"}>{hook?.icons?.ShoppingBasketRoundedIcon}</i>
                                            <span className={"text-truncate text-14px text-bold"}>15 items</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={""}>
                            <HorizontalSpace attributes={hook?.horizontalSeparatorAttributes} />
                        </div>

                        {/* details */}
                        <div className={"mb-0 p-3 position-relative"}>
                            <div className={"bg-blue-light-theme min-height-100px rounded-3px mb-2 mt-1 p-3"}>
                                <div className={"d-flex position-relative height-45px mb-2"}>
                                    <div className={"w-100 me-2"}>
                                        <div>Delivery:</div>
                                        <div className={"text-bold"}>{hook?.transaction?.deliveryFee}</div>
                                    </div>
                                    <div className={"w-100 me-2"}>
                                        <div>Market Agent Fee:</div>
                                        <div className={"text-bold"}>NGN0.00</div>
                                    </div>
                                    <div className={"w-100"}>
                                        <div>Service Charge:</div>
                                        <div className={"text-bold"}>{hook?.transaction?.serviceCharge}</div>
                                    </div>
                                </div>
                                <div className={"d-flex position-relative height-45px mb-0"}>
                                    <div className={"w-100 me-2"}>
                                        <div>VAT</div>
                                        <div className={"text-bold"}>NGN0.00</div>
                                    </div>
                                    <div className={"w-100 me-2"}>
                                        <div>Products</div>
                                        <div className={"text-bold"}>0.00%</div>
                                    </div>
                                    <div className={"w-100"}>
                                        <div>Payment Method</div>
                                        <div className={"text-bold"}>{hook?.transaction?.paymentMethod}</div>
                                    </div>
                                </div>
                            </div>

                            {/* market agent details */}
                            <div className={"d-flex position-relative height-45px mb-4"}>
                                <div className={"w-100 d-flex align-items-center justify-content-start mb-0"}>
                                    <div className={"max-width-35px min-width-35px height-35px position-relative me-2 rounded-3 btransaction btransaction-2px btransaction-white-theme"}>
                                        <Avatar attributes={hook?.shopperPhotoAttributes} />
                                    </div>
                                    <div>
                                        <div className={"d-flex align-items-center text-13px text-bold mb-0 max-width-200px-"}>
                                            <span className={"me-2 text-truncate"}>{hook?.transaction?.shopper}</span>
                                            <i className={"d-flex icon-13px text-12px"}>{hook?.icons?.VerifiedIcon}</i>
                                        </div>
                                        <div className={"d-flex text-12px m-0 opacity-8"}>
                                            <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                                                <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.LocalPhoneIcon}</i>
                                                <span className={"d-flex text-truncate max-width-80px"}>{hook?.transaction?.phoneNumber}</span>
                                            </div>

                                            <div className={"d-flex align-items-center w-100 justify-content-start text-12px"}>
                                                <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.ContactMailRoundedIcon}</i>
                                                <span className={"d-flex text-truncate max-width-150px"}>{hook?.transaction?.shopperEmailAddress}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"m-0 mb-5"}>
                                <div className={"d-flex align-items-center justify-content-start text-18px mb-3"}>
                                    <div className={"w-100 d-flex align-items-center justify-content-start text-18px me-2"}>
                                        <div className={"d-flex align-items-center justify-content-start me-2 line-height-20px"}>
                                            {/* <i className={"m-0 me-2"}>{hook?.icons.ShoppingBasketRoundedIcon}</i> */}
                                            <span className={"text-bold"}>Transaction</span>
                                        </div>
                                        <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                                            <i className={"d-flex m-0 icon-13px"}>{hook?.icons?.TagIcon}</i>
                                            <span className={"d-flex text-truncate max-width-80px line-height-13px"}>{hook?.transaction?.transactionId}</span>
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start"}>
                                        {/* <Toolbar attributes={hook?.transactionToolbarAttributes} /> */}
                                    </div>
                                </div>
                                <div className={"height-260px position-relative"}>
                                    <DataGrid attributes={hook?.transactionProductsDatagrid?.attributes} />
                                </div>
                            </div>

                        </div>

                        {/* fund wallet */}
                        <ModalDialog attributes={hook?.fundTransactionModalDialogAttributes} />

                        {/* wallet transfer */}
                        <ModalDialog attributes={hook?.walletTransferModalDialogAttributes} />

                        {/* bank transfer */}
                        <ModalDialog attributes={hook?.bankTransferModalDialogAttributes} />

                    </div>
                </div>
            </div >
        </div >
    );
}

export default TransactionDetailsView;