import React from 'react';
import { ModalDialogCollection } from '../../../shared/components/dialog/modal.dialog.collection.component';
import { StackablePanel } from '../../../shared/components/stackable.panel/stackable.panel.component';
import useUserManagementHook from './user.management.hook';

const UserManagementView = () => {

    const hook = useUserManagementHook();

    return (
        <div>
            {/* categories, variations, colors & types management stackable panel */}
            <StackablePanel attributes={hook?.stackablePanelAttributes} />

            {/* create or edit category modal */}
            <ModalDialogCollection attributes={hook?.modalDialogCollectionAttributes} />

        </div>
    );
}

export default UserManagementView;