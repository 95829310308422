import { useCallback, useEffect, useRef, useState } from 'react';
import { UserService } from '../user.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, COMPONENTYPE, DOCKTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditUserHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [users, setUsers] = useState([]);
    const [user, setUser] = useState([]);
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const categoryDgridName = "createoredit-parent-category-datagrid";
    const parentCategoriesDatagrid = useDataGrid({
        name: categoryDgridName
    });
    const categoryTokenSearchFieldName = "createoredit-parent-category-token-search-field";
    const parentCategoriesTokenSearchField = useTokenSearchField({
        name: categoryTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const userService = UserService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {

        // clear token
        parentCategoriesTokenSearchField?.clear();

        // clear datagrid
        parentCategoriesDatagrid.clearSelection();

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

            // add token
            if ((args?.data?.parentId !== null) && (args?.data?.parentId !== "null")) {
                parentCategoriesTokenSearchField?.addToken({
                    value: args?.data?.parentId,
                    text: args?.data?.parentName,
                    onButtonClick: (e, args) => {
                        parentCategoriesDatagrid.clearSelection();
                    }
                });
            }
        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setUser({
            ...user,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        //
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await userService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await userService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleCancelSelectedParent = (args) => {
        //
    }

    return {
        user: user,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // media
        mediaHeaderLabelAttributes: {
            text: "User Media Gallery:",
            style: themes?.label?.textUpperCaseBold13px
        },
        mediaUploaderAttributes: {
            formElement: true,
            id: "media",
            value: user?.media,
            style: themes?.mediaGallery?.card180px,
            observer: componentObserver
        },

        // user details header label
        userDetailsHeaderLabelAttributes: {
            text: "User Basic Details",
            style: themes?.label?.textUpperCaseBold13px
        },

        // user name
        userNameLabelAttributes: {
            text: "User name:",
            style: themes?.label?.text13px
        },
        userNameFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for this user",
            id: "userName",
            value: user?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // user description
        userDescriptionLabelAttributes: {
            text: "Description:",
            style: themes?.label?.text13px
        },
        userDescriptionFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Briefly describe this user",
            id: "userDescription",
            isMultiline: true,
            value: user?.userDescription,
            style: themes?.field?.WYSIWYGField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // user category
        userLabelAttributes: {
            text: "Choose a category:",
            style: themes?.label?.text13px
        },
        userMultiSelectDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.textField,
            formElement: true,
            key: "categoryId",
            title: ["categoryName"],
            value: ["categoryId"],
            isStripped: false,
            isSelectable: true,
            isSingleSelect: true,
            dataset: users,
            dataObject: user,
            onChange: handleSearchFieldChange,
            onTokenCancelButtonClick: handleCancelSelectedParent,
            tokenButton: {
                trailing: icons?.ClearRoundedIcon,
            },
        },

        themes: themes,
    }
}

export default useCreateOrEditUserHook;