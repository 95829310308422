import { useCallback, useEffect, useRef, useState } from 'react';
import { ContentCategoryService } from './content.category.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE } from '../../../../shared/enums/enums';
import { useForm } from '../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditContentCategoryHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({
        categoryName: '',
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const _parentCategoryDgridName = "createoredit-parent-category-datagrid";
    const parentCategoriesDatagrid = useDataGrid({
        name: _parentCategoryDgridName
    });
    const _parentCategoryTokenSearchFieldName = "createoredit-parent-category-token-search-field";
    const parentCategoriesTokenSearchField = useTokenSearchField({
        name: _parentCategoryTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const categoryService = ContentCategoryService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        //
        getParentCategories("");

        // clear token
        parentCategoriesTokenSearchField?.clear();

        // clear datagrid
        parentCategoriesDatagrid.clearSelection();

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

            // add token
            if ((args?.data?.parentCategoryId !== null) && (args?.data?.parentCategoryId !== "null")) {
                parentCategoriesTokenSearchField?.addToken({
                    value: args?.data?.parentCategoryId,
                    text: args?.data?.parentCategoryName,
                    onButtonClick: (e, args) => {
                        parentCategoriesDatagrid.clearSelection();
                    }
                });
            }
        } else {
            form.clear();
        }
    }, [form])

    // services
    const getParentCategories = useCallback(async (search) => {
        // pageLoader?.show({
        //     message: "Fetching available categories, please wait...",
        //     show: true
        // });

        // repopulate grid with data
        await categoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 20
        })
            .then(response => {
                setCategories(response.data.list);
                // pageLoader?.close();
            })
            .catch(error => {
                // pageLoader?.close();
                // alertDialog.show({
                //     title: "Oops! something went wrong",
                //     message: "We're sorry your requests can't be completed at the moment. Please try again later."
                // });
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setCategory({
            ...category,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        getParentCategories(e.value);
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await categoryService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await categoryService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleCancelSelectedParentCategory = (args) => {
        //
    }

    const handleRowSelected = (args) => {
        if (args?.selectedRow) {
            parentCategoriesTokenSearchField?.addToken({
                value: args?.selectedRow?.categoryId,
                text: args?.selectedRow?.categoryName,
                onButtonClick: (e, args) => {
                    parentCategoriesDatagrid.clearSelection();
                }
            })
        }
    }

    return {
        category: category,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        categoryNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        categoryNameField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for your category",
            id: "categoryName",
            value: category?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        categoryDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        categoryDescriptionField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Category description",
            id: "categoryDescription",
            isMultiline: true,
            value: category?.categoryDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditContentCategoryHook;