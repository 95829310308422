import React from "react";
import Button from "../../components/button/button.component";
import { TextField } from "../../components/field/textfield.component";
import Password from "../../components/password/password.component";
import Separator from "../../components/separator/separator.component";
import Label from "../../components/label/label.component";
import { usePrimaryUIThemes } from '../../hooks/ui.themes/index';
import { useIconsThemes } from '../../hooks/ui.icons.hook';
import useAuthenticationWidgetHook from './authentication.widget.hook.js'


const AuthenticationWidget = ({ attributes }) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // hook
    const hook = useAuthenticationWidgetHook(attributes);

    return (
        <form method={attributes?.method} onSubmit={attributes?.onSubmit} autoComplete={'false'} className={'needs-validation text-left'} noValidate>
            <div className={'flex-column'}>

                <div className={"position-relative height-120px"}>
                    {/* <AlertBox attributes={hook?.alertBoxAttributes} /> */}
                </div>

                <div className={'margin-bottom-30px'}>
                    <div className={'text-14px line-height-18px'}>Welcome back</div>
                    <h2 className={'text-33px line-height-36px text-bold'}>Login to your account</h2>
                </div>

                {/* email field */}
                <div className={'w-100 m-0 mb-4'}>
                    <label className={'w-100 form-label align-left text-bold text-13px line-height-17px margin-bottom-6px'}>
                        Email address:
                    </label>
                    <div className={'input-group has-validation'}>
                        <TextField attributes={hook?.emailAddressTextFieldAttributes} />
                        <div className={'invalid-feedback'}>
                            Please choose a username.
                        </div>
                    </div>
                </div>

                {/* password field */}
                <div className={"w-100 m-0 mb-3"}>
                    <div className={"d-flex justify-content-between margin-bottom-6px"}>
                        <Label attributes={{
                            text: "Password:"
                        }} />
                        <div className={"w-50 text-end text-13px text-primary text-bold"}>
                            <div className={"d-flex float-end justify-content-end cursor-pointer"} onClick={attributes?.onForgotPassword}>Forgot password?</div>
                        </div>
                    </div>
                    <Password attributes={hook?.passwordTextFieldAttributes} />
                    <div className={'invalid-feedback'}>
                        Please provide a valid password.
                    </div>
                </div>

                {/* submit button */}
                <div className={'w-100 m-0 mb-3'}>
                    <Button attributes={hook?.submitButtonAttributes} />
                </div>

                <div className={""}>
                    {/* separator */}
                    <Separator text={'OR'} theme={'bg-brown-theme'} />

                    <div className={'w-100 mb-2 mt-3'}>
                        {/* <Button attributes={{
                            type: "button",
                            text: "Create an account",
                            // style: formThemes.secondaryButton,
                            onClick: handleRegister
                        }} /> */}
                    </div>
                </div>

                <div className={"position-relative height-120px"}></div>
            </div>
        </form>
    );
}
export default AuthenticationWidget;