import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import '../../../../../shared/helpers/global.variable.helper';
import { ProductVariationService } from './product.variation.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import CreateOrEditProductVariationView from './createoredit/createoredit.product.variation.view';

const useProductVariationsHook = () => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [productVariations, setProductVariations] = useState([]);
    const [selectedProductVariation, setSelectedProductVariation] = useState({});
    const [productVariationQueryString, setProductVariationQueryString] = useState("");
    const [productVariationPageNumber, setProductVariationPageNumber] = useState(1);
    const [productVariationRowsPerPage, setProductVariationRowsPerPage] = useState(25);
    const [productVariationTotalPages, setProductVariationTotalPages] = useState(0);
    const [productVariationTotalRows, setProductVariationTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No variant selected!",
            message: "Please select an variant from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New variant",
            successMessage: {
                title: "Variant created successfully!",
                message: "Variant was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit variant details",
            alertMessage: {
                title: "Multiple variants selected!",
                message: "Oops!, you selected multiple variants. Please select a variant from the list to continue"
            },
            successMessage: {
                title: "Variant updated successfully!",
                message: "Variant was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit variant details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these variants?" : "this variant?")
                }
            },
            successMessage: {
                title: "Variant deleted successfully!",
                message: "Variant was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these variants?" : "this variant?")
                }
            },
            successMessage: {
                title: "Variant published successfully!",
                message: "Variant was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these variants?" : "this variant?")
                }
            },
            successMessage: {
                title: "Variant published successfully!",
                message: "Variant was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these variants?" : "this variant?")
                }
            },
            successMessage: {
                title: "Variant archived successfully!",
                message: "Variant was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these variants?" : "this variant?")
                }
            },
            successMessage: {
                title: "Variant restored successfully!",
                message: "Variant was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available variants, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditVariationObserver = useRef();

    // components
    const datagridName = "product-variations-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-variations-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        addVariationButtonName: "add-variation-button",
        editVariationButtonName: "edit-variation-button",
        deleteVariationButtonName: "delete-variation-button",
        reloadVariationButtonName: "reload-variation-button",
    }

    const rightPaneToolbarName = "product-variations-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const variationService = ProductVariationService();

    // dialogs
    const createOrEditVariationDialogName = "createoredit-variation-modal";
    const createOrEditVariationDialog = useModalDialog({
        name: createOrEditVariationDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getVariations = useCallback(async (request) => {

        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await variationService.getAll(request).then(response => {
            datagrid?.hideLoader();

            // populate datagrid 
            setProductVariations(response?.data?.list);

            // set page
            setProductVariationPageNumber(response?.data?.page?.pageNumber);
            setProductVariationRowsPerPage(response?.data?.page?.rowsPerPage);
            setProductVariationTotalPages(response?.data?.page?.totalPages);
            setProductVariationTotalRows(response?.data?.page?.totalRows);

            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

        }).catch(error => {
            datagrid?.hideLoader();
            // alertDialog.show({
            //     title: "Oops! something went wrong",
            //     message: "We're sorry your requests can't be completed at the moment. Please try again later."
            // });
        });

    }, []);

    useEffect(() => {
        getVariations({
            queryString: productVariationQueryString,
            pageNumber: productVariationPageNumber,
            rowsPerPage: productVariationRowsPerPage
        });
    }, []);

    // variation
    const handleCreate = (e) => {
        // clear selection
        setSelectedProductVariation({});

        // show dialog
        createOrEditVariationDialog.show({
            title: "New variation",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditVariationObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple variations selected!",
                message: "Oops!, you selected multiple variations. Please select a variation from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedProductVariation = productVariations.find(v => v.productVariationId === datagrid.getSelectedRowKeyValue());
                setSelectedProductVariation(selectedProductVariation);

                createOrEditVariationDialog.show({
                    title: "Edit variation",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditVariationObserver.current({
                    data: selectedProductVariation,
                    callback: () => {
                        getVariations({
                            queryString: productVariationQueryString,
                            pageNumber: productVariationPageNumber,
                            rowsPerPage: productVariationRowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No variation selected!",
                    message: "Please select a variation from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these variations?" : "this variation?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    variationService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getVariations({
                            queryString: productVariationQueryString,
                            pageNumber: productVariationPageNumber,
                            rowsPerPage: productVariationRowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No variation selected!",
                message: "Please select a variation from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getVariations({
            queryString: args?.value,
            pageNumber: productVariationPageNumber,
            rowsPerPage: productVariationRowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (productVariationPageNumber > 1)
            getVariations({
                queryString: productVariationQueryString,
                pageNumber: productVariationPageNumber - 1,
                rowsPerPage: productVariationRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (productVariationPageNumber < productVariationTotalPages)
            getVariations({
                queryString: productVariationQueryString,
                pageNumber: productVariationPageNumber + 1,
                rowsPerPage: productVariationRowsPerPage
            });
    }

    return {
        componentObserver,
        productVariations,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addVariationButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add variation",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editVariationButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteVariationButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["productVariationId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["productVariationId"],
                    style: themes?.width30px
                },
                {
                    title: "Variation",
                    value: ["productVariationName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    type: COLUMNTYPE.DATETIME,
                    title: "Date Created",
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100px
                },
                {
                    default: "",
                    style: themes?.width30px
                },
            ],
            rowHeight: 40,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: productVariations
        }}  />,

        createOrEditDialog: {
            name: createOrEditVariationDialogName,
            data: selectedProductVariation,
            observer: createOrEditVariationObserver,
            widget: CreateOrEditProductVariationView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getVariations({
                                queryString: productVariationQueryString,
                                pageNumber: productVariationPageNumber,
                                rowsPerPage: productVariationRowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useProductVariationsHook;