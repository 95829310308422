import { useIconsThemes } from "./ui.icons.hook";

const useFlags = () => {
    const icons = useIconsThemes().rounded;

    return {
        colorFlags: [
            {
                label: "Danger",
                color: "bg-danger-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-danger-info-theme text-danger-info-dark-theme",
                icon: icons?.ClearRoundedIcon
            },
            {
                label: "Success",
                color: "bg-success-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-success-info-border-theme text-success-info-dark-theme",
                icon: icons?.CheckRoundedIcon
            },
            {
                label: "Disabled",
                color: "bg-disabled-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-disabled-info-theme text-disabled-info-dark-theme",
                icon: icons?.DoDisturbRoundedIcon
            },
            {
                label: "Warning",
                color: "bg-warning-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-warning-info-theme text-warning-info-dark-theme",
                icon: icons?.AccessTimeRoundedIcon
            }
        ],

        marketProductAvailabilityStatusFlags: [
            {
                index: 0,
                name: "not-in-stock",
                label: "Not in stock",
                color: "bg-danger-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-danger-info-theme text-danger-info-dark-theme",
                icon: icons?.ClearRoundedIcon
            },
            {
                index: 1,
                name: "in-stock",
                label: "In stock",
                color: "bg-success-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-success-info-border-theme text-success-info-dark-theme",
                icon: icons?.CheckRoundedIcon
            },
            {
                index: 2,
                name: "discontinued",
                label: "Discontinued",
                color: "bg-disabled-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-disabled-info-theme text-disabled-info-dark-theme",
                icon: icons?.DoDisturbRoundedIcon
            },
            {
                index: 3,
                name: "low-on-stock",
                label: "Low on stock",
                color: "bg-warning-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-warning-info-theme text-warning-info-dark-theme",
                icon: icons?.AccessTimeRoundedIcon
            }
        ],

        productVisibilityStatusFlags: [
            {
                index: 0,
                name: "unpublished",
                label: "Unpublished",
                color: "bg-warning-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-warning-info-theme text-warning-info-dark-theme",
                icon: icons?.VpnLockRoundedIcon
            },
            {
                index: 1,
                name: "published",
                label: "Published",
                color: "bg-success-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-success-info-border-theme text-success-info-dark-theme",
                icon: icons?.CheckRoundedIcon
            },
            {
                index: 2,
                name: "recalled",
                label: "Recalled",
                color: "bg-danger-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-danger-info-theme text-danger-info-dark-theme",
                icon: icons?.ClearRoundedIcon
            },
        ],

        orderFlags: [
            {
                label: "Requested",
                color: "bg-disabled-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-disabled-info-theme text-disabled-info-dark-theme",
                icon: icons?.DoDisturbRoundedIcon
            },
            {
                label: "Cancelled",
                color: "bg-danger-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-danger-info-theme text-danger-info-dark-theme",
                icon: icons?.ClearRoundedIcon
            },
            {
                label: "Completed",
                color: "bg-success-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-success-info-border-theme text-success-info-dark-theme",
                icon: icons?.CheckRoundedIcon
            },
            {
                label: "In Progress",
                color: "bg-warning-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-warning-info-theme text-warning-info-dark-theme",
                icon: icons?.AccessTimeRoundedIcon
            }
        ],
    };
}

export default useFlags;