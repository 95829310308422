import React, { useEffect, useRef, useState } from 'react';
import ResetPasswordWidget from '../../shared/widgets/reset.password/reset.password.widget';
import Button from '../../shared/components/button/button.component';
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const ForgotPasswordView = () => {
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(false);

    const authWidgetObserver = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setViewHeight(window.innerHeight);
        const windowResizer = (e) => {
            setViewHeight(window.innerHeight);
        }
        window.addEventListener('resize', windowResizer);

        // garbage collection
        return () => {
            window.removeEventListener('resize', windowResizer);
        }
    }, [viewHeight]);

    const handleResetPassword = (e) => {
        authWidgetObserver.current({
            inProgress: true,
            message: "Loading, please wait..."
        });
        setIsLoading(true);
        // setTimeout(() => {
        //     setIsLoading(false);
        // authWidgetObserver.current({
        //     inProgress: false,
        //     errorOccurred: false,
        //     message: "Done!"
        // });

        navigate("/verify-code");
        // }, 3000);

        e.cancelBubble = true;
        e.preventDefault();
    }

    const handleGoBack = (e) => {
        navigate("/");
    }

    return (
        <div>
            <div className={"position-relative height-" + viewHeight + "px d-flex align-items-center"}>
                <div className={"position-absolute start-0px top-0px bottom-0px width-60 d-flex justify-content-start align-items-center mx-auto bg-light banner-1"}>
                </div>

                <div className={"position-absolute end-0px top-0px bottom-0px width-40 d-flex justify-content-center align-items-start p-5 mx-auto bg-white"}>
                    <div className={"max-width-570px"}>
                        <div className={"width-390px height-100px"}>
                            <Button attributes={{
                                type: "button",
                                className: "ps-1 pe-1 text-center manrope-bold text-14px height-35px bg-light",
                                logo: <IoChevronBackOutline />,
                                text: "Back",
                                onClick: handleGoBack,
                            }}></Button>
                        </div>
                        <div className={"width-360px height-500px mx-auto mt-3"}>
                            <ResetPasswordWidget method={'post'} observer={authWidgetObserver} onSubmit={(e) => handleResetPassword(e)} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordView;