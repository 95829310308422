import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authenticate } from './home.service';
import { HttpStatusCode } from '../../../shared/enums/http.status.code.enums';
import { Slider } from '../../../shared/components/slider/slider.component';
import { SLIDERTYPETYPE } from '../../../shared/enums/enums';
import VideoCard from '../../../shared/components/card/video.card.component';

const HomeView = () => {
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(false);

    const authWidgetObserver = useRef(null);
    const navigate = useNavigate();

    const getOurServices = () => {
        return {
            caption: {
                themes: {
                    caption: "manrope-regular text-16px text-bold text-cradle-blue-theme mb-3",
                    title: "w-100 mx-auto text-20px text-dark text-bold mb-0",
                    className: "text-left mx-auto"
                },
                data: {
                    title: "Stream",
                },
            },
            data: {
                themes: {
                    media: "bg-cradle-blue-theme text-20px text-dark height-140px w-100 rounded-3",
                    title: "text-15px text-bold text-dark text-cradle-blue-theme- mb-0",
                    description: "text-14px text-dark",
                    className: "min-height-250px pt-3 pb-3"
                },
                component: VideoCard,
                items: [
                    {
                        media: {
                            src: null,
                            banner: "banner-2"
                        },
                        href: "/services",
                        title: "Creed III: The Soundtrack",
                        options: [
                            {
                                text: "Dreamville",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-4"
                        },
                        href: "/industries",
                        title: "Tobechukwu (Praise God)",
                        options: [
                            {
                                text: "Nathaniel Bassey",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-3"
                        },
                        href: "/business-solutions",
                        title: "Asiwaju (Official Video)",
                        options: [
                            {
                                text: "Ruger",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/our-work",
                        title: "Holiday",
                        options: [
                            {
                                text: "Rema",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-8"
                        },
                        href: "/business-solutions",
                        title: "One Side (Remix)",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-5"
                        },
                        href: "/our-work",
                        title: "Falz & Tekno - O Wa (Official Music Video)",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-6"
                        },
                        href: "/our-work",
                        title: "Corporate Training",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    }
                ],
                displayLimit: 5
            }
        }
    }

    const getActiveBots = () => {
        return {
            caption: {
                themes: {
                    caption: "manrope-regular text-16px text-bold text-cradle-blue-theme mb-3",
                    title: "w-100 mx-auto text-20px text-dark text-bold mb-0",
                    className: "text-left mx-auto"
                },
                data: {
                    title: "Active Bots",
                },
            },
            data: {
                themes: {
                    media: "bg-cradle-blue-theme text-20px text-dark height-140px w-100 rounded-3",
                    title: "text-15px text-bold text-dark text-cradle-blue-theme- mb-2",
                    description: "text-14px text-dark",
                    className: "min-height-250px pt-3 pb-3"
                },
                component: VideoCard,
                items: [
                    {
                        media: {
                            src: null,
                            banner: "banner-2"
                        },
                        href: "/services",
                        title: "Creed III: The Soundtrack",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-4"
                        },
                        href: "/industries",
                        title: "Tobechukwu (Praise God)",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-3"
                        },
                        href: "/business-solutions",
                        title: "Asiwaju (Official Video)",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-1"
                        },
                        href: "/our-work",
                        title: "Holiday",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-8"
                        },
                        href: "/business-solutions",
                        title: "One Side (Remix)",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-5"
                        },
                        href: "/our-work",
                        title: "Falz & Tekno - O Wa (Official Music Video)",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    },
                    {
                        media: {
                            src: null,
                            banner: "banner-6"
                        },
                        href: "/our-work",
                        title: "Corporate Training",
                        options: [
                            {
                                text: "Learn more",
                                href: ""
                            }
                        ]
                    }
                ],
                displayLimit: 5
            }
        }
    }

    React.useEffect(() => {
        setViewHeight(window.innerHeight);
        const windowResizer = (e) => {
            setViewHeight(window.innerHeight);
        }
        window.addEventListener('resize', windowResizer);

        // garbage collection
        return () => {
            window.removeEventListener('resize', windowResizer);
        }
    }, [viewHeight]);

    const handleLogin = async (args) => {

        authWidgetObserver.current({
            inProgress: true,
            message: "Loading, please wait..."
        });
        setIsLoading(true);

        await authenticate(args.data).then((response) => {
            setIsLoading(false);
            if (response.statusCode === HttpStatusCode.FOUND) {
                authWidgetObserver.current({
                    inProgress: false,
                    errorOccurred: false,
                    message: response.message
                });
                navigate("/start");
            } else {
                authWidgetObserver.current({
                    inProgress: false,
                    errorOccurred: true,
                    message: response.message
                });
            }
        }).catch((error) => {
            authWidgetObserver.current({
                inProgress: false,
                errorOccurred: false,
                message: "Done!"
            });
        });

        args.e.cancelBubble = true;
        args.e.preventDefault();
    }

    const handleForgotPassword = (e) => {
        navigate("/reset-password");
    }

    return (
        <div className={"p-5"}>
            <div className={'position-relative height-' + viewHeight + 'px d-flex align-items-start'}>
                <div className={"w-100"}>

                    {/* section 1 */}
                    <div className={"mx-auto pt-0 pb-5"}>
                        <div className={"max-width-1300px mx-auto"}>
                            <Slider attributes={getOurServices()} type={SLIDERTYPETYPE.TOPRIGHTARROWS} />
                        </div>
                    </div>

                    {/* section 2 */}
                    <div className={"mx-auto pt-0 pb-5"}>
                        <div className={"max-width-1300px mx-auto"}>
                            <Slider attributes={getActiveBots()} type={SLIDERTYPETYPE.TOPRIGHTARROWS} />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default HomeView;