import { https } from '../../../../../shared/utilities';


const PharmacyProductVariationService = () => {
    const request = https();
    const controller = "/pharmacy-product-variations";

    return { 
        getAll: async (data) => await request.post(controller + "/list", data), 
        getAllByPharmacy: async (data) => await request.post(controller + "/list-by-pharmacy", data),
        get: async (id) => await request.post(controller + "/list", id), 
        search: async (data) => await request.post(controller + "/list", data),
        save: async (data) => await request.post(controller + "/save", data), 
        saveSelectedPharmacyProductVariation: async (data) => await request.post(controller + "/save-by-pharmacy", data), 
        saveSelectedPharmacyBulkProductVariation: async (data) => await request.post(controller + "/save-bulk-by-pharmacy", data), 
        update: async (data) => await request.post(controller + "/update", data),
        updateSelectedPharmacyProductVariation: async (data) => await request.post(controller + "/update-by-pharmacy", data), 
        remove: async (data) => await request.post(controller + "/delete", data),
        removeByPharmacy: async (data) => await request.post(controller + "/delete-by-pharmacy", data),
    }
}

export { PharmacyProductVariationService }