import React, { useEffect, useRef } from "react";
import { Avatar } from "../avatar";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const AddressLocationCard = ({ attributes }) => {
    const icons = useIconsThemes()?.rounded;
    const componentRef = useRef(null);

    // useEffect(() => {

    // }, [attributes])

    return (
        <div ref={componentRef} className={"d-flex align-items-center justify-content-start mb-1"}>
            <div className={"max-width-38px min-width-38px height-38px position-relative me-2"}>
                <Avatar attributes={attributes?.leading} />
            </div>
            <div className={"mt-1"}>
                <div className={"d-flex align-items-center text-13px text-bold mb-1 max-width-200px-"}>
                    <span className={"me-2 text-truncate line-height-14px height-15px"}>{attributes?.name}</span>
                </div>
                <div className={"d-flex text-12px m-0 opacity-8 text-truncate"}>
                    <div className={"d-flex align-items-center justify-content-start text-12px me-3 text-truncate"}>
                        <i className={"d-flex me-1 icon-14px"}>{icons?.LocationOnIcon}</i>
                        <span className={"d-flex text-truncate w-100"}>{attributes?.address}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AddressLocationCard;