import React from 'react';
import useCreateOrEditProductDealHook from './createoredit.product.deal.hook';
import { TextField } from '../../../../../shared/components/field/textfield.component';
import { MultiTextField } from '../../../../../shared/components/field';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { ImageUploader } from '../../../../../shared/components/image/image.uploader.component';
import { MultiSelectDropDownField } from '../../../../../shared/components/field/multi.select.dropdown.field.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid } from '../../../../../shared/components/datagrid';
import HorizontalSeparator from '../../../../../shared/components/separator/horizontal.separator.component';

const CreateOrEditProductDealView = ({ attributes }) => {

    const hook = useCreateOrEditProductDealHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-1 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>

                        {/* product images */}
                        <div className={"position-relative w-100 min-height-118px mb-5 mt-0 p-0 d-flex justify-content-between align-items-center"}>
                            <div className={"w-100 max-width-200px min-height-200px mb-2 mt-0 me-2 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.frontImageUploaderAttributes} model={hook?.productMedia} isImageAvailable={hook?.productMedia ? true : false} />
                            </div>
                            <div className={"w-100 max-width-200px min-height-200px mb-2 mt-0 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.backImageUploaderAttributes} model={hook?.productMedia} isImageAvailable={hook?.productMedia ? true : false} />
                            </div>
                        </div>

                        {/* product name field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Deal name:</div>
                            <TextField
                                attributes={hook?.productNameFieldAttributes} />
                        </div>

                        {/* category field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Choose a category:</div>
                            <SingleSelectDropDownField
                                attributes={hook?.productCategoryDropDownFieldAttributes} />
                        </div>

                        {/* product description field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Description:</div>
                            <MultiTextField
                                attributes={hook?.productDescriptionFieldAttributes} />
                        </div>

                        {/* size & price field */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100 me-2"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Market:</div>
                                <MultiSelectDropDownField attributes={hook?.productDealMarketDropDownFieldAttributes} />
                            </div>

                            <div className={"w-100 max-width-180px"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Discount:</div>
                                <div className={"d-flex w-100"}>
                                    <div className={"w-100 me-1"}>
                                        <SingleSelectDropDownField attributes={hook?.productDealPriceFieldAttributes} />
                                    </div>
                                    <div className={"min-width-70px"}>
                                        <TextField attributes={hook?.productDealDiscountFieldAttributes} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={"position-relative height-2px mt-4 mb-4"}>
                            <HorizontalSeparator attributes={{
                                style: hook?.themes?.horizontalSeparator
                            }} />
                        </div>

                        <div className={"mb-4 d-flex justify-content-between"}>
                            <div>
                                <h2 className={"text-18px text-bold line-height-23px text-blue-theme"}>Products</h2>
                            </div>

                            <div>
                                <Toolbar attributes={hook?.productDealProductsDatagridToolbarAttributes} />
                            </div>
                        </div>

                        <div className={"position-relative"}>
                            <DataGrid attributes={hook?.productDealIngredientDatagridAttributes} />
                        </div>

                        {/* seo */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>SEO Tags:</div>
                                <MultiTextField
                                    attributes={hook?.productTagsFieldAttributes} />
                            </div>

                        </div>

                    </div>
                </form>
            </div >
        </div >
    );
}

export default CreateOrEditProductDealView;