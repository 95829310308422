import React, { useEffect, useRef } from "react";
import { Avatar } from "../avatar";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import { AddressLocationCard } from "../card";

const DispatchMap = ({ attributes }) => {

    const icons = useIconsThemes()?.rounded;

    const componentRef = useRef(null);

    useEffect(() => {
        //
    }, [attributes])

    return (
        <div className={"m-0"} ref={componentRef}>
            {/* <div aria-controls="dispatch-map" className={"w-100 height-100px position-relative overflow-hidden before bg-blue-light-theme mb-3"} dangerouslySetInnerHTML={{ __html: attributes?.pickup?.map }}></div> */}
            <div className={"height-200px- position-relative"}>
                {/* market address details */}
                <div className={"d-flex align-items-center justify-content-start m-0 mb-3 position-relative"}>
                    <AddressLocationCard attributes={attributes?.pickup} />
                    <div className={"height-30px position-absolute top-38px start-17px border border-start-2px border-dashed border-blue-light-theme"}></div>
                </div>

                {/* drop-off address details */}
                <div className={"d-flex align-items-center justify-content-start m-0 position-relative"}>
                    <AddressLocationCard attributes={attributes?.dropOff} />
                </div>

                <div>
                </div>
            </div>
        </div>
    );
}
export default DispatchMap;