import {v4 as uuid} from 'uuid';

const useAppConfig = () => {

    const api_base_url = process.env.REACT_APP_API_URL;

    return {
        apiBaseUrl: api_base_url,

        sessionAuthDetails: (() => {
            return {
                userId: uuid()
            }
        })()
    }
}
export default useAppConfig