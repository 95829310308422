import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { ShopperService } from './shopper.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import CreateOrEditShopperView from './createoredit.shopper/createoredit.shopper.view';
import ShopperDetailsView from './shopper.details/shopper.details.view';
import { Grid } from '../../../../shared/components/grid/grid.component';
import { CountryService } from '../../configuration.settings/settings/location/country/country.service';
import ActivateShopperApprovalView from './shopper.approval/shopper.approval.view';
import ShopperApprovalView from './shopper.approval/shopper.approval.view';
import MenuList from '../../../../shared/components/menu/menu.list.component';
import FundCustomerWalletView from '../../finance.core/wallet.management/customer.wallet/fund.wallet/fund.wallet.view';
import ShopperWalletTransactionsView from './shopper.wallet.transactions/shopper.wallet.transactions.view';
import ShopperShoppingHistoryView from './shopper.shopping.history/shopper.shopping.history.view';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';
import global from '../../../../shared/helpers/global.variable.helper';

const useShoppersHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [shoppers, setShoppers] = useState([]);
    const [selectedShopper, setSelectedShopper] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No shopper selected!",
            message: "Please select an shopper from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New shopper",
            successMessage: {
                title: "Shopper created successfully!",
                message: "Shopper was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit shopper details",
            alertMessage: {
                title: "Multiple shoppers selected!",
                message: "Oops!, you selected multiple shoppers. Please select a shopper from the list to continue"
            },
            successMessage: {
                title: "Shopper updated successfully!",
                message: "Shopper was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit shopper details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these shoppers?" : "this shopper?")
                }
            },
            successMessage: {
                title: "Shopper deleted successfully!",
                message: "Shopper was successfully deleted."
            }
        },

        handleApproval: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to approval " + ((count > 1) ? "these shoppers?" : "this shopper?")
                }
            },
            successMessage: {
                title: "Shopper published successfully!",
                message: "Shopper was successfully published."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these shoppers?" : "this shopper?")
                }
            },
            successMessage: {
                title: "Shopper published successfully!",
                message: "Shopper was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these shoppers?" : "this shopper?")
                }
            },
            successMessage: {
                title: "Shopper archived successfully!",
                message: "Shopper was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these shoppers?" : "this shopper?")
                }
            },
            successMessage: {
                title: "Shopper restored successfully!",
                message: "Shopper was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available shoppers, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditShopperObserver = useRef();
    const shopperApprovalObserver = useRef();
    const shopperDetailsViewObserver = useRef();
    const shoppersDatagridObserver = useRef();
    const fundShopperWalletObserver = useRef();
    const viewShopperWalletTransactionsObserver = useRef();
    const viewShopperShoppingHistoryObserver = useRef();

    // components
    const datagridName = "shoppers-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "shoppers-left-pane-toolbar";
    const leftToolbarButtons = {
        addShopperButtonName: "add-shopper-button",
        editShopperButtonName: "edit-shopper-button",
        deleteShopperButtonName: "delete-shopper-button",
        reloadShopperButtonName: "reload-shopper-button",
    }

    const rightPaneToolbarName = "shoppers-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // dialogs
    const createOrEditShopperDialogName = "createoredit-shopper-modal";
    const shopperApprovalDialogName = "shopper-shopper-approval-modal";
    const fundShopperWalletDialogName = "fund-shopper-shopper-modal";
    const viewShopperWalletTransactionsDialogName = "view-shopper-shopper-wallet-transactions-modal";
    const viewShopperShoppingHistoryDialogName = "view-shopper-shopper-shopping-history-modal";

    const createOrEditShopperDialog = useModalDialog({
        name: createOrEditShopperDialogName
    });
    const shopperApprovalDialog = useModalDialog({
        name: shopperApprovalDialogName
    });
    const fundShopperWalletDialog = useModalDialog({
        name: fundShopperWalletDialogName
    });
    const viewShopperWalletTransactionsDialog = useModalDialog({
        name: viewShopperWalletTransactionsDialogName
    })
    const viewShopperShoppingHistoryDialog = useModalDialog({
        name: viewShopperShoppingHistoryDialogName
    })
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const shopperService = ShopperService();

    const getShoppers = useCallback(async (request) => {

        showPagingInfo(1, 1);
        datagrid?.showLoader(messages?.loader?.fetchingMessage);

        await shopperService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setShoppers(response?.data?.list);
                shoppersDatagridObserver.current?.selectDefault();

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getShoppers({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch (e) { }
    }

    // event handlers
    const handleCreate = (e) => {
        setSelectedShopper({});
        createOrEditShopperObserver.current.show({
            title: messages?.handleCreate?.dialogTitle,
            isNew: true,
            data: {}
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple shoppers selected!",
                message: "Oops!, you selected multiple shoppers. Please select a shopper from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedShopper = shoppers.find(shopper => shopper.shopperId === datagrid.getSelectedRowKeyValue());
                setSelectedShopper(selectedShopper);

                // listen to callout for changes
                createOrEditShopperObserver.current.show({
                    title: messages?.handleEdit?.dialogTitle,
                    data: selectedShopper,
                    isNew: false,
                    callback: () => {
                        getShoppers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });

            } else {
                alertDialog.show({
                    title: "No shopper selected!",
                    message: "Please select a shopper from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these shoppers?" : "this shopper?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    shopperService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getShoppers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No shopper selected!",
                message: "Please select a shopper from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these shoppers?" : "this shopper?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    shopperService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getShoppers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No shopper selected!",
                message: "Please select a shopper from the list to continue"
            });
        }
    }

    const handleKYC = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            // confirmDialog.show({
            //     title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
            //     message: "Are you sure you want to approval " + ((datagrid.selectedRowsCount() > 1) ? "these shoppers?" : "this shopper?"),
            //     onConfirmed: (e) => {
            //         // pageLoader.show({
            //         //     message: "Loading, please wait...",
            //         // });

            //         // shopperService.approval(datagrid.getSelectedRowsKeyValue()).then(response => {
            //         //     getShoppers({
            //         //         queryString: queryString,
            //         //         pageNumber: pageNumber,
            //         //         rowsPerPage: rowsPerPage
            //         //     });
            //         //     pageLoader.hide();
            //         // });




            //     }
            // });


            const selectedShopper = shoppers.find(shopper => shopper.shopperId === datagrid.getSelectedRowKeyValue());
            setSelectedShopper(selectedShopper);

            shopperApprovalDialog.show({
                title: "Know Your Customer",
                showCloseButton: true,
                isNew: false
            });

            // listen to dialog for changes
            shopperApprovalObserver.current({
                data: selectedShopper,
                callback: () => {
                    getShoppers({
                        queryString: queryString,
                        pageNumber: pageNumber,
                        rowsPerPage: rowsPerPage
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No shopper selected!",
                message: "Please select a shopper from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getShoppers({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getShoppers({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getShoppers({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getShoppers({
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages) {
            setRowsPerPage(args?.value);
            getShoppers({
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
        }
    }

    const handlePageNumberChange = (e, args) => {
        setPageNumber(args?.value);
        getShoppers({
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    const handleDatagridRowSelected = (args) => {
        shopperDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    const handleWalletMenuItemClick = (args) => {
        switch (args?.index) {
            case 0:
                break;
            case 1:
                // show dialog
                viewShopperWalletTransactionsDialog.show({
                    title: "Wallet transactions",
                    showCloseButton: true,
                    isNew: true
                });

                viewShopperWalletTransactionsObserver.current({
                    data: args.selectedRow
                });
                break;
            case 2:
                // show dialog
                fundShopperWalletDialog.show({
                    title: "Fund wallet",
                    showCloseButton: true,
                    isNew: true
                });

                fundShopperWalletObserver.current({
                    data: args.selectedRow
                });
                break;
            default:
                break;
        }
    }

    const handleShopperShoppingHistory = (args) => {
        viewShopperShoppingHistoryDialog.show({
            title: "Shopping history",
            showCloseButton: true,
            isNew: true
        });

        viewShopperShoppingHistoryObserver.current({
            data: args.selectedRow
        });
    }

    return {
        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addShopperButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add shopper",
                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.editShopperButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteShopperButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadShopperButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "KYC",
                                    leading: icons?.ThumbUpAltIcon,
                                    onClick: (e) => handleKYC(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadShopperButtonName,
                                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                    text: "Wallet",
                                    leading: icons?.WalletRoundedIcon,
                                    onClick: (e) => { },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button,
                                    widget: <MenuList attributes={{
                                        items: [
                                            {
                                                leading: icons?.AccountBalanceWalletIcon,
                                                title: "Wallet balance details",
                                            },
                                            // {
                                            //     leading: icons?.AccountBalanceRoundedIcon,
                                            //     title: "View wallet transactions",
                                            // },
                                            {
                                                leading: icons?.AccountBalanceRoundedIcon,
                                                title: "Fund wallet",
                                            }
                                        ],
                                        style: themes?.menuList,
                                        onItemClick: handleWalletMenuItemClick,
                                        isSelectable: false
                                    }} />
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.reloadShopperButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Shopping History",
                                    leading: icons?.LocalMallIcon,
                                    onClick: (e) => handleShopperShoppingHistory(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        gridArea: (() => {
            return (
                <div className={"w-100 position-absolute end-0px top-53px start-0px bottom-0px "}>
                    <Grid attributes={{
                        dockType: DOCKTYPE.FILL,
                        fixedPane: GRIDPANE?.LEFT,
                        paneWidth: themes?.maxWidth440px,
                        allowAutoScroll: true,
                        pane: {
                            left: (
                                <ShopperDetailsView attributes={{
                                    observer: shopperDetailsViewObserver
                                }} />
                            ),

                            right: (
                                <>
                                    <CalloutWindow attributes={{
                                        title: messages?.handleCreate?.dialogTitle,
                                        name: createOrEditShopperDialogName,
                                        data: selectedShopper,
                                        observer: createOrEditShopperObserver,
                                        widget: CreateOrEditShopperView,
                                        style: {
                                            window: "max-width-460px"
                                        },
                                        buttons: [
                                            {
                                                text: "Save & continue",
                                                style: themes?.button?.primary,
                                                onClick: (e, winArgs) => {
                                                    winArgs.submit(e, args => {
                                                        pageLoader.close();
                                                        getShoppers({
                                                            queryString: queryString,
                                                            pageNumber: pageNumber,
                                                            rowsPerPage: rowsPerPage
                                                        });

                                                        setSelectedShopper({});
                                                        createOrEditShopperObserver.current.close();
                                                    });
                                                }
                                            },
                                            {
                                                text: "Cancel",
                                                style: themes?.button?.secondary,
                                                onClick: (e, winArgs) => winArgs.close(e)
                                            }
                                        ]
                                    }} />

                                    <div className={"position-absolute top-0px start-15px bottom-10px end-10px p-3 pt-1 pb-3"}>
                                        <DataGrid attributes={{
                                            name: datagridName,
                                            observer: shoppersDatagridObserver,
                                            key: ["shopperId"],
                                            columns: [
                                                {
                                                    default: "",
                                                    style: themes?.width15px
                                                },
                                                {
                                                    type: COLUMNTYPE.CHECKBOX,
                                                    value: ["shopperId"],
                                                    style: themes?.width30px
                                                },
                                                {
                                                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                    value: ["shopperMediaUrl"],
                                                    default: "",
                                                    style: themes?.width35px,
                                                    image: {
                                                        id: "mediaId",
                                                        key: "mediaId",
                                                        url: "mediaUrl",
                                                        size: themes?.size35px,
                                                        leading: icons?.PhotoSizeSelectActualRoundedIcon,
                                                        style: themes?.image,
                                                        config: {
                                                            downloadUrl: shopperService?.downloadUrl
                                                        }
                                                    }
                                                },
                                                {
                                                    default: "",
                                                    style: themes?.width15px
                                                },
                                                {
                                                    title: "Name",
                                                    value: ["name"],
                                                    default: "",
                                                    style: themes?.width100,
                                                },
                                                {
                                                    default: "",
                                                    style: themes?.width10px
                                                },
                                                {
                                                    title: "Phone No.",
                                                    value: ["phoneNumber"],
                                                    default: "",
                                                    style: themes?.width100px
                                                },
                                                {
                                                    default: "",
                                                    style: themes?.width10px
                                                },
                                                {
                                                    title: "Email Address",
                                                    value: ["emailAddress"],
                                                    default: "",
                                                    style: themes?.width100,
                                                },
                                                {
                                                    default: "",
                                                    style: themes?.width10px
                                                },
                                                {
                                                    title: "Created",
                                                    value: ["dateCreatedAlias"],
                                                    default: "",
                                                    style: themes?.width90px
                                                },
                                                {
                                                    default: "",
                                                    style: themes?.width10px
                                                },
                                            ],
                                            isStripped: true,
                                            isSelectable: true,
                                            dockType: DOCKTYPE.FILL,
                                            onRowSelected: handleDatagridRowSelected,
                                            floatingBar: {
                                                page: {
                                                    pageNumber: pageNumber,
                                                    numberOfPages: totalPages,
                                                    rowsPerPageList: global.variables.rowsPerPageList,
                                                    onPageNumberChange: handlePageNumberChange,
                                                    onRowsPerPageClick: handleRowsPerPageChange,
                                                    onHomeClick: handleFirstPage,
                                                    onPreviousClick: handlePreviousPage,
                                                    onNextClick: handleNextPage
                                                }
                                            },
                                            dataset: shoppers
                                        }} showPaneOnAction={false} />
                                    </div>
                                </>
                            )
                        }
                    }} />
                </div>
            )
        })(),



        shopperKycDialog: {
            name: shopperApprovalDialogName,
            data: selectedShopper,
            observer: shopperApprovalObserver,
            widget: ShopperApprovalView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getShoppers({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

        viewShopperWalletTransactionsDialog: {
            name: viewShopperWalletTransactionsDialogName,
            data: selectedShopper,
            observer: viewShopperWalletTransactionsObserver,
            widget: ShopperWalletTransactionsView,
            style: {
                window: "max-width-840px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getShoppers({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

        fundShopperWalletDialog: {
            name: fundShopperWalletDialogName,
            data: selectedShopper,
            observer: fundShopperWalletObserver,
            widget: FundCustomerWalletView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getShoppers({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

        viewShopperShoppingHistoryDialog: {
            name: viewShopperShoppingHistoryDialogName,
            data: selectedShopper,
            observer: viewShopperShoppingHistoryObserver,
            widget: ShopperShoppingHistoryView,
            style: {
                window: "max-width-840px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getShoppers({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useShoppersHook;