import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

const createAccount = async (data) => {
    try {
        return await axios({
            method: "post",
            url: api_url + "/auth/account-signup",
            headers: {
                "content-type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            data: data
        }).then(response => response.data).catch(error => error);
    } catch (e) {
        return e;
    }
}

export { createAccount }