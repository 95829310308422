import React from 'react';
import { Grid } from '../../../../shared/components/grid/grid.component';
import useProductDealsHook from './product.deals.hook';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';

const ProductDealsView = () => {

    const hook = useProductDealsHook();

    return (
        <div>
            {hook?.toolbar}
            {hook?.gridArea}

            {/* create or edit product */}
            <CalloutWindow attributes={hook?.productCreateOrEditCalloutWindowAttributes} />
        </div >
    );
}

export default ProductDealsView;