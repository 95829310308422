import React from 'react';
import useAddMarketProductHook from './add.market.product.hook';
import { DataGrid } from '../../../../../shared/components/datagrid';

const AddMarketProductView = ({ attributes }) => {

    const hook = useAddMarketProductHook(attributes);

    return (
        <div className={"position-absolute start-20px top-0px end-20px bottom-0px p-0 pt-0 pb-1"}>
            <div className={"w-100"}>
                <>
                    {hook?.toolbar}
                </>

                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-0"} noValidate>
                    <div className={"position-absolute top-60px start-0px bottom-0px end-0px"}>
                        <DataGrid
                            attributes={hook?.datagridAttributes}
                        />
                    </div>
                </form>
            </div>
        </div >
    );
}

export default AddMarketProductView;