import { useCallback, useEffect, useRef, useState } from 'react';
import { WalletService } from '../wallet.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../../shared/components/datagrid';
import { TOOLBARITEMTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';

const useFundCustomerWalletHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [wallet, setWallet] = useState({});
    const [transaction, setTransaction] = useState({
        initiatingWalletId: ""
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const walletService = WalletService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(transaction);
        if (isEmptyField)
            return;

        transaction.walletNumber = args?.data?.walletNumber;
        args.data = transaction;
        handleSubmitTransaction(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            setWallet(args?.data);
        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setWallet({
            ...wallet,
            [e.id]: e.value
        })
    }

    const handleSubmitTransaction = async (args) => {
        args?.inProgress(true);
        await walletService.fundWallet(args?.data).then((response) => {
            args?.successful({
                data: args?.data,
                callback: args?.callback
            });
            args?.inProgress(false);
        }).catch((error) => {
            args?.error(error);
        });
    }

    return {
        wallet: wallet,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // amount
        amountFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "NGN 0.00",
            id: "transactionAmount",
            value: wallet?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // narration
        narrationFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "",
            id: "transactionDescription",
            value: wallet?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useFundCustomerWalletHook;