import React from 'react';
import useCatererProductVariationsHook from './caterer.product.variations.hook';
import { DataGrid } from '../../../../../shared/components/datagrid';

const CatererProductVariationView = ({ attributes }) => {

    const hook = useCatererProductVariationsHook(attributes);

    return (
        <div className={"min-height-300px"}>
            {hook?.toolbar?.widget}
            <div className={"w-100- position-absolute end-15px top-53px start-15px bottom-0px "}>
                <DataGrid attributes={hook?.datagridAttributes} />
            </div>
        </div>
    );
}

export default CatererProductVariationView;