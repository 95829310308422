import { useCallback, useEffect, useRef, useState } from 'react';
import { ProductColorService } from '../product.color.service';
import usePrimaryUIThemes from '../../../../../../shared/hooks/ui.themes/primary.themes.hook';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../../shared/components/datagrid/datagrid.component';
import { useForm } from '../../../../../../shared/components/form/form.component';

const useCreateOrEditProductColorHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [productColor, setProductColor] = useState({
        colorName: '',
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const colorService = ProductColorService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);
        } else {
            form.clear();
        }
    }, [form])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setProductColor({
            ...productColor,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await colorService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await colorService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        color: productColor,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        colorNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        colorNameField: {
            formElement: true,
            validate: true,
            placeholder: "Choose a name for your color",
            id: "productColorName",
            value: productColor?.colorName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        colorDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        colorDescriptionField: {
            formElement: true,
            validate: true,
            placeholder: "Color description",
            id: "productColorDescription",
            isMultiline: true,
            value: productColor?.colorDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditProductColorHook;