import { useCallback, useEffect, useRef, useState } from 'react';
import { StateService } from '../state.service';
import { usePrimaryUIThemes } from '../../../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../../../shared/hooks/ui.icons.hook';
import { useForm } from '../../../../../../../shared/components/form/form.component';

const useCreateOrEditStateHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [state, setState] = useState([]);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const stateService = StateService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);


        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setState({
            ...state,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await stateService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await stateService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        state: state,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // state name
        stateNameFieldAttributes: {
            formElement: true,
            validate: true,
            id: "stateName",
            value: state?.stateName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // state postal code
        stateCodeFieldAttributes: {
            formElement: true,
            validate: true,
            id: "stateCode",
            value: state?.StateCode,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // latitude
        latitudeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "",
            id: "latitude",
            value: state?.latitude,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // longitude
        longitudeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "",
            id: "longitude",
            value: state?.longitude,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditStateHook;