import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { useForm } from '../../../../../shared/components/form/form.component';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { ProductBrandService } from '../../../configuration.settings/settings/product.brands/product.brand.service';
import { MarketProductService } from '../../market/market.products/market.product.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { MarketService } from '../../market/market.service';
import { ProductService } from '../../../product.management/product/product.service';


const useCatererPurchaseMarketHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes().rounded;

    // state objects
    const [markets, setMarkets] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState({});
    const [marketQueryString, setMarketQueryString] = useState("");
    const [marketPageNumber, setMarketPageNumber] = useState(1);
    const [marketRowsPerPage, setMarketRowsPerPage] = useState(30);
    const [marketTotalPages, setMarketTotalPages] = useState(0);
    const [marketTotalRows, setMarketTotalRows] = useState(0);

    const [productCategories, setProductCategories] = useState([]);
    const [productBrands, setProductBrands] = useState([]);

    // const [purchaseMarketProducts, setPurchaseMarketProducts] = useState([]);
    const [purchaseMarketProduct, setPurchaseMarketProduct] = useState({});
    const [purchaseMarketProductQueryString, setPurchaseMarketProductQueryString] = useState("");
    const [purchaseMarketProductPageNumber, setPurchaseMarketProductPageNumber] = useState(1);
    const [purchaseMarketProductRowsPerPage, setPurchaseMarketProductRowsPerPage] = useState(25);
    const [purchaseMarketProductTotalPages, setPurchaseMarketProductTotalPages] = useState(0);
    const [purchaseMarketProductTotalRows, setPurchaseMarketProductTotalRows] = useState(0);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const selectedPurchaseMarketsObserver = useRef(null);
    const selectedPurchaseMarketObserver = useRef(null);
    const selectedPurchaseMarketProductsObserver = useRef(null);

    const purchaseMarketsDatagridObserver = useRef(null);
    const purchaseMarketProductDatagridObserver = useRef(null);
    const marketProductsViewObserver = useRef();

    // components
    const purchaseMarketDatagridName = "purchase-market-datagrid";
    const purchaseMarketDatagrid = useDataGrid({
        name: purchaseMarketDatagridName
    });
    const purchaseMarketProductDatagridName = "purchase-market-product-datagrid";
    const purchaseMarketProductDatagrid = useDataGrid({
        name: purchaseMarketProductDatagridName
    });

    const addMarketProductForm = useForm({
        ref: reactiveForm
    });


    const filterMarketProductToolbarName = "market-products-pane-toolbar";
    const filterMarketProductToolbar = useToolbar({
        name: filterMarketProductToolbarName
    });
    const filterMarketProductToolbarItems = {
        filterMarketFieldName: "filter-market-field",
        filterProductFieldName: "filter-product-field",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        // const isEmptyField = addMarketProductForm.validate(args.data);
        // if (isEmptyField)
        //     return;
        handleSaveOrUpdate(args);
    }, [])

    const widgetObserver = useCallback(async (args) => {

        // set initial record hint to zero
        filterMarketProductToolbar.button({
            name: filterMarketProductToolbarItems.pagesButtonName
        }).setText("Page 0 of 0");

        // set selected market
        setSelectedMarket(args?.data);
        selectedPurchaseMarketObserver.current = args?.data;


        // load markets
        await getMarkets({
            queryString: marketQueryString,
            pageNumber: marketPageNumber,
            rowsPerPage: marketRowsPerPage
        });

        // load product categories
        await getProductCategories("");

        // load product brands
        await getProductBrands("");

        // clear purchase market products datagrid
        purchaseMarketProductDatagrid.clearSelection();

    }, [addMarketProductForm])

    // services
    const marketService = MarketService();
    const purchaseMarketProductService = MarketProductService();
    const productCategoryService = ProductCategoryService();
    const productBrandService = ProductBrandService();
    const productService = ProductService();
    const productImageService = ProductImageService();

    const getMarkets = useCallback(async (request) => {
        await marketService.getAll(request)
            .then(response => {
                purchaseMarketDatagrid?.hideLoader();

                // populate datagrid 
                selectedPurchaseMarketsObserver.current = response?.data?.list;
                purchaseMarketsDatagridObserver.current?.selectDefault();

                // set page
                setMarketPageNumber(response?.data?.page?.pageNumber);
                setMarketRowsPerPage(response?.data?.page?.rowsPerPage);
                setMarketTotalPages(response?.data?.page?.totalPages);
                setMarketTotalRows(response?.data?.page?.totalRows);

            })
            .catch(error => {
                //
            });
    }, []);

    const getMarketProducts = useCallback(async (request) => {
        purchaseMarketProductDatagrid.showLoader();
        await purchaseMarketProductService.getMarketInventory(request)
            .then(response => {
                purchaseMarketProductDatagrid.hideLoader();

                // populate datagrid 
                selectedPurchaseMarketProductsObserver.current = response?.data?.list;
                purchaseMarketProductDatagridObserver.current?.updateList(selectedPurchaseMarketProductsObserver.current);

                // set page
                setPurchaseMarketProductPageNumber(response?.data?.page?.pageNumber);
                setPurchaseMarketProductRowsPerPage(response?.data?.page?.rowsPerPage);
                setPurchaseMarketProductTotalPages(response?.data?.page?.totalPages);
                setPurchaseMarketProductTotalRows(response?.data?.page?.totalRows);

                filterMarketProductToolbar.button({
                    name: filterMarketProductToolbarItems.pagesButtonName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);
            })
            .catch(error => {
                purchaseMarketProductDatagrid.hideLoader();
            });
    }, []);

    const getProductCategories = useCallback(async (search) => {
        await productCategoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 5
        }).then(response => {
            setProductCategories(response.data.list);
        }).catch(error => {
            //
        });
    }, []);

    const getProductBrands = useCallback(async (search) => {
        await productBrandService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 5
        }).then(response => {
            setProductBrands(response.data.list);
        }).catch(error => {
            // 
        });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleCategorySearchFieldChange = async (e) => {
        getProductCategories(e.value);
    }

    const handleBrandSearchFieldChange = async (e) => {
        getProductBrands(e.value);
    }

    const handleMarketSearchRecords = async (e, args) => {
        await getMarkets({
            queryString: args?.value,
            pageNumber: marketPageNumber,
            rowsPerPage: marketRowsPerPage
        });
    }

    const handleMarketProductSearchRecords = async (e, args) => {
        await getMarketProducts({
            marketId: args?.selectedRow?.marketId,
            queryString: purchaseMarketProductQueryString,
            pageNumber: purchaseMarketProductPageNumber,
            rowsPerPage: purchaseMarketProductRowsPerPage
        });
    }

    const handleMarketProductPreviousRecords = async (e, args) => {
        if (purchaseMarketProductPageNumber > 1)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: purchaseMarketProductQueryString,
                pageNumber: purchaseMarketProductPageNumber - 1,
                rowsPerPage: purchaseMarketProductRowsPerPage
            });
    }

    const handleMarketProductNextRecords = async (e, args) => {
        if (purchaseMarketProductPageNumber < purchaseMarketProductTotalPages) {
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: purchaseMarketProductQueryString,
                pageNumber: purchaseMarketProductPageNumber + 1,
                rowsPerPage: purchaseMarketProductRowsPerPage
            });
        }
    }

    const handleSaveOrUpdate = async (args) => {
        const selectedRowsKeyValue = purchaseMarketProductDatagrid.getSelectedRowsKeyValue();

        const selectedProducts = selectedPurchaseMarketProductsObserver.current.filter((product) => {
            return selectedRowsKeyValue.indexOf(product?.marketProductInventoryId) >= 0
        });

        args?.successful({
            data: selectedProducts,
            callback: args?.callback
        });
    }

    const handleColumnSortButtonClick = async (e, args) => {
        //
    }

    const handleMarketSearchFieldChange = async (e) => {
        getMarkets(e.value);
    }

    const handleOnSelectMarket = async (args) => {
        await getMarketProducts({
            marketId: args?.selectedRow?.marketId,
            queryString: purchaseMarketProductQueryString,
            pageNumber: purchaseMarketProductPageNumber,
            rowsPerPage: purchaseMarketProductRowsPerPage
        });
    }

    const handlePurchaseMarketDatagridRowSelected = async (args) => {
        setSelectedMarket(args?.selectedRow);
        await getMarketProducts({
            marketId: args?.selectedRow?.marketId,
            queryString: purchaseMarketProductQueryString,
            pageNumber: purchaseMarketProductPageNumber,
            rowsPerPage: purchaseMarketProductRowsPerPage
        });
    }

    return {
        reactiveForm: reactiveForm,
        selectedMarket,


        gridViewAttributes: {
            dockType: DOCKTYPE.FILL,
            fixedPane: GRIDPANE?.LEFT,
            paneWidth: themes?.width320px,
            allowAutoScroll: true,
            pane: {
                left: (
                    <>
                        <DataGrid attributes={{
                            name: purchaseMarketDatagridName,
                            observer: purchaseMarketsDatagridObserver,
                            key: ["marketId"],
                            columns: [
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    type: COLUMNTYPE.CHECKBOX,
                                    value: ["marketId"],
                                    style: themes?.width20px
                                },
                                {
                                    default: "",
                                    style: themes?.width10px
                                },
                                {
                                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                                    value: "media1Url",
                                    default: "",
                                    style: themes?.width35px,
                                    image: {
                                        id: "media1Id",
                                        key: "media1Id",
                                        url: "media1Url",
                                        size: themes?.size35px,
                                        leading: icons?.BrandingWatermarkRoundedIcon,
                                        style: themes?.image,
                                        config: {
                                            downloadUrl: productImageService?.downloadUrl
                                        },
                                    }
                                },
                                {
                                    default: "",
                                    style: themes?.width15px
                                },
                                {
                                    title: "Market",
                                    value: ["marketName"],
                                    default: "",
                                    style: themes?.width100
                                },
                                {
                                    default: "",
                                    style: themes?.width15px
                                }
                            ],
                            isStripped: true,
                            isSelectable: true,
                            dockType: DOCKTYPE.FILL,
                            onRowSelected: handlePurchaseMarketDatagridRowSelected,
                            dataset: selectedPurchaseMarketsObserver.current
                        }} />
                    </>
                ),

                right: <>

                    <div className={"w-100 flex-column"}>
                        <div className={themes?.toolbarCollectionWrapperNoPadding}>
                            <div className={"w-50"}>
                                <Toolbar attributes={{
                                    items: [
                                        {
                                            type: TOOLBARITEMTYPE.SPACE,
                                            style: themes?.toolbar?.space?.space15
                                        },
                                        {
                                            name: filterMarketProductToolbarItems.filterButtonName,
                                            type: TOOLBARITEMTYPE.SEARCHFIELD,
                                            menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                            leading: icons?.SearchRoundedIcon,
                                            placeholder: "Search",
                                            trailing: icons?.ArrowForwardRoundedIcon,
                                            observer: componentObserver,
                                            style: themes?.toolbar?.searchField,
                                            onClick: (e, args) => { handleMarketProductSearchRecords(e, args) }
                                        },
                                        {
                                            type: TOOLBARITEMTYPE.SPACE,
                                            style: themes?.toolbar?.space?.space4
                                        },
                                    ]
                                }} />
                            </div>
                            <div className={"w-50 d-flex justify-content-end"}>
                                <Toolbar attributes={{
                                    name: filterMarketProductToolbarName,
                                    items: [
                                        {
                                            type: TOOLBARITEMTYPE.SPACE,
                                            style: themes?.toolbar?.space?.space4
                                        },
                                        {
                                            name: filterMarketProductToolbarItems.previousButtonName,
                                            type: TOOLBARITEMTYPE.BUTTON,
                                            tooltip: "Previous",
                                            leading: icons?.ArrowBackIosRoundedIcon,
                                            onClick: (e, args) => { handleMarketProductPreviousRecords(e, args) },
                                            observer: componentObserver,
                                            style: themes?.toolbar?.startDropDownButton
                                        },
                                        {
                                            name: filterMarketProductToolbarItems.nextButtonName,
                                            type: TOOLBARITEMTYPE.BUTTON,
                                            tooltip: "Next",
                                            leading: icons?.ArrowForwardIosRoundedIcon,
                                            onClick: (e, args) => { handleMarketProductNextRecords(e, args) },
                                            observer: componentObserver,
                                            style: themes?.toolbar?.endDropDownButton
                                        },
                                    ]
                                }} />
                            </div>
                        </div>

                        <form ref={reactiveForm}
                            method={attributes.method}
                            autoComplete={"false"}
                            className={"w-100 text-left pt-0"} noValidate>
                            <div className={"position-relative height-440px"}>
                                <DataGrid attributes={{
                                    name: purchaseMarketProductDatagridName,
                                    observer: purchaseMarketProductDatagridObserver,
                                    key: ["marketProductInventoryId"],
                                    columns: [
                                        {
                                            default: "",
                                            style: themes?.width15px
                                        },
                                        {
                                            type: COLUMNTYPE.CHECKBOX,
                                            value: ["marketProductInventoryId"],
                                            style: themes?.width30px
                                        },
                                        {
                                            default: "",
                                            style: themes?.width10px
                                        },
                                        {
                                            type: COLUMNTYPE.IMAGETHUMBNAIL,
                                            value: "media1Url",
                                            default: "",
                                            style: themes?.width35px,
                                            image: {
                                                id: "media1Id",
                                                key: "media1Id",
                                                url: "media1Url",
                                                size: themes?.size35px,
                                                leading: icons?.BrandingWatermarkRoundedIcon,
                                                style: themes?.image,
                                                config: {
                                                    downloadUrl: productImageService?.downloadUrl
                                                },
                                            }
                                        },
                                        {
                                            default: "",
                                            style: themes?.width15px
                                        },
                                        {
                                            title: "Product",
                                            value: ["productName"],
                                            default: "",
                                            style: themes?.width100
                                        },
                                        {
                                            default: "",
                                            style: themes?.width15px
                                        },
                                        {
                                            title: "Price",
                                            key: "price",
                                            value: "priceAlias",
                                            default: "",
                                            textAlign: ALIGNMENTTYPE.RIGHT,
                                            style: themes?.width90px
                                        },
                                        {
                                            default: "",
                                            style: themes?.width10px
                                        },
                                        {
                                            title: "Color",
                                            value: "productColor",
                                            default: "",
                                            style: themes?.width70px
                                        },
                                        {
                                            default: "",
                                            style: themes?.width10px
                                        },
                                        {
                                            title: "Type",
                                            value: ["productType"],
                                            default: "",
                                            style: themes?.width90px
                                        },
                                        {
                                            default: "",
                                            style: themes?.width10px
                                        },
                                        {
                                            title: "Variation",
                                            value: ["productVariation"],
                                            default: "",
                                            style: themes?.width100px
                                        },
                                        {
                                            default: "",
                                            style: themes?.width15px
                                        }
                                    ],
                                    isStripped: true,
                                    isSelectable: true,
                                    dockType: DOCKTYPE.FILL,
                                    onColumnSortButtonClick: handleColumnSortButtonClick,
                                    dataset: selectedPurchaseMarketProductsObserver.current
                                }} />
                            </div>
                        </form>
                    </div>

                </>
            }
        },

        themes: themes,
    }
}

export default useCatererPurchaseMarketHook;