import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { OrderService } from './order.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import CreateOrEditOrderView from './createoredit.order/createoredit.order.view';
import OrderDetailsView from './order.details/order.details.view';
import useFlags from '../../../../shared/hooks/flags.hook';
import { Grid } from '../../../../shared/components/grid/grid.component';
import global from '../../../../shared/helpers/global.variable.helper';

const useOrdersHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // 
    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No order selected!",
            message: "Please select an order from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New order",
            successMessage: {
                title: "Order created successfully!",
                message: "Order was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit order details",
            alertMessage: {
                title: "Multiple orders selected!",
                message: "Oops!, you selected multiple orders. Please select a order from the list to continue"
            },
            successMessage: {
                title: "Order updated successfully!",
                message: "Order was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit order details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these orders?" : "this order?")
                }
            },
            successMessage: {
                title: "Order deleted successfully!",
                message: "Order was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these orders?" : "this order?")
                }
            },
            successMessage: {
                title: "Order published successfully!",
                message: "Order was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these orders?" : "this order?")
                }
            },
            successMessage: {
                title: "Order published successfully!",
                message: "Order was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these orders?" : "this order?")
                }
            },
            successMessage: {
                title: "Order archived successfully!",
                message: "Order was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these orders?" : "this order?")
                }
            },
            successMessage: {
                title: "Order restored successfully!",
                message: "Order was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available orders, please wait..."
        }
    };

    // flags
    const flags = useFlags();

    // observers
    const componentObserver = useRef();
    const createOrEditOrderObserver = useRef();
    const orderDetailsViewObserver = useRef();
    const ordersDatagridObserver = useRef();

    // components
    const datagridName = "orders-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "orders-left-pane-toolbar";
    const leftToolbarButtons = {
        addOrderButtonName: "add-order-button",
        editOrderButtonName: "edit-border-button",
        deleteOrderButtonName: "delete-order-button",
        reloadOrderButtonName: "reload-order-button",
    }

    const rightPaneToolbarName = "orders-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const orderService = OrderService();

    // dialogs
    const createOrEditOrderDialogName = "createoredit-border-modal";
    const createOrEditOrderDialog = useModalDialog({
        name: createOrEditOrderDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getOrders = useCallback(async (request) => {

        showPagingInfo(1, 1);

        pageLoader.show();
        datagrid.clearSelection();

        await orderService.getAll(request)
            .then(response => {
                pageLoader.hide();

                // populate datagrid 
                setOrders(response?.data?.list);
                ordersDatagridObserver.current?.selectDefault();

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);

            })
            .catch(error => {
                pageLoader.hide();
            });

    }, []);

    useEffect(() => {
        getOrders({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch (error) {
            // ignore
        }
    }

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedOrder({});

        // show dialog
        createOrEditOrderDialog.show({
            title: "New order",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditOrderObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple orders selected!",
                message: "Oops!, you selected multiple orders. Please select a order from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedOrder = orders.find(order => order.orderId === datagrid.getSelectedRowKeyValue());
                setSelectedOrder(selectedOrder);

                createOrEditOrderDialog.show({
                    title: "Edit order",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditOrderObserver.current({
                    data: selectedOrder,
                    callback: () => {
                        getOrders({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No order selected!",
                    message: "Please select a order from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these orders?" : "this order?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    orderService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getOrders({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No order selected!",
                message: "Please select a order from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these orders?" : "this order?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    orderService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getOrders({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No order selected!",
                message: "Please select a order from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these orders?" : "this order?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    orderService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getOrders({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No order selected!",
                message: "Please select a order from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these orders?" : "this order?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    orderService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getOrders({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No order selected!",
                message: "Please select a order from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these orders?" : "this order?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    orderService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getOrders({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No order selected!",
                message: "Please select a order from the list to continue"
            });
        }
    }

    const handleDatagridRowSelected = (args) => {
        orderDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    const handleSearchRecords = (e, args) => {
        setQueryString(args?.value);
        getOrders({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handleFirstPage = (e, args) => {
        if (pageNumber > 1)
            getOrders({
                queryString: queryString,
                pageNumber: 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getOrders({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getOrders({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (pageNumber < totalPages) {
            setRowsPerPage(args?.value);
            getOrders({
                queryString: queryString,
                pageNumber: pageNumber,
                rowsPerPage: args?.value
            });
        }
    }

    const handlePageNumberChange = (e, args) => {
        setPageNumber(args?.value);
        getOrders({
            queryString: queryString,
            pageNumber: args?.value,
            rowsPerPage: rowsPerPage
        });
    }

    return {
        componentObserver,
        orders,

        toolbar: (() => {
            return (
                <div className={themes?.toolbarCollectionWrapper}>
                    <div className={"w-50"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.addOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Add order",

                                    leading: icons?.AddRoundedIcon,
                                    onClick: (e) => handleCreate(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.editOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Edit",
                                    leading: icons?.EditRoundedIcon,
                                    onClick: (e) => handleEdit(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.deleteOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.PublishRoundedIcon,
                                    onClick: (e) => handlePublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.reloadOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Unpublish",
                                    leading: icons?.VpnLockRoundedIcon,
                                    onClick: (e) => handleUnpublish(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: leftToolbarButtons.reloadOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DriveFolderUploadRoundedIcon,
                                    onClick: (e) => handleArchive(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: leftToolbarButtons.reloadOrderButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    text: "Restore",
                                    leading: icons?.ReplyRoundedIcon,
                                    onClick: (e) => handleRestoreArchive(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                },
                            ]
                        }} />
                    </div>
                    <div className={"w-50 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space15
                                },
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField,
                                    onClick: (e, args) => { handleSearchRecords(e, args) },
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),


        gridArea: (() => {
            return (
                <div className={"w-100 position-absolute end-0px top-53px start-0px bottom-0px "}>
                    <Grid attributes={{
                        dockType: DOCKTYPE.FILL,
                        fixedPane: GRIDPANE?.RIGHT,
                        paneWidth: themes?.minWidth440px,
                        allowAutoScroll: true,
                        pane: {
                            left: (
                                <div className={"position-absolute top-0px start-15px bottom-10px end-10px p-3 pt-1 pb-3"}>
                                    <DataGrid attributes={{
                                        name: datagridName,
                                        observer: ordersDatagridObserver,
                                        key: ["customerOrderId"],
                                        columns: [
                                            {
                                                default: "",
                                                style: themes?.width15px
                                            },
                                            {
                                                type: COLUMNTYPE.CHECKBOX,
                                                value: ["customerOrderId"],
                                                style: themes?.width30px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                            {
                                                type: COLUMNTYPE.IMAGETHUMBNAIL,
                                                value: ["productMediaUrl"],
                                                default: "",
                                                style: themes?.width35px,
                                                image: {
                                                    size: themes?.size35px,
                                                    leading: icons?.ShoppingBasketRoundedIcon,
                                                    style: themes?.image,
                                                }
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Customer",
                                                value: ["customerName"],
                                                default: "",
                                                style: themes?.width100,
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Amount",
                                                value: ["amountAlias"],
                                                default: "",
                                                style: themes?.width120px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                title: "Order. ID",
                                                value: ["orderId"],
                                                default: "",
                                                style: themes?.width100px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                type: COLUMNTYPE.FLAG,
                                                title: "",
                                                value: ["status"],
                                                default: "",
                                                style: themes?.width30px,
                                                flags: flags.orderFlags
                                            },
                                            {
                                                default: "",
                                                style: themes?.width10px
                                            },
                                            {
                                                // type: COLUMNTYPE.DATETIME,
                                                title: "Date",
                                                value: ["dateCreated"],
                                                default: "",
                                                style: themes?.width90px
                                            },
                                            {
                                                default: "",
                                                style: themes?.width5px
                                            },
                                        ],
                                        isStripped: true,
                                        isSelectable: true,
                                        dockType: DOCKTYPE.FILL,
                                        onRowSelected: handleDatagridRowSelected,
                                        floatingBar: {
                                            page: {
                                                pageNumber: pageNumber,
                                                numberOfPages: totalPages,
                                                rowsPerPageList: global.variables.rowsPerPageList,
                                                onPageNumberChange: handlePageNumberChange,
                                                onRowsPerPageClick: handleRowsPerPageChange,
                                                onHomeClick: handleFirstPage,
                                                onPreviousClick: handlePreviousPage,
                                                onNextClick: handleNextPage
                                            }
                                        },
                                        dataset: orders
                                    }} />
                                </div>
                            ),

                            right: (
                                <div className={"position-absolute top-0px start-0px bottom-10px end-15px p-0 pe-0"}>
                                    <OrderDetailsView attributes={{
                                        observer: orderDetailsViewObserver
                                    }}></OrderDetailsView>
                                </div>
                            )
                        }
                    }} />
                </div>
            )

        })(),

        createOrEditDialog: {
            name: createOrEditOrderDialogName,
            data: selectedOrder,
            observer: createOrEditOrderObserver,
            widget: CreateOrEditOrderView,
            style: {
                window: "max-width-530px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getOrders({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useOrdersHook;