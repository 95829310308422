import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../shared/components/field/token.search.textfield.component';
import { OrderService } from '../order.service';

const useCreateOrEditOrderHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [order, setOrder] = useState({
        categoryName: '',
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const categoryDgridName = "createoredit-parent-category-datagrid";
    const parentCategoriesDatagrid = useDataGrid({
        name: categoryDgridName
    });
    const categoryTokenSearchFieldName = "createoredit-parent-category-token-search-field";
    const parentCategoriesTokenSearchField = useTokenSearchField({
        name: categoryTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const orderService = OrderService();

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {

        // clear token
        parentCategoriesTokenSearchField?.clear();

        // clear datagrid
        parentCategoriesDatagrid.clearSelection();

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

            // add token
            if ((args?.data?.parentCategoryId !== null) && (args?.data?.parentCategoryId !== "null")) {
                parentCategoriesTokenSearchField?.addToken({
                    value: args?.data?.parentCategoryId,
                    text: args?.data?.parentCategoryName,
                    onButtonClick: (e, args) => {
                        parentCategoriesDatagrid.clearSelection();
                    }
                });
            }
        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setOrder({
            ...order,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        //
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await orderService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await orderService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleCancelSelectedParentCategory = (args) => {
    }

    return {
        order: order,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // media
        mediaHeaderLabelAttributes: {
            text: "Order Media Gallery:",
            style: themes?.label?.textUpperCaseBold13px
        },
        mediaUploaderAttributes: {
            formElement: true,
            id: "media",
            value: order?.media,
            style: themes?.mediaGallery?.card180px,
            observer: componentObserver
        },

        // order details header label
        orderDetailsHeaderLabelAttributes: {
            text: "Order Basic Details",
            style: themes?.label?.textUpperCaseBold13px
        },

        // order name
        orderNameLabelAttributes: {
            text: "Order name:",
            style: themes?.label?.text13px
        },
        orderNameFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for this order",
            id: "orderName",
            value: order?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // order description
        orderDescriptionLabelAttributes: {
            text: "Description:",
            style: themes?.label?.text13px
        },
        orderDescriptionFieldAttributes: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Briefly describe this order",
            id: "orderDescription",
            isMultiline: true,
            value: order?.orderDescription,
            style: themes?.field?.WYSIWYGField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // order category
        orderCategoryLabelAttributes: {
            text: "Choose a category:",
            style: themes?.label?.text13px
        },
        categoryMultiSelectDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.textField,
            formElement: true,
            key: "categoryId",
            title: ["categoryName"],
            value: ["categoryId"],
            isStripped: false,
            isSelectable: true,
            isSingleSelect: true,
            // dataset: orderCategories,
            dataObject: order,
            onChange: handleSearchFieldChange,
            onTokenCancelButtonClick: handleCancelSelectedParentCategory,
            tokenButton: {
                trailing: icons?.ClearRoundedIcon,
            },
        },

        // variations
        variationLabelAttributes: {
            text: "Order sizes:",
            style: themes?.label?.text13px
        },
        variationMultiSelectDropDownFieldAttributes: {
            name: "variation-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select choices for this option",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.textField,
            formElement: true,
            key: "productVariationId",
            title: ["productVariationName"],
            value: ["productVariationId"],
            isStripped: false,
            isSelectable: true,
            // dataset: orderVariations,
            dataObject: order,
            onChange: handleSearchFieldChange,
            onTokenCancelButtonClick: handleCancelSelectedParentCategory,
            tokenButton: {
                trailing: icons?.ClearRoundedIcon,
            },
        },

        // colors
        colorLabelAttributes: {
            text: "Colors available:",
            style: themes?.label?.text13px
        },
        colorMultiSelectDropDownFieldAttributes: {
            name: "color-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select choices for this option",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.textField,
            formElement: true,
            key: "productColorId",
            title: ["productColorName"],
            value: ["productColorId"],
            isStripped: false,
            isSelectable: true,
            // dataset: orderColors,
            dataObject: order,
            onChange: handleSearchFieldChange,
            onTokenCancelButtonClick: handleCancelSelectedParentCategory,
            tokenButton: {
                trailing: icons?.ClearRoundedIcon,
            },
        },

        // types
        typeLabelAttributes: {
            text: "Type:",
            style: themes?.label?.text13px
        },
        typeMultiSelectDropDownFieldAttributes: {
            name: "type-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select choices for this option",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.textField,
            formElement: true,
            key: "productTypeId",
            title: ["productTypeName"],
            value: ["productTypeId"],
            isStripped: false,
            isSelectable: true,
            // dataset: orderTypes,
            dataObject: order,
            onChange: handleSearchFieldChange,
            onTokenCancelButtonClick: handleCancelSelectedParentCategory,
            tokenButton: {
                trailing: icons?.ClearRoundedIcon,
            },
        },

        themes: themes,
    }
}

export default useCreateOrEditOrderHook;