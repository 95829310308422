import React from 'react';
import { Routes, Route } from 'react-router-dom';
import useSettingsHook from './settings.hook';
import { CollapsibleSidebarMenu } from '../../../../shared/components/collapsible.sidebar.menu/collapsible.sidebar.menu.component';
import ProductCategoriesView from './product.categories/product.categories.view';
import ProductVariationsView from './product.variations/product.variations.view';
import ProductAvailabilityStatusesView from './product.availability.status/product.availability.statuses.view';
import ProductColorsView from './product.colors/product.colors.view';
import ProductTypesView from './product.types/product.types.view';
import ProductBrandsView from './product.brands/product.brands.view';
import MarketTypesView from './market.types/market.types.view';
import BanksView from './bank/banks.view';
import ProductImagesView from './product.images/product.images.view';
import CountriesView from './location/country/countries.view';
import StatesView from './location/state/states.view';
import CitiesView from './location/city/cities.view';

const SettingsView = () => {

    const hook = useSettingsHook();

    return (
        <div className={"position-absolute top-0px start-0px bottom-0px end-0px w-100- pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px transparent-border-blue-dark-theme border-end-0px text-blue-theme"}>
            <div className={"position-absolute top-0px start-0px bottom-0px width-290px p-3 overflow-hidden overflow-y-auto border-none border-top-0px border-bottom-0px border-start-1px transparent-border-blue-dark-theme border-end-0px"}>
                <div className={"position-relative p-3 overflow-hidden overflow-y-auto "}>
                    <CollapsibleSidebarMenu attributes={hook?.sideMenuCollection} />
                </div>
            </div>

            {/*  */}
            <div className={"position-absolute top-0px start-280px bottom-0px end-0px w-100- pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-1px bg-white-theme transparent-border-blue-dark-theme border-end-0px"}>
                <Routes>
                    <Route path='/' element={<div>Settings</div>} />

                    {/* MARKET MANAGEMENT */}
                    <Route path='/market-types' element={<MarketTypesView />} />

                    {/* LOCATION */}
                    <Route path='/countries' element={<CountriesView />} />
                    <Route path='/states' element={<StatesView />} />
                    <Route path='/cities' element={<CitiesView />} />
                    <Route path='/lgas' element={<CountriesView />} />
                    <Route path='/banks' element={<BanksView />} />
                    <Route path='/currencies' element={<CountriesView />} />

                    {/* PRODUCT MANAGEMENT */}
                    <Route path='/product-categories' element={<ProductCategoriesView />} />
                    <Route path='/product-variations' element={<ProductVariationsView />} />
                    <Route path='/product-colors' element={<ProductColorsView />} />
                    <Route path='/product-types' element={<ProductTypesView />} />
                    <Route path='/product-brands' element={<ProductBrandsView />} />
                    <Route path='/product-availability-status' element={<ProductAvailabilityStatusesView />} />
                    <Route path='/product-images' element={<ProductImagesView />} />

                </Routes>
            </div>
        </div>
    );
}

export default SettingsView;