import React from 'react';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import useBanksHook from './banks.hook';

const BanksView = () => {

    const hook = useBanksHook();

    return (
        <div>
            {hook?.toolbar?.widget}
            <div className={"w-100- position-absolute end-15px top-53px start-15px bottom-0px "}>
                {hook?.datagridAttributes}
            </div>
            <ModalDialog attributes={hook?.createOrEditDialog} />
        </div>
    );
}

export default BanksView;