import { useCallback, useEffect, useRef, useState } from 'react';
import { CourierService } from '../courier.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useForm } from '../../../../../shared/components/form/form.component';
import { TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { CountryService } from '../../../configuration.settings/settings/location/country/country.service';
import { StateService } from '../../../configuration.settings/settings/location/state/state.service';
import { CityService } from '../../../configuration.settings/settings/location/city/city.service';
import { LocalGovernmentAreaService } from '../../../configuration.settings/settings/location/lga/lga.service';
import { MarketService } from '../../market/market.service';

const useCreateOrEditCourierHook = (attributes) => {

    // config
    const appConfig = useAppConfig();

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [courier, setCourier] = useState({});
    const [gender, setGender] = useState([]);

    const countries = useRef([]);
    const states = useRef([]);
    const cities = useRef([]);

    // refs
    const courierObserver = useRef(null);
    const componentObserver = useRef(null);
    const profilePhotoObserver = useRef(null);
    const genderObserver = useRef(null);
    const countryObserver = useRef(null);
    const stateObserver = useRef(null);
    const cityObserver = useRef(null);
    const dobObserver = useRef(null);
    const marketObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const toggleToolbarName = "toggle-toolbar";
    const toggleToolbarButtons = {
        profileButtonName: "profile-button",
        bankDetailsButtonName: "bank-details-button",
        guarantorButtonName: "guarantor-details-button"
    }
    const form = useForm({
        ref: reactiveForm
    });

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setCourier(args?.data);
        courierObserver.current = args?.data;

        // load profile image
        profilePhotoObserver.current.loadImage(courierObserver.current);
        dobObserver.current.setValue(courierObserver.current);
        
        // get countries
        await getCountries({
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 1000
        });

        if (args?.data !== null) {
            form.setData(args?.data);
        } else {
            form.clear();
        }
    }, [form])

    // services
    const courierService = CourierService();
    const countryService = CountryService();
    const stateService = StateService();
    const cityService = CityService();
    const marketService = MarketService();

    const getGender = useCallback(() => {
        const genderArr = ["Female", "Male"];
        setGender(() => Array.from(genderArr, (title, index) => ({ title, value: index + 1 })));
    }, [])

    const getCountries = useCallback(async (request) => {
        await countryService.getAll(request)
            .then(response => {
                countries.current = response?.data?.list;
                countryObserver.current.updateList(countries.current);
                countryObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    const getStates = useCallback(async (request) => {
        await stateService.getAll(request)
            .then(response => {
                states.current = response?.data?.list;
                stateObserver.current.updateList(states.current);
                stateObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    const getCities = useCallback(async (request) => {
        await cityService.getAll(request)
            .then(response => {
                cities.current = response?.data?.list;
                cityObserver.current.updateList(cities.current);
                cityObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    const getMarkets = useCallback(async (request) => {
        await marketService.getAll(request)
            .then(response => {
                marketObserver.current = response?.data?.list;
                marketObserver.current.updateList(cities.current);
                marketObserver.current.selectItem();
            })
            .catch(error => {
                //
            });

    }, []);

    useEffect(() => {
        (async () => {

            // get gender
            await getGender();

            // get countries
            await getCountries({
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 1000
            });

            // get markets
            await getMarkets({
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 10
            });

            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleDOBFieldChange = async (e) => {
        //
    }

    const handleCountryChange = async (e) => {
        getStates({
            countryId: e.value,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 50
        });
    }

    const handleStateChange = async (e) => {
        getCities({
            stateId: e.value,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 50
        });
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await courierService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await courierService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleProfilePhotoUpload = async (args) => {
        if (Object.keys(args).length > 0) {
            courierObserver.current = args?.data;
        }
    }

    const handleMarketChange = async (args) => {

    }

    const handleMenuItemClick = async (args) => {

    }

    const handleToggleCreateOrEditForm = () => {
        console.log("Hello Code");
    }

    return {
        courier: courier,
        courierPhoto: courierObserver.current,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,


        // toggle toolbar
        toggleToolbarAttributes: {
            name: toggleToolbarName,
            items: [
                {
                    name: toggleToolbarButtons.profileButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    text: "About",
                    leading: icons?.PersonIcon,
                    onClick: (e) => handleToggleCreateOrEditForm(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
                {
                    name: toggleToolbarButtons.bankDetailsButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    leading: icons?.AccountBalanceRoundedIcon,
                    onClick: (e) => handleToggleCreateOrEditForm(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
            ]
        },


        // media
        profilePhotoUploaderAttributes: {
            formElement: true,
            validate: true,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            caption: "*.png, .jpg",
            size: themes?.sizeAbsolute,
            observer: profilePhotoObserver,
            leading: icons?.BrandingWatermarkRoundedIcon,
            style: themes?.image42,
            model: courierObserver.current,
            isImageAvailable: courierObserver.current ? true : false,
            onUploadCompleted: handleProfilePhotoUpload,
            config: {
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: courierService?.uploadUrl,
                downloadUrl: courierService?.downloadUrl
            }
        },

        // menu actions
        menuActionAttributes: {
            items: [
                {
                    leading: icons?.PersonIcon,
                    title: "Update guarantor form",
                },
                {
                    leading: icons?.AccountBalanceRoundedIcon,
                    title: "Bank details",
                },
                {
                    leading: icons?.AccessTimeRoundedIcon,
                    title: "Reset account",
                },
                {
                    leading: icons?.NewReleasesIcon,
                    title: "Account privileges",
                },
            ],
            style: themes?.menuList,
            onItemClick: handleMenuItemClick,
            isSelectable: false
        },

        // first name
        firstNameFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Your first name",
            id: "firstName",
            value: courier?.firstName,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // last name
        lastNameFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "last name",
            id: "lastName",
            value: courier?.lastName,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // contact details
        contactAddressFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Contact address",
            id: "contactAddress",
            value: courier?.contactAddress,
            style: themes?.field?.textField,
            leading: icons?.LocationOnIcon,
            observer: componentObserver
        },

        // country
        countrySelectDropDownFieldAttributes: {
            name: "country-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Country",
            onClick: (e, args) => { },
            observer: countryObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "countryId",
            title: "countryName",
            value: "countryId",
            items: countries.current,
            model: courier,
            onChange: handleCountryChange
        },

        // state
        stateSelectDropDownFieldAttributes: {
            name: "state-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "State",
            onClick: (e, args) => { },
            observer: stateObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "stateId",
            title: "stateName",
            value: "stateId",
            items: states.current,
            model: courier,
            onChange: handleStateChange
        },

        // city
        citySelectDropDownFieldAttributes: {
            name: "city-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "City",
            onClick: (e, args) => { },
            observer: cityObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "cityId",
            title: "cityName",
            value: "cityId",
            items: cities.current,
            model: courier
        },

        // postal code
        postalCodeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Postal code",
            id: "postalCode",
            value: courier?.postalCode,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // about
        aboutFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Briefly describe this person",
            id: "about",
            isMultiline: true,
            value: courier?.about,
            style: themes?.field?.WYSIWYGField,
            observer: componentObserver
        },

        // gender
        genderSelectDropDownFieldAttributes: {
            name: "gender-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select",
            onClick: (e, args) => { },
            observer: genderObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "gender",
            title: "title",
            value: "value",
            isStripped: false,
            isSelectable: true,
            isSingleSelect: true,
            items: gender,
            model: courier
        },

        // dob
        dobDropDownFieldAttributes: {
            observer: dobObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "dob",
            title: "title",
            value: "value",
            model: courier,
            day: {
                name: "dob-day-selectable-dropdownlist-field"
            },
            month: {
                name: "dob-month-selectable-dropdownlist-field"
            },
            year: {
                name: "dob-year-selectable-dropdownlist-field"
            },
            onChange: handleDOBFieldChange
        },

        // email address
        emailAddressFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "e.g. anything@domain.com",
            id: "emailAddress",
            value: courier?.emailAddress,
            leading: icons?.MailIcon,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // phone number
        phoneNumberFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "XXXX-XXXX-XXXX",
            id: "phoneNumber",
            value: courier?.phoneNumber,
            leading: icons?.LocalPhoneIcon,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // 
        marketDropDownFieldAttributes: {
            name: "market-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a market",
            observer: componentObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "marketId",
            title: "marketName",
            value: "marketId",
            items: marketObserver.current,
            model: courier,
            isSearchable: true,
            onLookupChange: handleMarketChange
        },

        themes: themes,
    }
}

export default useCreateOrEditCourierHook;