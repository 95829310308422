const usePrimaryUIThemes = () => {

    // theme
    const primaryThemes = {
        // page header
        pageHeader: "navbar- navbar-expand-lg- height-60px p-0 d-flex align-items-center position-absolute top-0px start-0px end-0px z-index-1 bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme",

        // search field
        field: {
            
            // primary textfield
            textField: {
                g: "height-37px justify-content-center border-1px border transparent-border-blue-dark-theme shadow-sm-",
                bgColor: "bg-blue-light-theme-",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-dark-theme"
            },

            smallTextField: {
                g: "height-30px justify-content-center border-1px border rounded-1 transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-17px text-16px",
                trailing: "icon-17px text-16px",
                text: "text-12px text-dark-theme line-height-13px",
            },

            dateField: {
                g: "height-40px justify-content-center border-1px border transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-dark-theme",
            },

            smallDateField: {
                g: "height-30px justify-content-center border-1px border rounded-1 transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-12px text-dark-theme line-height-13px",
            },

            searchField: {
                g: "text-14px justify-content-center text-blue-theme border-0px padding-top-7px padding-bottom-7px rounded-pill",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-dark-theme",
            },

            tokenSearchField: {
                textField: "height-40px justify-content-center text-blue-theme bg-blue-light-theme border-1px border transparent-border-blue-dark-theme",
                token: {
                    g: "height-24px min-width-37px shadow-sm me-2 rounded-1 p-0 ps-1 pe-1 border-1px border",
                    bgColor: "token-white-theme text-blue-theme transparent-border-blue-dark-theme",
                    leading: "icon-16px text-16px",
                    trailing: "icon-16px text-16px",
                    text: "text-13px max-width-80px text-truncate"
                }
            },

            multiTextField: {
                g: "min-height-70px justify-content-center border-1px border transparent-border-blue-dark-theme",
                bgColor: "bg-white-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                textArea: "",
                text: "text-13px text-dark-theme"
            },

            multiSelectDropDownField: {
                g: "height-37px w-100 cursor-pointer justify-content-start border-1px border transparent-border-blue-dark-theme shadow-sm-",
                bgColor: "bg-white-theme-  cursor-pointer",
                leading: "icon-16px text-16px",
                trailing: "height-37px min-width-25px icon-16px text-16px",
                text: "text-13px text-dark-theme text-start"
            },

            singleSelectDropDownField: {
                g: "height-37px w-100 justify-content-start border-1px border transparent-border-blue-dark-theme shadow-sm-",
                bgColor: "bg-blue-light-theme-",
                leading: "icon-16px text-16px",
                trailing: "position-absolute top-0px end-0px bottom-1px mx-auto height-35px min-width-25px icon-16px text-16px bg-white-theme rounded-3",
                text: "position-absolute start-0px top-0px end-30px bottom-0px text-13px p-2 text-dark-theme text-start text-truncate",
                dropdown: "max-height-200px overflow-hidden overflow-y-auto"
            },

            // WYSIWYG Editor
            WYSIWYGEditor: {
                editor: {
                    g: "border-0px border transparent-border-blue-dark-theme-",
                    bgColor: "bg-blue-light-theme-",
                    leading: "icon-16px text-16px",
                    trailing: "icon-16px text-16px",
                    textArea: "",
                    text: "text-13px text-dark-theme",
                    editor: "min-height-100px "
                },
                toolbar: {
                    button: {
                        g: "height-34px min-width-37px me-0 rounded-0 ps-1 pe-1 border-0px border",
                        bgColor: "btn-blue-light-theme- text-blue-theme transparent-border-blue-dark-theme",
                        leading: "icon-18px text-19px",
                        trailing: "icon-18px text-19px",
                        text: ""
                    },
                    dropDownButton: {
                        g: "height-34px width-37px me-1 rounded-1 border-1px border ps-1 pe-1",
                        bgColor: "btn-blue-light-theme text-blue-theme",
                        leading: "icon-16px text-18px"
                    },

                    // menu button
                    menuButton: {
                        g: "height-34px me-0 rounded-1 border-1px border ps-1 pe-1",
                        bgColor: "btn-blue-light-theme text-blue-theme",
                        leading: "icon-16px text-14px",
                        menu: "width-60px"
                    },

                    // space
                    space: (() => {
                        const items = {};
                        for (let i = 1; i <= 500; i++) {
                            items["space" + i] = "width-" + i + "px line-height-17px";
                        }
                        return items;
                    })(),

                    // separator
                    separator: "height-34px width-1px line-height-17px transparent-bg-blue-dark-theme",

                    // label
                    label: (() => {
                        const items = {};
                        for (let i = 10; i <= 500; i++) {
                            items["label" + i] = {
                                g: "height-34px me-0 rounded-1 border-0px border ps-1 pe-1",
                                bgColor: "btn-blue-light-theme text-blue-theme",
                                leading: "icon-16px text-14px",
                                text: "text-blue-theme align-left text-" + i + "px line-height-" + (i + 2) + "px margin-bottom-0px"
                            };
                        }
                        return items;
                    })(),
                }
            },

            datagridColumnCellField: {
                g: "height-37px justify-content-center border-2px border",
                bgColor: "bg-transparent",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-blue-theme",
                cell: "ps-0"
            },
        },

        // toolbarcollection
        toolbarCollection: "height-50px",
        toolbarCollectionWrapper: "w-100 d-flex align-items-center ps-3 pe-3 height-50px bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme",
        toolbarCollectionWrapperNoPadding: "w-100 d-flex align-items-center ps-0 pe-0 height-50px bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme",
        toolbarCollectionWrapperRightPadding: "position-absolute end-15px top-0px start-0px d-flex align-items-center ps-0 height-50px bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme",
        
        // toolbar
        toolbar: {

            // 
            button: {
                g: "height-34px min-width-37px me-0 rounded-1 ps-1 pe-1 border-1px border",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
                text: ""
            },

            // 
            endButton: {
                g: "height-34px min-width-37px me-0 rounded-1 ps-1 pe-1 border-1px border",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
                text: ""
            },

            // 
            flatButton: {
                g: "height-34px min-width-37px me-1 rounded-1 ps-1 pe-1 border-0px border",
                bgColor: "btn-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
                text: ""
            },

            // 
            selectedButton: {
                g: "height-34px min-width-37px me-1 rounded-1 ps-1 pe-1 border-1px border",
                bgColor: "btn-blue-theme text-white-theme transparent-border-blue-dark-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
                text: ""
            },

            // 
            iconButton: {
                g: "height-34px min-width-37px me-1 rounded-1 ps-1 pe-1 border-0px border",
                bgColor: "btn-transparent-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-18px text-18px",
                trailing: "icon-18px text-18px",
                text: ""
            },

            // 
            flatIconButton: {
                g: "height-34px min-width-37px me-1 rounded-1 ps-1 pe-1 border-0px border",
                bgColor: "flat-icon-btn-transparent-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-18px text-18px",
                trailing: "icon-18px text-18px",
                text: ""
            },

            // 
            dropDownButton: {
                g: "height-34px width-37px me-1 rounded-1 border-1px border ps-1 pe-1",
                bgColor: "btn-blue-light-theme text-blue-theme",
                leading: "icon-16px text-18px"
            },

            // 
            startDropDownButton: {
                g: "height-34px width-37px me-1 rounded-1 border-1px border ps-1 pe-1",
                bgColor: "btn-blue-light-theme text-blue-theme",
                leading: "icon-16px text-18px"
            },

            // 
            endDropDownButton: {
                g: "height-34px width-37px ms-0 rounded-1 border-1px border ps-1 pe-1",
                bgColor: "btn-blue-light-theme text-blue-theme",
                leading: "icon-16px text-18px"
            },

            // 
            endDropDownButtonNoLeftMargin: {
                g: "height-34px width-37px ms-0 rounded-1 border-1px border ps-1 pe-1",
                bgColor: "btn-blue-light-theme text-blue-theme",
                leading: "icon-16px text-18px"
            },

            // menu button
            menuButton: {
                g: "height-34px me-0 rounded-1 border-1px border ps-1 pe-1",
                bgColor: "btn-blue-light-theme text-blue-theme",
                leading: "icon-16px text-14px",
                menu: "width-60px"
            },

            // page field button
            pageFieldButton: {
                g: "height-34px me-0 rounded-1 border-0px border ps-2 pe-2",
                bgColor: "btn-blue-light-theme text-blue-theme",
                leading: "icon-16px text-14px",
                field: "height-34px min-width-30px d-flex justify-item-center align-items-center rounded-1 border-1px border ps-2 pe-2 border-0px text-13px text-blue-theme text-center",
                text: "text-13px text-blue-theme",
            },

            // space
            space: (() => {
                const items = {};
                for (let i = 1; i <= 500; i++) {
                    items["space" + i] = "width-" + i + "px line-height-17px";
                }
                return items;
            })(),

            // label
            label: (() => {
                const items = {};
                for (let i = 10; i <= 500; i++) {
                    items["label" + i + "px"] = {
                        g: "height-34px me-0 ps-1 pe-1",
                        bgColor: "btn-blue-light-theme text-blue-theme",
                        leading: "icon-16px text-14px",
                        text: "text-blue-theme align-left text-" + i + "px line-height-" + (i + 2) + "px margin-bottom-0px"
                    };
                }
                return items;
            })(),

            // search field
            searchField: {
                g: "text-13px text-blue-theme border-1px padding-top-6px padding-start-6px padding-bottom-6px padding-end-3px rounded-1",
                bgColor: "bg-blue-light-theme-",
                leading: "icon-18px text-18px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-blue-theme",
                button: {
                    g: "height-20px min-width-20px me-0 rounded-1 p-0 ps-0 pe-0 border-0px border",
                    bgColor: "btn-blue-light-theme- text-blue-theme transparent-border-blue-dark-theme",
                    leading: "icon-18px text-18px",
                    trailing: "icon-18px text-18px",
                    text: ""
                },
            },

            // 
            multiSelectDropDownField: {
                g: "height-34px min-width-100px justify-content-start border-1px border transparent-border-blue-dark-theme rounded-1",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-start",
                dropDown: ""
            },

        },

        // tokenbar
        tokenbar: {
            token: {
                g: "height-24px min-width-37px me-1 rounded-1 p-0 ps-1 pe-1 border-1px border",
                bgColor: "token-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px"
            }
        },

        // button
        button: {
            primary: {
                g: "text-center manrope-bold text-13px height-36px w-100 border-1px border",
                bgColor: "btn-blue-theme transparent-border-blue-dark-theme",
                textColor: "text-center manrope-bold text-13px text-white-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
            },
            secondary: {
                g: "text-center manrope-bold text-13px height-36px w-100 border-1px border",
                bgColor: "btn-blue-light-theme transparent-border-blue-dark-theme",
                textColor: "text-center manrope-bold text-13px text-blue-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
            },
            blue: {
                g: "text-center manrope-bold text-13px height-36px w-100 border-1px border",
                bgColor: "btn-blue-theme transparent-border-blue-dark-theme",
                textColor: "text-center manrope-bold text-13px text-white-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
            },
            lightBlue: {
                g: "text-center manrope-bold text-13px height-36px w-100 border-1px border",
                bgColor: "btn-blue-light-theme transparent-border-blue-light-theme",
                textColor: "text-center manrope-bold text-13px text-white-theme",
                leading: "icon-18px text-19px",
                trailing: "icon-18px text-19px",
            },
        },

        // circle button
        circleButton: {
            g: "text-center manrope-bold text-14px text-white height-38px w-100",
            bgColor: "btn-blue-theme text-white-theme",
            textColor: "text-center manrope-bold text-14px text-white",
        },

        // icon
        iconButton: {
            g: "text-center manrope-bold text-13px text-white height-36px w-100 border-1px border",
            bgColor: "btn-blue-light-theme transparent-border-blue-light-theme",
            textColor: "text-center manrope-bold text-13px text-blue-theme",
        },

        // image
        image: {
            g: "bg-cover bg-center text-white cursor-pointer rounded-3",
            bgColor: "bg-cover bg-center bg-blue-light-theme- rounded-3 border border-solid border-1px transparent-border-blue-light-theme",
            textColor: "text-center manrope-bold text-13px text-blue-theme",
            placeholder: "bg-cover bg-center opacity-9 text-blue-light-theme icon-25px text-25px",
            image: "bg-cover bg-center rounded-3 border border-solid border-1px border-blue-light-theme"
        },

        // droddown button
        dropDownButton: {
            g: "height-34px min-width-100px me-1 rounded-1 border-1px border ps-1 pe-1",
            bgColor: "btn-blue-light-theme text-dark-theme",
            leading: "icon-16px text-18px"
        },

        // label
        label: (() => {
            const items = {};
            for (let i = 10; i <= 500; i++) {
                items["text" + i + "px"] = "text-blue-theme align-left text-" + i + "px line-height-" + (i + 2) + "px margin-bottom-0px";
                items["textUpperCase" + i + "px"] = "text-blue-theme align-left text-" + i + "px text-uppercase line-height-" + (i + 2) + "px margin-bottom-0px";
                items["textBold" + i + "px"] = "text-blue-theme align-left text-" + i + "px text-bold line-height-" + (i + 2) + "px margin-bottom-0px";
                items["textUpperCaseBold" + i + "px"] = "text-blue-theme align-left text-" + i + "px text-uppercase text-bold line-height-" + (i + 2) + "px margin-bottom-0px";
            }
            return items;
        })(),

        // linked label
        linkedLabel: (() => {
            const items = {};
            for (let i = 10; i <= 500; i++) {
                items["text" + i + "px"] = "text-blue-theme align-left text-" + i + "px line-height-" + (i + 2) + "px margin-bottom-0px opacity-8";
                items["textUpperCase" + i + "px"] = "text-blue-theme align-left text-" + i + "px text-uppercase line-height-" + (i + 2) + "px margin-bottom-0px opacity-8";
                items["textBold" + i + "px"] = "text-blue-theme align-left text-" + i + "px text-bold line-height-" + (i + 2) + "px margin-bottom-0px opacity-8";
                items["textUpperCaseBold" + i + "px"] = "text-blue-theme align-left text-" + i + "px text-uppercase text-bold line-height-" + (i + 2) + "px margin-bottom-0px opacity-8";
            }
            return items;
        })(),

        // menu
        dropDownMenu: {
            selectDropDownMenuField: {
                button: {
                    g: "height-40px border-1px border transparent-border-blue-dark-theme",
                    bgColor: "bg-blue-light-theme",
                    leading: "icon-16px text-16px",
                    trailing: "icon-16px text-16px",
                    text: "text-13px text-blue-theme",
                },
                menu: {
                    item: "p-0",
                    title: "text-13px ps-1 pe-2"
                }
            },
            smallSelectDropDownMenuField: {
                button: {
                    g: "height-30px border-1px border rounded-1 transparent-border-blue-dark-theme",
                    bgColor: "bg-blue-light-theme",
                    leading: "icon-16px text-16px",
                    trailing: "icon-16px text-16px",
                    text: "text-13px text-blue-theme",
                },
                menu: {
                    item: "p-0",
                    title: "text-13px ps-1 pe-2"
                }
            },
            // selectDropDownMenuField: {
            //     button: "height-40px bg-grey-theme border-none",
            //     menu: {
            //         item: "p-0",
            //         title: "text-13px ps-1 pe-2"
            //     }
            // },
            // smallSelectDropDownMenuField: {
            //     button: "height-40px bg-grey-theme border-none",
            //     menu: {
            //         item: "p-0",
            //         title: "text-13px ps-1 pe-2"
            //     }
            // },
        },

        // space
        space: "text-11px text-bold width-10px line-height-17px margin-bottom-4px",

        // separator
        horizontalSeparator: "transparent-bg-blue-dark-theme",

        // menu list
        menuList: {
            item: "height-30px text-blue-theme rounded-2 cursor-pointer",
            leading: "icon-17px text-18px",
            trailing: "icon-17px text-18px",
            title: "text-13px pe-3 text-normal text-nowrap text-blue-theme line-height-22px",
            selection: "bg-light-theme"
        },

        // tab
        tab: {
            tabControl: "ms-0 ps-3",
            tabButton: {
                tab: "height-33px rounded-0px text-blue-theme after-blue-theme ps-2 pe-2 me-1",
                text: "text-13px line-height-14px",
                leading: "text-15px icon-16px"
            },
            selectedTabButton: {
                tab: "height-33px rounded-0px text-blue-theme text-bold after-blue-theme after--3px after-border-3px after-size-30px ps-2 pe-2 me-1",
                text: "text-13px",
                leading: "text-15px icon-16px"
            }
        },

        // media card
        mediaGallery: (() => {
            const cards = {};
            for (let i = 10; i <= 500; i++) {
                cards["card" + i + "px"] = {
                    g: "height-" + i + "px w-100 border- border-0px transparent-border-blue-dark-theme- rounded-3px",
                    bgColor: "bg-blue-light-theme cursor-pointer",
                    textColor: "text-center text-blue-theme text-13px opacity-9",
                    icon: "d-flex justify-content-center text-center icon icon-" + Math.ceil((i / 4)) + "px",
                };
            }
            return cards;
        })(),

        // modal button
        modal: {
            title: "text-blue-theme "
        },

        // custom field
        custom: {
            textField: {
                g: "height-37px width-200px justify-content-start border-1px border transparent-border-blue-dark-theme shadow-sm-",
                bgColor: "bg-blue-light-theme-",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-blue-theme text-start"
            },
            smallTextField: {
                g: "height-30px border-1px border rounded-1 transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-17px text-16px",
                trailing: "icon-17px text-16px",
                text: "text-12px text-blue-theme line-height-13px",
            },
            dateField: {
                g: "height-40px border-1px border transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-blue-theme",
            },
            smallDateField: {
                g: "height-30px border-1px border rounded-1 transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-12px text-blue-theme line-height-13px",
            },
            searchField: {
                g: "text-14px text-blue-theme border-0px padding-top-7px padding-bottom-7px rounded-pill",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-blue-theme",
            },
            tokenSearchField: {
                textField: "height-40px text-blue-theme bg-blue-light-theme border-1px border transparent-border-blue-dark-theme",
                token: {
                    g: "height-24px min-width-37px shadow-sm me-2 rounded-1 p-0 ps-1 pe-1 border-1px border",
                    bgColor: "token-white-theme text-blue-theme transparent-border-blue-dark-theme",
                    leading: "icon-16px text-16px",
                    trailing: "icon-16px text-16px",
                    text: "text-13px max-width-80px text-truncate"
                }
            },
            multiTextField: {
                g: "min-height-70px border-1px border transparent-border-blue-dark-theme",
                bgColor: "bg-blue-light-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                textArea: "",
                text: "text-13px text-blue-theme"
            },
            WYSIWYG: {
                editor: {
                    g: "border-1px border transparent-border-blue-dark-theme-",
                    bgColor: "bg-blue-light-theme-",
                    leading: "icon-16px text-16px",
                    trailing: "icon-16px text-16px",
                    textArea: "",
                    text: "text-13px text-blue-theme",
                    editor: "min-height-100px "
                },
                toolbar: {
                    button: {
                        g: "height-34px min-width-37px me-0 rounded-0 ps-1 pe-1 border-0px border",
                        bgColor: "btn-blue-light-theme- text-blue-theme transparent-border-blue-dark-theme",
                        leading: "icon-18px text-19px",
                        trailing: "icon-18px text-19px",
                        text: ""
                    },
                    dropDownButton: {
                        g: "height-34px width-37px me-1 rounded-1 border-1px border ps-1 pe-1",
                        bgColor: "btn-blue-light-theme text-blue-theme",
                        leading: "icon-16px text-18px"
                    },

                    // menu button
                    menuButton: {
                        g: "height-34px me-0 rounded-1 border-1px border ps-1 pe-1",
                        bgColor: "btn-blue-light-theme text-blue-theme",
                        leading: "icon-16px text-14px",
                        menu: "width-60px"
                    },

                    // space
                    space: (() => {
                        const items = {};
                        for (let i = 1; i <= 500; i++) {
                            items["space" + i] = "width-" + i + "px line-height-17px";
                        }
                        return items;
                    })(),

                    // separator
                    separator: "height-34px width-1px line-height-17px transparent-bg-blue-dark-theme",

                    // label
                    label: (() => {
                        const items = {};
                        for (let i = 10; i <= 500; i++) {
                            items["label" + i] = {
                                g: "height-34px me-0 rounded-1 border-0px border ps-1 pe-1",
                                bgColor: "btn-blue-light-theme text-blue-theme",
                                leading: "icon-16px text-14px",
                                text: "text-blue-theme align-left text-" + i + "px line-height-" + (i + 2) + "px margin-bottom-0px"
                            };
                        }
                        return items;
                    })(),
                }
            },
        },

        // carousel
        carousel: {
            g: "text-center manrope-bold text-14px text-white height-100px w-100",
            card: "width-100px min-width-100px",
            bgColor: "btn-blue-theme text-white-theme",
            textColor: "text-center manrope-bold text-14px text-white",
        },

        // collapsible list
        collapsibleSidebarMenu: {
            g: "pb-0",
            leading: "icon icon-22px text-18px text-uppercase text-blue-theme line-height-19px",
            title: "text-13px text-normal text-uppercase- line-height-22px",
            items: {
                leading: "text-18px text-uppercase",
                title: "text-13px line-height-22px text-blue-theme",
                item: "mb-0",
                selected: "bg-blue-light-theme",
                hover: "hover-bg-blue-light-theme"
            },
        },

        // color flags
        colorFlags: [],

        // 
        toastr: {
            success: {
                window: "max-width-380px bg-success",
                title: "text-white-theme",
                message: "text-white-theme"
            },
            error: {
                window: "max-width-380px bg-danger",
                title: "text-white-theme",
                message: "text-white-theme"
            },
            info: {
                window: "max-width-380px bg-info",
                title: "text-white-theme",
                message: "text-white-theme"
            },
            warning: {
                window: "max-width-380px bg-warning",
                title: "text-white-theme",
                message: "text-white-theme"
            }
        }
    };

    // avatar
    ((a) => {
        for (let i = 10; i <= 500; i++) {
            a["avatar" + i + "px"] = {
                placeholder: "d-flex align-items-center text-blue-theme icon-" + Math.ceil((i / 4)) + "px mx-auto",
                g: "w-100 border- border-0px transparent-border-blue-dark-theme- rounded-5px",
                bgColor: "bg-blue-light-theme cursor-pointer",
                textColor: "text-center text-blue-theme text-13px opacity-9",
                icon: "d-flex justify-content-center text-center icon icon-" + Math.ceil((i / 4)) + "px",
            };
        }
        return a;
    })(primaryThemes);

    // icon
    ((a) => {
        for (let i = 10; i <= 500; i++) {
            a["icon" + i + "px"] = "icon-" + i + "px text-" + i + "px text-blue-theme text-blue-theme text-start"
        }
        return a;
    })(primaryThemes);

    // image
    ((img) => {
        for (let i = 10; i <= 500; i++) {
            img["image" + i] = {
                g: "text-white cursor-pointer",
                bgColor: "bg-blue-light-theme transparent-border-blue-light-theme",
                textColor: "text-center manrope-bold text-13px text-blue-theme",
                placeholder: "opacity-9 icon-" + i + "px text-" + i + "px",
                image: "rounded-0",
            };
        }
        return img;
    })(primaryThemes);

    // size
    ((a) => {
        a["sizeAbsolute"] = "position-absolute top-0 start-0 bottom-0 end-0 m-0 text-blue-theme text-blue-theme text-start";
        for (let i = 10; i <= 500; i++) {
            a["size" + i + "px"] = "width-" + i + "px height-" + i + "px m-1 text-blue-theme text-blue-theme text-start";
        }
        return a;
    })(primaryThemes);

    // height
    ((a) => {
        for (let i = 10; i <= 500; i++) {
            a["height-" + i + "px"] = "height-" + i + "px";
            a["min-height-" + i + "px"] = "min-height-" + i + "px";
        }
        return a;
    })(primaryThemes);

    // width
    ((a) => {
        for (let i = 10; i <= 1000; i++) {
            if (i <= 100) {
                a["width" + i] = "w-" + i + " text-blue-theme text-13px text-start white-space-nowrap"
            }
            a["width" + i + "px"] = "min-width-" + i + "px text-blue-theme text-13px text-start white-space-nowrap"
        }
        return a;
    })(primaryThemes);

    // max-width
    ((a) => {
        for (let i = 10; i <= 1000; i++) {
            if (i <= 100) {
                a["maxWidth" + i] = "w-" + i + " text-blue-theme text-13px text-start white-space-nowrap"
            }
            a["maxWidth" + i + "px"] = "max-width-" + i + "px text-blue-theme text-13px text-start white-space-nowrap"
        }
        return a;
    })(primaryThemes);

    // min-width
    ((a) => {
        for (let i = 10; i <= 1000; i++) {
            if (i <= 100) {
                a["minWidth" + i] = "w-" + i + " min-width-" + i + "px text-blue-theme text-13px text-start white-space-nowrap"
            }
            a["minWidth" + i + "px"] = "min-width-" + i + "px text-blue-theme text-13px text-start white-space-nowrap"
        }
        return a;
    })(primaryThemes);

    // toolbar buttons
    ((toolbar) => {
        for (let i = 10; i <= 500; i++) {
            toolbar["searchField" + i + "px"] = {
                g: "w-100 text-13px text-blue-theme border-1px padding-top-6px padding-bottom-6px rounded-1 min-width-" + i + "px",
                bgColor: "bg-blue-light-theme-",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-blue-theme",
            };
            
            toolbar["selectDropDownField" + i + "px"] = {
                g: "height-34px min-width-100px me-1 ps-1 pe-1 justify-content-start border-1px border transparent-border-blue-dark-theme rounded-1 min-width-" + i + "px",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-start",
                dropDown: "width-" + (i + 100) + "px"
            };
            
            toolbar["selectDropDownButton" + i + "px"] = {
                g: "height-34px min-width-100px me-1 ps-1 pe-1 justify-content-start border-1px border transparent-border-blue-dark-theme rounded-1 min-width-30px",
                bgColor: "btn-blue-light-theme text-blue-theme transparent-border-blue-dark-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                text: "text-13px text-start",
                dropDown: "width-" + (i + 100) + "px"
            };
        }
        return toolbar;
    })(primaryThemes.toolbar);

    // fields
    ((field) => {
        for (let i = 10; i <= 500; i++) {
            field["multiTextField" + i + "Hpx"] = {
                g: "min-height-" + i + "px justify-content-center border-1px border transparent-border-blue-dark-theme",
                bgColor: "bg-white-theme",
                leading: "icon-16px text-16px",
                trailing: "icon-16px text-16px",
                textArea: "",
                text: "text-13px text-dark-theme"
            };
        }
        return field;
    })(primaryThemes.field);

    return primaryThemes;
}

export default usePrimaryUIThemes;