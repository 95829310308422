import React from 'react';
import useCourierApprovalHook from './courier.approval.hook';
import { TextField } from '../../../../../shared/components/field';

const CourierApprovalView = ({ attributes }) => {

    const hook = useCourierApprovalHook(attributes);

    return (
        <div className={"w-100 position-relative p-4 pt-4 pb-5"}>
            <form ref={hook?.reactiveForm}
                method={attributes.method}
                autoComplete={"false"}
                className={"w-100 text-left pt-2"} noValidate>
                <div className={"w-100"}>
                    <div className={"w-100 m-0 mb-4"}>
                        <div className={"mb-2 text-blue-theme"}>
                            Password:
                        </div>
                        <TextField
                            attributes={hook?.emailAddressFieldAttributes} />
                    </div>

                    <div className={"w-100 m-0 mb-4"}>
                        <div className={"mb-2 text-blue-theme"}>
                            Confirmal :
                        </div>
                        <TextField
                            attributes={hook?.phoneNumberFieldAttributes} />
                    </div>
                </div>
            </form>
        </div >
    );
}

export default CourierApprovalView;