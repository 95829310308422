import { DOCKTYPE, GRIDPANE } from '../../../../shared/enums/enums';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { useToolbarCollection } from '../../../../shared/components/toolbar/toolbar.collection.component';
import { useModalDialogCollection } from '../../../../shared/components/dialog/modal.dialog.collection.component';
import { useStackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';
import { useCallback, useEffect, useState } from 'react';
import useCustomerLoansHook from './loan/loans.hook';

const useLoanManagementHook = () => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const stackablePanelComponentName = "wallet-management-stackable-panel";
    const tabComponentName = "wallet-management-tab";
    const toolbarCollectionComponentName = "wallet-management-toolbar";
    const modalDialogCollectionComponentName = "wallet-management-modal";

    // custom hooks
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });
    const toolbarCollectionComponent = useToolbarCollection({
        name: toolbarCollectionComponentName
    });
    const modalDialogCollectionComponent = useModalDialogCollection({
        name: modalDialogCollectionComponentName
    });

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // callbacks
    const handleStackablePanelToggle = useCallback((tabIndex) => {
        stackablePanelComponent.setPanel(1);
    }, [])

    // 
    // component hooks

    const customerLoansComponentHook = useCustomerLoansHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });

    useEffect(() => {
        //
    }, [])

    // const manageTabAttributes = () => {
    //     return {
    //         name: tabComponentName,
    //         themes: themes,
    //         dockType: DOCKTYPE.FILL,
    //         onTabClick: (e, args) => handleTabButtonClick(e, args),
    //         items: [
    //             {
    //                 leading: icons?.TollRoundedIcon,
    //                 text: "Loans",
    //                 target: "customerLoans-tab-button",
    //                 style: themes?.tab?.selectedTabButton,
    //                 widget: (() => {
    //                     return (
    //                         <Grid attributes={{
    //                             dockType: DOCKTYPE.FILL,
    //                             fixedPane: GRIDPANE?.RIGHT,
    //                             paneWidth: themes?.width500px,
    //                             pane: {
    //                                 left: customerLoansComponentHook?.datagridAttributes,
    //                                 right: customerLoansComponentHook?.detailsComponent
    //                             }
    //                         }} />
    //                     )

    //                 })()
    //             },
    //             {
    //                 leading: icons?.AccountTreeIcon,
    //                 text: "Repayments",
    //                 target: "repayments-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //             {
    //                 leading: icons?.AccountTreeIcon,
    //                 text: "Due Loans",
    //                 target: "due-loans-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //             {
    //                 leading: icons?.AdminPanelSettingsIcon,
    //                 text: "Missed Repayments",
    //                 target: "missed-repayments-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //             {
    //                 leading: icons?.AdminPanelSettingsIcon,
    //                 text: "Loans in Arrears",
    //                 target: "loans-in-arrears-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //             {
    //                 leading: icons?.AdminPanelSettingsIcon,
    //                 text: "No Repayments",
    //                 target: "no-repayments-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //             {
    //                 leading: icons?.AdminPanelSettingsIcon,
    //                 text: "Past Date",
    //                 target: "past-date-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //             {
    //                 leading: icons?.AssessmentRoundedIcon,
    //                 text: "Reports",
    //                 target: "loan-reports-tab-button",
    //                 style: themes?.tab?.tabButton,
    //                 widget: null
    //             },
    //         ]
    //     }
    // }

    // toolbar for creating or editing
    const createOrEditToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                customerLoansComponentHook?.toolbar,
            ]
        }
    }

    return {
        // stackablePanelAttributes: {
        //     name: stackablePanelComponentName,
        //     items: [
        //         {
        //             widget: (() => {
        //                 return (
        //                     <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 bcustomerLoan-solid bcustomerLoan-top-0px bcustomerLoan-bottom-0px bcustomerLoan-start-0px bcustomerLoan-end-0px"}>
        //                         {/* toolbar */}
        //                         {/* <ToolbarCollection attributes={manageToolbarCollectionAttributes()} /> */}

        //                         { customerLoansComponentHook?.toolbar?.widget }

        //                         {/* datagrid view */}
        //                         <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
        //                             <Grid attributes={} />
        //                         </div>
        //                     </div>
        //                 );
        //             })()
        //         },
        //     ]
        // },

        toolbarAttributes: customerLoansComponentHook?.toolbar,

        gridAttributes: {
            dockType: DOCKTYPE.FILL,
            fixedPane: GRIDPANE?.RIGHT,
            paneWidth: themes?.width500px,
            pane: {
                left: customerLoansComponentHook?.datagridAttributes,
                right: customerLoansComponentHook?.detailsComponent
            }
        },

        modalDialogCollectionAttributes: {
            name: modalDialogCollectionComponentName,
            items: [
                // customerLoansComponentHook?.createOrEditDialog,
                // donationRequestsComponentHook?.createOrEditDialog,
            ]
        },

    };
}

export default useLoanManagementHook;