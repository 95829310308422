import React, { useCallback, useEffect, useRef } from "react";
import { SlOptions } from "react-icons/sl"

const HorizontalSeparator = ({ attributes }) => {

    const componentObserver = useRef();

    return (
        <div ref={componentObserver} className={"w-100 height-3px position-absolute end-0px start-0px z-index-0"}>
            <div className={"position-absolute start-0px top-0px end-0px bottom-0px- height-1px " + ((attributes?.style) ? attributes?.style : "opacity-1 bg-blue-dark-theme")}></div>
            <div className={"position-absolute start-0px top-2px end-0px bottom-0px- height-1px bg-secondary-theme opacity-1"}></div>
        </div>
    );
}
export default HorizontalSeparator;