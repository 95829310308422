import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { useForm } from '../../../../../shared/components/form/form.component';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { ProductBrandService } from '../../../configuration.settings/settings/product.brands/product.brand.service';
import { CustomersOrdersService } from '../../market/market.products/market.product.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { MarketService } from '../../market/market.service';
import { ProductService } from '../../../product.management/product/product.service';
import { CatererCustomerOrderService } from './pharmacy.customer.order.service';


const useCatererCustomersOrdersHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes().rounded;

    // state objects
    const [markets, setMarkets] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState({});

    // const [catererCustomerOrders, setCustomersOrders] = useState([]);
    const [catererCustomerOrder, setCustomersOrder] = useState({});
    const [catererCustomerOrderQueryString, setCustomersOrderQueryString] = useState("");
    const [catererCustomerOrderPageNumber, setCustomersOrderPageNumber] = useState(1);
    const [catererCustomerOrderRowsPerPage, setCustomersOrderRowsPerPage] = useState(25);
    const [catererCustomerOrderTotalPages, setCustomersOrderTotalPages] = useState(0);
    const [catererCustomerOrderTotalRows, setCustomersOrderTotalRows] = useState(0);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const selectedPurchaseMarketObserver = useRef(null);
    const selectedCustomersOrderObserver = useRef(null);
    const selectedCustomersOrdersObserver = useRef(null);
    const marketProductsViewObserver = useRef();

    // components
    const purchaseMarketDatagridName = "purchase-market-datagrid";
    const purchaseMarketDatagrid = useDataGrid({
        name: purchaseMarketDatagridName
    });
    const catererCustomerOrderDatagridName = "purchase-market-product-datagrid";
    const catererCustomerOrderDatagrid = useDataGrid({
        name: catererCustomerOrderDatagridName
    });

    const addCustomersOrdersForm = useForm({
        ref: reactiveForm
    });


    const filterCustomerOrderToolbarName = "caterer-customers-order-pane-toolbar";
    const filterCustomerOrderToolbar = useToolbar({
        name: filterCustomerOrderToolbarName
    });
    const toolbarItems = {
        filterMarketFieldName: "filter-customer-order-field",
        filterCustomerOrderFieldName: "filter-customer-order-field",
        previousButtonName: "caterer-customer-order-previous-button",
        pagesLabelName: "caterer-customer-order-pages-menu-button",
        nextButtonName: "caterer-customer-order-next-button",
    }

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        // const isEmptyField = addCustomersOrdersForm.validate(args.data);
        // if (isEmptyField)
        //     return;
        handleSaveOrUpdate(args);
    }, [])

    const widgetObserver = useCallback(async (args) => {

        // set initial record hint to zero
        filterCustomerOrderToolbar.button({
            name: toolbarItems.pagesLabelName
        }).setText("Page 0 of 0");

        // set selected market
        setSelectedMarket(args?.data);
        selectedPurchaseMarketObserver.current = args?.data;


        // // load markets
        // await getCustomersOrders({
        //     queryString: marketQueryString,
        //     pageNumber: marketPageNumber,
        //     rowsPerPage: marketRowsPerPage
        // });

        // clear purchase market products datagrid
        catererCustomerOrderDatagrid.clearSelection();

        // if (args?.data !== null) {
        //     addCustomersOrdersForm.setData(args?.data);
        // } else {
        //     addCustomersOrdersForm.clear();
        // }
    }, [addCustomersOrdersForm])

    // services
    const catererCustomerOrderService = CatererCustomerOrderService();
    const productImageService = ProductImageService();

    const getCustomersOrders = useCallback(async (request) => {
        catererCustomerOrderDatagrid.showLoader();
        await catererCustomerOrderService.getAll(request)
            .then(response => {
                catererCustomerOrderDatagrid.hideLoader();

                // populate datagrid 
                selectedCustomersOrdersObserver.current = response?.data?.list;

                // set page
                setCustomersOrderPageNumber(response?.data?.page?.pageNumber);
                setCustomersOrderRowsPerPage(response?.data?.page?.rowsPerPage);
                setCustomersOrderTotalPages(response?.data?.page?.totalPages);
                setCustomersOrderTotalRows(response?.data?.page?.totalRows);

                filterCustomerOrderToolbar.button({
                    name: toolbarItems.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);
            })
            .catch(error => {
                catererCustomerOrderDatagrid.hideLoader();
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleCustomersOrdersSearchRecords = async (e, args) => {
        await getCustomersOrders({
            marketId: args?.selectedRow?.marketId,
            queryString: catererCustomerOrderQueryString,
            pageNumber: catererCustomerOrderPageNumber,
            rowsPerPage: catererCustomerOrderRowsPerPage
        });
    }

    const handleCustomersOrdersPreviousRecords = async (e, args) => {
        if (catererCustomerOrderPageNumber > 1)
            await getCustomersOrders({
                marketId: selectedMarket?.marketId,
                queryString: catererCustomerOrderQueryString,
                pageNumber: catererCustomerOrderPageNumber - 1,
                rowsPerPage: catererCustomerOrderRowsPerPage
            });
    }

    const handleCustomersOrdersNextRecords = async (e, args) => {
        if (catererCustomerOrderPageNumber < catererCustomerOrderTotalPages)
            await getCustomersOrders({
                marketId: selectedMarket?.marketId,
                queryString: catererCustomerOrderQueryString,
                pageNumber: catererCustomerOrderPageNumber + 1,
                rowsPerPage: catererCustomerOrderRowsPerPage
            });
    }

    const handleDatagridRowSelected = async (args) => {
        setSelectedMarket(args?.selectedRow);
        //
    }

    const handleSaveOrUpdate = async (args) => {
        const selectedRowsKeyValue = catererCustomerOrderDatagrid.getSelectedRowsKeyValue();

        const selectedProducts = selectedCustomersOrdersObserver.current.filter((product) => {
            return selectedRowsKeyValue.indexOf(product?.marketProductInventoryId) >= 0
        });

        args?.successful({
            data: selectedProducts,
            callback: args?.callback
        });
    }

    return {
        reactiveForm: reactiveForm,
        selectedMarket,

        toolbarAttributes: {
            name: filterCustomerOrderToolbarName,
            items: [
                {
                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                    name: toolbarItems.filterCustomerOrderFieldName,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.SearchRoundedIcon,
                    placeholder: "Find product...",
                    trailing: icons?.ArrowForwardRoundedIcon,
                    style: themes?.toolbar?.searchField,
                    onClick: (e, args) => { handleCustomersOrdersSearchRecords(e, args) },
                },
                {
                    type: TOOLBARITEMTYPE.SPACE,
                    style: themes?.toolbar?.space?.space4
                },
                {
                    name: toolbarItems.previousButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Previous",
                    leading: icons?.ArrowBackIosRoundedIcon,
                    onClick: (e, args) => { handleCustomersOrdersPreviousRecords(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.startDropDownButton
                },
                {
                    name: toolbarItems.pagesLabelName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    observer: componentObserver,
                    style: themes?.toolbar?.menuButton
                },
                {
                    name: toolbarItems.nextButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Next",
                    leading: icons?.ArrowForwardIosRoundedIcon,
                    onClick: (e, args) => { handleCustomersOrdersNextRecords(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.endDropDownButton
                },
            ]
        },

        datagridAttributes: {
            name: catererCustomerOrderDatagridName,
            key: ["marketProductInventoryId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["marketProductInventoryId"],
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Customer",
                    value: ["productName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Price",
                    key: "price",
                    value: "priceAlias",
                    default: "",
                    textAlign: ALIGNMENTTYPE.RIGHT,
                    style: themes?.width90px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Variation",
                    value: ["productVariation"],
                    default: "",
                    style: themes?.width100px
                },
                {
                    default: "",
                    style: themes?.width15px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            onRowSelected: handleDatagridRowSelected,
            dataset: selectedCustomersOrdersObserver.current
        },

        themes: themes,
    }
}

export default useCatererCustomersOrdersHook;