import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { InvestorReportDashboardService } from './investor.report.dashboard.service';

const useInvestorReportDashboardHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditUserObserver = useRef();

    // components
    const datagridName = "users-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "users-left-pane-toolbar";
    const leftToolbarButtons = {
        addUserButtonName: "add-user-button",
        editUserButtonName: "edit-user-button",
        deleteUserButtonName: "delete-user-button",
        reloadUserButtonName: "reload-user-button",
    }

    const rightPaneToolbarName = "users-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const userService = InvestorReportDashboardService();

    // dialogs
    const createOrEditUserDialogName = "createoredit-user-modal";
    const createOrEditUserDialog = useModalDialog({
        name: createOrEditUserDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getUsers = useCallback(async (request) => {

        showPagingInfo(1, 1);

        pageLoader?.show({
            message: "Fetching available users, please wait...",
            show: true
        });

        datagrid.clearSelection();

        await userService.getAll(request)
            .then(response => {
                pageLoader?.close();

                // populate datagrid 
                setUsers(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);

            })
            .catch(error => {
                pageLoader?.close();
                // alertDialog.show({
                //     title: "Oops! something went wrong",
                //     message: "We're sorry your requests can't be completed at the moment. Please try again later."
                // });
            });

    }, []);

    useEffect(() => {
        getUsers({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch(error) {
            // ignore
        }
    }

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedUser({});

        // show dialog
        createOrEditUserDialog.show({
            title: "New user",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditUserObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple users selected!",
                message: "Oops!, you selected multiple users. Please select a user from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedUser = users.find(user => user.userId === datagrid.getSelectedRowKeyValue());
                setSelectedUser(selectedUser);

                createOrEditUserDialog.show({
                    title: "Edit user",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditUserObserver.current({
                    data: selectedUser,
                    callback: () => {
                        getUsers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No user selected!",
                    message: "Please select a user from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these users?" : "this user?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    userService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getUsers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No user selected!",
                message: "Please select a user from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these users?" : "this user?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    userService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getUsers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No user selected!",
                message: "Please select a user from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these users?" : "this user?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    userService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getUsers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No user selected!",
                message: "Please select a user from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these users?" : "this user?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    userService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getUsers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No user selected!",
                message: "Please select a user from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these users?" : "this user?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    userService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getUsers({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No user selected!",
                message: "Please select a user from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getUsers({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getUsers({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getUsers({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleAssignUserToMarket = (e) => {
        // 
    }

    const handleSelectRecord = (e, args) => {
        datagrid.setSelectedRow(args.key);
        const selectedUser = users.find(user => user.userId === args.key);
        setSelectedUser(selectedUser);
    }

    const handleDatagridRowMenuItemClick = (args) => {
        switch (args.index) {
            case 7:
                //
                break;
            case 6:
                handleAssignUserToMarket();
                break;
            case 5:
                handleRestoreArchive();
                break;
            case 4:
                handleArchive();
                break;
            case 3:
                handleUnpublish();
                break;
            case 2:
                handlePublish();
                break;
            case 1:
                handleDelete();
                break;
            case 0:
            default:
                handleEdit();
                break;
        }
    }

    return {
        componentObserver,
        users,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addUserButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Generate PDF",
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["userId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["userId"],
                    style: themes?.width30px
                },
                // {
                //     title: "User",
                //     value: ["userId"],
                //     default: "",
                //     style: themes?.width90px
                // },
                {
                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                    value: ["userMediaUrl"],
                    default: "",
                    style: themes?.width35px,
                    image: {
                        size: themes?.size35px,
                        leading: icons?.PhotoSizeSelectActualRoundedIcon,
                        style: themes?.image,
                    }
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Name",
                    value: ["name"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Phone Number",
                    value: ["phoneNumber"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Assigned To",
                    value: ["marketAssigned"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.FLAG,
                    title: "Status",
                    value: ["status"],
                    default: "",
                    style: themes?.width130px,
                    flags: [
                        {
                            title: "Approved",
                            color: "bg-success-info-theme buser buser-top-1px buser-start-1px buser-bottom-1px buser-end-1px buser-success-info-buser-theme text-success-info-dark-theme",
                            icon: icons?.CheckRoundedIcon
                        },
                        {
                            title: "Assigned",
                            color: "bg-success-info-theme buser buser-top-1px buser-start-1px buser-bottom-1px buser-end-1px buser-success-info-buser-theme text-success-info-dark-theme",
                            icon: icons?.CheckRoundedIcon
                        },
                        {
                            title: "Cancelled",
                            color: "bg-danger-info-theme buser buser-top-1px buser-start-1px buser-bottom-1px buser-end-1px buser-danger-info-theme text-danger-info-dark-theme",
                            icon: icons?.ClearRoundedIcon
                        },
                        {
                            title: "Discontinued",
                            color: "bg-disabled-theme buser buser-top-1px buser-start-1px buser-bottom-1px buser-end-1px buser-disabled-info-theme text-disabled-info-dark-theme",
                            icon: icons?.DoDisturbRoundedIcon
                        },
                        {
                            title: "Low On Stock",
                            color: "bg-warning-info-theme buser buser-top-1px buser-start-1px buser-bottom-1px buser-end-1px buser-warning-info-theme text-warning-info-dark-theme",
                            icon: icons?.AccessTimeRoundedIcon
                        }
                    ]
                },
                {
                    default: "",
                    style: themes?.width10px
                },
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: users
        }}  />,

        detailsComponent: null,

    };
}

export default useInvestorReportDashboardHook;