import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { ProductCategoryService } from './product.category.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import CreateOrEditProductCategoryView from './createoredit/createoredit.product.category.view';

const useProductCategoriesHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No country selected!",
            message: "Please select an country from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New country",
            successMessage: {
                title: "Country created successfully!",
                message: "Country was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit country details",
            alertMessage: {
                title: "Multiple countries selected!",
                message: "Oops!, you selected multiple countries. Please select a country from the list to continue"
            },
            successMessage: {
                title: "Country updated successfully!",
                message: "Country was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit country details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country deleted successfully!",
                message: "Country was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country archived successfully!",
                message: "Country was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country restored successfully!",
                message: "Country was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available countries, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditCategoryObserver = useRef();

    // components
    const datagridName = "product-categories-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-categories-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        addCategoryButtonName: "add-category-button",
        editCategoryButtonName: "edit-category-button",
        deleteCategoryButtonName: "delete-category-button",
        reloadCategoryButtonName: "reload-category-button",
    }

    const rightPaneToolbarName = "product-categories-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const categoryService = ProductCategoryService();

    // dialogs
    const createOrEditCategoryDialogName = "createoredit-category-modal";
    const filterCategoryDialogName = "filter-category-modal";
    const createOrEditCategoryDialog = useModalDialog({
        name: createOrEditCategoryDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getCategories = useCallback(async (request) => {

        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await categoryService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setCategories(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getCategories({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // category
    const handleCreate = (e) => {
        // clear selection
        setSelectedCategory({});

        // show dialog
        createOrEditCategoryDialog.show({
            title: "New category",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditCategoryObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple categories selected!",
                message: "Oops!, you selected multiple categories. Please select a category from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedCategory = categories.find(c => c.productCategoryId === datagrid.getSelectedRowKeyValue());
                setSelectedCategory(selectedCategory);

                createOrEditCategoryDialog.show({
                    title: "Edit category",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditCategoryObserver.current({
                    data: selectedCategory,
                    callback: () => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No category selected!",
                    message: "Please select a category from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these categories? " : "this category?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    categoryService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getCategories({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getCategories({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getCategories({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    return {
        componentObserver,
        categories,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add category",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["productCategoryId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["productCategoryId"],
                    style: themes?.width30px
                },
                {
                    title: "Category",
                    value: ["productCategoryName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    title: "Parent",
                    value: ["parentCategoryName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    type: COLUMNTYPE.DATETIME,
                    title: "Date Created",
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100px
                },
                {
                    default: "",
                    style: themes?.width30px
                },
            ],
            rowHeight: 40,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: categories
        }} />,

        createOrEditDialog: {
            name: createOrEditCategoryDialogName,
            data: selectedCategory,
            observer: createOrEditCategoryObserver,
            widget: CreateOrEditProductCategoryView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getCategories({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useProductCategoriesHook;