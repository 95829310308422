import React from 'react';
import SearchField from '../../shared/components/searchfield/searchfield.component';

import { AlertDialog } from '../../shared/components/alert/alert.dialog.component';
import { ConfirmDialog } from '../../shared/components/confirm/confirm.dialog.component';
import { PageLoader } from '../../shared/components/loader/page.loader.component';
import { CollapsibleSidebarMenu } from '../../shared/components/collapsible.sidebar.menu/collapsible.sidebar.menu.component';

import { usePrimaryUIThemes } from '../../shared/hooks/ui.themes/index';
import useAppCoreHook from './app.core.hook';
import { Toastr } from '../../shared/components/toastr/toastr.component';

const AppCore = () => {

    // theme
    const themes = usePrimaryUIThemes();

    // startup Hook
    const hook = useAppCoreHook();

    return (
        <div>
            <div className={"position-fixed z-index-0 top-0px start-1px bottom-0px p-3 ps-14 pt-1 width-260px bg-white-theme text-blue-theme border-solid transparent-border-blue-dark-theme border-top-0px border-start-0px border-bottom-0px border-end-1px"}>
                <div className={"d-flex align-items-center height-60px mb-0 p-3 ps-0 pt-1 pb-1 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px transparent-border-blue-dark-theme"}>
                    <div className={"d-flex justify-content-start w-100"}>
                        <div className={"icon height-35px width-100 logo-default image-contain bg-white-theme me-2"}></div>
                    </div>
                </div>
                <div className={"position-absolute top-60px start-14px bottom-15px end-10px overflow-hidden ps-3 pe-3 padding-top-20px"}>
                    <div className={"position-absolute top-0px start-0px bottom-0px end--5px overflow-y-auto ps-0 pb-0 padding-top-20px padding-bottom-20px padding-end-5px"}>
                        <CollapsibleSidebarMenu attributes={hook?.sidebarMenuCollectionAttributes} />
                    </div>
                </div>
            </div>

            <div className={"position-fixed top-0px start-261px bottom-0px end-0px"}>
                <nav className={themes?.pageHeader}>
                    <div className={"container-fluid d-flex justify-content-between align-items-center pt-0 pb-0 ps-0 pe-0"}>
                        <div className={"w-100 d-flex justify-content-start align-items-center text-blue-theme p-2"}>
                            <div className={"text-25px text-bold height-30px m-0 p-0 me-2 d-flex align-items-center"}>
                                {hook?.selectedSidebarMenuItem?.leading}
                            </div>
                            <div className={"text-20px text-bold height-30px"}>
                                {hook?.selectedSidebarMenuItem?.title}
                            </div>
                        </div>

                        <div className={"w-100 d-flex justify-content-center p-2"}>
                            <div className={"w-100 max-width-600px d-flex justify-content-center"}>
                                {/* <SearchField attributes={hook?.searchField} /> */}
                            </div>
                        </div>

                        <div className={"w-100 d-flex justify-content-end"}>
                            {
                                hook?.toolbar
                            }
                        </div>

                        <div className={"width-60px min-width-60px height-60px bg-light-theme- border border-top-0px border-bottom-0px border-end-0px d-flex justify-content-center"}>
                            {
                                hook?.authNob
                            }
                        </div>
                    </div>
                </nav>

                <div className={"bg-white p-0 position-absolute top-60px start-0px bottom-0px end-0px ps-0 pe-0 ms-0 me-0"}>
                    {hook?.routes}
                </div>
            </div>

            {/* alert dialog */}
            <AlertDialog />

            {/* toastr */}
            <Toastr attributes={hook?.toastrAttributes} />

            {/* confirm dialog */}
            <ConfirmDialog />

            {/* page loader */}
            <PageLoader attributes={{
                style: {
                    loader: "backdrop-dark-theme",
                    text: "text-white-theme",
                    spinner: "text-white border-2px text-white-theme icon-90px"
                }
            }} />
        </div>
    );
}

export default AppCore;
