import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { ProductAvailabilityStatusService } from '../product.availability.status.service';

const useCreateOrEditProductAvailabilityStatusHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // availabilityStatus objects
    const [availabilityStatus, setAvailabilityStatus] = useState([]);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const availabilityStatusService = ProductAvailabilityStatusService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);


        } else {
            form.clear();
        }
    }, [form])

    // services

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setAvailabilityStatus({
            ...availabilityStatus,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await availabilityStatusService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await availabilityStatusService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        availabilityStatus: availabilityStatus,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // availabilityStatus name
        availabilityStatusNameFieldAttributes: {
            formElement: true,
            validate: true,
            id: "availabilityStatusName",
            value: availabilityStatus?.availabilityStatusName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // availabilityStatus postal code
        availabilityStatusCodeFieldAttributes: {
            formElement: true,
            validate: true,
            id: "availabilityStatusCode",
            value: availabilityStatus?.AvailabilityStatusCode,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // latitude
        latitudeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "",
            id: "latitude",
            value: availabilityStatus?.latitude,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // longitude
        longitudeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "",
            id: "longitude",
            value: availabilityStatus?.longitude,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditProductAvailabilityStatusHook;