import React from 'react';
import useCreateOrEditProductCategoryHook from './createoredit.product.category.hook';
import Label from '../../../../../../shared/components/label/label.component';
import { TextField } from '../../../../../../shared/components/field';
import { TokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';
import { DataGrid } from '../../../../../../shared/components/datagrid';
import { SingleSelectDropDownField } from '../../../../../../shared/components/field/single.select.dropdown.field.component';

const CreateOrEditProductCategoryView = ({ attributes }) => {

    const hook = useCreateOrEditProductCategoryHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0"}>
            <div className={"w-100 height-320px position-relative "}>
                <form ref={hook?.reactiveForm} method={attributes.method} autoComplete={"false"} className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>
                        {/* name field */}
                        <div className={"w-100 m-0 mb-4"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.categoryNameLabel} />
                            </div>
                            <TextField
                                attributes={hook?.categoryNameField} />
                        </div>

                        {/* parent category field */}
                        <div className={"w-100 mb-3"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Choose a category:</div>
                            <SingleSelectDropDownField
                                attributes={hook?.parentCategoryDropDownFieldAttributes} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateOrEditProductCategoryView;