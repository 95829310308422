import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, GRIDPANE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { MarketProductService } from '../market.products/market.product.service';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { AddressLocationCard } from '../../../../../shared/components/card';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';

const useManageMarketProductListHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [selectedProductMarket, setSelectedProductMarket] = useState(null);
    const [products, setProducts] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // obervers
    const componentObserver = useRef(null);
    const reactiveFormObserver = useRef(null);
    const reactiveFormObserverManager = useRef(null);
    const productDetailsViewObserver = useRef();
    const productDetailsViewReactiveFormModule = useRef();

    // components
    const productDatagridName = "market-product-list-datagrid";
    const datagrid = useDataGrid({
        name: productDatagridName
    });

    const form = useForm({
        ref: reactiveFormObserver
    });

    const leftPaneToolbarName = "market-product-list-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        deleteMarketButtonName: "delete-market-button",
    }

    const rightPaneToolbarName = "market-product-list-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // dialog
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();

    // services
    const marketProductService = MarketProductService();
    const productImageService = ProductImageService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        productDetailsViewReactiveFormModule.current({
            action: args?.action,
            data: selectedMarket
        });

        reactiveFormObserverManager.current = args;
    }, [selectedMarket])

    const widgetObserver = useCallback(async (args) => {
        // set selected market
        setSelectedMarket(args?.data);
        setSelectedProductMarket(args?.data);

        console.log(args);

        // 
        datagrid.showLoader("");
        setTimeout(async () => {
            await getMarketProducts({
                marketId: args?.data?.marketId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 15
            })
        }, 1000);

        if (args?.data !== null) {
            // form.setData(args?.data);
        } else {
            form.clear();
        }
    }, [form])

    // services
    const getMarketProducts = useCallback(async (request) => {
        datagrid.clearSelection();
        datagrid.showLoader("");

        await marketProductService.getMarketProducts(request)
            .then(response => {
                // populate datagrid 
                setProducts(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesButtonName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

                datagrid.hideLoader();

            })
            .catch(error => {
                //
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;

        })();
    }, []);

    const handleDelete = (e, args) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    // console.log(datagrid.getSelectedRowsKeyValue());
                    marketProductService.removeMarketProduct(datagrid.getSelectedRowsKeyValue()).then(response => {
                        // reload market product list
                        getMarketProducts({
                            marketId: selectedMarket?.marketId,
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });

                        // trigger callback to reload product inventory list
                        attributes?.callback({
                            data: reactiveFormObserverManager?.current?.data
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getMarketProducts({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getMarketProducts({
                marketId: selectedMarket?.marketId,
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleDatagridRowSelected = (args) => {
        //
    }

    return {
        reactiveForm: reactiveFormObserver,
        themes: themes,

        toolbar: (() => {
            return (
                <div className={"w-100 d-flex justify-content-between align-items-center ps-0 pe-0 height-50px bg-white border-solid border-top-0px border-bottom-1px border-start-0px border-end-0px transparent-border-blue-dark-theme"}>
                    <div className={"width-60px"}>
                        <Toolbar attributes={{
                            name: leftPaneToolbarName,
                            items: [
                                {
                                    name: leftToolbarButtons.deleteMarketButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    leading: icons?.DeleteRoundedIcon,
                                    onClick: (e) => handleDelete(e),
                                    observer: componentObserver,
                                    style: themes?.toolbar?.button
                                }
                            ]
                        }} />
                    </div>
                    <div className={"w-70 d-flex justify-content-end"}>
                        <Toolbar attributes={{
                            name: rightPaneToolbarName,
                            items: [
                                {
                                    name: rightToolbarButtons.filterButtonName,
                                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    leading: icons?.SearchRoundedIcon,
                                    placeholder: "Search",
                                    trailing: icons?.ArrowForwardRoundedIcon,
                                    observer: componentObserver,
                                    style: themes?.toolbar?.searchField250px,
                                    onClick: (e, args) => { handleSearchRecords(e, args) }
                                },
                                {
                                    type: TOOLBARITEMTYPE.SPACE,
                                    style: themes?.toolbar?.space?.space4
                                },
                                {
                                    name: rightToolbarButtons.previousButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Previous",
                                    leading: icons?.ArrowBackIosRoundedIcon,
                                    onClick: (e, args) => { handlePreviousPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.startDropDownButton
                                },
                                {
                                    name: rightToolbarButtons.nextButtonName,
                                    type: TOOLBARITEMTYPE.BUTTON,
                                    tooltip: "Next",
                                    leading: icons?.ArrowForwardIosRoundedIcon,
                                    onClick: (e, args) => { handleNextPage(e, args) },
                                    observer: componentObserver,
                                    style: themes?.toolbar?.endDropDownButton
                                },
                            ]
                        }} />
                    </div>
                </div>
            )
        })(),

        // 
        dataGridAttributes: {
            name: productDatagridName,
            formElement: true,
            validate: true,
            key: "marketProductId",
            id: "productIds",
            columns: [
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["marketProductId"],
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                    value: ["mediaUrl"],
                    default: "",
                    style: themes?.width35px,
                    image: {
                        id: "mediaId",
                        key: "mediaId",
                        url: "mediaUrl",
                        size: themes?.size35px,
                        leading: icons?.BrandingWatermarkRoundedIcon,
                        style: themes?.image,
                        config: {
                            downloadUrl: productImageService?.downloadUrl
                        },
                    }
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Product",
                    value: ["productName"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width50px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            onRowSelected: handleDatagridRowSelected,
            dataset: products
        },

        themes: themes,
    }
}

export default useManageMarketProductListHook;