import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { COMPONENTYPE, TOOLBARITEMTYPE } from "../../enums/enums";
import { SingleSelectDropDownField } from "./single.select.dropdown.field.component";

const DateOfBirthDropDownFieldAttributes = forwardRef(({ attributes }, ref) => {

    const date = new Date();

    const [days, setDays] = useState([]);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);

    const dob = useRef({
        "day": date.getDay(),
        "month": date.getUTCMonth(),
        "year": date.getUTCFullYear(),
    });
    const yearModel = useRef({
        "value": date.getUTCFullYear()
    });
    const monthModel = useRef({
        "value": date.getUTCMonth()
    });
    const dayModel = useRef({
        "value": date.getDay()
    });

    const hook = useRef();
    const componentObserver = useRef();
    const yearObserver = useRef(null);
    const monthObserver = useRef(null);
    const dayObserver = useRef(null);

    const hookInitializer = () => {
        return {
            dobDaySelectDropDownFieldAttributes: {
                name: attributes?.day?.name,
                type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
                placeholder: "Day",
                onClick: (e, args) => { },
                observer: dayObserver,
                style: attributes?.style,
                key: "value",
                title: "value",
                value: "value",
                isStripped: false,
                isSelectable: true,
                isSingleSelect: true,
                items: days,
                model: dayModel?.current,
                onChange: handleDayChange,
            },
            dobMonthSelectDropDownFieldAttributes: {
                name: attributes?.month?.name,
                type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
                placeholder: "Month",
                onClick: (e, args) => { },
                observer: monthObserver,
                style: attributes?.style,
                key: "value",
                title: "title",
                value: "value",
                isStripped: false,
                isSelectable: true,
                isSingleSelect: true,
                items: months,
                model: monthModel?.current,
                onChange: handleMonthChange,
            },
            dobYearSelectDropDownFieldAttributes: {
                name: attributes?.year?.name,
                type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
                placeholder: "Year",
                onClick: (e, args) => { },
                observer: yearObserver,
                style: attributes?.style,
                key: "value",
                title: "value",
                value: "value",
                isStripped: false,
                isSelectable: true,
                isSingleSelect: true,
                items: years,
                model: yearModel?.current,
                onChange: handleYearChange,
            },
        }
    }

    const getDays = useCallback(() => {
        setDays(() => Array.from(new Array(31), (name, index) => ({ value: (((index + 1).toString().length > 1) ? (index + 1).toString() : ("0" + (index + 1))) })));
    }, [])

    const getMonths = useCallback(() => {
        const monthsArr = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        setMonths(() => Array.from(monthsArr, (name, index) => ({ title: name, value: (((index + 1).toString().length > 1) ? (index + 1).toString() : ("0" + (index + 1))) })));
    }, [])

    const getYears = useCallback(() => {
        const currentYear = 2024;
        setYears(() => Array.from(new Array((100)), (x, y) => ({ value: (currentYear - y) })));
    }, [])

    useImperativeHandle(attributes?.observer, () => ({
        getValue() {
            return "";
        },

        setValue(model) {
            attributes.model = model;
            
            yearModel.current.value = years[0].value;
            monthModel.current.value = months[0].value;
            dayModel.current.value = days[0].value;

            if (attributes?.model && Object.keys(attributes?.model).length > 0) {
                if (attributes?.model[attributes?.key]) {
                    const dobArr = attributes?.model[attributes?.key]?.split("-");
                    yearModel.current.value = dobArr[0];
                    monthModel.current.value = dobArr[1];
                    dayModel.current.value = dobArr[2];
                } else {

                }
            }
        },

    }))

    useEffect(() => {
        hook.current = hookInitializer();
        getDays();
        getMonths();
        getYears();
    }, [attributes]);

    const handleDayChange = (e) => {
        dob.day = e.value;

        // hidden field
        const field = componentObserver.current.querySelector("input[type=\"hidden\"]");
        field.value = dob.year + "-" + dob.month + "-" + dob.day;

        if (attributes?.onChange)
            attributes?.onChange(dob);
    }

    const handleMonthChange = (e) => {
        dob.month = e.value;

        // hidden field
        const field = componentObserver.current.querySelector("input[type=\"hidden\"]");
        field.value = dob.year + "-" + dob.month + "-" + dob.day;

        if (attributes?.onChange)
            attributes?.onChange(dob);
    }

    const handleYearChange = (e) => {
        dob.year = e.value;

        // hidden field
        const field = componentObserver.current.querySelector("input[type=\"hidden\"]");
        field.value = dob.year + "-" + dob.month + "-" + dob.day;

        if (attributes?.onChange)
            attributes?.onChange(dob);
    }

    return (
        <div ref={componentObserver} className={"d-flex align-items-center w-100 position-relative"}>
            <input
                name={attributes?.key}
                id={attributes?.key}
                aria-owns={attributes?.name}
                aria-controls={attributes?.formElement}
                aria-roledescription={COMPONENTYPE.DOBSELECTDROPDOWNFIELD}
                aria-required={attributes?.validate}
                type={"hidden"} />
            <div className={"me-1 width-68px"}>
                <SingleSelectDropDownField
                    attributes={hook?.current?.dobDaySelectDropDownFieldAttributes} />
            </div>
            <div className={"me-1 width-120px"}>
                <SingleSelectDropDownField
                    attributes={hook?.current?.dobMonthSelectDropDownFieldAttributes} />
            </div>
            <div className={"width-90px"}>
                <SingleSelectDropDownField
                    attributes={hook?.current?.dobYearSelectDropDownFieldAttributes} />
            </div>
        </div>
    );
})
export { DateOfBirthDropDownFieldAttributes };