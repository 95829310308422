import React, { useEffect, useState } from 'react';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

const Password = ({ attributes, onChange }) => {
    const [toggleState, setToggleState] = useState(true);

    useEffect(() => {
        (async () => {
            if (attributes.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const handleVisibility = (e) => {
        if (toggleState) {
            setToggleState(false);
        } else {
            setToggleState(true);
        }
    }

    const notifier = (e) => {
        //
    }

    const handleOnChange = (e) => {
        onChange(e.target);
    }

    return (
        <div className={'d-flex position-relative'}>
            <input onChange={handleOnChange} id={attributes?.id} value={attributes?.value} type={(toggleState) ? 'password' : 'text'} className={'form-control text-14px ' + ((attributes?.style) ? attributes?.style : 'rounded-4px')} />
            <div onClick={(e) => handleVisibility(e)} className={'d-flex justify-content-center align-items-center position-absolute end-10px bottom-10px top-0px m-auto icon-20px cursor-pointer'}>
                <div className={'height-18px position-relative ' + ((toggleState) ? 'show' : 'hide')}><BsFillEyeFill /></div>
                <div className={'height-18px position-relative ' + ((!toggleState) ? 'show' : 'hide')}><BsFillEyeSlashFill /></div>
            </div>
        </div>
    );
}
export default Password;