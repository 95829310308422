import React from 'react';
import { Grid } from '../../../../shared/components/grid/grid.component';
import useProductsHook from './products.hook';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';

const ProductView = () => {

    const hook = useProductsHook();

    return (
        <div>
            {hook?.toolbar}
            <div className={"w-100 position-absolute end-0px top-50px start-0px bottom-0px "}>
                <Grid attributes={hook?.gridViewAttributes} />
            </div>

            {/* create or edit product */}
            <CalloutWindow attributes={hook?.productCreateOrEditCalloutWindowAttributes} />

            {/* product filter */}
            <CalloutWindow attributes={hook?.productFilterCalloutWindowAttributes} />

            {/* show markets to shop for product ingredients */}
            <ModalDialog attributes={hook?.productSheetCreateOrUploadDialogAttributes} />
        </div >
    );
}

export default ProductView;