import React from 'react';
import useContentsManagerHook from './contents.manager.hook';
import { ModalDialogCollection } from '../../../shared/components/dialog/modal.dialog.collection.component';
import { OffCanvasCalloutCollection } from '../../../shared/components/offcanvas.callout/offcanvas.callout.collection.component';
import { StackablePanel } from '../../../shared/components/stackable.panel/stackable.panel.component';

const ContentsManagerView = () => {

    const hook = useContentsManagerHook();

    return (
        <div>
            {/* categories, variations, colors & types management stackable panel */}
            <StackablePanel attributes={hook?.stackablePanelAttributes} />

            {/* create or edit category modal */}
            <ModalDialogCollection attributes={hook?.modalDialogCollectionAttributes} />

            {/* create or edit category offset callout */}
            <OffCanvasCalloutCollection attributes={hook?.offcanvasCalloutCollectionAttributes} />

        </div>
    );
}

export default ContentsManagerView;