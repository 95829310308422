import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useForm } from '../../../../shared/components/form/form.component';
import { ContentService } from './content.service';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { useStackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';
import { ToolbarCollection, useToolbarCollection } from '../../../../shared/components/toolbar/toolbar.collection.component';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { Tab } from '../../../../shared/components/tab/tab.component';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import MenuList from '../../../../shared/components/menu/menu.list.component';

const useCreateOrEditContentHook = (attributes) => {

    // icons
    const icons = useIconsThemes()?.rounded;

    // theme
    const themes = usePrimaryUIThemes();
    
    // state objects
    const [content, setContent] = useState([]);

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    const leftPaneToolbarName = "content-contents-left-pane-toolbar";
    const leftToolbarButtons = {
        addContentButtonName: "add-content-button",
        editContentButtonName: "edit-content-button",
        deleteContentButtonName: "delete-content-button",
        reloadContentButtonName: "reload-content-button",
    }

    const rightPaneToolbarName = "content-contents-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const contentService = ContentService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form]);

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

        } else {
            form.clear();
        }
    }, [form]);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    // event handlers
    const handleTextFieldChange = (e) => {
        setContent({
            ...content,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        //
    }

    const handleMoreOptionsMenuItemClick = (args) => {
        switch (args.index) {
            case 0:
            default:
                // handleEdit();
                break;
        }
    }

    return {
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addContentButtonName,
                                        type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMLEFT,
                                        text: "Menu",
                                        leading: icons?.MenuRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button,
                                        widget: <MenuList attributes={{
                                            items: [
                                                {
                                                    leading: icons?.EditRoundedIcon,
                                                    title: "Save content as draft",
                                                },
                                                {
                                                    leading: icons?.DeleteRoundedIcon,
                                                    title: "Schedule publish date",
                                                },
                                                {
                                                    type: MENUITEMTYPE.SEPARATOR,
                                                },
                                                {
                                                    leading: icons?.PublishRoundedIcon,
                                                    title: "History",
                                                },
                                                {
                                                    leading: null,
                                                    title: "Duplicate content",
                                                },
                                                {
                                                    leading: icons?.DriveFolderUploadRoundedIcon,
                                                    title: "Archive",
                                                }
                                            ],
                                            style: themes?.menuList,
                                            onItemClick: handleMoreOptionsMenuItemClick,
                                            isSelectable: false
                                        }} />
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: leftToolbarButtons.publishContentButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Publish",
                                        leading: icons?.PublishRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button,
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.publishContentButtonName,
                                        type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.MoreVertRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button,
                                        widget: <MenuList attributes={{
                                            items: [
                                                {
                                                    leading: icons?.SavingsRoundedIcon,
                                                    title: "Save content as draft",
                                                },
                                                {
                                                    leading: icons?.MoreTimeRoundedIcon,
                                                    title: "Schedule publish date",
                                                },
                                                {
                                                    type: MENUITEMTYPE.SEPARATOR,
                                                },
                                                {
                                                    leading: icons?.AddRoundedIcon,
                                                    title: "Duplicate content",
                                                },
                                                {
                                                    leading: icons?.DriveFolderUploadRoundedIcon,
                                                    title: "Archive",
                                                },
                                                {
                                                    type: MENUITEMTYPE.SEPARATOR,
                                                },
                                                {
                                                    leading: icons?.ClearRoundedIcon,
                                                    title: "Cancel",
                                                }
                                            ],
                                            style: themes?.menuList,
                                            onItemClick: handleMoreOptionsMenuItemClick,
                                            isSelectable: false
                                        }} />
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        // content title
        contentTitleFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Title",
            id: "contentName",
            value: content?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // content description
        contentDescriptionFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Briefly describe this content",
            id: "contentDescription",
            isMultiline: true,
            value: content?.contentDescription,
            style: themes?.field?.WYSIWYGEditor,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

    }
}

export default useCreateOrEditContentHook;