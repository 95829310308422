import { useCallback, useEffect, useRef, useState } from 'react';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { ProductCategoryService } from '../../../configuration.settings/settings/product.categories/product.category.service';
import { ProductDealService } from '../product.deal.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';
import { ProductService } from '../../product/product.service';
import { MarketService } from '../../../market.management/market/market.service';

const useCreateOrEditProductDealHook = (attributes) => {

    const appConfig = useAppConfig();

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [product, setProduct] = useState();
    const [productCategories, setProductCategories] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [selectedProductCategory, setSelectedProductCategory] = useState([]);
    const [productImages, setProductImages] = useState([]);
    const [productImageQueryString, setProductImageQueryString] = useState("");
    const [productImagePageNumber, setProductImagePageNumber] = useState(1);
    const [productImageRowsPerPage, setProductImageRowsPerPage] = useState(15);
    const [productImageTotalPages, setProductImageTotalPages] = useState(0);
    const [productImageTotalRows, setProductImageTotalRows] = useState(0);
    const [productImageSelectedProductImage, setSelectedProductImage] = useState({});

    const queryConfigurations = {
        default: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productBrands: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        markets: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productColors: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productTypes: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productCategories: {
            pageNumber: 1,
            rowsPerPage: 20
        },
        productImages: {
            pageNumber: 1,
            rowsPerPage: 15
        }
    }

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No images selected!",
            message: "Please select an images from the list to continue"
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available images, please wait..."
        }
    };

    // observer
    const productObserver = useRef(null);
    const componentObserver = useRef(null);
    const reactiveFormObserver = useRef(null);
    const productFrontImageUploaderObserver = useRef(null);
    const productBackImageUploaderObserver = useRef(null);
    const productCategoryDropDownFieldObserver = useRef(null);
    const productMarketDropDownFieldObserver = useRef(null);
    const productSelectedMarketsObserver = useRef(null);
    const productDealProductsObserver = useRef(null);
    const productDealProductsDatagridObserver = useRef(null);

    // components
    const productImageGalleryToolbarName = "product-images-gallery-toolbar";
    const productImageGalleryToolbar = useToolbar({
        name: productImageGalleryToolbarName
    });
    const productImageGalleryToolbarButtons = {
        filterButtonName: "product-images-gallery-filter-button",
        previousButtonName: "product-images-gallery-previous-button",
        pagesLabelName: "product-images-gallery-pages-label",
        nextButtonName: "product-images-gallery-next-button",
    }

    const productDealProductsDatagridToolbarName = "product-deal-products-datagrid-toolbar-name";
    const productDealProductsDatagridToolbarButtons = {
        filterButton: "filter-button",
        previousButton: "previous-button",
        pagesButton: "pages-label",
        nextButton: "next-button",
    }

    const productImageGalleryDatagridName = "product-images-gallery-datagrid";
    const productImageGalleryDatagrid = useDataGrid({
        name: productImageGalleryDatagridName
    });

    const productDealProductsDatagridName = "product-deal-products-datagrid";
    const productDealProductsDatagrid = useDataGrid({
        name: productDealProductsDatagridName
    });

    const form = useForm({
        ref: reactiveFormObserver
    });

    // toastr
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setProduct(args?.data);
        setSelectedProductCategory(args?.data);

        setProductCategories([]);
        setMarkets([]);

        // clear selected items
        productCategoryDropDownFieldObserver?.current?.clearItem();
        productMarketDropDownFieldObserver?.current?.clearItems();

        //
        getProductImages({
            queryString: productImageQueryString,
            pageNumber: productImagePageNumber,
            rowsPerPage: productImageRowsPerPage
        });

        // create a promise list of product brands, categories, variations, types, etc.
        const request = {
            queryString: "",
            pageNumber: queryConfigurations.default.pageNumber,
            rowsPerPage: queryConfigurations.default.rowsPerPage
        };

        Promise.all([
            productCategoryService.getAll(request)
                .then(response => response?.data?.list),

            marketService.getAll(request)
                .then(response => response?.data?.list)
        ]).then(response => {
            response.forEach(list => {

                console.log(list);

                // get product categories
                if (list[0].hasOwnProperty("productCategoryId")) {
                    if (!list.find(x => x.productCategoryId === args?.data?.productCategoryId)) {
                        list.push({
                            productCategoryId: args?.data?.productCategoryId,
                            productCategoryName: args?.data?.productCategory
                        });
                    }
                    setProductCategories(list);
                    productCategoryDropDownFieldObserver?.current?.addToStore(list);
                }

                // get markets
                if (list[0].hasOwnProperty("marketId")) {
                    if (!list.find(x => x.marketId === args?.data?.marketId)) {
                        list.push({
                            marketId: args?.data?.marketId,
                            market: args?.data?.market
                        });
                    }
                    setMarkets(list);
                    productMarketDropDownFieldObserver?.current?.addToStore(list);
                }
            });
        }).catch(error => {
            //
        });

        productFrontImageUploaderObserver.current?.loadImage(args?.data);
        productBackImageUploaderObserver.current?.loadImage(args?.data);

        if (args?.data !== null) {
            //
            productObserver.current = await args?.data;

            // select category
            productCategoryDropDownFieldObserver.current.selectItem({
                productCategoryId: args?.data?.productCategoryId,
                productCategoryName: args?.data?.productCategory
            });

            // clear product attribute list
            productSelectedMarketsObserver.current = [];

            // create a promise list of product attributes
            const productSelectedRequest = {
                productId: productObserver.current?.productId,
                queryString: "",
                pageNumber: queryConfigurations.default.pageNumber,
                rowsPerPage: queryConfigurations.default.rowsPerPage
            };
            const promiseSelectedList = [
                productDealService.getProductDealSelectedMarkets(productSelectedRequest)
                    .then(response => response?.data?.list)
            ];

            // get all product details attributes
            Promise.all(promiseSelectedList).then(response => {

                response.forEach(list => {
                    if (Array.isArray(list) && list.length > 0) {
                        // get product selected markets
                        if (list[0].hasOwnProperty("marketSelectionId")) {
                            productSelectedMarketsObserver.current = list;
                            productMarketDropDownFieldObserver?.current?.selectItems(list);
                        }
                    }
                });
            }).catch(error => {
                //
            });

            // set form data
            form.setData(args?.data);
        } else {
            form.clear();

            //
            productObserver.current = await {};
        }
    }, [form])

    // services
    const productCategoryService = ProductCategoryService();
    const marketService = MarketService();
    const productService = ProductService();
    const productDealService = ProductDealService();
    const productImageService = ProductImageService();

    const getProductCategories = useCallback(async (search) => {
        await productCategoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 1000000
        }).then(response => {
            setProductCategories(response.data.list);
            // console.log(product);
        }).catch(error => {
        });
    }, []);

    const getMarkets = useCallback(async (search) => {
        await marketService.getAll({
            queryString: search,
            pageNumber: queryConfigurations.markets.pageNumber,
            rowsPerPage: queryConfigurations.markets.rowsPerPage
        })
            .then(response => {
                setMarkets(response.data.list);

                //
                // productMarketDropDownFieldObserver?.current?.selectItems(productSelectedVariationsObserver.current);
            })
            .catch(error => {
            });

    }, []);

    const getProductImages = useCallback(async (request) => {

        productImageGalleryDatagrid?.showLoader(messages?.loader?.fetchingMessage);
        productImageGalleryDatagrid.clearSelection();

        await productImageService.getAll(request)
            .then(response => {
                productImageGalleryDatagrid?.hideLoader();

                // populate datagrid 
                setProductImages(response?.data?.list);

                // set page
                setProductImagePageNumber(response?.data?.page?.pageNumber);
                setProductImageRowsPerPage(response?.data?.page?.rowsPerPage);
                setProductImageTotalPages(response?.data?.page?.totalPages);
                setProductImageTotalRows(response?.data?.page?.totalRows);

                productImageGalleryToolbar.button({
                    name: productImageGalleryToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                productImageGalleryDatagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setProduct({
            ...product,
            [e.id]: e.value
        })
    }

    const handleProductImage1Upload = async (args) => {
        //
    }

    const handleProductImage2Upload = async (args) => {
        //
    }

    const handleSearchCategoryFieldChange = (e) => {
        getProductCategories(e.value);
    }

    const handleSearchMarketFieldChange = (e) => {
        getMarkets(e.value);
    }

    const handleAddDealProductsFromMarket = (e) => {
        // // clear selection
        // setProductIngredient({});

        // // show dialog
        // productIngredientsMarketDialog.show({
        //     title: "Shop for Products",
        //     showCloseButton: true,
        //     isNew: true
        // });

        // // listen to dialog for changes
        // catererProductIngredientsMarketDialogObserver.current({
        //     data: null
        // });

    }

    const handleDeleteDealProduct = (e) => {
        // if (productIngredientDatagrid.selectedRowsCount() > 0) {
        //     confirmDialog.show({
        //         title: (productIngredientDatagrid.selectedRowsCount() + ((productIngredientDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
        //         message: "Are you sure you want to delete " + ((productIngredientDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
        //         onConfirmed: (e) => {
        //             pageLoader.show({
        //                 message: "Loading, please wait...",
        //             });

        //             console.log(productIngredientDatagrid.getSelectedRowsKeyValue());
        //             catererProductService.removeProductIngredients({
        //                 productId: catererProductObserver.current?.productId,
        //                 productIngredients: productIngredientDatagrid.getSelectedRowsKeyValue()
        //             }).then(response => {
        //                 getCatererProductIngredients({
        //                     catererProductId: catererProductObserver.current?.productId,
        //                     queryString: productIngredientDatagridQueryString,
        //                     pageNumber: productIngredientDatagridPageNumber,
        //                     rowsPerPage: productIngredientDatagridRowsPerPage
        //                 });
        //                 pageLoader.hide();
        //             });
        //         }
        //     });
        // } else {
        //     alertDialog.show({
        //         title: "No product selected!",
        //         message: "Please select a product from the list to continue"
        //     });
        // }
    }

    const handleSaveOrUpdate = async (args) => {
        console.log(args?.data);
        // return;

        pageLoader.show({
            message: "Loading, please wait...",
        });
        if (args?.isNew) {
            await productService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader.hide();
                toastr.success("Product successfully created");
            }).catch((error) => {
                pageLoader.hide();
                toastr.error("Oops! something went wrong.");
            });
        } else {
            await productService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader.hide();
                toastr.success("Product successfully updated");
            }).catch((error) => {
                pageLoader.hide();
                toastr.error("Oops! something went wrong.");
            });
        }
    }

    return {
        product: product,
        media: productObserver.current,

        componentObserver: componentObserver,
        reactiveForm: reactiveFormObserver,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // media
        frontImageUploaderAttributes: {
            formElement: true,
            validate: true,
            id: "media1Id",
            key: "media1Id",
            url: "media1Url",
            caption: "*File supported .png, .jpg, .webp",
            size: themes?.sizeAbsolute,
            observer: productFrontImageUploaderObserver,
            leading: icons?.BrandingWatermarkRoundedIcon,
            style: themes?.image,
            model: productObserver.current,
            isImageAvailable: productObserver.current ? true : false,
            onUploadCompleted: handleProductImage1Upload,
            config: {
                key: "mediaId",
                url: "mediaUrl",
                imageSize: 500,
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: productService?.uploadUrl,
                downloadUrl: productService?.downloadUrl
            }
        },

        backImageUploaderAttributes: {
            formElement: true,
            validate: true,
            id: "media2Id",
            key: "media2Id",
            url: "media2Url",
            caption: "*File supported .png, .jpg, .webp",
            size: themes?.sizeAbsolute,
            observer: productBackImageUploaderObserver,
            leading: icons?.BrandingWatermarkRoundedIcon,
            style: themes?.image,
            model: productObserver.current,
            isImageAvailable: productObserver.current ? true : false,
            onUploadCompleted: handleProductImage2Upload,
            config: {
                key: "mediaId",
                url: "mediaUrl",
                imageSize: 500,
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: productService?.uploadUrl,
                downloadUrl: productService?.downloadUrl
            }
        },

        // product name
        productNameFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Choose a name for this product",
            id: "productName",
            value: product?.categoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // product description
        productDescriptionFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "Briefly describe this product",
            id: "productDescription",
            isMultiline: true,
            value: product?.productDescription,
            style: themes?.field?.multiTextField200Hpx,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // product category
        productCategoryDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            observer: productCategoryDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productCategoryId",
            title: "productCategoryName",
            value: "productCategoryId",
            items: productCategories,
            isSearchable: true,
            model: productObserver?.current,
            onFilter: handleSearchCategoryFieldChange
        },

        // market
        productDealMarketDropDownFieldAttributes: {
            name: "market-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a market",
            observer: productMarketDropDownFieldObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "marketId",
            title: "marketName",
            value: "marketId",
            items: markets,
            isSearchable: true,
            model: productObserver?.current,
            onLookupChange: handleSearchMarketFieldChange
        },

        // price
        productDealPriceFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "0.00",
            id: "serviceFee",
            value: product?.serviceFee,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // discount
        productDealDiscountFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "0%",
            id: "discount",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // product tags
        productTagsFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "e.g. Stew ingredient",
            id: "productTags",
            isMultiline: true,
            value: product?.productTags,
            style: themes?.field?.multiTextField200Hpx,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // product ingredients datagrid attributes
        productDealProductsDatagridToolbarAttributes: {
            name: productDealProductsDatagridToolbarName,
            items: [
                {
                    name: productDealProductsDatagridToolbarButtons?.addProductButton,
                    type: TOOLBARITEMTYPE.BUTTON,
                    text: "Add",
                    leading: icons?.AddRoundedIcon,
                    onClick: (e) => handleAddDealProductsFromMarket(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
                {
                    type: TOOLBARITEMTYPE.SPACE,
                    style: themes?.toolbar?.space?.space4
                },
                {
                    name: productDealProductsDatagridToolbarButtons?.deleteProductButton,
                    type: TOOLBARITEMTYPE.BUTTON,
                    leading: icons?.DeleteRoundedIcon,
                    onClick: (e) => handleDeleteDealProduct(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
            ]
        },

        // 
        productDealProductsDatagridAttributes: {
            name: productDealProductsDatagridName,
            key: "marketProductInventoryId",
            observer: productDealProductsDatagridObserver,
            columns: [
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: "marketProductInventoryId",
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                    value: "media1Url",
                    default: "",
                    style: themes?.width35px,
                    image: {
                        id: "media1Id",
                        key: "media1Id",
                        url: "media1Url",
                        size: themes?.size35px,
                        leading: icons?.BrandingWatermarkRoundedIcon,
                        style: themes?.image,
                        config: {
                            downloadUrl: productImageService?.downloadUrl
                        },
                    }
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Product",
                    value: "productName",
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Price",
                    value: "priceAlias",
                    default: "",
                    textAlign: ALIGNMENTTYPE.RIGHT,
                    style: themes?.width90px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.TEXTFIELD,
                    title: "Qty",
                    value: "quantity",
                    default: "",
                    style: themes?.width50px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.UNDOCK,
            dataset: productDealProductsObserver.current
        },

        themes: themes,
    }
}

export default useCreateOrEditProductDealHook;