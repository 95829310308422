import React from 'react';
import useProductFilterHook from './product.filter.hook';
import { TextField } from '../../../../../shared/components/field/textfield.component';
import { MultiTextField } from '../../../../../shared/components/field';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { ImageUploader } from '../../../../../shared/components/image/image.uploader.component';
import { MultiSelectDropDownField } from '../../../../../shared/components/field/multi.select.dropdown.field.component';

const ProductFilterView = ({ attributes }) => {

    const hook = useProductFilterHook(attributes);

    return (
        <div className={"w-100 position-relative p-2 pt-1 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>

                        {/* category field */}
                        {/* brand & variation field */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"min-width-150px me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Sort by:</div>
                                <SingleSelectDropDownField
                                    attributes={hook?.productSortColumnDropDownFieldAttributes} />
                            </div>

                            <div className={"min-width-280px me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Choose a category:</div>
                                <SingleSelectDropDownField
                                    attributes={hook?.productCategoryDropDownFieldAttributes} />
                            </div>

                            <div className={"min-width-100px"}>
                                <div className={"mb-2 opacity-0- " + hook?.themes?.label?.text13px}>Order</div>
                                <SingleSelectDropDownField
                                    attributes={hook?.productSortOrderDropDownFieldAttributes} />
                            </div>
                        </div>

                    </div>
                </form>
            </div >
        </div >
    );
}

export default ProductFilterView;