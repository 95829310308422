import React from "react";
import { COMPONENTYPE } from "../../enums/enums";

const HiddenTextField = ({ attributes, dataObject, onChange }) => {

    const handleOnChange = (e) => {
        if (onChange)
            onChange(e.target);
    }

    return (
        <div className={"d-flex position-relative p-0"}>
            <input
                aria-controls={attributes?.formElement}
                aria-roledescription={COMPONENTYPE.TEXTFIELD}
                aria-required={attributes?.validate}
                onChange={handleOnChange}
                id={attributes?.id}
                name={attributes?.id}
                placeholder={attributes?.placeholder}
                defaultValue={attributes?.value || ""}
                type={'hidden'}
                className={"w-100 form-control text-13px rounded-3px border-0px p-0 bg-transparent " + attributes?.style?.text} />
        </div>
    );
}
export { HiddenTextField };