import React from 'react';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import useOrderTransactionsHook from './order.transactions.hook';

const OrderTransactionsView = () => {

    const hook = useOrderTransactionsHook();

    return (
        <div>
            {hook?.toolbar}
            {hook?.gridArea}

            {/* create or edit market modal */}
            <ModalDialog attributes={hook?.createOrEditDialog} />
        </div >
    );

}

export default OrderTransactionsView;