import React from 'react';
import useCreateOrEditContentHook from './createoredit.content.hook';
import Label from '../../../../shared/components/label/label.component';
import { StackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';
import { ModalDialogCollection } from '../../../../shared/components/dialog/modal.dialog.collection.component';
import { TextField } from '../../../../shared/components/field/textfield.component';
import { WYSIWYGEditor } from '../../../../shared/components/field/wysiwyg.editor.component';
import { MultiSelectDropDownField } from '../../../../shared/components/field/multi.select.dropdown.field.component';
import { Toolbar } from '../../../../shared/components/toolbar/toolbar.component';

const CreateOrEditContentView = ({ attributes }) => {

    const hook = useCreateOrEditContentHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0 pb-5"}>
            <div className={"w-100 position-absolute top-0px start-0px end-0px height-40px z-index-10"}>
                {
                    (() => {
                        return hook?.toolbar.widget;
                    })()
                }
            </div>

            <div className={"w-100 position-relative pt-5 pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 height-460px- d-flex"}>
                        <div className={"w-100 max-width-650px mx-auto flex-column"}>
                            {/* media upload */}
                            <div className={"mb-4"}>
                                <div className={"mb-2"}>
                                    <Label attributes={hook?.mediaHeaderLabelAttributes} />
                                </div>
                                <div className={"mb-4 rounded-5px"}>
                                    {/* <ProfilePhotoUploader attributes={hook?.mediaUploaderAttributes} /> */}
                                </div>
                            </div>

                            {/* content details */}
                            <div className={"mb-4"}>

                                {/* content name field */}
                                <div className={"w-100 mb-4"}>
                                    <TextField
                                        attributes={hook?.contentTitleFieldAttributes} />
                                </div>

                                {/* content description */}
                                <div className={"w-100 m-0 mb-4"}>
                                    <WYSIWYGEditor
                                        attributes={hook?.contentDescriptionFieldAttributes} />
                                </div>

                            </div>

                        </div>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default CreateOrEditContentView;