import { useCallback, useEffect, useRef, useState } from 'react';
import { MediaService } from './media.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE } from '../../../../shared/enums/enums';
import { useForm } from '../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../shared/components/field/token.search.textfield.component';

const useUploadMediaHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [categories, setMediaCollection] = useState([]);
    const [media, setMedia] = useState({
        mediaName: '',
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const _parentMediaDgridName = "createoredit-parent-gallery-media-datagrid";
    const parentMediaCollectionDatagrid = useDataGrid({
        name: _parentMediaDgridName
    });
    const _parentMediaTokenSearchFieldName = "createoredit-parent-gallery-media-token-search-field";
    const parentMediaCollectionTokenSearchField = useTokenSearchField({
        name: _parentMediaTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const mediaService = MediaService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        //
        getParentMediaCollection("");

        // clear token
        parentMediaCollectionTokenSearchField?.clear();

        // clear datagrid
        parentMediaCollectionDatagrid.clearSelection();

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

            // add token
            if ((args?.data?.parentMediaId !== null) && (args?.data?.parentMediaId !== "null")) {
                parentMediaCollectionTokenSearchField?.addToken({
                    value: args?.data?.parentMediaId,
                    text: args?.data?.parentMediaName,
                    onButtonClick: (e, args) => {
                        parentMediaCollectionDatagrid.clearSelection();
                    }
                });
            }
        } else {
            form.clear();
        }
    }, [form])

    // services
    const getParentMediaCollection = useCallback(async (search) => {
        // pageLoader?.show({
        //     message: "Fetching available categories, please wait...",
        //     show: true
        // });

        // repopulate grid with data
        await mediaService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 20
        })
            .then(response => {
                setMediaCollection(response.data.list);
                // pageLoader?.close();
            })
            .catch(error => {
                // pageLoader?.close();
                // alertDialog.show({
                //     title: "Oops! something went wrong",
                //     message: "We're sorry your requests can't be completed at the moment. Please try again later."
                // });
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setMedia({
            ...media,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        getParentMediaCollection(e.value);
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await mediaService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await mediaService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleCancelSelectedParentMedia = (args) => {
        //
    }

    const handleRowSelected = (args) => {
        if (args?.selectedRow) {
            parentMediaCollectionTokenSearchField?.addToken({
                value: args?.selectedRow?.mediaId,
                text: args?.selectedRow?.mediaName,
                onButtonClick: (e, args) => {
                    parentMediaCollectionDatagrid.clearSelection();
                }
            })
        }
    }

    return {
        media: media,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        mediaNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        mediaNameField: {
            formElement: true,
            validate: true,
            placeholder: "Choose a name for your media",
            id: "mediaName",
            value: media?.mediaName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        mediaDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        mediaDescriptionField: {
            formElement: true,
            validate: true,
            placeholder: "Media description",
            id: "mediaDescription",
            isMultiline: true,
            value: media?.mediaDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useUploadMediaHook;