import React from 'react';
import HorizontalSpace from '../../../../../shared/components/separator/horizontal.space.component';
import { Tab } from '../../../../../shared/components/tab/tab.component';
import { Avatar } from '../../../../../shared/components/avatar';
import { StackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import useOrderDetailsHook from './order.details.hook';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { DataGrid } from '../../../../../shared/components/datagrid';
import { Line } from 'react-chartjs-2';
import Flag from '../../../../../shared/components/flag/flag.component';
import DispatchMap from '../../../../../shared/components/map/dispatch.map';

const OrderDetailsView = ({ attributes }) => {

    const hook = useOrderDetailsHook(attributes);

    return (
        <div className={"w-100 position-absolute top-0px start-0px bottom-0px end-0px p-0 pt-0 pb-5"}>

            <div className={"position-absolute top-10px start-15px end-0px z-index-1 d-flex align-items-center justify-content-start text-18px mb-3"}>
                <div className={"w-100 d-flex align-items-center justify-content-start text-18px me-2"}>
                    <div className={"d-flex align-items-center justify-content-start me-2 line-height-20px"}>
                        {/* <i className={"m-0 me-2"}>{hook?.icons.ShoppingBasketRoundedIcon}</i> */}
                        <span className={"text-bold"}>Order - </span>
                    </div>
                    <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                        <i className={"d-flex m-0 icon-13px"}>{hook?.icons?.TagIcon}</i>
                        <span className={"text-bold text-18px d-flex line-height-20px"}>{hook?.order?.orderId}</span>
                    </div>
                </div>
            </div>

            {/* details */}
            <div className={"mb-0 p-3 position-absolute top-50px start-0px bottom-0px end-0px overflow-auto overflow-x-hidden pt-0"}>
                <div className={"m-0 mb-0"}>
                    <div className={"position-relative"}>
                        <DataGrid attributes={hook?.orderProductsDatagrid} />
                    </div>
                </div>

                <div className={"bg-blue-light-theme min-height-100px rounded-3px mb-2 mt-1 p-3"}>
                    <div className={"d-flex position-relative height-45px mb-2"}>
                        <div className={"w-100 me-2"}>
                            <div>Delivery:</div>
                            <div className={"text-bold"}>{hook?.order?.deliveryFee}</div>
                        </div>
                        <div className={"w-100 me-2"}>
                            <div>Shopper's Fee:</div>
                            <div className={"text-bold"}>{hook?.order?.shopperFee}</div>
                        </div>
                        <div className={"w-100"}>
                            <div>Service Charge:</div>
                            <div className={"text-bold"}>{hook?.order?.serviceFee}</div>
                        </div>
                    </div>
                    <div className={"d-flex position-relative height-45px mb-0"}>
                        <div className={"w-100 me-2"}>
                            <div>VAT</div>
                            <div className={"text-bold"}>{hook?.order?.vat}</div>
                        </div>
                        <div className={"w-100 me-2"}>
                            <div>Discount</div>
                            <div className={"text-bold"}>0.00%</div>
                        </div>
                        <div className={"w-100"}>
                            <div>Total Price</div>
                            <div className={"text-bold"}>{hook?.order?.price}</div>
                        </div>
                    </div>
                </div>

                {/* market agent details */}
                <div className={"d-flex position-relative height-45px mb-4"}>
                    <div className={"w-100 d-flex align-items-center justify-content-start mb-0"}>
                        <div className={"max-width-35px min-width-35px height-35px position-relative me-2 rounded-3 border border-2px border-white-theme"}>
                            <Avatar attributes={hook?.shopperPhotoAttributes} />
                        </div>
                        <div>
                            <div className={"d-flex align-items-center text-13px text-bold mb-0 max-width-200px-"}>
                                <span className={"me-2 text-truncate"}>{hook?.order?.shopper}</span>
                                <i className={"d-flex icon-13px text-12px"}>{hook?.icons?.VerifiedIcon}</i>
                            </div>
                            <div className={"d-flex text-12px m-0 opacity-8"}>
                                <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                                    <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.LocalPhoneIcon}</i>
                                    <span className={"d-flex text-truncate max-width-80px"}>{hook?.order?.phoneNumber}</span>
                                </div>

                                <div className={"d-flex align-items-center w-100 justify-content-start text-12px"}>
                                    <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.ContactMailRoundedIcon}</i>
                                    <span className={"d-flex text-truncate max-width-150px"}>{hook?.order?.shopperEmailAddress}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* drop off location */}
                <div className={"m-0"}>
                    <div className={"text-18px w-100 mb-2 line-height-20px"}>
                        <div className={"text-bold mb-1"}>Delivery Details</div>
                        <div className={"text-12px opacity-8"}>October 28, 2023. 01:36 PM</div>
                    </div>
                    <DispatchMap attributes={{
                        pickup: {
                            map: hook?.order?.marketMap,
                            leading: hook?.marketAddressIconAttributes,
                            name: hook?.order?.market,
                            address: hook?.order?.marketAddress
                        },
                        dropOff: {
                            leading: hook?.dropOffAddressIconAttributes,
                            name: hook?.order?.customerName,
                            address: hook?.order?.dropOffAddress
                        }
                    }} />
                </div>
            </div>

            {/* fund wallet */}
            <ModalDialog attributes={hook?.fundOrderModalDialogAttributes} />

            {/* wallet transfer */}
            <ModalDialog attributes={hook?.walletTransferModalDialogAttributes} />

            {/* bank transfer */}
            <ModalDialog attributes={hook?.bankTransferModalDialogAttributes} />

        </div >
    );
}

export default OrderDetailsView;