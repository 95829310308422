import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../shared/components/datagrid';
import CreateOrEditCategoryView from './createoredit.content.category.view';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../shared/components/loader/page.loader.component';
import { ContentCategoryService } from './content.category.service';
import { usePrimaryUIThemes } from '../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../shared/hooks/ui.icons.hook';
import MenuList from '../../../../shared/components/menu/menu.list.component';

const useContentCategoriesHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditCategoryObserver = useRef();

    // components
    const datagridName = "product-categories-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-categories-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        addCategoryButtonName: "add-category-button",
        editCategoryButtonName: "edit-category-button",
        deleteCategoryButtonName: "delete-category-button",
        reloadCategoryButtonName: "reload-category-button",
    }

    const rightPaneToolbarName = "product-categories-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const categoryService = ContentCategoryService();

    // dialogs
    const createOrEditCategoryDialogName = "createoredit-category-modal";
    const filterCategoryDialogName = "filter-category-modal";
    const createOrEditCategoryDialog = useModalDialog({
        name: createOrEditCategoryDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getCategories = useCallback(async (request) => {
        pageLoader?.show({
            message: "Fetching available categories, please wait...",
            show: true
        });

        datagrid.clearSelection();

        await categoryService.getAll(request)
            .then(response => {
                pageLoader?.close();

                // populate datagrid 
                setCategories(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                pageLoader?.close();
                // alertDialog.show({
                //     title: "Oops! something went wrong",
                //     message: "We're sorry your requests can't be completed at the moment. Please try again later."
                // });
            });

    }, []);

    useEffect(() => {
        getCategories({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // category
    const handleCreate = (e) => {
        // clear selection
        setSelectedCategory({});

        // show dialog
        createOrEditCategoryDialog.show({
            title: "New category",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditCategoryObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple categories selected!",
                message: "Oops!, you selected multiple categories. Please select a category from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedCategory = categories.find(category => category.categoryId === datagrid.getSelectedRowKeyValue());
                setSelectedCategory(selectedCategory);

                createOrEditCategoryDialog.show({
                    title: "Edit category",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditCategoryObserver.current({
                    data: selectedCategory,
                    callback: () => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No category selected!",
                    message: "Please select a category from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these categories?" : "this category?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    categoryService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these categories?" : "this category?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    categoryService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these categories?" : "this category?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    categoryService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these categories?" : "this category?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    categoryService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these categories?" : "this category?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    categoryService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCategories({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getCategories({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getCategories({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getCategories({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleAddContentToCategory = (e) => {
        // 
    }

    const handleSelectRecord = (e, args) => {
        datagrid.setSelectedRow(args.key);
        const selectedCategory = categories.find(category => category.categoryId === args.key);
        setSelectedCategory(selectedCategory);
    }

    const handleDatagridRowMenuItemClick = (args) => {
        switch (args.index) {
            case 7:
                //
                break;
            case 6:
                handleAddContentToCategory();
                break;
            case 5:
                handleRestoreArchive();
                break;
            case 4:
                handleArchive();
                break;
            case 3:
                handleUnpublish();
                break;
            case 2:
                handlePublish();
                break;
            case 1:
                handleDelete();
                break;
            case 0:
            default:
                handleEdit();
                break;
        }
    }

    return {
        componentObserver,
        categories,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add category",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: leftToolbarButtons.reloadCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.PublishRoundedIcon,
                                        onClick: (e) => handlePublish(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Unpublish",
                                        leading: icons?.VpnLockRoundedIcon,
                                        onClick: (e) => handleUnpublish(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: leftToolbarButtons.reloadCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DriveFolderUploadRoundedIcon,
                                        onClick: (e) => handleArchive(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Restore",
                                        leading: icons?.ReplyRoundedIcon,
                                        onClick: (e) => handleRestoreArchive(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    // {
                                    //     name: rightToolbarButtons.label,
                                    //     type: TOOLBARITEMTYPE.LABEL,
                                    //     menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                    //     text: "25 of 1200 records",
                                    //     trailing: icons?.FilterAltRoundedIcon,
                                    //     observer: componentObserver,
                                    //     style: themes?.toolbar?.label?.label13
                                    // },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["categoryId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["categoryId"],
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.TOOLBAR,
                    value: ["categoryId"],
                    style: themes?.width30px,
                    items: [
                        {
                            type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                            menuPosition: MENUALIGNMENTTYPE.BOTTOMLEFT,
                            leading: icons?.MoreVertRoundedIcon,
                            onClick: (e, args) => { handleSelectRecord(e, args) },
                            style: themes?.toolbar?.iconButton,
                            widget: <MenuList attributes={{
                                items: [
                                    {
                                        leading: icons?.EditRoundedIcon,
                                        title: "Browse category",
                                    },
                                    {
                                        leading: icons?.DeleteRoundedIcon,
                                        title: "Delete",
                                    },
                                    {
                                        leading: icons?.PublishRoundedIcon,
                                        title: "Publish",
                                    },
                                    {
                                        leading: null,
                                        title: "Unpublish",
                                    },
                                    {
                                        type: MENUITEMTYPE.SEPARATOR,
                                    },
                                    {
                                        leading: icons?.DriveFolderUploadRoundedIcon,
                                        title: "Archive",
                                    },
                                    {
                                        leading: null,
                                        title: "Restore",
                                    },
                                    {
                                        type: MENUITEMTYPE.SEPARATOR,
                                    },
                                    {
                                        leading: icons?.AddRoundedIcon,
                                        title: "Add a new product",
                                    },
                                    {
                                        leading: icons?.MenuRoundedIcon,
                                        title: "Browse available products",
                                    }
                                ],
                                style: themes?.menuList,
                                onItemClick: handleDatagridRowMenuItemClick,
                                isSelectable: false
                            }} />
                        },
                    ]
                },
                {
                    title: "Category",
                    value: ["categoryName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    title: "Parent",
                    value: ["parentCategoryName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    type: COLUMNTYPE.TOOLBAR,
                    value: ["categoryId"],
                    style: themes?.width70px,
                    items: [
                        {
                            type: TOOLBARITEMTYPE.BUTTON,
                            menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                            leading: icons?.VpnLockRoundedIcon,
                            onClick: (e, args) => { handleSelectRecord(e, args) },
                            style: themes?.toolbar?.iconButton
                        },
                        {
                            type: TOOLBARITEMTYPE.BUTTON,
                            menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                            leading: icons?.LockRoundedIcon,
                            onClick: (e, args) => { handleSelectRecord(e, args) },
                            style: themes?.toolbar?.iconButton
                        },
                    ]
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Author",
                    value: ["author"],
                    default: "",
                    style: themes?.width60
                },
                {
                    default: "",
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.DATETIME,
                    title: "Date Created",
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: categories
        }}  />,

        createOrEditDialog: {
            name: createOrEditCategoryDialogName,
            data: selectedCategory,
            observer: createOrEditCategoryObserver,
            widget: CreateOrEditCategoryView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getCategories({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useContentCategoriesHook;