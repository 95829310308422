import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CustomerService } from '../customer.service';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    BarElement,
} from 'chart.js';
import { faker } from '@faker-js/faker';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import { OrderService } from '../../../order.management/orders/order.service';
import useFlags from '../../../../../shared/hooks/flags.hook';
import global from '../../../../../shared/helpers/global.variable.helper';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const useCustomerDetailsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [customer, setCustomer] = useState([]);
    // 
    const [orders, setCustomerOrders] = useState([]);
    const [orderQueryString, setCustomerOrderQueryString] = useState("");
    const [orderPageNumber, setCustomerOrderPageNumber] = useState(1);
    const [orderRowsPerPage, setCustomerOrderRowsPerPage] = useState(10);
    const [orderTotalPages, setCustomerOrderTotalPages] = useState(0);
    const [orderTotalRows, setCustomerOrderTotalRows] = useState(0);
    // 
    const [shoppingList, setShoppingList] = useState([]);
    const [shoppingListQueryString, setShoppingListQueryString] = useState("");
    const [shoppingListPageNumber, setShoppingListPageNumber] = useState(1);
    const [shoppingListRowsPerPage, setShoppingListRowsPerPage] = useState(10);
    const [shoppingListTotalPages, setShoppingListTotalPages] = useState(0);
    const [shoppingListTotalRows, setShoppingListTotalRows] = useState(0);

    const actionButtonsMenuItems = {
        fundCustomerMenuItem: "fund-customer-menuitem",
        customerToCustomerTransferMenuItem: "customer-to-customer-transfer-menuitem",
        bankTransferMenuItem: "bank-transfer-menuitem",
        placeLienOnCustomerMenuItem: "place-lien-on-customer-menuitem",
        lockCustomerAccountMenuItem: "lock-customer-account-menuitem",
        sendMessageMenuItem: "send-message-menuitem",
        printAccountStatmentMenuItem: "print-account-statment-menuitem",
        downloadAccountStatmentPdfMenuItem: "download-account-statment-pdf-menuitem",
        shareAccountStatmentPdfMenuItem: "share-account-statment-pdf-menuitem",
    };

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);
    const fundCustomerObserver = useRef(null);
    const customerTransferObserver = useRef(null);
    const bankTransferObserver = useRef(null);

    // flags
    const flags = useFlags();

    const stackablePanelComponentName = "customer-stackable-panel";
    const customerOptionToolbarName = "customer-option-toolbar";
    const ordersDatagridName = "customer-orders-datagrid";
    const shoppingListDatagridName = "customer-shopping-list";
    const fundCustomerModalDialogName = "fund-customer-modal-dialog";
    const customerTransferModalDialogName = "customer-transfer-modal-dialog";
    const bankTransferModalDialogName = "bank-transfer-modal-dialog";
    const ordersToolbarName = "orders-toolbar";
    const shoppingListToolbarName = "shopping-list-toolbar";

    // components
    const form = useForm({
        ref: reactiveForm
    });

    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const customerOptionToolbarButtons = {
        actionsButtonName: "add-market-product-button",
    };

    const fundCustomerModalDialog = useModalDialog({
        name: fundCustomerModalDialogName
    });

    const customerTransferModalDialog = useModalDialog({
        name: customerTransferModalDialogName
    });

    const bankTransferModalDialog = useModalDialog({
        name: bankTransferModalDialogName
    });

    const ordersToolbar = useToolbar({
        name: ordersToolbarName
    });

    const ordersToolbarButtons = {
        previousButtonName: "orders-previous-button",
        pagesLabelName: "orders-pages-info-label",
        nextButtonName: "orders-next-button",
    }

    const ordersDatagrid = useDataGrid({
        name: ordersDatagridName
    });

    const shoppingListToolbar = useToolbar({
        name: shoppingListToolbarName
    });

    const shoppingListToolbarButtons = {
        previousButtonName: "shopping-list-previous-button",
        pagesLabelName: "shopping-list-pages-info-label",
        nextButtonName: "shopping-list-next-button",
    }

    const shoppingListDatagrid = useDataGrid({
        name: shoppingListDatagridName
    });

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setCustomer(args?.data);

        // get customer's shopping list
        getCustomerShoppingList({
            customerId: args?.data?.customerId,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 10
        });

        // get customer's orders
        getCustomerOrders({
            customerId: args?.data?.customerId,
            queryString: "",
            pageNumber: 1,
            rowsPerPage: 10
        });
    }, [])

    // services
    const orderService = OrderService();

    const options = useMemo(() => {
        return {
            responsive: true,
            plugins: {
                legend: {
                    position: 'top',
                },
                // title: {
                //     display: true,
                //     text: 'Monthly Withdrawals',
                // },
            },
        }
    }, []);

    const getCustomerOrders = useCallback(async (request) => {
        ordersDatagrid.clearSelection();
        await orderService.getOrderHistory(request)
            .then(response => {
                setCustomerOrders(response?.data?.list);

                setCustomerOrderPageNumber(response?.data?.page?.pageNumber);
                setCustomerOrderRowsPerPage(response?.data?.page?.rowsPerPage);
                setCustomerOrderTotalPages(response?.data?.page?.totalPages);
                setCustomerOrderTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                //
            });
    }, []);

    const getCustomerShoppingList = useCallback(async (request) => {
        shoppingListDatagrid.clearSelection();
        await orderService.getOrderHistory(request)
            .then(response => {
                setCustomerOrders(response?.data?.list);

                setCustomerOrderPageNumber(response?.data?.page?.pageNumber);
                setCustomerOrderRowsPerPage(response?.data?.page?.rowsPerPage);
                setCustomerOrderTotalPages(response?.data?.page?.totalPages);
                setCustomerOrderTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                //
            });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleFundCustomer = (args) => {
        fundCustomerModalDialog.show({
            title: "Fund customer"
        });
        fundCustomerObserver.current({
            data: customer
        })
    }

    const handleCustomerTransfer = (args) => {
        customerTransferModalDialog.show({
            title: "Transfer to customer"
        });
        customerTransferObserver.current({
            data: customer
        })
    }

    const handleBankTransfer = (args) => {
        bankTransferModalDialog.show({
            title: "Bank transfer"
        });
        bankTransferObserver.current({
            data: customer
        })
    }

    const handleCustomerToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.lockCustomerAccountMenuItem:
                // updateMarketWithNewProducts();
                break;
            case actionButtonsMenuItems?.placeLienOnCustomerMenuItem:
                // addProductsToMarket();
                break;
            case actionButtonsMenuItems?.bankTransferMenuItem:
                handleBankTransfer();
                break;
            case actionButtonsMenuItems?.customerToCustomerTransferMenuItem:
                handleCustomerTransfer();
                break;
            case actionButtonsMenuItems.fundCustomerMenuItem:
            default:
                handleFundCustomer();
                break;
        }
    }

    

    const handleFirstPage = (e, args) => {
        if (orderPageNumber > 1)
            getCustomerOrders({
                customerId: customer?.customerId,
                queryString: orderQueryString,
                pageNumber: 1,
                rowsPerPage: orderRowsPerPage
            });
    }

    const handlePreviousPage = (e, args) => {
        if (orderPageNumber > 1)
            getCustomerOrders({
                customerId: customer?.customerId,
                queryString: orderQueryString,
                pageNumber: orderPageNumber - 1,
                rowsPerPage: orderRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (orderPageNumber < orderTotalPages)
            getCustomerOrders({
                customerId: customer?.customerId,
                queryString: orderQueryString,
                pageNumber: orderPageNumber + 1,
                rowsPerPage: orderRowsPerPage
            });
    }

    const handleRowsPerPageChange = (e, args) => {
        if (orderPageNumber < orderTotalPages) {
            setCustomerOrderRowsPerPage(args?.value);
            getCustomerOrders({
                customerId: customer?.customerId,
                queryString: orderQueryString,
                pageNumber: orderPageNumber,
                rowsPerPage: args?.value
            });
        }
    }

    const handlePageNumberChange = (e, args) => {
        setCustomerOrderPageNumber(args?.value);
        getCustomerOrders({
            customerId: customer?.customerId,
            queryString: orderQueryString,
            pageNumber: args?.value,
            rowsPerPage: orderRowsPerPage
        });
    }

    const handleRefresh = (e) => {
        getCustomerOrders({
            customerId: customer?.customerId,
            queryString: orderQueryString,
            pageNumber: orderPageNumber,
            rowsPerPage: orderRowsPerPage
        });
    }


    return {
        customer: customer,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // profile photo
        profilePhotoAttributes: {
            formElement: true,
            id: "media",
            value: customer?.media,
            placeholder: icons?.PersonIcon,
            style: themes?.avatar280px,
            observer: componentObserver
        },

        // actions toolbar
        actionsMenuButton: {
            name: customerOptionToolbarName,
            items: [
                {
                    name: customerOptionToolbarButtons.actionsButtonName,
                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.MenuRoundedIcon,
                    onClick: (e) => { },
                    observer: componentObserver,
                    style: themes?.toolbar?.button,
                    widget: <MenuList attributes={{
                        items: [
                            {
                                leading: icons?.DataSaverOnIcon,
                                name: actionButtonsMenuItems.fundCustomerMenuItem,
                                title: "Fund customer",
                            },
                            {
                                leading: icons?.CompareArrowsRoundedIcon,
                                name: actionButtonsMenuItems.customerToCustomerTransferMenuItem,
                                title: "Customer-to-customer transfer ",
                            },
                            {
                                leading: icons?.AccountBalanceRoundedIcon,
                                name: actionButtonsMenuItems.bankTransferMenuItem,
                                title: "Transfer to other banks",
                            },
                            {
                                leading: icons?.LockRoundedIcon,
                                name: actionButtonsMenuItems?.lockCustomerAccountMenuItem,
                                title: "Lock customer",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.MailIcon,
                                name: actionButtonsMenuItems?.sendMessageMenuItem,
                                title: "Send message",
                            },
                            // {
                            //     type: MENUITEMTYPE.SEPARATOR,
                            // },
                            // {
                            //     leading: icons?.LocalPrintshopIcon,
                            //     name: actionButtonsMenuItems?.printAccountStatmentMenuItem,
                            //     title: "Print statment",
                            // },
                            // {
                            //     leading: icons?.LocalPrintshopIcon,
                            //     name: actionButtonsMenuItems?.downloadAccountStatmentPdfMenuItem,
                            //     title: "Download PDF",
                            // },
                            // {
                            //     leading: icons?.ShareIcon,
                            //     name: actionButtonsMenuItems?.shareAccountStatmentPdfMenuItem,
                            //     title: "Share statment as PDF",
                            // },
                        ],
                        style: themes?.menuList,
                        onItemClick: handleCustomerToolbarOptionsMenuItemClick,
                        isSelectable: false
                    }} />
                }
            ]
        },

        // 
        shoppingListToolbarAttributes: {
            name: ordersToolbarName,
            items: [
                {
                    name: ordersToolbarButtons.previousButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Previous",
                    leading: icons?.ArrowBackIosRoundedIcon,
                    onClick: (e, args) => { handlePreviousPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.startDropDownButton
                },
                {
                    name: ordersToolbarButtons.nextButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Next",
                    leading: icons?.ArrowForwardIosRoundedIcon,
                    onClick: (e, args) => { handleNextPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.endDropDownButton
                },
            ]
        },

        // 
        shoppingListDatagridAttributes: {
            name: shoppingListDatagridName,
            key: ["customerOrderId"],
            columns: [
                {
                    default: "",
                    style: themes?.width5px
                },
                {
                    type: COLUMNTYPE.FLAG,
                    value: ["status"],
                    title: "",
                    default: "",
                    style: themes?.width30px,
                    flags: flags?.orderFlags
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Order",
                    value: ["orderId"],
                    default: "",
                    style: themes?.width100px,
                    widget: null
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Amount",
                    value: ["price"],
                    default: "",
                    style: themes?.width90px
                },
            ],
            rowHeight: 34,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.UNDOCK,
            placeholder: {
                showImage: false,
                text: "No shopping list available yet."
            },
            dataset: orders
        },

        // 
        ordersToolbarAttributes: {
            name: ordersToolbarName,
            items: [
                {
                    name: ordersToolbarButtons.previousButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Previous",
                    leading: icons?.ArrowBackIosRoundedIcon,
                    onClick: (e, args) => { handlePreviousPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.startDropDownButton
                },
                {
                    name: ordersToolbarButtons.nextButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Next",
                    leading: icons?.ArrowForwardIosRoundedIcon,
                    onClick: (e, args) => { handleNextPage(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.endDropDownButton
                },
            ]
        },

        // 
        ordersDatagridAttributes: {
            name: ordersDatagridName,
            key: ["customerOrderId"],
            columns: [
                {
                    default: "",
                    style: themes?.width5px
                },
                {
                    type: COLUMNTYPE.FLAG,
                    value: ["status"],
                    title: "",
                    default: "",
                    style: themes?.width30px,
                    flags: flags?.orderFlags
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Order",
                    value: ["orderId"],
                    default: "",
                    style: themes?.width100px,
                    widget: null
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Amount",
                    value: ["price"],
                    default: "",
                    style: themes?.width90px
                },
            ],
            rowHeight: 34,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.UNDOCK,
            placeholder: {
                showImage: false,
                text: "No order available yet."
            },
            dataset: orders
        },

        themes: themes,
        icons: icons,

    }
}

export default useCustomerDetailsHook;