import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import '../../../../../shared/helpers/global.variable.helper';
import { MarketTypeService } from './market.type.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import CreateOrEditMarketTypeView from './createoredit/createoredit.market.type.view';

const useMarketTypesHook = () => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [types, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState({});
    const [typeQueryString, setTypeQueryString] = useState("");
    const [typePageNumber, setTypePageNumber] = useState(1);
    const [typeRowsPerPage, setTypeRowsPerPage] = useState(25);
    const [typeTotalPages, setTypeTotalPages] = useState(0);
    const [typeTotalRows, setTypeTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No country selected!",
            message: "Please select an country from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New country",
            successMessage: {
                title: "Country created successfully!",
                message: "Country was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit country details",
            alertMessage: {
                title: "Multiple countries selected!",
                message: "Oops!, you selected multiple countries. Please select a country from the list to continue"
            },
            successMessage: {
                title: "Country updated successfully!",
                message: "Country was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit country details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country deleted successfully!",
                message: "Country was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country archived successfully!",
                message: "Country was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country restored successfully!",
                message: "Country was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available countries, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditTypeObserver = useRef();

    // components
    const datagridName = "product-types-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-types-left-pane-toolbar";
    const leftToolbarButtons = {
        addTypeButtonName: "add-type-button",
        editTypeButtonName: "edit-type-button",
        deleteTypeButtonName: "delete-type-button",
        reloadTypeButtonName: "reload-type-button",
    }

    const rightPaneToolbarName = "product-types-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const typeService = MarketTypeService();

    // dialogs
    const createOrEditTypeDialogName = "createoredit-type-modal";
    const createOrEditTypeDialog = useModalDialog({
        name: createOrEditTypeDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getTypes = useCallback(async (request) => {
        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await typeService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setTypes(response?.data?.list);

                // set page
                setTypePageNumber(response?.data?.page?.pageNumber);
                setTypeRowsPerPage(response?.data?.page?.rowsPerPage);
                setTypeTotalPages(response?.data?.page?.totalPages);
                setTypeTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getTypes({
            queryString: typeQueryString,
            pageNumber: typePageNumber,
            rowsPerPage: typeRowsPerPage
        });
    }, []);

    // type
    const handleCreate = (e) => {
        // clear selection
        setSelectedType({});

        // show dialog
        createOrEditTypeDialog.show({
            title: "New type",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditTypeObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple types selected!",
                message: "Oops!, you selected multiple types. Please select a type from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedType = types.find(type => type.typeId === datagrid.getSelectedRowKeyValue());
                setSelectedType(selectedType);

                createOrEditTypeDialog.show({
                    title: "Edit type",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditTypeObserver.current({
                    data: selectedType,
                    callback: () => {
                        getTypes({
                            queryString: typeQueryString,
                            pageNumber: typePageNumber,
                            rowsPerPage: typeRowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No type selected!",
                    message: "Please select a type from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these types?" : "this type?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    typeService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getTypes({
                            queryString: typeQueryString,
                            pageNumber: typePageNumber,
                            rowsPerPage: typeRowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No type selected!",
                message: "Please select a type from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getTypes({
            queryString: args?.value,
            pageNumber: typePageNumber,
            rowsPerPage: typeRowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (typePageNumber > 1)
            getTypes({
                queryString: typeQueryString,
                pageNumber: typePageNumber - 1,
                rowsPerPage: typeRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (typePageNumber < typeTotalPages)
            getTypes({
                queryString: typeQueryString,
                pageNumber: typePageNumber + 1,
                rowsPerPage: typeRowsPerPage
            });
    }

    return {
        componentObserver,
        types,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addTypeButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add type",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editTypeButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteTypeButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["marketTypeId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["marketTypeId"],
                    style: themes?.width30px
                },
                {
                    title: "Type",
                    value: ["marketType"],
                    default: "",
                    style: themes?.width100
                },
                {
                    type: COLUMNTYPE.DATETIME,
                    title: "Date Created",
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width100px
                },
                {
                    default: "",
                    style: themes?.width30px
                }
            ],
            rowHeight: 40,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: types
        }} />,

        createOrEditDialog: {
            name: createOrEditTypeDialogName,
            data: selectedType,
            observer: createOrEditTypeObserver,
            widget: CreateOrEditMarketTypeView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getTypes({
                                queryString: typeQueryString,
                                pageNumber: typePageNumber,
                                rowsPerPage: typeRowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useMarketTypesHook;