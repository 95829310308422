import React, { useCallback, useEffect, useRef, useState } from "react";
import { MENUALIGNMENTTYPE } from "../../enums/enums";

const DropDownButton = ({ attributes }) => {

    const [button, setProperties] = useState({
        inProgress: false,
        message: ""
    });

    const componentObserver = useRef();

    const collapseAll = useCallback((e) => {
        [...document.querySelectorAll("div[aria-owns=\"dropdown\"]")].forEach((item, i) => {
            item.classList.add("hide");
        });
    }, []);

    useEffect(() => {
        document.body.addEventListener("click", collapseAll);

        return () => {
            document.body.removeEventListener("click", collapseAll);
        }
    }, [])

    const getMenuAlignment = (e) => {
        switch (e) {
            case MENUALIGNMENTTYPE.TOPLEFT:
                return "top-37px start-0px";
            case MENUALIGNMENTTYPE.TOPRIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.BOTTOMRIGHT:
                return "top-37px end-0px";
            case MENUALIGNMENTTYPE.BOTTOMLEFT:
            default:
                return "top-37px start-0px";
        }
    }

    const handleOnClick = (e) => {
        if (attributes?.baseClickHandler) {
            attributes?.baseClickHandler(e);
        }

        const dropdown = componentObserver.current.querySelector("*[aria-owns=\"dropdown\"]");
        if (dropdown.classList.contains("hide")) {
            collapseAll(e);
            dropdown.classList.remove("hide");
            if (attributes.onClick) {
                attributes.onClick(e, {
                    key: ((e) => componentObserver.current.parentNode.parentNode.parentNode.getAttribute("aria-rowindex"))()
                });
            }

            // let parentPanel = componentObserver.current;
            // while (!parentPanel?.parentNode?.querySelector("*[aria-controls=\"stackable-panel\"]")) {
            //     parentPanel = parentPanel.parentNode;
            // }
        } else {
            dropdown.classList.add("hide");
        }
        e.stopPropagation();
    }

    const handleDropdownStopPropagation = (e) => {
        e.cancelBubble = true;
        e.stopPropagation();
    }

    return (
        <div id={attributes?.name} ref={componentObserver} className={"d-flex position-relative"}>
            <button type={attributes?.type} className={"btn border-0px- " + ((attributes?.text ? "rounded-pill- " : "rounded-circle- p-0 ") + ((attributes?.style?.g) ? attributes?.style?.g : "shadow-sm- border-0px height-34px") + " " + ((attributes?.style?.bgColor) ? attributes?.style?.bgColor : "btn-grey-theme"))} onClick={handleOnClick}>
                <div className={"position-relative d-flex justify-content-center align-items-center"}>
                    <div className={"d-flex justify-content-center align-items-center text-13px " + (attributes?.text ? "ps-2 pe-2" : " ps-0 pe-0")}>
                        <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                            {attributes?.leading}
                        </i>
                        <span aria-controls="label" className={"white-space-nowrap " + (attributes?.style?.text) + " " + (attributes?.text ? "" : "hide")}>{attributes?.text}</span>
                        <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                            {attributes?.trailing}
                        </i>
                    </div>
                    <div className={"position-absolute top-0px end-0px bottom-0px margin-auto d-flex icon icon-20px " + ((attributes.showProgress) ? "show" : "hide")}>
                        <div className={"spinner-border icon icon-20px " + ((attributes.showProgress || button.inProgress) ? "show" : "hide")} role="status">
                            <span className={"visually-hidden"}>Loading...</span>
                        </div>
                    </div>
                </div>
            </button>
            <div onClick={handleDropdownStopPropagation} aria-owns="dropdown" className={"select-dropdown-menu hide position-absolute z-index-10 min-width-100- " + getMenuAlignment(attributes?.menuPosition)}>
                <div className={"bg-white border border-1px transparent-border-blue-dark-theme shadow padding-8px pt-1 pb-1 rounded-3 w-100 min-width-100px min-height-50px display-inline-block float-start"}>
                    {
                        (() => {
                            if (typeof (attributes?.widget) == "function") {
                                return attributes?.widget({
                                    attributes: {}
                                });
                            } else {
                                return attributes?.widget
                            }
                        })()
                    }
                </div>
            </div>
        </div>
    );
}
export default DropDownButton;