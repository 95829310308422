import React from 'react';
import { TextField } from '../../../../../../shared/components/field/textfield.component';
import useCreateOrEditAvailabilityStatusHook from './createoredit.availability.status.hook';

const CreateOrEditProductAvailabilityStatusView = ({ attributes }) => {

    const hook = useCreateOrEditAvailabilityStatusHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>

                        {/* country name */}
                        <div className={"w-100 mb-3"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>
                                Name
                            </div>
                            <TextField
                                attributes={hook?.countryNameFieldAttributes} />
                        </div>

                        <div className={"d-flex"}>
                            {/* country code */}
                            <div className={"w-100 mb-3 me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>
                                    Country:
                                </div>
                                <TextField
                                    attributes={hook?.countryCodeFieldAttributes} />
                            </div>

                            {/* dialing code */}
                            <div className={"w-100 mb-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>
                                    Dialing Code:
                                </div>
                                <TextField
                                    attributes={hook?.dialingCodeFieldAttributes} />
                            </div>
                        </div>

                        <div className={"d-flex"}>
                            {/* latitude code */}
                            <div className={"w-100 mb-3 me-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>
                                    Latitude:
                                </div>
                                <TextField
                                    attributes={hook?.latitudeFieldAttributes} />
                            </div>

                            {/* longitude code */}
                            <div className={"w-100 mb-3"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>
                                    Longitude:
                                </div>
                                <TextField
                                    attributes={hook?.longitudeFieldAttributes} />
                            </div>
                        </div>

                    </div>
                </form>
            </div >
        </div >
    );
}

export default CreateOrEditProductAvailabilityStatusView;