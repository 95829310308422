import React, { useEffect } from "react";

const Separator = (props) => {

    const { className, text, type, height = 30 } = props;
    // const hRef = useRef();

    useEffect(() => {

    }, [props, className, type, text])

    return (
        <div {...props} className={"w-100 text-bold text-" + props.textSize + "px height-" + height + "px line-height-" + (props['line-height'] ? props['line-height'] : height) + "px"}>
            <div className={'d-flex justify-content-between align-items-center'}>
                <div className={'w-50 height-1px bg-secondary opacity-4 d-flex'}></div>
                <div className={'d-flex align-items-center ms-2 me-2 text-13px'}>
                    <span>{props?.text}</span>
                </div>
                <div className={'w-50 height-1px bg-secondary opacity-4 d-flex'}></div>
            </div>
        </div>
    );
}
export default Separator;