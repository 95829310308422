import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../button/button.component";

const Caption = (props) => {

    const { attributes } = props;

    const wdgCaptionClassName = (attributes?.themes) ? attributes?.themes?.caption : "text-60px text-bold hide";
    const wdgIconClassName = (attributes?.themes) ? attributes?.themes?.icon : "text-60px text-dark text-bold mb-4 hide";
    const wdgTitleClassName = (attributes?.themes) ? attributes?.themes?.title : "text-60px text-bold mb-4 hide";
    const wdgDescriptionClassName = (attributes?.themes) ? attributes?.themes?.description : "text-16px mb-4 hide";

    useEffect(() => {
        // 
    }, [attributes])

    return (
        <div className={attributes?.themes?.className}>
            <div className={"mb-3 " + ((attributes?.data?.caption) ? "show" : "hide")}>
                <h1 className={wdgCaptionClassName + " mb-3"}>{attributes?.data?.caption}</h1>
            </div>
            <div className={wdgIconClassName}>{attributes?.data?.icon}</div>
            <h1 className={wdgTitleClassName + ((attributes?.data?.description) ? " " : " mb-0")}>{attributes?.data?.title}</h1>
            <div className={wdgDescriptionClassName + ((attributes?.data?.description) ? " show" : " hide")}>{attributes?.data?.description}</div>
            <div className={"d-flex align-items-center"}>
                <div className={attributes?.buttons?.className + " d-flex me-2 " + (attributes?.buttons ? "" : "hide")}>
                    {
                        attributes?.buttons?.map((button, i) => {
                            return (
                                <Button key={i} attributes={button} />
                            )
                        })
                    }
                </div>
                <div className={attributes?.links?.className + " d-flex " + (attributes?.links ? "" : "")}>
                    {
                        attributes?.links?.map((link, i) => {
                            return (
                                <Link key={i} className={link.className + " d-flex align-items-center text-14px text-decoration-none text-blue-theme"}>
                                    <span className={"text-bold me-1"}>{link?.text}</span>
                                    <i className={link?.className + " m-0 p-0"}>
                                        {link?.icon}
                                    </i>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}
export { Caption }