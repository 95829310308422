import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { BankService } from './bank.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import BankDetailsView from './bank.details/bank.details.view';
import CreateOrEditBankView from './createoredit.bank/createoredit.bank.view';

const useBanksHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [banks, setBanks] = useState([]);
    const [selectedBank, setSelectedBank] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    // observers
    const componentObserver = useRef();
    const createOrEditBankObserver = useRef();
    const bankDetailsViewObserver = useRef();

    // components
    const datagridName = "banks-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "banks-left-pane-toolbar";
    const leftToolbarButtons = {
        addBankButtonName: "add-bank-button",
        editBankButtonName: "edit-bank-button",
        deleteBankButtonName: "delete-bank-button",
        reloadBankButtonName: "reload-bank-button",
    }

    const rightPaneToolbarName = "banks-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const bankService = BankService();

    // dialogs
    const createOrEditBankDialogName = "createoredit-bank-modal";
    const createOrEditBankDialog = useModalDialog({
        name: createOrEditBankDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getBanks = useCallback(async (request) => {

        showPagingInfo(1, 1);

        datagrid?.showLoader("Fetching available customer banks, please wait...");

        await bankService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setBanks(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getBanks({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch(error) {
            // ignore
        }
    }

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedBank({});

        // show dialog
        createOrEditBankDialog.show({
            title: "New bank",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditBankObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple banks selected!",
                message: "Oops!, you selected multiple customer banks. Please select a customer bank from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedBank = banks.find(bank => bank.bankId === datagrid.getSelectedRowKeyValue());
                setSelectedBank(selectedBank);

                createOrEditBankDialog.show({
                    title: "Edit customer bank",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditBankObserver.current({
                    data: selectedBank,
                    callback: () => {
                        getBanks({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No customer bank selected!",
                    message: "Please select a customer bank from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these customer wBanks?" : "this customer bank?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    bankService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getBanks({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No customer bank selected!",
                message: "Please select a customer bank from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these customer banks?" : "this customer bank?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    bankService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getBanks({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No customer bank selected!",
                message: "Please select a customer bank from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these customer banks?" : "this customer bank?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    bankService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getBanks({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No customer bank selected!",
                message: "Please select a customer bank from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these customer banks?" : "this customer bank?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    bankService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getBanks({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No customer bank selected!",
                message: "Please select a customer bank from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these customer banks?" : "this customer bank?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    bankService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getBanks({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No customer bank selected!",
                message: "Please select a customer bank from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getBanks({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getBanks({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getBanks({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleDatagridRowSelected = (args) => {
        bankDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    return {
        componentObserver,
        banks,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addBankButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add a bank",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editBankButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteBankButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: leftToolbarButtons.reloadBankButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.PublishRoundedIcon,
                                        onClick: (e) => handlePublish(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadBankButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Unpublish",
                                        leading: icons?.VpnLockRoundedIcon,
                                        onClick: (e) => handleUnpublish(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    }
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["bankId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["bankId"],
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width5px
                },
                {
                    title: "Code",
                    value: ["bankCode"],
                    default: "",
                    style: themes?.width80px,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Name",
                    value: ["bankName"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Address",
                    value: ["contactAddress"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width90px
                },
            ],
            rowHeight: 35,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            onRowSelected: handleDatagridRowSelected,
            dataset: banks
        }} />,

        detailsComponent: <BankDetailsView attributes={{
            observer: bankDetailsViewObserver
        }} />,

        createOrEditDialog: {
            name: createOrEditBankDialogName,
            data: selectedBank,
            observer: createOrEditBankObserver,
            widget: CreateOrEditBankView,
            style: {
                window: "max-width-530px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getBanks({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useBanksHook;