import React, { useRef } from "react";
import { COMPONENTYPE, TOOLBARITEMTYPE } from "../../enums/enums";
import { Toolbar, useToolbar } from "../toolbar/toolbar.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";

const WYSIWYGEditor = ({ attributes, dataObject, onChange }) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;
    
    // observers
    const wysiwygToolbarObserver = useRef();

    // 
    const toolbarName = "wysiwyg-editor-toolbar";
    const toolbar = useToolbar({
        name: toolbarName
    });
    const toolbarButtons = [
        {
            name: "bold-btn",
            type: TOOLBARITEMTYPE.BUTTON,
            tooltip: "Bold",
            leading: icons?.FormatBoldRoundedIcon,
            // onClick: (e) => handleCreate(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "italic-btn",
            type: TOOLBARITEMTYPE.BUTTON,
            tooltip: "Italic",
            leading: icons?.FormatItalicRoundedIcon,
            // onClick: (e) => handleEdit(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "underline-btn",
            type: TOOLBARITEMTYPE.BUTTON,
            tooltip: "Underline",
            leading: icons?.FormatUnderlinedRoundedIcon,
            // onClick: (e) => handleDelete(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            type: TOOLBARITEMTYPE.SPACE,
            style: attributes?.style?.toolbar?.separator
        },
        {
            name: "backcolor-btn",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.FormatColorTextRoundedIcon,
            // onClick: (e) => handlePublish(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "forecolor-btn",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.FormatColorTextRoundedIcon,
            // onClick: (e) => handlePublish(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.ColorLensRoundedIcon,
            // onClick: (e) => handleUnpublish(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            type: TOOLBARITEMTYPE.SPACE,
            style: attributes?.style?.toolbar?.separator
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.FormatListBulletedRoundedIcon,
            // onClick: (e) => handleRestoreArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.FormatListNumberedRoundedIcon,
            // onClick: (e) => handleRestoreArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.FormatIndentDecreaseRoundedIcon,
            // onClick: (e) => handleRestoreArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.FormatIndentIncreaseRoundedIcon,
            // onClick: (e) => handleRestoreArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            type: TOOLBARITEMTYPE.SPACE,
            style: attributes?.style?.toolbar?.separator
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.InsertPhotoRoundedIcon,
            // onClick: (e) => handleArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.InsertLinkRoundedIcon,
            // onClick: (e) => handleArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            name: "",
            type: TOOLBARITEMTYPE.BUTTON,
            leading: icons?.GridOnRoundedIcon,
            // onClick: (e) => handleArchive(e),
            observer: wysiwygToolbarObserver,
            style: attributes?.style?.toolbar?.button
        },
        {
            type: TOOLBARITEMTYPE.SPACE,
            style: attributes?.style?.toolbar?.separator
        },
    ]
    // {
    //     addVendorButtonName: "add-vendor-button",
    //     editVendorButtonName: "edit-vendor-button",
    //     deleteVendorButtonName: "delete-vendor-button",
    //     reloadVendorButtonName: "reload-vendor-button",
    // }

    const handleOnChange = (e) => {
        if (onChange)
            onChange(e.target);
    }

    return (
        <div className={"position-relative form-control p-0 " + (attributes?.style?.editor?.g)}>
            <div className={""}>
                <Toolbar attributes={{
                    name: toolbarName,
                    items: toolbarButtons
                }} />
            </div>
            <div className={"position-relative " + attributes?.style?.editor?.editor + " " + attributes?.style?.editor?.bgColor}>
                <div
                    contentEditable="true"
                    aria-controls={attributes?.formElement}
                    aria-roledescription={COMPONENTYPE.TEXTFIELD}
                    aria-required={attributes?.validate}
                    onChange={handleOnChange}
                    id={attributes?.id}
                    placeholder={attributes?.placeholder}
                    defaultValue={attributes?.value || ""}
                    type={'text'}
                    className={"position-absolute top-0px bottom-0px end-0px start-0px w-100 form-control ps-2 text-13px rounded-5px border-0px bg-transparent " + attributes?.style?.editor?.text}></div>
                <div className={"position-absolute top-0px bottom-0px end-0px"}>

                </div>
            </div>
        </div>
    );
}
export { WYSIWYGEditor };