import React, { useEffect, useRef, useState } from "react";

const usePageLoader = () => {

    const show = (attributes) => {
        const dialog = document.body.querySelector("*[aria-owns=\"page-loader-modal\"]");
        dialog.classList.add("display-block");

        const message = dialog.querySelector("*[aria-owns=\"message-section\"]");
        message.innerHTML = attributes?.message || "";
    }

    const close = () => {
        const dialog = document.body.querySelector("*[aria-owns=\"page-loader-modal\"]");
        dialog.classList.remove("display-block");
    }

    const hide = () => {
        const dialog = document.body.querySelector("*[aria-owns=\"page-loader-modal\"]");
        dialog.classList.remove("display-block");
    }

    return { show, close, hide }
}

const PageLoader = ({ attributes }) => {

    const pageLoaderObserver = useRef();

    return (
        <div ref={pageLoaderObserver}>
            <div aria-owns={"page-loader-modal"} className={"modal " + attributes?.style?.loader} id="alert-dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered"}>
                    <div className={"modal-content border-0px bg-transparent"}>
                        <div className={"modal-body text-13px"}>
                            <div className={"d-flex justify-content-center mb-3"}>
                                <div className={"spinner-border " + attributes?.style?.spinner} role="status"></div>
                            </div>
                            <div aria-owns={"message-section"} className={"d-flex justify-content-center text-center text-14px mb-3 " + attributes?.style?.text}>{attributes?.message}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export {PageLoader, usePageLoader}