import React, { useEffect, useRef, useState } from "react";
import Button from "../button/button.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import Label from "../label/label.component";

const useAlertDialog = () => {

    const show = (attributes) => {
        const dialog = document.body.querySelector("*[aria-owns=\"alert-dialog-modal\"]");
        dialog.classList.add("display-block");

        const headerTitle = dialog.querySelector("*[aria-owns=\"title-section\"]");
        headerTitle.innerHTML = attributes?.title || "";

        const message = dialog.querySelector("*[aria-owns=\"message-section\"]");
        message.innerHTML = attributes?.message || "";
    }

    const close = () => {
        const dialog = document.body.querySelector("*[aria-owns=\"alert-dialog-modal\"]");
        dialog.classList.remove("display-block");
    }

    return { show, close }
}

const AlertDialog = ({ attributes }) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [params, setParams] = useState({
        title: attributes?.title,
        message: attributes?.message
    });

    const alertDialogObserver = useRef();

    useEffect(() => {
    }, [attributes])

    const close = (e) => {
        alertDialogObserver.current.querySelector("div[aria-owns=\"alert-dialog-modal\"]").classList.remove("display-block");
    }

    return (
        <div ref={alertDialogObserver}>
            <div aria-owns={"alert-dialog-modal"} className={"modal transparent-bg-dark-theme "} id="alert-dialog" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered " + attributes?.style?.window + " text-blue-dark-theme"}>
                    <div className={"modal-content shadow-lg border-1px transparent-border-blue-dark-theme"}>
                        <div className={"modal-header border-0px pb-0"}>
                            <div aria-owns={"title-section"} className={"modal-title text-14px text-bold " + themes?.labelColor} id="staticBackdropLabel"></div>
                        </div>
                        <div aria-owns={"message-section"} className={"modal-body text-13px " + themes?.labelColor}></div>
                        <div className={"modal-footer border-top-0px pt-0"}>
                            <Button attributes={{
                                text: attributes?.button ? attributes?.button.text : "Okay",
                                style: themes?.button?.secondary,
                                onClick: close
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export { AlertDialog, useAlertDialog }