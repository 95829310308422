import { useCallback, useEffect, useRef, useState } from 'react';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { CatererCustomerTransactionService } from './caterer.customer.transaction.service';

const useCatererCustomersTransactionsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes().rounded;

    // state objects
    const [selectedCaterer, setSelectedCaterer] = useState({});
    const [customersTransactions, setCustomersTransactions] = useState([]);
    const [selectedCustomerTransaction, setSelectedCustomerTransaction] = useState({});
    const [customersTransactionsQueryString, setCustomersTransactionsQueryString] = useState("");
    const [customersTransactionsPageNumber, setCustomersTransactionsPageNumber] = useState(1);
    const [customersTransactionsRowsPerPage, setCustomersTransactionsRowsPerPage] = useState(25);
    const [customersTransactionsTotalPages, setCustomersTransactionsTotalPages] = useState(0);
    const [customersTransactionsTotalRows, setCustomersTransactionsTotalRows] = useState(0);

    // refs
    const componentObserver = useRef(null);
    const reactiveFormObserver = useRef(null);
    const selectedCatererObserver = useRef(null);
    const selectedCustomersTransactionsObserver = useRef(null);
    const customersTransactionsObserver = useRef(null);

    // components
    const purchaseMarketDatagridName = "purchase-market-datagrid";
    const filterCustomerTransactionsToolbarName = "caterer-customers-transaction-pane-toolbar";
    const customerTransactionsDatagridName = "purchase-market-product-datagrid";

    const purchaseMarketDatagrid = useDataGrid({
        name: purchaseMarketDatagridName
    });

    const customerTransactionsDatagrid = useDataGrid({
        name: customerTransactionsDatagridName
    });

    const filterCustomerTransactionsToolbar = useToolbar({
        name: filterCustomerTransactionsToolbarName
    });

    const toolbarItems = {
        filterMarketFieldName: "filter-customer-transaction-field",
        filterCustomerTransactionsFieldName: "filter-customer-transaction-field",
        previousButtonName: "caterer-customer-transaction-previous-button",
        pagesLabelName: "caterer-customer-transaction-pages-menu-button",
        nextButtonName: "caterer-customer-transaction-next-button",
    }


    // services
    const customerTransactionsService = CatererCustomerTransactionService();

    const getCustomersTransactions = useCallback(async (request) => {
        customerTransactionsDatagrid.showLoader();
        await customerTransactionsService.getAll(request)
            .then(response => {
                customerTransactionsDatagrid.hideLoader();

                // populate datagrid 
                setCustomersTransactions(response?.data?.list)
                customersTransactionsObserver.current = response?.data?.list;

                // set page
                setCustomersTransactionsPageNumber(response?.data?.page?.pageNumber);
                setCustomersTransactionsRowsPerPage(response?.data?.page?.rowsPerPage);
                setCustomersTransactionsTotalPages(response?.data?.page?.totalPages);
                setCustomersTransactionsTotalRows(response?.data?.page?.totalRows);

                filterCustomerTransactionsToolbar.button({
                    name: toolbarItems.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);
            })
            .catch(error => {
                customerTransactionsDatagrid.hideLoader();
            });

    }, []);


    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        // const isEmptyField = addCustomersTransactionsForm.validate(args.data);
        // if (isEmptyField)
        //     return;
        handleSaveOrUpdate(args);
    }, [])

    const widgetObserver = useCallback(async (args) => {

        // set initial record hint to zero
        filterCustomerTransactionsToolbar.button({
            name: toolbarItems.pagesLabelName
        }).setText("Page 0 of 0");

        // set selected market
        setSelectedCaterer(args?.data);
        selectedCatererObserver.current = args?.data;


        // // load markets
        // await getCustomersTransactions({
        //     queryString: customersTransactionsQueryString,
        //     pageNumber: customersTransactionsPageNumber,
        //     rowsPerPage: customersTransactionsRowsPerPage
        // });

        // clear customers transactions datagrid
        customerTransactionsDatagrid.clearSelection();

    }, [])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleCustomersTransactionsSearchRecords = async (e, args) => {
        await getCustomersTransactions({
            catererId: selectedCaterer?.marketId,
            queryString: customersTransactionsQueryString,
            pageNumber: customersTransactionsPageNumber,
            rowsPerPage: customersTransactionsRowsPerPage
        });
    }

    const handleCustomersTransactionsPreviousRecords = async (e, args) => {
        if (customersTransactionsPageNumber > 1)
            await getCustomersTransactions({
                catererId: selectedCaterer?.marketId,
                queryString: customersTransactionsQueryString,
                pageNumber: customersTransactionsPageNumber - 1,
                rowsPerPage: customersTransactionsRowsPerPage
            });
    }

    const handleCustomersTransactionsNextRecords = async (e, args) => {
        if (customersTransactionsPageNumber < customersTransactionsTotalPages)
            await getCustomersTransactions({
                catererId: selectedCaterer?.marketId,
                queryString: customersTransactionsQueryString,
                pageNumber: customersTransactionsPageNumber + 1,
                rowsPerPage: customersTransactionsRowsPerPage
            });
    }

    const handleDatagridRowSelected = async (args) => {
        setSelectedCustomerTransaction(args?.selectedRow);
    }

    const handleSaveOrUpdate = async (args) => {
        const selectedRowsKeyValue = customerTransactionsDatagrid.getSelectedRowsKeyValue();

        const selectedProducts = customersTransactionsObserver.current.filter((product) => {
            return selectedRowsKeyValue.indexOf(product?.marketProductInventoryId) >= 0
        });

        args?.successful({
            data: selectedProducts,
            callback: args?.callback
        });
    }

    return {
        reactiveForm: reactiveFormObserver,
        selectedCaterer,

        toolbarAttributes: {
            name: filterCustomerTransactionsToolbarName,
            items: [
                {
                    type: TOOLBARITEMTYPE.SEARCHFIELD,
                    name: toolbarItems.filterCustomerTransactionsFieldName,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.SearchRoundedIcon,
                    placeholder: "Find product...",
                    trailing: icons?.ArrowForwardRoundedIcon,
                    style: themes?.toolbar?.searchField,
                    onClick: (e, args) => { handleCustomersTransactionsSearchRecords(e, args) },
                },
                {
                    type: TOOLBARITEMTYPE.SPACE,
                    style: themes?.toolbar?.space?.space4
                },
                {
                    name: toolbarItems.previousButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Previous",
                    leading: icons?.ArrowBackIosRoundedIcon,
                    onClick: (e, args) => { handleCustomersTransactionsPreviousRecords(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.startDropDownButton
                },
                {
                    name: toolbarItems.pagesLabelName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    observer: componentObserver,
                    style: themes?.toolbar?.menuButton
                },
                {
                    name: toolbarItems.nextButtonName,
                    type: TOOLBARITEMTYPE.BUTTON,
                    tooltip: "Next",
                    leading: icons?.ArrowForwardIosRoundedIcon,
                    onClick: (e, args) => { handleCustomersTransactionsNextRecords(e, args) },
                    observer: componentObserver,
                    style: themes?.toolbar?.endDropDownButton
                },
            ]
        },

        datagridAttributes: {
            name: customerTransactionsDatagridName,
            key: ["marketProductInventoryId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["marketProductInventoryId"],
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Customer",
                    value: ["productName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Price",
                    key: "price",
                    value: "priceAlias",
                    default: "",
                    textAlign: ALIGNMENTTYPE.RIGHT,
                    style: themes?.width90px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    title: "Variation",
                    value: ["productVariation"],
                    default: "",
                    style: themes?.width100px
                },
                {
                    default: "",
                    style: themes?.width15px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            onRowSelected: handleDatagridRowSelected,
            dataset: selectedCustomersTransactionsObserver.current
        },

        themes: themes,
    }
}

export default useCatererCustomersTransactionsHook;