import { DOCKTYPE } from '../../../shared/enums/enums';
import { usePrimaryUIThemes } from '../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../shared/hooks/ui.icons.hook';
import { ToolbarCollection, useToolbarCollection } from '../../../shared/components/toolbar/toolbar.collection.component';
import { useModalDialogCollection } from '../../../shared/components/dialog/modal.dialog.collection.component';
import { Tab } from '../../../shared/components/tab/tab.component';
import { useStackablePanel } from '../../../shared/components/stackable.panel/stackable.panel.component';
import { useCallback, useState } from 'react';
import useContentsHook from './contents/contents.hook';
import useContentCategoriesHook from './content.categories/content.categories.hook';
import useMediaGalleryHook from './media.gallery/media.gallery.hook';

const useContentManagerHook = () => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const stackablePanelComponentName = "contents-manager-stackable-panel";
    const tabComponentName = "contents-manager-tab";
    const toolbarCollectionComponentName = "contents-manager-toolbar-collection";
    const modalDialogCollectionComponentName = "contents-manager-modal";
    const offcanvasCalloutCollectionComponentName = "contents-manager-offcanvas-callout";

    // custom hooks
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });
    const toolbarCollectionComponent = useToolbarCollection({
        name: toolbarCollectionComponentName
    });
    const modalDialogCollectionComponent = useModalDialogCollection({
        name: modalDialogCollectionComponentName
    });

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // callbacks
    const handleStackablePanelToggle = useCallback((tabIndex) => {
        stackablePanelComponent.setPanel(1);
    }, [])

    // 
    // component hooks
    const contentsComponentHook = useContentsHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });
    const contentCategoriesComponentHook = useContentCategoriesHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });
    const mediaGalleryComponentHook = useMediaGalleryHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });

    // 
    const handleTabButtonClick = (e, args) => {
        // select toolbar
        toolbarCollectionComponent.setToolbar(args.index);

        // select modal dialog
        modalDialogCollectionComponent.setDialog(args.index);

        // set selected tab
        setSelectedTabIndex(args.index);

    }

    // toolbar for managing dataset
    const manageToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                contentsComponentHook?.toolbar,
                contentCategoriesComponentHook?.toolbar,
                mediaGalleryComponentHook?.toolbar
            ]
        }
    }

    const manageTabAttributes = () => {
        return {
            name: tabComponentName,
            themes: themes,
            dockType: DOCKTYPE.FILL,
            onTabClick: (e, args) => handleTabButtonClick(e, args),
            items: [
                {
                    leading: icons?.NewspaperRoundedIcon,
                    text: "Contents",
                    target: "contents-tab-button",
                    style: themes?.tab?.selectedTabButton,
                    widget: contentsComponentHook?.datagridAttributes
                },
                {
                    leading: icons?.CategoryRoundedIcon,
                    text: "Categories",
                    target: "content-categories-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: contentCategoriesComponentHook?.datagridAttributes
                },
                {
                    leading: icons?.PermMediaRoundedIcon,
                    text: "Media Gallery",
                    target: "media-gallery-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: mediaGalleryComponentHook?.datagridAttributes
                },
            ]
        }
    }

    // toolbar for creating or editing
    const createOrEditToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                contentsComponentHook?.toolbar,
                contentCategoriesComponentHook?.toolbar,
                mediaGalleryComponentHook?.toolbar
            ]
        }
    }

    return {
        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                {/* toolbar */}
                                <ToolbarCollection attributes={manageToolbarCollectionAttributes()} />

                                {/* datagrid view */}
                                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                                    <Tab attributes={manageTabAttributes()} />
                                </div>
                            </div>
                        );
                    })()
                },

                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px"}>
                                {/* toolbar */}
                                <ToolbarCollection attributes={createOrEditToolbarCollectionAttributes()} />


                                {/* editor/form view */}
                                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                                    
                                </div>
                            </div>
                        );
                    })()
                }
            ]
        },

        toolbarCollectionAttributes: manageToolbarCollectionAttributes,

        tabAttributes: manageTabAttributes,

        modalDialogCollectionAttributes: {
            name: modalDialogCollectionComponentName,
            items: [
                contentsComponentHook?.createOrEditDialog,
                contentCategoriesComponentHook?.createOrEditDialog,
                mediaGalleryComponentHook?.createOrEditDialog,
            ]
        },

        offcanvasCalloutCollectionAttributes: {
            name: offcanvasCalloutCollectionComponentName,
            items: [
                contentsComponentHook?.createOrEditOffcanvasCallout,
            ]
        }
    };
}

export default useContentManagerHook;