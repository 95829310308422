import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from './login.service';

const useLoginHook = () => {
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [isLoading, setIsLoading] = useState(false);

    const authWidgetObserver = useRef(null);
    const navigate = useNavigate();

    const authService = useAuthService();

    React.useEffect(() => {
        setViewHeight(window.innerHeight);
        const windowResizer = (e) => {
            setViewHeight(window.innerHeight);
        }
        window.addEventListener('resize', windowResizer);

        // garbage collection
        return () => {
            window.removeEventListener('resize', windowResizer);
        }
    }, [viewHeight]);

    const handleAuthenticationEvent = async (args) => {
        //
        authWidgetObserver.current({
            inProgress: true,
            message: "Loading, please wait..."
        });
        setIsLoading(true);

        await authService.authenticate(args.data).then((response) => {
            setIsLoading(false);
            navigate("/start/home");

            // if (response.statusCode === HttpStatusCode.FOUND) {
            //     sessionStorage.setItem("auth", JSON.stringify(response.data));
            //     authWidgetObserver.current({
            //         inProgress: false,
            //         errorOccurred: false,
            //         message: response.message
            //     });
            //     navigate("/start");
            // } else {
            //     authWidgetObserver.current({
            //         inProgress: false,
            //         errorOccurred: true,
            //         message: response.message
            //     });
            // }
        }).catch((error) => {
            authWidgetObserver.current({
                inProgress: false,
                errorOccurred: false,
                message: "Done!"
            });
        });

        args.e.cancelBubble = true;
        args.e.preventDefault();
    }

    const handleForgotPasswordEvent = (e) => {
        navigate("/reset-password");
    }

    return {
        authenticationWidgetAttributes: {
            method: 'post',
            observer: authWidgetObserver,
            onSubmit: handleAuthenticationEvent,
            onForgotPassword: handleForgotPasswordEvent
        }
    };
}

export default useLoginHook;