import { useCallback, useEffect, useRef, useState } from 'react';
import { ProductImageService } from '../product.image.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditProductImageHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [vendors, setImages] = useState([]);
    const [vendor, setImage] = useState({
        vendorName: '',
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const _parentImageDgridName = "createoredit-parent-vendor-datagrid";
    const parentImagesDatagrid = useDataGrid({
        name: _parentImageDgridName
    });
    const _parentImageTokenSearchFieldName = "createoredit-parent-vendor-token-search-field";
    const parentImagesTokenSearchField = useTokenSearchField({
        name: _parentImageTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const vendorService = ProductImageService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [])

    const widgetObserver = useCallback(async (args) => {
        //
        getParentImages("");

        // clear token
        parentImagesTokenSearchField?.clear();

        // clear datagrid
        parentImagesDatagrid.clearSelection();

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);

            // add token
            if ((args?.data?.parentImageId !== null) && (args?.data?.parentImageId !== "null")) {
                parentImagesTokenSearchField?.addToken({
                    value: args?.data?.parentImageId,
                    text: args?.data?.parentImageName,
                    onButtonClick: (e, args) => {
                        parentImagesDatagrid.clearSelection();
                    }
                });
            }
        } else {
            form.clear();
        }
    }, [form])

    // services
    const getParentImages = useCallback(async (search) => {

        // repopulate grid with data
        await vendorService.getImages({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 20
        })
            .then(response => {
                setImages(response.data.list);
                // pageLoader?.close();
            })
            .catch(error => {
                // pageLoader?.close();
                // alertDialog.show({
                //     title: "Oops! something went wrong",
                //     message: "We're sorry your requests can't be completed at the moment. Please try again later."
                // });
            });

    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setImage({
            ...vendor,
            [e.id]: e.value
        })
    }

    const handleSearchFieldChange = (e) => {
        getParentImages(e.value);
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await vendorService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await vendorService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    const handleCancelSelectedParentImage = (args) => {
        //
    }

    const handleRowSelected = (args) => {
        if (args?.selectedRow) {
            parentImagesTokenSearchField?.addToken({
                value: args?.selectedRow?.vendorId,
                text: args?.selectedRow?.vendorName,
                onButtonClick: (e, args) => {
                    parentImagesDatagrid.clearSelection();
                }
            })
        }
    }

    return {
        vendor: vendor,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        vendorNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        vendorNameField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for your vendor",
            id: "vendorName",
            value: vendor?.vendorName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        vendorDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        vendorDescriptionField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Image description",
            id: "vendorDescription",
            isMultiline: true,
            value: vendor?.vendorDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditProductImageHook;