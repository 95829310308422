import axios from "axios";
import { httpGetHeaderOptions, httpPostHeaderOptions } from "./http.headers";

const api_base_url = process.env.REACT_APP_API_URL;

const https = () => {
    const post = (url, data) => {
        return axios({
            method: "post",
            url: api_base_url + url,
            headers: httpPostHeaderOptions,
            data: data
        })
    }

    const get = (url, data) => {
        return axios({
            method: "get",
            url: api_base_url + url,
            headers: httpGetHeaderOptions,
            data: data
        })
    }

    return { BASE_URL: api_base_url, post, get }
};
export default https;