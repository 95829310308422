import React from 'react';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import useCustomersHook from './customers.hook';

const CustomersView = () => {

    const hook = useCustomersHook();

    return (
        <div>
            {hook?.toolbar}
            {hook?.gridArea}

            {/* create or edit market modal */}
            <ModalDialog attributes={hook?.createOrEditDialog} />
        </div >
    );
    
}

export default CustomersView;