import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { PharmacyProductColorService } from './pharmacy.product.color.service';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';

const usePharmacyProductColorsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [pharmacyProductColors, setPharmacyProductColors] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    
    const [selectedPharmacyProductColors, setSelectedPharmacyProductColors] = useState([]);
    const [selectedPharmacyProductColorsQueryString, setSelectedPharmacyProductColorsQueryString] = useState("");
    const [selectedPharmacyProductColorsPageNumber, setSelectedPharmacyProductColorsPageNumber] = useState(1);
    const [selectedPharmacyProductColorsRowsPerPage, setSelectedPharmacyProductColorsRowsPerPage] = useState(20);
    const [selectedPharmacyProductColorsTotalPages, setSelectedPharmacyProductColorsTotalPages] = useState(0);
    const [selectedPharmacyProductColorsTotalRows, setSelectedPharmacyProductColorsTotalRows] = useState(0);
    const [selectedPharmacyProductColor, setSelectedPharmacyProductColor] = useState({});

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No country selected!",
            message: "Please select an country from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New country",
            successMessage: {
                title: "Country created successfully!",
                message: "Country was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit country details",
            alertMessage: {
                title: "Multiple countries selected!",
                message: "Oops!, you selected multiple countries. Please select a country from the list to continue"
            },
            successMessage: {
                title: "Country updated successfully!",
                message: "Country was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit country details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country deleted successfully!",
                message: "Country was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country archived successfully!",
                message: "Country was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country restored successfully!",
                message: "Country was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available countries, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditColorObserver = useRef();
    const reactiveFormObserver = useRef(null);
    const selectedPharmacyObserver = useRef();
    const selectedPharmacyProductColorsObserver = useRef();
    const selectedPharmacyProductColorObserver = useRef();

    // components
    const datagridName = "pharmacy-product-colors-datagrid";
    const leftPaneToolbarName = "pharmacy-product-colors-left-pane-toolbar";
    const rightPaneToolbarName = "pharmacy-product-colors-right-pane-toolbar";

    const leftToolbarButtons = {
        addColorButtonName: "add-color-button",
        editColorButtonName: "edit-color-button",
        deleteColorButtonName: "delete-color-button",
        reloadColorButtonName: "reload-color-button",
    }

    const rightToolbarButtons = {
        selectButtonName: "select-colors-button",
    }

    const datagrid = useDataGrid({
        name: datagridName
    });

    // dialogs
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();

    // services
    const colorService = PharmacyProductColorService();

    const getPharmacyProductColors = useCallback(async (request) => {
        await colorService.getAll(request)
            .then(response => {
                // populate datagrid 
                setPharmacyProductColors(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                // datagrid?.hideLoader();
            });

    }, []);

    const getSelectedPharmacyProductColors = useCallback(async (request) => {
        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await colorService.getAllByPharmacy(request)
            .then(response => {
                // populate datagrid 
                setTimeout(async () => {
                    setSelectedPharmacyProductColors(response?.data?.list);
                    selectedPharmacyProductColorsObserver.current = response?.data?.list;
                    datagrid?.hideLoader();
                }, 1000);

                // set page
                setSelectedPharmacyProductColorsPageNumber(response?.data?.page?.pageNumber);
                setSelectedPharmacyProductColorsRowsPerPage(response?.data?.page?.rowsPerPage);
                setSelectedPharmacyProductColorsTotalPages(response?.data?.page?.totalPages);
                setSelectedPharmacyProductColorsTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                datagrid?.hideLoader();
            });
    }, []);

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        //
    }, [])

    const widgetObserver = useCallback(async (args) => {

        selectedPharmacyObserver.current = args?.data;

        // load product colors
        await getPharmacyProductColors({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });

        await getSelectedPharmacyProductColors({
            pharmacyId: args?.data?.pharmacyId,
            queryString: selectedPharmacyProductColorsQueryString,
            pageNumber: selectedPharmacyProductColorsPageNumber,
            rowsPerPage: selectedPharmacyProductColorsRowsPerPage
        });

    }, [])

    useEffect(() => {
        (async (attributes) => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })(attributes);
    }, []);

    // color
    const handleCreate = async (e) => {
        // add/create a new pharmacy product color
        await colorService.saveSelectedPharmacyProductColor({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            productColorName: "Untitled product color"
        }).then(response => {
            getSelectedPharmacyProductColors({
                pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                queryString: selectedPharmacyProductColorsQueryString,
                pageNumber: selectedPharmacyProductColorsPageNumber,
                rowsPerPage: selectedPharmacyProductColorsRowsPerPage
            });
            pageLoader.hide();
            toastr.success("Product color added successfully");
        });
    }
    
    const handleCreateSelected = async (args) => {
        await colorService.saveSelectedPharmacyBulkProductColor({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            pharmacyProductColors: args?.data
        }).then(response => {
            getSelectedPharmacyProductColors({
                pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                queryString: selectedPharmacyProductColorsQueryString,
                pageNumber: selectedPharmacyProductColorsPageNumber,
                rowsPerPage: selectedPharmacyProductColorsRowsPerPage
            });
            pageLoader.hide();
            toastr.success("Product color added successfully");
        });
    }

    const handleDelete = async (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these colors? " : "this color?"),
                onConfirmed: async (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    await colorService.removeByPharmacy(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getSelectedPharmacyProductColors({
                            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                            queryString: selectedPharmacyProductColorsQueryString,
                            pageNumber: selectedPharmacyProductColorsPageNumber,
                            rowsPerPage: selectedPharmacyProductColorsRowsPerPage
                        });
                        pageLoader.hide();
                        toastr.success("Product color deleted successfully");
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No color selected!",
                message: "Please select a color from the list to continue"
            });
        }
    }

    const handleReload = async () => {
        await getSelectedPharmacyProductColors({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            queryString: selectedPharmacyProductColorsQueryString,
            pageNumber: selectedPharmacyProductColorsPageNumber,
            rowsPerPage: selectedPharmacyProductColorsRowsPerPage
        });
    }

    const handleSelectedPharmacyProductColorSearchFieldChange = (args) => {
        getPharmacyProductColors({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handleSelectedPharmacyProductColorUpdate = async (args) => {
        // update existing pharmacy product color
        await colorService.updateSelectedPharmacyProductColor({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            productColorId: args?.productColorId,
            productColorName: args?.productColorName
        }).then(response => {
            getSelectedPharmacyProductColors({
                pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                queryString: selectedPharmacyProductColorsQueryString,
                pageNumber: selectedPharmacyProductColorsPageNumber,
                rowsPerPage: selectedPharmacyProductColorsRowsPerPage
            });
            pageLoader.hide();
            // toastr.success("Product color updated successfully");
        });
    }

    return {
        componentObserver,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapperNoPadding}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addColorButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add",
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteColorButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadColorButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.ReplayRoundedIcon,
                                        onClick: (e) => handleReload(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        name: rightToolbarButtons.selectButtonName,
                                        type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTBUTTON,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        placeholder: "Select a product color",
                                        observer: componentObserver,
                                        style: themes?.toolbar?.selectDropDownField250px,
                                        formElement: true,
                                        key: "productColorId",
                                        title: "productColorName",
                                        value: "productColorId",
                                        isMultiSelectable: true,
                                        listItemHeight: 30,
                                        onClick: (e, args) => { },
                                        dataset: pharmacyProductColors,
                                        onFilter: handleSelectedPharmacyProductColorSearchFieldChange,
                                        buttons: [
                                            {
                                                leading: icons?.AddRoundedIcon,
                                                text: "Add selected",
                                                style: themes?.button?.primary,
                                                onClick: (e, winArgs) => {
                                                    winArgs.submit(e, args => {
                                                        handleCreateSelected(args);
                                                    });
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagridAttributes: {
            name: datagridName,
            key: ["productColorId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["productColorId"],
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.TEXTFIELD,
                    title: "Color",
                    value: "productColorName",
                    key: "productColorName",
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width10px
                },
            ],
            rowHeight: 40,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: selectedPharmacyProductColorsObserver.current,
            onRowColumnCellLostFocus: handleSelectedPharmacyProductColorUpdate
        },

    };
}

export default usePharmacyProductColorsHook;