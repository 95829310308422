import React, { useEffect, useRef, useState } from "react";
import Button from "../button/button.component";

const OffCanvasCallout = ({ attributes }) => {

    const [params, setParams] = useState({
        title: attributes?.title,
        message: attributes?.message,
        showCloseButton: attributes?.showCloseButton | true,
        style: {
            window: attributes?.style?.window,
        },
        widget: attributes?.widget,
        isNew: attributes?.isNew
    });
    const [inProgress, setInProgress] = useState(false);

    // callout
    const callout = useOffCanvasCallout();

    // refs
    const calloutRef = useRef();
    const calloutNotifier = useRef();

    const close = (currentTarget) => {
        callout.close(currentTarget);
    }

    return (
        <div ref={calloutRef}>
            <div
                aria-owns={attributes?.name}
                aria-controls={"offcanvas-callout"}
                className={"offcanvas offcanvas-end transparent-bg-dark-theme- width-560px "}
                data-bs-backdrop={"static"}
                tabIndex={"-1"}
                id={"staticBackdrop"}>
                <div className="offcanvas-header">
                    <h5 aria-controls="heading" className={"offcanvas-title"} id="staticBackdropLabel">Offcanvas</h5>
                    <button type="button" className={"btn-close icon-14px p-0 me-0 " + (params?.showCloseButton ? "" : "hide")} onClick={e => { close(e.currentTarget) }}></button>
                </div>
                <div className={"offcanvas-body"}>
                    <div>
                        {
                            (() => {
                                if (params?.widget)
                                    return params?.widget({
                                        attributes: {
                                            observer: attributes?.observer,
                                            reactiveFormModule: calloutNotifier,
                                            data: attributes?.data
                                        }
                                    });
                            })()
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

const useOffCanvasCallout = (attributes) => {

    const show = (attr) => {
        const callout = document.body.querySelector("*[aria-owns=\"" + attributes?.name + "\"]");
        const h1 = callout?.querySelector("h1");
        if (h1)
            h1.innerHTML = attr?.title || "";
        callout?.classList.add("show");
        callout?.setAttribute("is-new", attr?.isNew);
    }

    const close = (currentTarget) => {
        if (currentTarget) {
            const callout = currentTarget?.parentNode?.parentNode;
            if (callout)
                callout.classList.remove("show");
        }
    }

    return { show, close }
}
export { OffCanvasCallout, useOffCanvasCallout }