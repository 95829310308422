import React from 'react';
import useCreateOrEditPharmacyProductHook from './createoredit.pharmacy.product.hook';
import { TextField } from '../../../../../shared/components/field/textfield.component';
import { MultiTextField } from '../../../../../shared/components/field';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { ImageUploader } from '../../../../../shared/components/image/image.uploader.component';
import { DataGrid } from '../../../../../shared/components/datagrid';
import HorizontalSeparator from '../../../../../shared/components/separator/horizontal.separator.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';

const CreateOrEditPharmacyProductView = ({ attributes }) => {

    const hook = useCreateOrEditPharmacyProductHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-1 pb-3"}>
            <div className={"w-100 position-relative pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column"}>

                        {/* product images */}
                        {/* <div className={"position-relative w-100 d-flex mb-4 mt-0 p-0"}>
                            <div className={"w-100 max-width-180px min-height-142px me-3 mt-0 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.imageUploaderAttributes} model={hook?.productMedia} isImageAvailable={hook?.productMedia ? true : false} />
                            </div>
                        </div> */}

                        {/* product images */}
                        <div className={"position-relative w-100 min-height-118px mb-5 mt-0 p-0 d-flex justify-content-between align-items-center"}>
                            <div className={"w-100 max-width-200px min-height-200px mb-2 mt-0 me-2 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.frontImageUploaderAttributes} model={hook?.productMedia} isImageAvailable={hook?.productMedia ? true : false} />
                            </div>
                            <div className={"w-100 max-width-200px min-height-200px mb-2 mt-0 p-0 position-relative"}>
                                <ImageUploader attributes={hook?.backImageUploaderAttributes} model={hook?.productMedia} isImageAvailable={hook?.productMedia ? true : false} />
                            </div>
                        </div>

                        {/* product name field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Product name:</div>
                            <TextField attributes={hook?.productNameFieldAttributes} />
                        </div>

                        {/* product description field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Description:</div>
                            <MultiTextField attributes={hook?.productDescriptionFieldAttributes} />
                        </div>

                        {/* category field */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2 " + hook?.themes?.label?.text13px}>Choose a category:</div>
                            <SingleSelectDropDownField attributes={hook?.categorySingleSelectDropDownFieldAttributes} />
                        </div>

                        {/* size & price field */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100 me-2"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Size/Weight:</div>
                                <SingleSelectDropDownField attributes={hook?.variationSingleSelectDropDownFieldAttributes} />
                            </div>

                            <div className={"w-100 max-width-130px"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Price:</div>
                                <TextField attributes={hook?.productPriceFieldAttributes} />
                            </div>
                        </div>

                        {/* color & type field */}
                        <div className={"w-100 d-flex mb-4"}>
                            <div className={"w-100 me-2"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Product color:</div>
                                <SingleSelectDropDownField attributes={hook?.colorSingleSelectDropDownFieldAttributes} />
                            </div>

                            <div className={"w-100"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Product type:</div>
                                <SingleSelectDropDownField attributes={hook?.typeSingleSelectDropDownFieldAttributes} />
                            </div>
                        </div>

                        {/* manufactured, expiry date, and batch number field */}
                        <div className={"w-100 mb-4 d-flex"}>
                            <div className={"w-100 me-2"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Manufactured:</div>
                                <TextField attributes={hook?.manufacturedDateFieldAttributes} />
                            </div>

                            <div className={"w-100 me-2"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Expires:</div>
                                <TextField attributes={hook?.expiryDateFieldAttributes} />
                            </div>

                            <div className={"w-100"}>
                                <div className={"mb-2 " + hook?.themes?.label?.text13px}>Batch Number:</div>
                                <TextField attributes={hook?.batchNumberFieldAttributes} />
                            </div>
                        </div>

                    </div>
                </form>
            </div>

            {/* show markets to shop for product ingredients */}
            <ModalDialog attributes={hook?.productIngredientsMarketsDialogAttributes} />
        </div>
    );
}

export default CreateOrEditPharmacyProductView;