import React, { Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom"
import Error404Component from "./components/errors/error404.view";
import LayoutComponent from "./layouts/layout.component";

const AppRouterModule = () => {  
  return (
    <div>
      <Router>
        <Suspense fallback={<Error404Component />}>
          <Routes>
            <Route path="/*" element={<LayoutComponent />}></Route>
          </Routes>
        </Suspense>
      </Router>
    </div>
  );
}

export default AppRouterModule;
