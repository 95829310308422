import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { usePrimaryUIThemes } from '../../hooks/ui.themes/index';
import { useIconsThemes } from '../../hooks/ui.icons.hook';


const useAuthenticationWidgetHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [auth, setAuth] = useState({
        email: "",
        password: ""
    });

    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    const navigate = useNavigate();
    const componentObserver = useRef(null);
    const alertObserver = useRef(null);

    const widgetObserver = useCallback(async (e) => {
        componentObserver.current({
            inProgress: e.inProgress,
            errorOccurred: e.errorOccurred,
            asyncMessage: e.message
        });

        // 
        alertObserver.current({
            state: e.errorOccurred,
            message: e.message
        })
    }, [])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;
        })();
    }, []);

    const handleTextFieldChangeEvent = (e) => {
        setAuth({
            ...auth,
            [e.id]: e.value
        })
    }

    const handleAuthenticationEvent = (e) => {
        attributes?.onSubmit({
            e: e,
            data: auth
        });
    }

    const handleRegisterEvent = (value) => {
        navigate("/register");
    }

    return {
        alertObserver: alertObserver,

        // alert box attributes
        alertBoxAttributes: {
            observer: alertObserver
        },

        // email address
        emailAddressTextFieldAttributes: {
            readOnly: status.inProgress,
            placeholder: "Please enter your email to login.",
            id: "email",
            value: auth.email,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChangeEvent
        },

        // password
        passwordTextFieldAttributes: {
            readOnly: status.inProgress,
            placeholder: "Please enter your password to continue.",
            id: "password",
            value: auth.password,
            style: themes?.field?.textField,
            observer: componentObserver,
            required: true,
            onChange: handleTextFieldChangeEvent
        },

        // submit button
        submitButtonAttributes: {
            type: 'button',
            text: 'Log in',
            icon: <BsArrowRight />,
            style: themes?.button?.primary,
            onClick: handleAuthenticationEvent,
            showProgress: false,
            isProgress: status.isProgress,
            observer: componentObserver
        }
    };
}
export default useAuthenticationWidgetHook;