import React, { useCallback, useEffect, useRef } from "react";
import { DOCKTYPE } from "../../enums/enums";
import { ModalDialog } from "./modal.dialog.component";

const ModalDialogCollection = ({ attributes }) => {

    // components
    const modalDialogCollection = useModalDialogCollection(attributes);

    // refs
    const modalDialogCollectionComponent = useRef()

    const resizer = useCallback(() => {
        if (modalDialogCollection.target()) {
            const modalDialogCollectionButtonHeight = modalDialogCollection.getButtonContainerHeight();
            const getModalDialogContainer = modalDialogCollection.getModalDialogContainer();
            if (getModalDialogContainer)
                getModalDialogContainer.setAttribute("style", "top: " + modalDialogCollectionButtonHeight + "px");
        }
    }, [modalDialogCollection]);

    useEffect(() => {
        if (attributes?.dockType) {
            resizer();
            window.addEventListener("resize", resizer);

            // garbage collection
            return () => {
                window.removeEventListener("resize", resizer);
            }
        }
    }, [])

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return attributes?.themes + " position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return attributes?.themes + " position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return attributes?.themes + " position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return attributes?.themes + " position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return attributes?.themes + " position-absolute top-0px start-0px end-0px";
            default:
                return attributes?.themes + " position-relative";
        }
    }

    return (
        <div aria-controls="modal-dialog-collection" id={attributes?.name} ref={modalDialogCollectionComponent} className={"w-100 " + getDockStyle(attributes?.dockType)}>
            {
                (() => {
                    let dockStyle;
                    switch (attributes?.dockType) {
                        case DOCKTYPE.FILL:
                            dockStyle = getDockStyle(DOCKTYPE.BOTTOM);
                            break;
                        default:
                            dockStyle = "position-relative";
                            break;
                    }

                    return (
                        <div aria-controls="modal-dialog-collection-panel-container">
                            <div aria-controls="stackable-panel">
                                {
                                    attributes?.items?.map((item, i) => {
                                        return (
                                            <div aria-controls="panel" panel-index={i} className={"position-absolute top-0px start-0px " + ((i === 0) ? "" : "hide")} key={attributes.name + "-stackable-panel-" + i}>
                                                {
                                                    (() => {
                                                        if (item?.widget) {
                                                            return (<ModalDialog attributes={item} />)
                                                        }
                                                    })()
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    )
                })()
            }
        </div>
    );
}

const useModalDialogCollection = (attributes) => {

    const themes = attributes?.themes?.modalDialogCollection;

    const addWidget = () => {

    }

    const getDialogContainer = () => {
        const _modalDialogCollection = target();
        return _modalDialogCollection?.querySelector("div[aria-controls=\"modal-dialog-collection-panel-container\"]");
    }

    const getDialogs = () => {

    }

    const getDialogByIndex = (index) => {
        const _modalDialogCollection = target();
        if (_modalDialogCollection) {
            const modalDialogs = _modalDialogCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            const modalDialogArray = [...modalDialogs];
            return modalDialogArray[index];
        }
        return null;
    }

    const getDialogByName = (name) => {
        const _modalDialogCollection = target();
        if (_modalDialogCollection) {
            const modalDialogs = _modalDialogCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            const modalDialogArray = [...modalDialogs];
            return modalDialogArray.find(x => x.querySelector("div[aria-owns=\"" + name + "\"]"));
        }
        return null;
    }

    const target = () => {
        return document.getElementById(attributes?.name);
    }

    const setDialog = (index) => {
        const _modalDialogCollection = target();
        if (_modalDialogCollection) {
            const modalDialogs = _modalDialogCollection.querySelectorAll("div[aria-controls=\"panel\"]");
            [...modalDialogs].forEach((modalDialog, i) => {
                modalDialog.classList.add("hide")
            });
            const selectedModalDialog = _modalDialogCollection.querySelector("div[panel-index=\"" + index + "\"]");
            selectedModalDialog.classList.remove("hide");
        }
    }

    return {
        addWidget,
        getDialogContainer,
        getDialogs,
        getDialogByIndex,
        getDialogByName,
        name: attributes?.name,
        setDialog,
        target
    }
}

export { ModalDialogCollection, useModalDialogCollection };