import React, { useCallback, useEffect, useRef, useState } from "react";
import { BlogCard, ItemCard } from "../card";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { SLIDERTYPETYPE } from "../../enums/enums";
import { Caption } from "../caption/caption.component";

const CardSliderWithBottomArrow = (props) => {
    const [carousel, setCarousel] = useState({});

    const cardSliderRef = useRef();

    const { attributes } = props;
    const { themes, items, displayLimit } = attributes?.data;

    const carouselItems = [
        {
            cards: [],
            default: true
        },
        {
            cards: [],
            default: false
        }
    ];

    const buttonClassName = "position-absolute top-0px bottom-0px shadow- cursor-pointer text-26px text-bold m-auto rounded-circle width-50px height-50px bg-white d-flex justify-content-center align-items-center"

    const getCarouselObject = useCallback(() => {
        const viewPort = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-viewport\"]");
        const cardDeck = viewPort.querySelector("div[aria-owns=\"carousel-deck\"]");
        const cards = cardDeck.querySelectorAll("div[aria-owns=\"carousel-card\"]");
        const viewControl = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-control\"]");
        const carouselLeftButton = viewControl.querySelector("div[aria-owns=\"carousel-left-button\"]");
        const carouselRightButton = viewControl.querySelector("div[aria-owns=\"carousel-right-button\"]");

        return {
            viewPort: viewPort,
            deck: cardDeck,
            leftButton: carouselLeftButton,
            rightButton: carouselRightButton,
            cards,
            cardWidth: (() => Math.ceil((viewPort.offsetWidth / displayLimit)))(),
            slide: (limit) => {
                cardDeck.setAttribute("style", "left: " + (-limit) + "px");
                cardDeck.classList.add("slide-left");
            },
            clearSelection: () => {
                cards.forEach(card => card.removeAttribute("aria-selected"));
            }
        };
    }, [displayLimit])

    useEffect(() => {
        const carousel = getCarouselObject();
        setCarousel(carousel);

        carousel.deck.classList.add("start-0px");
        carousel.deck.classList.add("slide-left");

        // set selected
        const selectedCard = carousel.deck.firstChild;
        if (selectedCard)
            selectedCard.setAttribute("aria-selected", "true");

        // hide left button
        carousel.leftButton?.classList.add("hide");

    }, [themes, items, getCarouselObject])

    const handleLeftButton = (e) => {
        const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
        if (Math.abs(selectedCard.getAttribute("aria-colindex")) > 0) {
            const actualCardWidth = carousel.cardWidth;
            let slideLimit = Math.abs(parseInt(getComputedStyle(carousel.deck).getPropertyValue("left")));

            // get selected card width & margin
            slideLimit -= actualCardWidth;

            // slide in card
            carousel.slide(slideLimit);

            // clear selected card
            selectedCard.removeAttribute("aria-selected");

            // select the next card
            const previousCard = selectedCard.previousSibling;
            previousCard.setAttribute("aria-selected", "true");

            // show right button
            carousel.rightButton.classList.remove("hide");

            // hide right button
            if ((Math.abs(selectedCard.getAttribute("aria-colindex")) - 1) === 0) {
                carousel.leftButton.classList.add("hide");
            }
        }
    }

    const handleRightButton = () => {
        const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
        if ((Math.abs(selectedCard.getAttribute("aria-colindex")) + displayLimit) < (carousel.cards.length)) {
            const actualCardWidth = carousel.cardWidth;
            let slideLimit = Math.abs(parseInt(getComputedStyle(carousel.deck).getPropertyValue("left")));

            // get selected card width & margin
            slideLimit += actualCardWidth;

            // slide in card
            carousel.slide(slideLimit);

            // clear selected card
            selectedCard.removeAttribute("aria-selected");

            // select the next card
            const nextCard = selectedCard.nextSibling;
            nextCard.setAttribute("aria-selected", "true");

            // show left button
            carousel.leftButton.classList.remove("hide");

            // hide right button
            if ((Math.abs(selectedCard.getAttribute("aria-colindex")) + displayLimit) === (carousel.cards.length - 1)) {
                carousel.rightButton.classList.add("hide");
            }
        }
    }

    return (
        <div ref={cardSliderRef} className={" mb-4 position-relative"}>
            <div className={"mt-0 mb-2"}>
                <Caption attributes={attributes?.caption}></Caption>
            </div>
            <div aria-owns="carousel-viewport" className={themes.className + " overflow-hidden position-relative max-width-1300px m-auto border-0px border-solid"}>
                <div aria-owns="carousel-deck" className={themes.className + " d-flex- position-absolute top-0px bottom-0px left-0px"}>
                    {
                        attributes?.data?.items.map((item, i) => {
                            const itemClassName = (i === 0) ? " ps-0 pe-3" : ((i === attributes?.data?.items.length - 1) ? " ps-3 pe-0" : " ps-3 pe-3")
                            return <div aria-owns="carousel-card" aria-colindex={i} key={i} className={itemClassName + " max-width-" + carousel.cardWidth + "px min-width-" + carousel.cardWidth + "px float-start w-100 text-decoration-none"}>
                                <ItemCard attributes={(() => {
                                    return {
                                        themes: themes,
                                        data: item
                                    }
                                })()} />
                            </div>
                        })
                    }
                </div>
            </div>

            <div aria-owns="carousel-control" className={"position-relative max-width-400px mx-auto d-flex justify-content-center"}>
                <div className={"d-flex align-items-center"}>
                    {
                        carouselItems.map((item, i) => {
                            const itemClassName = (i === 0) ? " ms-0 me-1" : ((i === attributes?.data?.items.length - 1) ? " ms-1 me-0" : " ms-1 me-1")
                            const defaultClassName = (item.default === true) ? " width-30px rounded-pill bg-blue-theme" : " rounded-circle"
                            return (
                                <div key={i} className={"width-10px height-10px  bg-grey-theme" + itemClassName + defaultClassName}></div>
                            )
                        })
                    }
                </div>

                <div onMouseUp={e => { handleLeftButton(e) }} aria-owns="carousel-left-button" className={buttonClassName + " start--25px text-dark"}>
                    <BsChevronLeft />
                </div>
                <div onMouseUp={e => { handleRightButton(e) }} aria-owns="carousel-right-button" className={buttonClassName + " end--25px text-dark"}>
                    <BsChevronRight />
                </div>
            </div>
        </div>
    )
}

const CardSliderWithTopRightArrow = (props) => {
    const [carousel, setCarousel] = useState({});

    const cardSliderRef = useRef();

    const { attributes } = props;
    const { themes, items, displayLimit } = attributes?.data;

    const carouselItems = [
        {
            cards: [],
            default: true
        },
        {
            cards: [],
            default: false
        }
    ];

    const buttonClassName = "position-absolute top-0px bottom-0px shadow- cursor-pointer text-26px text-bold m-auto rounded-circle width-50px height-50px bg-white d-flex justify-content-center align-items-center"

    const getCarouselObject = useCallback(() => {
        const viewPort = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-viewport\"]");
        const cardDeck = viewPort.querySelector("div[aria-owns=\"carousel-deck\"]");
        const cards = cardDeck.querySelectorAll("div[aria-owns=\"carousel-card\"]");
        const viewControl = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-control\"]");
        const carouselLeftButton = viewControl.querySelector("div[aria-owns=\"carousel-left-button\"]");
        const carouselRightButton = viewControl.querySelector("div[aria-owns=\"carousel-right-button\"]");

        return {
            viewPort: viewPort,
            deck: cardDeck,
            leftButton: carouselLeftButton,
            rightButton: carouselRightButton,
            cards,
            cardWidth: (() => Math.ceil((viewPort.offsetWidth / displayLimit)))(),
            slide: (limit) => {
                cardDeck.setAttribute("style", "left: " + (-limit) + "px");
                cardDeck.classList.add("slide-left");
            },
            clearSelection: () => {
                cards.forEach(card => card.removeAttribute("aria-selected"));
            }
        };
    }, [displayLimit])

    useEffect(() => {
        const carousel = getCarouselObject();
        setCarousel(carousel);

        carousel.deck.classList.add("start-0px");
        carousel.deck.classList.add("slide-left");

        // set selected
        const selectedCard = carousel.deck.firstChild;
        if (selectedCard)
            selectedCard.setAttribute("aria-selected", "true");

        // hide left button
        carousel.leftButton?.classList.add("hide");
    }, [themes, items, getCarouselObject])

    const handleLeftButton = (e) => {
        const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
        if (Math.abs(selectedCard.getAttribute("aria-colindex")) > 0) {
            const actualCardWidth = carousel.cardWidth;
            let slideLimit = Math.abs(parseInt(getComputedStyle(carousel.deck).getPropertyValue("left")));

            // get selected card width & margin
            slideLimit -= actualCardWidth;

            // slide in card
            carousel.slide(slideLimit);

            // clear selected card
            selectedCard.removeAttribute("aria-selected");

            // select the next card
            const previousCard = selectedCard.previousSibling;
            previousCard.setAttribute("aria-selected", "true");

            // show right button
            carousel.rightButton.classList.remove("hide");

            // hide right button
            if ((Math.abs(selectedCard.getAttribute("aria-colindex")) - 1) === 0) {
                carousel.leftButton.classList.add("hide");
            }
        }
    }

    const handleRightButton = () => {
        const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
        if ((Math.abs(selectedCard.getAttribute("aria-colindex")) + displayLimit) < (carousel.cards.length)) {
            const actualCardWidth = carousel.cardWidth;
            let slideLimit = Math.abs(parseInt(getComputedStyle(carousel.deck).getPropertyValue("left")));

            // get selected card width & margin
            slideLimit += actualCardWidth;

            // slide in card
            carousel.slide(slideLimit);

            // clear selected card
            selectedCard.removeAttribute("aria-selected");

            // select the next card
            const nextCard = selectedCard.nextSibling;
            nextCard.setAttribute("aria-selected", "true");

            // show left button
            carousel.leftButton.classList.remove("hide");

            // hide right button
            if ((Math.abs(selectedCard.getAttribute("aria-colindex")) + displayLimit) === (carousel.cards.length - 1)) {
                carousel.rightButton.classList.add("hide");
            }
        }
    }

    return (
        <div ref={cardSliderRef} className={" mb-4 position-relative"}>
            <div className={"w-100 mt-0 mb-2 d-flex justify-content-between"}>
                <div className={"w-100"}>
                    <Caption attributes={attributes?.caption}></Caption>
                </div>

                <div aria-owns="carousel-control" className={"position-relative w-100 max-width-80px mx-auto d-flex justify-content-center"}>
                    <div onMouseUp={e => { handleLeftButton(e) }} aria-owns="carousel-left-button" className={buttonClassName + " start--25px text-dark"}>
                        <BsChevronLeft />
                    </div>
                    <div onMouseUp={e => { handleRightButton(e) }} aria-owns="carousel-right-button" className={buttonClassName + " end--25px text-dark"}>
                        <BsChevronRight />
                    </div>
                </div>
            </div>
            <div aria-owns="carousel-viewport" className={themes.className + " overflow-hidden position-relative max-width-1300px m-auto border-0px border-solid"}>
                <div aria-owns="carousel-deck" className={themes.className + " position-absolute top-0px bottom-0px left-0px white-space-nowrap"}>
                    {
                        attributes?.data?.items.map((item, i) => {
                            const itemClassName = (i === 0) ? " ps-0 pe-0" : ((i === attributes?.data?.items.length - 1) ? " ps-3 pe-0" : " ps-3 pe-0")
                            return <div aria-owns="carousel-card" aria-colindex={i} key={i} className={itemClassName + " max-width-" + carousel.cardWidth + "px min-width-" + carousel.cardWidth + "px display-inline-block w-100 text-decoration-none"}>
                                {/* <ItemCard attributes={(() => {
                                    return {
                                        themes: themes,
                                        data: item
                                    }
                                })()} /> */}
                                {
                                    (() => {
                                        if (attributes?.data?.component) {
                                            return attributes?.data?.component({
                                                attributes: (() => {
                                                    return {
                                                        themes: themes,
                                                        data: item
                                                    }
                                                })()
                                            })
                                        }
                                    })()
                                }
                            </div>
                        })
                    }
                </div>
            </div>
        </div>
    )
}

const CardSliderWithSideArrow = (props) => {
    const [carousel, setCarousel] = useState({});
    const { attributes } = props;
    const { themes, items, displayLimit } = attributes;

    const cardSliderRef = useRef();

    const buttonClassName = "position-absolute top-0px bottom-0px shadow- cursor-pointer text-26px text-bold m-auto rounded-circle width-50px height-50px bg-white d-flex justify-content-center align-items-center"

    const getCarouselObject = useCallback(() => {
        const viewPort = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-viewport\"]");
        const cardDeck = viewPort.querySelector("div[aria-owns=\"carousel-deck\"]");
        const cards = cardDeck.querySelectorAll("div[aria-owns=\"carousel-card\"]");
        const carouselLeftButton = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-left-button\"]");
        const carouselRightButton = cardSliderRef.current.querySelector("div[aria-owns=\"carousel-right-button\"]");

        return {
            viewPort: viewPort,
            deck: cardDeck,
            leftButton: carouselLeftButton,
            rightButton: carouselRightButton,
            cards,
            cardWidth: (() => Math.ceil((viewPort.offsetWidth / displayLimit)))(),
            slide: (limit) => {
                cardDeck.setAttribute("style", "left: " + (-limit) + "px");
                cardDeck.classList.add("slide-left");
            },
            clearSelection: () => {
                cards.forEach(card => card.removeAttribute("aria-selected"));
            }
        };
    }, [displayLimit])

    useEffect(() => {
        const carousel = getCarouselObject();
        setCarousel(carousel);

        carousel.deck.classList.add("start-0px");
        carousel.deck.classList.add("slide-left");

        if (displayLimit >= items.length)
            carousel.rightButton.classList.add("hide");

        // set selected
        const selectedCard = carousel.deck.firstChild;
        if (selectedCard)
            selectedCard.setAttribute("aria-selected", "true");

        // hide left button
        carousel.leftButton?.classList.add("hide");

    }, [themes, items, displayLimit, getCarouselObject])

    const handleLeftButton = (e) => {
        const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
        if (Math.abs(selectedCard.getAttribute("aria-colindex")) > 0) {
            const actualCardWidth = carousel.cardWidth;
            let slideLimit = Math.abs(parseInt(getComputedStyle(carousel.deck).getPropertyValue("left")));

            // get selected card width & margin
            slideLimit -= actualCardWidth;

            // slide in card
            carousel.slide(slideLimit);

            // clear selected card
            selectedCard.removeAttribute("aria-selected");

            // select the next card
            const previousCard = selectedCard.previousSibling;
            previousCard.setAttribute("aria-selected", "true");

            // show right button
            carousel.rightButton.classList.remove("hide");

            // hide right button
            if ((Math.abs(selectedCard.getAttribute("aria-colindex")) - 1) === 0) {
                carousel.leftButton.classList.add("hide");
            }
        }
    }

    const handleRightButton = () => {
        const selectedCard = carousel.deck.querySelector("div[aria-selected=\"true\"]");
        if ((Math.abs(selectedCard.getAttribute("aria-colindex")) + displayLimit) < (carousel.cards.length)) {
            const actualCardWidth = carousel.cardWidth;
            let slideLimit = Math.abs(parseInt(getComputedStyle(carousel.deck).getPropertyValue("left")));

            // get selected card width & margin
            slideLimit += actualCardWidth;

            // slide in card
            carousel.slide(slideLimit);

            // clear selected card
            selectedCard.removeAttribute("aria-selected");

            // select the next card
            const nextCard = selectedCard.nextSibling;
            nextCard.setAttribute("aria-selected", "true");

            // show left button
            carousel.leftButton.classList.remove("hide");

            // hide right button
            if ((Math.abs(selectedCard.getAttribute("aria-colindex")) + displayLimit) === (carousel.cards.length - 1)) {
                carousel.rightButton.classList.add("hide");
            }
        }
    }

    return (
        <div ref={cardSliderRef} className={"position-relative"}>
            <div aria-owns="carousel-viewport" className={themes.className + " overflow-hidden position-relative max-width-1300px m-auto border-0px border-solid"}>
                <div aria-owns="carousel-deck" className={themes.className + " d-flex position-absolute top-0px bottom-0px left-0px"}>
                    {
                        attributes?.items.map((item, i) => {
                            const newClassName = item.className + ((i === 0) ? " ps-0 pe-3" : " ps-3 pe-3");
                            return <div aria-owns="carousel-card" aria-colindex={i} key={i} className={newClassName + " min-width-" + carousel.cardWidth + "px w-100 text-decoration-none"}>
                                <BlogCard attributes={(() => {
                                    return {
                                        themes: themes,
                                        data: item
                                    }
                                })()} />
                            </div>
                        })
                    }
                </div>
            </div>

            <div onMouseUp={e => { handleLeftButton(e) }} aria-owns="carousel-left-button" className={buttonClassName + " start--65px z-index-1 text-dark"}>
                <BsChevronLeft />
            </div>
            <div onMouseUp={e => { handleRightButton(e) }} aria-owns="carousel-right-button" className={buttonClassName + " end--50px z-index-1 text-dark"}>
                <BsChevronRight />
            </div>
        </div>
    )
}

const Card360ViewSlider = (props) => {

    const { attributes } = props;
    const { themes, items } = attributes;

    const carouselItems = [
        {
            cards: [],
            default: true
        },
        {
            cards: [],
            default: false
        }
    ];

    useEffect(() => {
        //
    }, [themes, items])

    return (
        <div className={" mb-4"}>
            <div className={themes.className + " d-flex"}>
                {
                    attributes?.items.map((item, i) => {
                        const itemClassName = (i === 0) ? " ps-0 pe-3" : ((i === attributes?.items.length - 1) ? " ps-3 pe-0" : " ps-3 pe-3")
                        return <div key={i} className={item.className + itemClassName + " w-100 text-decoration-none"} to={item.href}>
                            <ItemCard attributes={(() => {
                                return {
                                    themes: themes,
                                    data: item
                                }
                            })()} />
                        </div>
                    })
                }
            </div>
            <div className={"d-flex justify-content-center"}>
                <div className={"d-flex align-items-center"}>
                    {
                        carouselItems.map((item, i) => {
                            const itemClassName = (i === 0) ? " ms-0 me-1" : ((i === attributes?.items.length - 1) ? " ms-1 me-0" : " ms-1 me-1")
                            const defaultClassName = (item.default === true) ? " width-30px rounded-pill bg-blue-theme" : " rounded-circle"
                            return (
                                <div key={i} className={"width-10px height-10px  bg-grey-theme" + itemClassName + defaultClassName}></div>
                            )
                        })
                    }

                </div>
            </div>
        </div>
    )
}

const Slider = (props) => {
    return (
        <div className={" mb-4"}>
            {
                (() => {
                    let slider;
                    switch (props.type) {
                        case SLIDERTYPETYPE.SLIDER360:
                            slider = <Card360ViewSlider attributes={props.attributes} />
                            break;
                        case SLIDERTYPETYPE.SIDEARROWS:
                            slider = <CardSliderWithSideArrow attributes={props.attributes} />
                            break;
                        case SLIDERTYPETYPE.TOPRIGHTARROWS:
                            slider = <CardSliderWithTopRightArrow attributes={props.attributes} />
                            break;
                        default:
                            slider = CardSliderWithBottomArrow(props)
                            break;
                    }
                    return slider;
                })()
            }
        </div>
    )
}

export { Slider }