import React, { useEffect, useRef } from "react";
import { DOCKTYPE, GRIDPANE } from "../../enums/enums";
import { useModalDialog } from "../dialog/modal.dialog.component";

const Grid = ({ attributes }) => {

    if (attributes?.filter)
        attributes.filter["name"] = attributes?.name + "-filter-dialog";

    const componentObserver = useRef();

    const grid = useGrid(attributes);

    useEffect(() => {
        window.addEventListener("resize", grid.resize);

        // resize datagrid column
        grid.resize();

        // garbage collection
        return () => {
            window.removeEventListener("resize", grid.resize);
        }

    }, [grid])

    const getDockStyle = (type) => {
        switch (type) {
            case DOCKTYPE.BOTTOM:
                return "position-absolute start-0px bottom-0px end-0px";
            case DOCKTYPE.FILL:
                return "position-absolute top-0px start-0px bottom-0px end-0px";
            case DOCKTYPE.LEFT:
                return "position-absolute top-0px start-0px bottom-0px";
            case DOCKTYPE.RIGHT:
                return "position-absolute top-0px bottom-0px end-0px";
            case DOCKTYPE.TOP:
                return "position-absolute top-0px start-0px end-0px";
            default:
                return;
        }
    }

    return (
        <div id={"grid-" + attributes?.name} className={"d-flex " + getDockStyle(attributes?.dockType)} aria-controls={"table-grid"} ref={componentObserver}>
            <div aria-controls="left-pane" className={((attributes?.fixedPane === GRIDPANE?.LEFT) ? attributes?.paneWidth : "w-100") + " position-relative " + ((attributes?.allowAutoScroll && (attributes?.fixedPane === GRIDPANE?.LEFT)) ? "overflow-auto overflow-x-hidden" : "")}>
                {
                    (() => {
                        return attributes?.pane?.left;
                    })()
                }
            </div>

            <div aria-controls="right-pane" className={((attributes?.fixedPane === GRIDPANE?.RIGHT) ? attributes?.paneWidth : ((attributes?.fixedPane === GRIDPANE?.NONE) ? "hide" : "w-100")) + " position-relative ms-0 border border-top-0px border-start-0px border-bottom-0px border-end-0px"}>
                <div aria-controls="pane-resize-handle" className="cursor-ew-resize position-absolute top-0px start-0px bottom-0px width-2px bg-blue-light-theme"></div>
                <div aria-keyshortcuts={"000"} aria-controls="details-view-pane" className={"position-absolute top-0px start-0px bottom-0px end-0px " + ((attributes?.allowAutoScroll && (attributes?.fixedPane === GRIDPANE?.RIGHT)) ? "overflow-hidden overflow-x-hidden-" : "")}>
                    {
                        (() => {
                            return attributes?.pane?.right;
                        })()
                    }
                </div>
            </div>
        </div >
    )

}

const useGrid = (attributes) => {

    const selectedRowTheme = "bg-blue-lighter-theme";

    // dialogs
    const filterDialogName = attributes?.name + "-filter-dialog";
    const filterDialog = useModalDialog({
        name: filterDialogName
    });

    const addSelectedRow = () => {

    }

    const addSelectedRows = () => {

    }

    const clearSelection = () => {
        const dgrid = target();
        if (dgrid) {
            const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            [...selectedRows].forEach(row => {
                row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRowTheme);
                row.classList.remove(selectedRowTheme);
                row?.removeAttribute("aria-selected");
                const checkbox = row.querySelector("input[type=\"checkbox\"]");
                if (checkbox)
                    checkbox.checked = false;
            });
        }
    }

    const getPaging = () => {
        const _target = target();
        return {
            pageNumber: parseInt(_target.getAttribute("pagenumber")) || attributes.page.pageNumber,
            rowsPerPage: parseInt(_target.getAttribute("rowsperpage")) || attributes.page.rowsPerPage,
            totalPages: parseInt(_target.getAttribute("totalpages")),
            totalRows: parseInt(_target.getAttribute("totalrows"))
        };
    }

    const getSelectedRow = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.find(x => x.checked);
        }
    }

    const getSelectedRowIndex = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const selectedRow = tbody.querySelector("tr[aria-selected=\"true\"]");
        if (selectedRow) {
            const selectedIndex = selectedRow.getAttribute("aria-rowindex");
            return selectedIndex;
        } else {
            return 0;
        }
    }

    const getSelectedRowKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked)[0].value;
        }
    }

    const getSelectedRowsKeyValue = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).map(item => item.value);
        }
    }

    const getSelectedRows = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            [...checkboxes].forEach(checkbox => {
                //
            });
        }
    }

    const headerColumns = () => {
        const dgrid = target();
        const thead = dgrid?.querySelector("thead");
        if (thead) {
            const columns = thead.querySelectorAll("*[aria-controls=\"header-column\"]");
            return [...columns];
        } else {
            return [];
        }
    }

    const hideLoader = () => {
        // const dgrid = target();
    }

    const isRowSelected = () => {

    }

    const itemsCount = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid.querySelector("tbody");
            const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
            return checkboxes.length;
        }
        return 0;
    }

    const nextPage = () => {
        const _target = target();
        const currentPageNumber = parseInt(_target.getAttribute("pagenumber"));
        const totalPages = parseInt(_target.getAttribute("totalpages"));
        if (currentPageNumber < totalPages) {
            _target.setAttribute("pagenumber", currentPageNumber + 1);
        }
    }

    const previousPage = () => {
        const _target = target();
        const currentPageNumber = parseInt(_target.getAttribute("pagenumber"));
        if ((currentPageNumber - 1) > 0) {
            _target.setAttribute("pagenumber", currentPageNumber - 1);
        }
    }

    const removeSelectedRow = () => {

    }

    const removeSelectedRows = () => {

    }

    const resize = () => {
        const datagridHeaderColumns = headerColumns();
        const datagridRows = rows();
        datagridRows?.forEach((row, i) => {
            const columns = row.querySelectorAll("*[aria-controls=\"row-column\"]");
            datagridHeaderColumns?.forEach((column, i) => {
                columns[i]?.setAttribute("style", "max-width:" + column?.offsetWidth + "px");
            });
        });
    }

    const resizeRowPanelGrid = () => {
        const dgrids = document.querySelectorAll("*[aria-controls=\"table-grid\"]");
        [...dgrids].forEach(dgrid => {
            const thead = dgrid?.querySelector("thead");
            if (thead) {

            }
        });
    }

    const rows = () => {
        const dgrid = target();
        if (dgrid) {
            const tbody = dgrid?.querySelector("tbody");
            const rows = tbody?.querySelectorAll("*[aria-controls=\"table-row\"]");
            return [...rows];
        }
        return [];
    }

    const rowColumns = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const columns = tbody.querySelectorAll("*[aria-controls=\"row-column\"]");
        return [...columns];
    }

    const selectAllRow = (selectedRow = null) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.add(selectedRowTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = true;
        });
    }

    const selectCheckbox = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        const isStripped = dgrid.getAttribute("aria-posinset");
        const isSelectable = dgrid.getAttribute("aria-multiselectable");

        [...selectedRows].forEach(row => {
            row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRowTheme);
            if (!isStripped)
                row.classList.remove(selectedRowTheme);
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        if (selectedRow && isSelectable) {
            selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
            selectedRow?.setAttribute("aria-selected", true);
            const checkbox = selectedRow.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = true;
        }
    }

    const selectDefaultRow = (attributes) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove(selectedRowTheme);
            const checkbox = item.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });
        if (selectedRows.length > 0) {
            selectedRows[0]?.classList.add(selectedRowTheme);
            const selectedCheckbox = selectedRows[0].querySelector("input[type=\"checkbox\"]");
            if (selectedCheckbox)
                selectedCheckbox.checked = true;
        }
    }

    const selectRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        const isSelectable = dgrid.getAttribute("aria-multiselectable");

        [...selectedRows].forEach(row => {
            const rowHeader = row.querySelector("*[aria-controls=\"table-row-header\"]");
            rowHeader.classList.remove(selectedRowTheme);
            rowHeader.classList.add(rowHeader.getAttribute("aria-atomic"));
            if (selectedRow === row) {
                rowHeader.classList.remove(rowHeader.getAttribute("aria-atomic"));
            }
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        if (selectedRow && isSelectable) {
            const selectedRowHeader = selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]");
            selectedRowHeader.classList.remove(selectedRowHeader.getAttribute("aria-atomic"));

            selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);

            selectedRow?.setAttribute("aria-selected", true);
            const selectedRowCheckbox = selectedRow.querySelector("input[type=\"checkbox\"]");
            if (selectedRowCheckbox) {
                selectedRowCheckbox.checked = true;
                // selectedRowCheckbox.click();
            }
        }
    }

    const selectedRowsCount = () => {
        const dgrid = target();
        const tbody = dgrid.querySelector("tbody");
        const checkboxes = tbody.querySelectorAll("input[type=\"checkbox\"]");
        if (checkboxes.length > 0) {
            const checkboxArrayList = [...checkboxes];
            return checkboxArrayList.filter(x => x.checked).length;
        }
        return 0;
    }

    const setPaging = () => {
        return attributes.page;
    }

    const setSelectedRow = (key) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(row => {
            row?.removeAttribute("aria-selected");
            const checkbox = row.querySelector("input[type=\"checkbox\"]");
            if (checkbox)
                checkbox.checked = false;
        });

        const selectedRow = dgrid.querySelector("*[id=\"" + key + "\"]");
        const checkbox = selectedRow.querySelector("input[type=\"checkbox\"]");
        if (checkbox && checkbox.value === key) {
            checkbox.checked = true;
            selectedRow?.setAttribute("aria-selected", true);
        }
    }

    const showFilterDialog = (dialogAttributes) => {
        dialogAttributes["name"] = filterDialogName;
        dialogAttributes["title"] = "Filters";
        filterDialog.show(dialogAttributes);
    }

    const showLoader = () => {
        // const dgrid = target();
    }

    const target = () => {
        return document.getElementById("dg-" + attributes?.name);
    }

    const toggleRowPanel = (selectedRow) => {
        if (selectedRow) {
            const dgrid = target();
            const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
            const isStripped = dgrid.getAttribute("aria-posinset");
            const isSelectable = dgrid.getAttribute("aria-multiselectable");
            [...selectedRows].forEach(row => {
                row.querySelector("*[aria-controls=\"table-row-header\"]").classList.remove(selectedRowTheme);
                row?.removeAttribute("aria-selected");

                const rowPanel = row.querySelector("*[aria-controls=\"table-row-panel\"]");
                if (rowPanel?.getAttribute("id") !== selectedRow?.querySelector("*[aria-controls=\"table-row-panel\"]")?.getAttribute("id"))
                    rowPanel.classList.add("hide");
            });

            [...selectedRows].forEach((row, i) => {
                if (isStripped && (i % 2) === 1)
                    row.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
            });

            if (isSelectable) {
                // selectedRow?.querySelector("*[aria-controls=\"table-row-header\"]").classList.add(selectedRowTheme);
                // selectedRow?.setAttribute("aria-selected", true);
            }

            const selectedRowPanel = selectedRow.querySelector("*[aria-controls=\"table-row-panel\"]");
            if (selectedRowPanel?.classList.contains("hide")) {
                selectedRowPanel?.classList.remove("hide");

                // reset/resize table
                const dgrid = selectedRowPanel?.querySelector("*[aria-controls=\"table-grid\"]");
                if (dgrid) {
                    const thead = dgrid?.querySelector("thead");
                    if (thead) {
                        const dgridHeaderColumns = [...thead.querySelectorAll("*[aria-controls=\"header-column\"]")];
                        const columnsWidth = [];
                        dgridHeaderColumns?.forEach((column, i) => {
                            columnsWidth.push(column.offsetWidth);
                        });

                        // get rows
                        const tbody = dgrid?.querySelector("tbody");
                        const dgridRows = [...tbody?.querySelectorAll("*[aria-controls=\"table-row\"]")];
                        dgridRows?.forEach((row, i) => {
                            const columns = [...row.querySelectorAll("*[aria-controls=\"row-column\"]")];
                            columnsWidth.forEach((width, i) => {
                                columns[i].setAttribute("style", "max-width:" + width + "px;");
                            });
                        })
                    }
                }
            } else {
                selectedRowPanel?.classList.add("hide");
            }
        }
    }

    const unselectAllRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove(selectedRowTheme);
            item.querySelector("input[type=\"checkbox\"]").checked = false;
        });
        if (selectedRows.length > 0) {
            selectedRows[0]?.classList.add(selectedRowTheme);
            selectedRows[0].querySelector("input[type=\"checkbox\"]").checked = true;
        }
    }

    const unselectCheckedRow = (selectedRow) => {
        const dgrid = target();
        const selectedRows = dgrid.querySelectorAll("*[aria-controls=\"table-row\"]");
        [...selectedRows].forEach(item => {
            item.classList.remove("bg-light-theme");
        });
        selectedRow?.classList.add("bg-light-theme");
    }

    return {
        addSelectedRow,
        addSelectedRows,
        clearSelection,
        target,
        getPaging,
        getSelectedRow,
        getSelectedRowIndex,
        getSelectedRowKeyValue,
        getSelectedRows,
        getSelectedRowsKeyValue,
        headerColumns,
        hideLoader,
        isRowSelected,
        itemsCount,
        nextPage,
        previousPage,
        removeSelectedRow,
        removeSelectedRows,
        resize,
        resizeRowPanelGrid,
        rows,
        rowColumns,
        selectAllRow,
        selectCheckbox,
        selectDefaultRow,
        selectRow,
        setPaging,
        setSelectedRow,
        selectedRowsCount,
        showFilterDialog,
        showLoader,
        toggleRowPanel,
        unselectAllRow,
        unselectCheckedRow
    }

}

export { Grid, useGrid }