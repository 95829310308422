import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CountryService } from './country.service';
import CreateOrEditCountryView from './createoredit.country/createoredit.country.view';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../../shared/components/datagrid';
import { Toolbar, useToolbar } from '../../../../../../shared/components/toolbar/toolbar.component';
import { useModalDialog } from '../../../../../../shared/components/dialog/modal.dialog.component';
import { useAlertDialog } from '../../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../../../shared/components/loader/page.loader.component';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../../shared/enums/enums';

const useCountriesHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No country selected!",
            message: "Please select an country from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New country",
            successMessage: {
                title: "Country created successfully!",
                message: "Country was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit country details",
            alertMessage: {
                title: "Multiple countries selected!",
                message: "Oops!, you selected multiple countries. Please select a country from the list to continue"
            },
            successMessage: {
                title: "Country updated successfully!",
                message: "Country was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit country details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country deleted successfully!",
                message: "Country was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country archived successfully!",
                message: "Country was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country restored successfully!",
                message: "Country was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available countries, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditCountryObserver = useRef();
    const countryDetailsViewObserver = useRef();

    // components
    const datagridName = "country-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "country-left-pane-toolbar";
    const leftToolbarButtons = {
        addCountryButtonName: "add-country-button",
        editCountryButtonName: "edit-country-button",
        deleteCountryButtonName: "delete-country-button",
        reloadCountryButtonName: "reload-country-button",
    }

    const rightPaneToolbarName = "country-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // dialogs
    const createOrEditCountryDialogName = "createoredit-country-modal";
    const createOrEditCountryDialog = useModalDialog({
        name: createOrEditCountryDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const countryService = CountryService();

    const getCountries = useCallback(async (request) => {

        showPagingInfo(1, 1);
        datagrid?.showLoader(messages?.loader?.fetchingMessage);

        await countryService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setCountries(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);

                showPagingInfo(response?.data?.page?.pageNumber, response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid.hideLoader();
            });

    }, []);

    useEffect(() => {
        getCountries({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }, []);

    // methods
    const showPagingInfo = (pageNumber, totalPages) => {
        try {
            rightPaneToolbar.button({
                name: rightToolbarButtons.pagesLabelName
            }).setText("Page " + pageNumber + " of " + totalPages);
        } catch(error) {
            // ignore
        }
    }

    // event handlers
    const handleCreate = (e) => {
        // clear selection
        setSelectedCountry({});

        // show dialog
        createOrEditCountryDialog.show({
            title: "New country",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditCountryObserver.current({
            data: null
        });
    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple country selected!",
                message: "Oops!, you selected multiple country. Please select a country from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedCountry = countries.find(country => country.countryId === datagrid.getSelectedRowKeyValue());
                setSelectedCountry(selectedCountry);

                createOrEditCountryDialog.show({
                    title: "Edit country",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditCountryObserver.current({
                    data: selectedCountry,
                    callback: () => {
                        getCountries({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No country selected!",
                    message: "Please select a country from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these countries?" : "this country?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    countryService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCountries({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No country selected!",
                message: "Please select a country from the list to continue"
            });
        }
    }

    const handlePublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to publish " + ((datagrid.selectedRowsCount() > 1) ? "these countries?" : "this country?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    countryService.publish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCountries({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No country selected!",
                message: "Please select a country from the list to continue"
            });
        }
    }

    const handleUnpublish = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to unpublish " + ((datagrid.selectedRowsCount() > 1) ? "these countries?" : "this country?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    countryService.unpublish(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCountries({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No country selected!",
                message: "Please select a country from the list to continue"
            });
        }
    }

    const handleArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to archive " + ((datagrid.selectedRowsCount() > 1) ? "these countries?" : "this country?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    countryService.sendToArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCountries({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No country selected!",
                message: "Please select a country from the list to continue"
            });
        }
    }

    const handleRestoreArchive = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to restore " + ((datagrid.selectedRowsCount() > 1) ? "these countries?" : "this country?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    countryService.restoreArchive(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getCountries({
                            queryString: queryString,
                            pageNumber: pageNumber,
                            rowsPerPage: rowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No country selected!",
                message: "Please select a country from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getCountries({
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (pageNumber > 1)
            getCountries({
                queryString: queryString,
                pageNumber: pageNumber - 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (pageNumber < totalPages)
            getCountries({
                queryString: queryString,
                pageNumber: pageNumber + 1,
                rowsPerPage: rowsPerPage
            });
    }

    const handleDatagridRowSelected = (args) => {
        countryDetailsViewObserver.current({
            data: args.selectedRow
        });
    }

    return {
        componentObserver,
        countries,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addCountryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add a country",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editCountryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteCountryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButtonNoLeftMargin
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["countryId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["countryId"],
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width5px
                },
                {
                    title: "Code",
                    value: ["countryCode"],
                    default: "",
                    style: themes?.width80px,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Name",
                    value: ["countryName"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Dialing",
                    value: ["dialingCode"],
                    default: "",
                    style: themes?.width100px,
                },
                {
                    title: "Latitude",
                    value: ["latitude"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    title: "Longitude",
                    value: ["longitude"],
                    default: "",
                    style: themes?.width100,
                },
                {
                    default: "",
                    style: themes?.width40px
                },
            ],
            rowHeight: 35,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            onRowSelected: handleDatagridRowSelected,
            dataset: countries
        }} />,

        createOrEditDialog: {
            name: createOrEditCountryDialogName,
            data: selectedCountry,
            observer: createOrEditCountryObserver,
            widget: CreateOrEditCountryView,
            style: {
                window: "max-width-400px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getCountries({
                                queryString: queryString,
                                pageNumber: pageNumber,
                                rowsPerPage: rowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useCountriesHook;