import React from 'react';
import useCatererPurchaseMarketHook from './caterer.purchase.markets.hook';
import { DataGrid } from '../../../../../shared/components/datagrid';
import { Grid } from '../../../../../shared/components/grid/grid.component';

const CatererPurchaseMarketView = ({ attributes }) => {

    const hook = useCatererPurchaseMarketHook(attributes);

    return (
        <div className={"w-100 height-520px position-relative p-3 pt-0 pb-1"}>
            <div className={"position-absolute top-15px start-15px bottom-15px end-15px"}>
                <Grid attributes={hook?.gridViewAttributes} />
            </div>
        </div>
    );
}

export default CatererPurchaseMarketView;