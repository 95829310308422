import React, { useRef, useState } from 'react';
import Button from '../../shared/components/button/button.component';
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import AccountSignUpWidget from '../../shared/widgets/account.signup/account.signup.widget';
import { createAccount } from './account.signup.service';
import { HttpStatusCode } from '../../shared/enums/http.status.code.enums';

const AccountSignUpView = () => {
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [isRegisteredSuccessfully, setIsRegisteredSuccessfully] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const authWidgetObserver = useRef(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        setViewHeight(window.innerHeight);
        const windowResizer = (e) => {
            setViewHeight(window.innerHeight);
        }
        window.addEventListener('resize', windowResizer);

        // garbage collection
        return () => {
            window.removeEventListener('resize', windowResizer);
        }
    }, [viewHeight]);

    const handleAccountSignUp = async (args) => {
        
        authWidgetObserver.current({
            inProgress: true,
            message: "Loading, please wait..."
        });
        setIsLoading(true);

        await createAccount(args.data).then((response) => {
            setIsLoading(false);
            if (response.statusCode === HttpStatusCode.FOUND) {
                authWidgetObserver.current({
                    inProgress: false,
                    errorOccurred: true,
                    message: response.message
                });
            } else {
                authWidgetObserver.current({
                    inProgress: false,
                    errorOccurred: false,
                    message: response.message
                });
                setIsRegisteredSuccessfully(true);
            }
        }).catch((error) => {
            authWidgetObserver.current({
                inProgress: false,
                errorOccurred: false,
                message: "Done!"
            });
        });

        args.e.cancelBubble = true;
        args.e.preventDefault();
    }

    const handleRegistrationSuccessful = (e) => {
        navigate("/");
    }

    const getAccountSignUpWidget = () => {
        return (
            <div className={(isRegisteredSuccessfully ? "hide" : "") + " max-width-570px"}>
                {/* <div className={"width-410px height-100px"}>
                    
                </div> */}
                <div className={"width-380px height-500px mx-auto mt-3"}>
                    <AccountSignUpWidget method={'post'} observer={authWidgetObserver} onSubmit={(e) => handleAccountSignUp(e)} />
                </div>
            </div>
        );
    }

    const getAccountSignUpSuccessfulWidget = () => {
        return (
            <div className={(!isRegisteredSuccessfully ? "hide" : "") + " max-width-570px"}>
                <div className={"height-100px"}></div>
                <div className={"width-360px height-500px mt-3"}>
                    {/* <SucessMessageWidget
                        method={'post'}
                        observer={{
                            formObserver: authWidgetObserver
                        }}
                        attributes={{
                            title: "Hooray!",
                            message: "Your registration was successful. Click on the 'Continue' to log in to your account.",
                            okayLabel: "Continue to login",
                            onClick: handleRegistrationSuccessful
                        }} /> */}
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className={"position-relative height-" + viewHeight + "px d-flex align-items-center"}>
                <div className={"position-absolute start-0px top-0px bottom-0px width-60 d-flex justify-content-start align-items-center mx-auto bg-light banner-1"}>
                </div>

                <div className={"position-absolute end-0px top-0px bottom-0px width-40 d-flex justify-content-center align-items-start p-5 pt-0 mx-auto bg-white"}>
                {
                    (() => {
                        if (!isRegisteredSuccessfully) {
                            return getAccountSignUpWidget()
                        } else {
                            return getAccountSignUpSuccessfulWidget()
                        }
                    })()
                }
                </div>
            </div>
        </div>
    );
}

export default AccountSignUpView;