import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProductVariationService } from '../product.variation.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { useDataGrid } from '../../../../../../shared/components/datagrid';
import { useForm } from '../../../../../../shared/components/form/form.component';

const useCreateOrEditProductVariationHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [productVariation, setProductVariation] = useState({
        productVariationName: '',
    });
    const [status, setStatus] = useState({
        inProgress: false,
        errorOccurred: false,
        asyncMessage: ""
    });

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const productVariationService = ProductVariationService();

    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);
        } else {
            form.clear();
            setProductVariation({});
        }
    }, [form])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        setProductVariation({
            ...productVariation,
            [e.id]: e.value
        })
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await productVariationService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await productVariationService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        variation: productVariation,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        variationNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        variationNameField: {
            formElement: true,
            validate: true,
            readOnly: status.inProgress,
            placeholder: "Choose a name for your variation",
            id: "productVariationName",
            value: productVariation?.productVariationName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditProductVariationHook;