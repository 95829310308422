import { DOCKTYPE, GRIDPANE } from '../../../shared/enums/enums';
import { usePrimaryUIThemes } from '../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../shared/hooks/ui.icons.hook';
import { ToolbarCollection, useToolbarCollection } from '../../../shared/components/toolbar/toolbar.collection.component';
import { useModalDialogCollection } from '../../../shared/components/dialog/modal.dialog.collection.component';
import { Tab } from '../../../shared/components/tab/tab.component';
import { useStackablePanel } from '../../../shared/components/stackable.panel/stackable.panel.component';
import { useCallback, useState } from 'react';
import { Grid } from '../../../shared/components/grid/grid.component';
import useUsersHook from './user/users.hook';
import useUserReportDashboardHook from './reports/user.report.dashboard.hook';

const useUserManagementHook = () => {

    const [selectedTabIndex, setSelectedTabIndex] = useState(0);

    const stackablePanelComponentName = "userr-management-stackable-panel";
    const tabComponentName = "userr-management-tab";
    const toolbarCollectionComponentName = "userr-management-toolbar";
    const modalDialogCollectionComponentName = "userr-management-modal";

    // custom hooks
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });
    const toolbarCollectionComponent = useToolbarCollection({
        name: toolbarCollectionComponentName
    });
    const modalDialogCollectionComponent = useModalDialogCollection({
        name: modalDialogCollectionComponentName
    });

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // callbacks
    const handleStackablePanelToggle = useCallback((tabIndex) => {
        stackablePanelComponent.setPanel(1);
    }, [])

    // 
    // component hooks
    const usersComponentHook = useUsersHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });
    const reportDashboardComponentHook = useUserReportDashboardHook({
        handleStackablePanelToggle: handleStackablePanelToggle,
        selectedTabIndex: selectedTabIndex
    });

    // 
    const handleTabButtonClick = (e, args) => {
        // select toolbar
        toolbarCollectionComponent.setToolbar(args.index);

        // select modal dialog
        modalDialogCollectionComponent.setDialog(args.index);

        // set selected tab
        setSelectedTabIndex(args.index);

    }

    // toolbar for managing dataset
    const manageToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                usersComponentHook?.toolbar,
                reportDashboardComponentHook?.toolbar,
            ]
        }
    }

    const manageTabAttributes = () => {
        return {
            name: tabComponentName,
            themes: themes,
            dockType: DOCKTYPE.FILL,
            onTabClick: (e, args) => handleTabButtonClick(e, args),
            items: [
                {
                    leading: icons?.PeopleRoundedIcon,
                    text: "Users",
                    target: "users-tab-button",
                    style: themes?.tab?.selectedTabButton,
                    widget: (() => {
                        return (
                            <Grid attributes={{
                                dockType: DOCKTYPE.FILL,
                                fixedPane: GRIDPANE?.RIGHT,
                                paneWidth: themes?.width500px,
                                pane: {
                                    left: usersComponentHook?.datagridAttributes,
                                    right: usersComponentHook?.detailsComponent
                                }
                            }} />
                        )

                    })()
                },
                {
                    leading: icons?.AccountTreeIcon,
                    text: "Roles",
                    target: "roles-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: null
                },
                {
                    leading: icons?.AdminPanelSettingsIcon,
                    text: "Privileges",
                    target: "privileges-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: null
                },
                {
                    leading: icons?.AssessmentRoundedIcon,
                    text: "Reports",
                    target: "users-report-tab-button",
                    style: themes?.tab?.tabButton,
                    widget: null
                },
            ]
        }
    }

    // toolbar for creating or editing
    const createOrEditToolbarCollectionAttributes = () => {
        return {
            name: toolbarCollectionComponentName,
            themes: themes?.toolbarCollection,
            items: [
                usersComponentHook?.toolbar,
                reportDashboardComponentHook?.toolbar,
            ]
        }
    }

    return {
        stackablePanelAttributes: {
            name: stackablePanelComponentName,
            items: [
                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 buser-solid buser-top-0px buser-bottom-0px buser-start-0px buser-end-0px"}>
                                {/* toolbar */}
                                <ToolbarCollection attributes={manageToolbarCollectionAttributes()} />


                                {/* datagrid view */}
                                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                                    <Tab attributes={manageTabAttributes()} />
                                </div>
                            </div>
                        );
                    })()
                },

                {
                    widget: (() => {
                        return (
                            <div className={"position-absolute top-0px start-0px bottom-0px w-100 pt-0 pb-5 buser-solid buser-top-0px buser-bottom-0px buser-start-0px buser-end-0px"}>
                                {/* toolbar */}
                                <ToolbarCollection attributes={createOrEditToolbarCollectionAttributes()} />


                                {/* datagrid view */}
                                <div className={"w-100 position-absolute end-0px top-51px start-0px bottom-0px "}>
                                    {/* <Tab attributes={tabAttributes()} /> */}
                                </div>
                            </div>
                        );
                    })()
                }
            ]
        },

        toolbarCollectionAttributes: manageToolbarCollectionAttributes,

        tabAttributes: manageTabAttributes,

        modalDialogCollectionAttributes: {
            name: modalDialogCollectionComponentName,
            items: [
                usersComponentHook?.createOrEditDialog,
                // donationRequestsComponentHook?.createOrEditDialog,
            ]
        },

    };
}

export default useUserManagementHook;