import React from 'react';
import { StackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import useMarketProductsHook from './market.products.hook';

const MarketProductsView = ({ attributes }) => {

    const hook = useMarketProductsHook(attributes);

    return (
        <div className={"position-absolute top-0 start-0 end-0 bottom-13px w-100 p-0 flex-column rounded"}>
            <div className={"position-absolute top-0px start-0px end-0px bottom-0px"}>
                <StackablePanel attributes={hook?.stackablePanelAttributes} />
            </div>

            <ModalDialog attributes={hook?.addMarketProductsDialogAttributes} />
        </div>
    );
}

export default MarketProductsView;