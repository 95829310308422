import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ProductCategoryService } from '../product.category.service';
import { usePrimaryUIThemes } from '../../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../../shared/hooks/ui.icons.hook';
import { DataGrid, useDataGrid } from '../../../../../../shared/components/datagrid';
import { TOOLBARITEMTYPE } from '../../../../../../shared/enums/enums';
import { useForm } from '../../../../../../shared/components/form/form.component';
import { useTokenSearchField } from '../../../../../../shared/components/field/token.search.textfield.component';

const useCreateOrEditProductCategoryHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;
    const customUIThemes = {

        singleSelectDropDownField: {
            g: "height-37px w-100 justify-content-start border-1px border transparent-border-blue-dark-theme shadow-sm-",
            bgColor: "bg-blue-light-theme-",
            leading: "icon-16px text-16px",
            trailing: "icon-16px text-16px",
            text: "text-13px text-dark-theme text-start max-width-100px- text-truncate",
            dropdown: "max-height-100px overflow-hidden overflow-y-auto"
        },

        toolbarCollectionWrapper: "d-flex align-items-center ps-0 pe-0 height-40px bg-white border-solid border-top-0px border-bottom-0px border-start-0px border-end-0px transparent-border-blue-dark-theme",

    };

    // state objects
    const [productCategory, setProductCategory] = useState({
        categoryName: '',
    });
    const [parentCategories, setParentCategories] = useState([]);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No country selected!",
            message: "Please select an country from the list to continue"
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available countries, please wait..."
        }
    };

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    // components
    const parentCategoriesDatagridName = "createoredit-parent-category-datagrid";
    const parentCategoriesDatagrid = useDataGrid({
        name: parentCategoriesDatagridName
    });
    const parentCategoriesTokenSearchFieldName = "createoredit-parent-category-token-search-field";
    const parentCategoriesTokenSearchField = useTokenSearchField({
        name: parentCategoriesTokenSearchFieldName
    });
    const form = useForm({
        ref: reactiveForm
    });

    // services
    const categoryService = ProductCategoryService();

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        //
        getParentCategories("");

        if (args?.data !== null) {
            // set form data
            form.setData(args?.data);
            setProductCategory(args?.data);

        } else {
            form.clear();
            setProductCategory({});
        }
    }, [form])

    // services
    const getParentCategories = useCallback(async (search) => {
        parentCategoriesDatagrid?.showLoader(messages?.loader?.fetchingMessage);
        await categoryService.getAll({
            queryString: search,
            pageNumber: 1,
            rowsPerPage: 1000000
        }).then(response => {
            parentCategoriesDatagrid?.hideLoader();
            setParentCategories(response.data.list);
        }).catch(error => {
            parentCategoriesDatagrid?.hideLoader();
        });
    }, []);

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleTextFieldChange = (e) => {
        // setProductCategory({
        //     ...productCategory,
        //     [e.id]: e.value
        // })
    }

    const handleSearchFieldChange = (e) => {
        getParentCategories(e.value);
    }

    const handleSaveOrUpdate = async (args) => {
        args?.inProgress(true);
        if (args?.isNew) {
            await categoryService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await categoryService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                args?.inProgress(false);
            }).catch((error) => {
                args?.error(error);
            });
        }
    }

    return {
        category: productCategory,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        // name
        categoryNameLabel: {
            text: "Name:",
            style: themes?.label?.textBold12px
        },
        categoryNameField: {
            formElement: true,
            validate: true,
            placeholder: "Choose a name for your category",
            id: "productCategoryName",
            value: productCategory?.productCategoryName,
            style: themes?.field?.textField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // description
        categoryDescriptionLabel: {
            text: "Description:",
            style: themes?.label?.textBold12px
        },
        categoryDescriptionField: {
            formElement: true,
            validate: true,
            placeholder: "Category description",
            id: "productCategoryDescription",
            isMultiline: true,
            value: productCategory?.productCategoryDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver,
            onChange: handleTextFieldChange
        },

        // parent category
        parentCategoryDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            observer: componentObserver,
            style: customUIThemes?.singleSelectDropDownField,
            formElement: true,
            key: "parentCategoryId",
            title: "productCategoryName",
            value: "productCategoryId",
            items: parentCategories,
            isSearchable: true,
            model: productCategory,
            onLookupChange: handleSearchFieldChange
        },

        themes: themes,
    }
}

export default useCreateOrEditProductCategoryHook;