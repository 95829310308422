import React from 'react';
import { DataGrid } from '../../../../../shared/components/datagrid';
import useManageMarketProductListHook from './manage.market.product.list.hook';

const ManageMarketProductListView = ({ attributes }) => {

    const hook = useManageMarketProductListHook(attributes);

    return (
        <div className={"position-absolute top-0px start-0px bottom-0px end-0px p-3 pt-0 pb-1"}>
            {hook?.toolbar}
            <div className={"position-relative height-470px"}>
                <DataGrid attributes={hook?.dataGridAttributes} />
            </div>
        </div >
    );
}

export default ManageMarketProductListView;