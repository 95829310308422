import React from 'react';
import HorizontalSpace from '../../../../../../shared/components/separator/horizontal.space.component';
import { Tab } from '../../../../../../shared/components/tab/tab.component';
import { Avatar } from '../../../../../../shared/components/avatar';
import { StackablePanel } from '../../../../../../shared/components/stackable.panel/stackable.panel.component';
import { Toolbar } from '../../../../../../shared/components/toolbar/toolbar.component';
import useBankDetailsHook from './bank.details.hook';
import { ModalDialog } from '../../../../../../shared/components/dialog/modal.dialog.component';
import { DataGrid } from '../../../../../../shared/components/datagrid';
import { Line } from 'react-chartjs-2';

const BankDetailsView = ({ attributes }) => {

    const hook = useBankDetailsHook(attributes);

    return (
        <div className={"w-100 position-relative p-0 pt-0 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <div className={"w-100 d-flex"}>
                    <div className={"w-100 flex-column"}>
                        <div className={"bg-blue-light-theme min-height-100px rounded-3px m-3 p-3"}>
                            <div className={"d-flex position-relative height-45px mb-2"}>
                                <div className={"w-100 me-2"}>
                                    <div>Available Bal.</div>
                                    <div className={"text-bold"}>NGN0.00</div>
                                </div>
                                <div className={"w-100 me-2"}>
                                    <div>Ledger Bal.</div>
                                    <div className={"text-bold"}>NGN0.00</div>
                                </div>
                                <div className={"w-100"}>
                                    <div>Min. Withdrawal Bal.</div>
                                    <div className={"text-bold"}>NGN0.00</div>
                                </div>
                            </div>
                            <div className={"d-flex position-relative height-45px mb-0"}>
                                <div className={"w-100 me-2"}>
                                    <div>Min. Interest Bal.</div>
                                    <div className={"text-bold"}>NGN0.00</div>
                                </div>
                                <div className={"w-100 me-2"}>
                                    <div>Interest Rate</div>
                                    <div className={"text-bold"}>0.00%</div>
                                </div>
                                <div className={"w-100"}>
                                    <div>Interest Posting Freq.</div>
                                    <div className={"text-bold"}>Every month</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className={""}>
                            <HorizontalSpace attributes={hook?.horizontalSeparatorAttributes} />
                        </div>

                        {/* details */}
                        <div className={"mb-4 p-3 position-relative"}>

                        </div>

                    </div>
                </div>
            </div >
        </div >
    );
}

export default BankDetailsView;