import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { PharmacyProductCategoryService } from './pharmacy.product.category.service';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useToastr } from '../../../../../shared/components/toastr/toastr.component';

const usePharmacyProductCategoriesHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [pharmacyProductCategories, setPharmacyProductCategories] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const [selectedPharmacyProductCategories, setSelectedPharmacyProductCategories] = useState([]);
    const [selectedPharmacyProductCategory, setSelectedPharmacyProductCategory] = useState({});
    const [selectedPharmacyProductCategoriesQueryString, setSelectedPharmacyProductCategoriesQueryString] = useState("");
    const [selectedPharmacyProductCategoriesPageNumber, setSelectedPharmacyProductCategoriesPageNumber] = useState(1);
    const [selectedPharmacyProductCategoriesRowsPerPage, setSelectedPharmacyProductCategoriesRowsPerPage] = useState(20);
    const [selectedPharmacyProductCategoriesTotalPages, setSelectedPharmacyProductCategoriesTotalPages] = useState(0);
    const [selectedPharmacyProductCategoriesTotalRows, setSelectedPharmacyProductCategoriesTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No country selected!",
            message: "Please select an country from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New country",
            successMessage: {
                title: "Country created successfully!",
                message: "Country was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit country details",
            alertMessage: {
                title: "Multiple countries selected!",
                message: "Oops!, you selected multiple countries. Please select a country from the list to continue"
            },
            successMessage: {
                title: "Country updated successfully!",
                message: "Country was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit country details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country deleted successfully!",
                message: "Country was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country published successfully!",
                message: "Country was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country archived successfully!",
                message: "Country was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these countries?" : "this country?")
                }
            },
            successMessage: {
                title: "Country restored successfully!",
                message: "Country was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available countries, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();
    const createOrEditCategoryObserver = useRef();
    const reactiveFormObserver = useRef(null);
    const selectedPharmacyObserver = useRef();
    const selectedPharmacyProductCategoriesObserver = useRef();
    const selectedPharmacyProductCategoryObserver = useRef();

    // components
    const datagridName = "pharmacy-product-categories-datagrid";
    const leftPaneToolbarName = "pharmacy-product-categories-left-pane-toolbar";
    const rightPaneToolbarName = "pharmacy-product-categories-right-pane-toolbar";

    const leftToolbarButtons = {
        addCategoryButtonName: "add-category-button",
        editCategoryButtonName: "edit-category-button",
        deleteCategoryButtonName: "delete-category-button",
        reloadCategoryButtonName: "reload-category-button",
    }

    const rightToolbarButtons = {
        selectButtonName: "select-categories-button",
    }

    const datagrid = useDataGrid({
        name: datagridName
    });


    // dialogs
    const createOrEditCategoryDialogName = "createoredit-category-modal";
    const createOrEditCategoryDialog = useModalDialog({
        name: createOrEditCategoryDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();
    const toastr = useToastr();

    // loader
    const pageLoader = usePageLoader();


    // services
    const categoryService = PharmacyProductCategoryService();

    const getPharmacyProductCategories = useCallback(async (request) => {
        await categoryService.getAll(request)
            .then(response => {
                // populate datagrid 
                setPharmacyProductCategories(response?.data?.list);

                // set page
                setPageNumber(response?.data?.page?.pageNumber);
                setRowsPerPage(response?.data?.page?.rowsPerPage);
                setTotalPages(response?.data?.page?.totalPages);
                setTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                // datagrid?.hideLoader();
            });

    }, []);

    const getSelectedPharmacyProductCategories = useCallback(async (request) => {
        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await categoryService.getAllByPharmacy(request)
            .then(response => {
                // populate datagrid 
                setTimeout(async () => {
                    setSelectedPharmacyProductCategories(response?.data?.list);
                    selectedPharmacyProductCategoriesObserver.current = response?.data?.list;
                    datagrid?.hideLoader();
                }, 1000);

                // set page
                setSelectedPharmacyProductCategoriesPageNumber(response?.data?.page?.pageNumber);
                setSelectedPharmacyProductCategoriesRowsPerPage(response?.data?.page?.rowsPerPage);
                setSelectedPharmacyProductCategoriesTotalPages(response?.data?.page?.totalPages);
                setSelectedPharmacyProductCategoriesTotalRows(response?.data?.page?.totalRows);
            })
            .catch(error => {
                datagrid?.hideLoader();
            });
    }, []);


    // callbacks
    const widgetReactiveFormModule = useCallback(async (args) => {
        //
    }, [])

    const widgetObserver = useCallback(async (args) => {

        selectedPharmacyObserver.current = args?.data;

        // load product categories
        await getPharmacyProductCategories({
            queryString: queryString,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });

        await getSelectedPharmacyProductCategories({
            pharmacyId: args?.data?.pharmacyId,
            queryString: selectedPharmacyProductCategoriesQueryString,
            pageNumber: selectedPharmacyProductCategoriesPageNumber,
            rowsPerPage: selectedPharmacyProductCategoriesRowsPerPage
        });

    }, [])

    useEffect(() => {
        (async (attributes) => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })(attributes);
    }, []);

    // category
    const handleCreate = async (e) => {
        // add/create a new pharmacy product category
        await categoryService.saveSelectedPharmacyProductCategory({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            productCategoryName: "Untitled product category"
        }).then(response => {
            getSelectedPharmacyProductCategories({
                pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                queryString: selectedPharmacyProductCategoriesQueryString,
                pageNumber: selectedPharmacyProductCategoriesPageNumber,
                rowsPerPage: selectedPharmacyProductCategoriesRowsPerPage
            });
            pageLoader.hide();
            toastr.success("Product category added successfully");
        });
    }
    
    const handleCreateSelected = async (args) => {
        await categoryService.saveSelectedPharmacyBulkProductCategory({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            pharmacyProductCategories: args?.data
        }).then(response => {
            getSelectedPharmacyProductCategories({
                pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                queryString: selectedPharmacyProductCategoriesQueryString,
                pageNumber: selectedPharmacyProductCategoriesPageNumber,
                rowsPerPage: selectedPharmacyProductCategoriesRowsPerPage
            });
            pageLoader.hide();
            toastr.success("Product category added successfully");
        });
    }

    const handleDelete = async (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these categories? " : "this category?"),
                onConfirmed: async (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    await categoryService.removeByPharmacy(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getSelectedPharmacyProductCategories({
                            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                            queryString: selectedPharmacyProductCategoriesQueryString,
                            pageNumber: selectedPharmacyProductCategoriesPageNumber,
                            rowsPerPage: selectedPharmacyProductCategoriesRowsPerPage
                        });
                        pageLoader.hide();
                        toastr.success("Product category deleted successfully");
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No category selected!",
                message: "Please select a category from the list to continue"
            });
        }
    }

    const handleReload = async () => {
        await getSelectedPharmacyProductCategories({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            queryString: selectedPharmacyProductCategoriesQueryString,
            pageNumber: selectedPharmacyProductCategoriesPageNumber,
            rowsPerPage: selectedPharmacyProductCategoriesRowsPerPage
        });
    }

    const handleSelectedPharmacyProductCategorySearchFieldChange = (args) => {
        getPharmacyProductCategories({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            queryString: args?.value,
            pageNumber: pageNumber,
            rowsPerPage: rowsPerPage
        });
    }

    const handleSelectedPharmacyProductCategoryUpdate = async (args) => {
        await categoryService.updateSelectedPharmacyProductCategory({
            pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
            productCategoryId: args?.productCategoryId,
            productCategoryName: args?.productCategoryName
        }).then(response => {
            getSelectedPharmacyProductCategories({
                pharmacyId: selectedPharmacyObserver.current?.pharmacyId,
                queryString: selectedPharmacyProductCategoriesQueryString,
                pageNumber: selectedPharmacyProductCategoriesPageNumber,
                rowsPerPage: selectedPharmacyProductCategoriesRowsPerPage
            });
            pageLoader.hide();
            // toastr.success("Product category updated successfully");
        });
    }

    return {
        componentObserver,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapperNoPadding}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add",
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.reloadCategoryButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.ReplayRoundedIcon,
                                        onClick: (e) => handleReload(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        name: rightToolbarButtons.selectButtonName,
                                        type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTBUTTON,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        placeholder: "Select a product category",
                                        observer: componentObserver,
                                        style: themes?.toolbar?.selectDropDownField250px,
                                        formElement: true,
                                        key: "productCategoryId",
                                        title: "productCategoryName",
                                        value: "productCategoryId",
                                        isMultiSelectable: true,
                                        listItemHeight: 30,
                                        onClick: (e, args) => { },
                                        dataset: pharmacyProductCategories,
                                        onFilter: handleSelectedPharmacyProductCategorySearchFieldChange,
                                        buttons: [
                                            {
                                                leading: icons?.AddRoundedIcon,
                                                text: "Add selected",
                                                style: themes?.button?.primary,
                                                onClick: (e, winArgs) => {
                                                    winArgs.submit(e, args => {
                                                        handleCreateSelected(args);
                                                    });
                                                }
                                            }
                                        ]
                                    }
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagridAttributes: {
            name: datagridName,
            key: ["productCategoryId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["productCategoryId"],
                    style: themes?.width30px
                },
                {
                    type: COLUMNTYPE.TEXTFIELD,
                    title: "Category",
                    value: "productCategoryName",
                    key: "productCategoryName",
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width10px
                },
            ],
            rowHeight: 40,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: selectedPharmacyProductCategoriesObserver.current,
            onRowColumnCellLostFocus: handleSelectedPharmacyProductCategoryUpdate
        },

    };
}

export default usePharmacyProductCategoriesHook;