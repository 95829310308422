import React from 'react';
import AppRouterModule from "./app.router.module"

function App() {
  return (
    <div className="App">
      <AppRouterModule></AppRouterModule>
    </div>
  );
}

export default App;
