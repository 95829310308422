import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Button from "../button/button.component";
import { usePrimaryUIThemes } from "../../hooks/ui.themes/index";
import { useIconsThemes } from "../../hooks/ui.icons.hook";
import { SLIDEDIRECTION } from "../../enums/enums";

const Toastr = forwardRef(({ attributes }, ref) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    const [params, setParams] = useState({
        title: attributes?.title,
        widget: attributes?.widget,
        isNew: attributes?.isNew
    });
    const [inProgress, setInProgress] = useState(false);

    // refs
    const componentRef = useRef();
    const stylesRef = useRef();

    const widgetObserver = useRef();
    const widgetNotifier = useRef();

    const close = () => {
        const dialog = componentRef?.current;
        if (dialog) {
            dialog?.classList.remove("w-100");
            dialog.classList.add("width-0px");
        }
    }

    useImperativeHandle(attributes?.observer, () => ({
        show(attr) {
            const dialog = componentRef?.current;
            close();

            const modalBody = dialog.querySelector("div[aria-controls=\"modal-body\"]");
            modalBody.scrollTop = 0;

            // setTimeout(() => {
            dialog.querySelector("h1").innerHTML = attr?.title || "";
            dialog.classList.remove("width-0px");
            dialog?.classList.add("w-100");
            dialog?.setAttribute("is-new", attr?.isNew);

            widgetObserver.current({
                data: attr?.data,
                callback: attr?.callback
            });
            // });
        },

        close() {
            // setTimeout(() => {
            close();
            // });
        }
    }))

    const getSlidePosition = () => {
        const styles = (attributes?.style?.window || "") + " rounded-4px z-index-5 width-0px opacity-0";
        let slideDirectionStyle = "position-absolute top-20px end-20px shadow-lg";
        return (slideDirectionStyle.trim() + " " + styles.trim()).trim();
    }

    return (
        <div ref={componentRef}
            aria-owns={"toastr-callout-window"}
            id="toastr-callout-window"
            aria-controls={"modal-callout-window"}
            className={getSlidePosition()}
            data-style={getSlidePosition()}
            aria-hidden="true"
            data-timeout={attributes?.timeout}>
            <div className={"modal-dialog "}>
                <div className={"modal-content position-absolute- top-0px start-0px bottom-0px end-0px padding-15px padding-start-22px shadow-lg border-1px transparent-border-blue-dark-theme-"}>
                    <div className={"modal-header border-0px pb-2 position-relative"}>
                        <div className={"w-100 d-flex justify-content-between align-items-center"}>
                            <h1 className={"modal-title text-17px text-bold line-height-23px pt-0 "}>{params?.title}</h1>
                            <div className={"ms-3 me-2 d-flex"}>
                                <div className={"spinner-border text-dark icon icon-14px text-8px " + (inProgress ? "" : "hide")} role="status"></div>
                            </div>
                        </div>
                    </div>
                    <div aria-controls="modal-body" aria-owns={"message-section"} className={"modal-body text-13px min-height-20px "}></div>
                </div>
            </div>
        </div>
    )
})

const useToastr = () => {
    const icons = useIconsThemes()?.rounded;
    const themes = usePrimaryUIThemes();

    const show = (attributes) => {
        const dialog = document.body.querySelector("*[aria-owns=\"toastr-callout-window\"]");
        // console.log(dialog?.getAttribute("data-style"));
        // console.log(dialog?.getAttribute("data-style").split(" "));
        dialog?.getAttribute("data-style").split(" ").forEach(i => {
            // console.log(i);
            // dialog.classList.remove(i);
            dialog.classList.add(i);
        });
        dialog?.classList.remove("opacity-0");
        dialog?.classList.remove("width-0px");
        dialog?.classList.add("slide-left");
        attributes?.style?.window.split(" ").forEach(i => {
            // console.log(i);
            dialog.classList.add(i);
        });

        const h1 = dialog.querySelector("h1");
        attributes?.style?.title.split(" ").forEach(i => {
            h1.classList.add(i);
        });
        h1.innerHTML = attributes?.title || "";

        const message = dialog.querySelector("*[aria-owns=\"message-section\"]");
        attributes?.style?.message.split(" ").forEach(i => {
            message.classList.add(i);
        });
        message.innerHTML = attributes?.message || "Enter value in the field below to continue.";

        const timeout = parseInt(dialog.getAttribute("data-timeout")) * 1000;
        setTimeout(() => {
            close();
        }, timeout);
    }

    const success = (message) => {
        show({
            style: themes?.toastr?.success,
            leading: icons?.CheckRoundedIcon,
            title: "Success",
            message: message
        });
    }

    const error = (message) => {
        show({
            style: themes?.toastr?.error,
            leading: icons?.ErrorRoundedIcon,
            title: "Oops! something went wrong",
            message: message
        });
    }

    const info = (message) => {
        show({
            style: themes?.toastr?.info,
            leading: icons?.InfoRoundedIcon,
            title: "Oops! something went wrong",
            message: message
        });
    }

    const warning = (message) => {
        show({
            style: themes?.toastr?.warning,
            leading: icons?.WarningRoundedIcon,
            title: "Warning",
            message: message
        });
    }


    const close = () => {
        const dialog = document.body.querySelector("*[aria-owns=\"toastr-callout-window\"]");
        dialog.classList.add("width-0px");
        dialog.classList.add("opacity-0");
        dialog.classList.remove("w-100");
        dialog.classList.remove("opacity-10");
        dialog.classList.remove("slide-left");

        dialog?.getAttribute("data-style").split(" ").forEach(i => {
            dialog.classList.add(i);
        });
    }

    return { show, close, success, error, info, warning }
}

export { Toastr, useToastr }