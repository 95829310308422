import React from 'react';
import useCreateBulkProductHook from './create.bulk.product.sheet.hook';

const CreateBulkProductView = ({ attributes }) => {
    const hook = useCreateBulkProductHook(attributes);
    return (
        <div className={"w-100 height-500px position-relative p-3 pt-0 pb-1"}>
            <div className={"position-absolute top-5px start-15px bottom-15px end-15px"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    {hook?.gridViewAttributes}
                </form>
            </div>
        </div>
    );
}

export default CreateBulkProductView;