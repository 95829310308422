import { useCallback, useEffect, useRef, useState } from 'react';
import useAppConfig from '../../../../../shared/hooks/app.config.hook';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { ALIGNMENTTYPE, COLUMNTYPE, DOCKTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { CatererProductCategoryService } from '../caterer.product.categories/caterer.product.category.service';
import { CatererProductVariationService } from '../caterer.product.variations/caterer.product.variation.service';
import { CatererProductColorService } from '../caterer.product.colors/caterer.product.color.service';
import { CatererProductTypeService } from '../caterer.product.types/caterer.product.type.service';
import { CatererProductService } from '../caterer.product/caterer.product.service';
import { ProductImageService } from '../../../configuration.settings/settings/product.images/product.image.service';
import CatererPurchaseMarketViewView from '../caterer.purchase.market/caterer.purchase.markets.view';

const useCreateOrEditCatererHook = (attributes) => {
    const appConfig = useAppConfig();

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes().rounded;
    const customUIThemes = {

        // image
        image: {
            g: "bg-cover bg-center text-white cursor-pointer rounded-6px",
            bgColor: "bg-cover bg-center bg-blue-light-theme transparent-border-blue-light-theme",
            textColor: "text-center manrope-bold text-13px text-blue-theme",
            placeholder: "bg-cover bg-center opacity-9 text-blue-dark-theme icon-35px text-480px"
        },


        // 
        singleSelectDropDownField: {
            g: "height-37px w-100 justify-content-start border-1px border transparent-border-blue-dark-theme shadow-sm-",
            bgColor: "bg-blue-light-theme-",
            leading: "icon-16px text-16px",
            trailing: "icon-16px text-16px",
            text: "text-13px text-dark-theme text-start max-width-100px- text-truncate",
            dropdown: "max-height-180px overflow-hidden overflow-y-auto"
        },

    };

    // state objects
    const [selectedCaterer, setSelectedCaterer] = useState();
    const [product, setProduct] = useState();
    const [productIngredients, setProductIngredients] = useState([]);
    const [productIngredient, setProductIngredient] = useState();
    const [productIngredientDatagridQueryString, setProductIngredientDatagridQueryString] = useState("");
    const [productIngredientDatagridPageNumber, setProductIngredientDatagridPageNumber] = useState(1);
    const [productIngredientDatagridRowsPerPage, setProductIngredientDatagridRowsPerPage] = useState(30);


    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No images selected!",
            message: "Please select an images from the list to continue"
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available images, please wait..."
        }
    };

    // observers
    const catererProductReactiveForm = useRef();
    const componentObserver = useRef();
    const imageUploaderObserver = useRef();
    const catererProductObserver = useRef();
    const catererProductIngredientsMarketDialogObserver = useRef();
    const productIngredientDatagridObserver = useRef();

    const productImages = useRef([]);
    const productCategories = useRef([]);
    const productVariations = useRef([]);
    const productColors = useRef([]);
    const productTypes = useRef([]);
    const productIngredientObserver = useRef([]);


    // components
    const form = useForm({
        ref: catererProductReactiveForm
    });
    const productIngredientDatagridName = "products-productIngredientDatagrid";
    const productIngredientDatagrid = useDataGrid({
        name: productIngredientDatagridName
    });

    const productIngredientDatagridToolbarName = "product-ingredient-productIngredientDatagrid-toolbar-name";
    const productIngredientDatagridToolbarButtons = {
        filterButton: "filter-button",
        previousButton: "previous-button",
        pagesButton: "pages-label",
        nextButton: "next-button",
    }

    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();
    const pageLoader = usePageLoader();

    const productIngredientsMarketDialogName = "add-market-product-modal";
    const productIngredientsMarketDialog = useModalDialog({
        name: productIngredientsMarketDialogName
    });


    // services
    const productCategoryService = CatererProductCategoryService();
    const productVariationService = CatererProductVariationService();
    const productColorService = CatererProductColorService();
    const productTypeService = CatererProductTypeService();
    const catererProductService = CatererProductService();
    const productImageService = ProductImageService();

    const getCatererProductIngredients = useCallback(async (request) => {
        productIngredientDatagrid?.showLoader(messages?.loader?.fetchingMessage);
        productIngredientDatagrid.clearSelection();
        await catererProductService.getProductIngredients(request)
            .then(response => {
                setTimeout(async () => {
                    productIngredientDatagrid?.hideLoader();
                    setProductIngredients(response?.data?.list);
                    productIngredientObserver.current = response?.data?.list;
                }, 1000);
            })
            .catch(error => {
                productIngredientDatagrid?.hideLoader();
            });
    }, []);

    const setCatererProductIngredientsList = useCallback(async (productList) => {
        const productIngredientList = [];
        productList.forEach(item => {
            productIngredientList.push({
                marketProductInventoryId: item?.marketProductInventoryId,
                productName: item?.productName,
                price: item?.price,
                priceAlias: item?.priceAlias,
                media1Id: item?.media1Id,
                media1Url: item?.media1Url,
                quantity: 1
            });
        });
        productIngredientObserver.current = productIngredientList;
        productIngredientDatagridObserver.current?.updateList(productIngredientList);
    }, [])


    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;

        args.data["catererId"] = catererProductObserver?.current?.catererId;
        args.data["productIngredients"] = productIngredientDatagrid?.getRowObjects();
        handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setProduct(args?.data);
        catererProductObserver.current = args?.data;

        // create a promise list of product attributes
        const promiseList = [
            productCategoryService.getAllByCaterer({
                catererId: catererProductObserver?.current?.catererId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list),

            productVariationService.getAllByCaterer({
                catererId: catererProductObserver?.current?.catererId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list),

            productColorService.getAllByCaterer({
                catererId: catererProductObserver?.current?.catererId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list),

            productTypeService.getAllByCaterer({
                catererId: catererProductObserver?.current?.catererId,
                queryString: "",
                pageNumber: 1,
                rowsPerPage: 100000
            }).then(response => response?.data?.list)
        ];

        // get all product details attributes
        Promise.all(promiseList).then(response => {
            response.forEach(list => {
                if (Array.isArray(list) && list.length > 0) {

                    // get product selected brands
                    if (list[0].hasOwnProperty("productCategoryId")) {
                        productCategories.current = list;
                    }

                    // get product selected colors
                    if (list[0].hasOwnProperty("productColorId")) {
                        productColors.current = list;
                    }

                    // get product selected types
                    if (list[0].hasOwnProperty("productTypeId")) {
                        productTypes.current = list;
                    }

                    // get product selected variations
                    if (list[0].hasOwnProperty("productVariationId")) {
                        productVariations.current = list;
                    }
                }
            });
        }).catch(error => {
            //
        });


        // load image
        imageUploaderObserver.current.loadImage(args?.data);

        if (args?.data !== null) {
            // 
            // load product ingredients
            await getCatererProductIngredients({
                catererProductId: catererProductObserver.current?.productId,
                queryString: productIngredientDatagridQueryString,
                pageNumber: productIngredientDatagridPageNumber,
                rowsPerPage: productIngredientDatagridRowsPerPage
            });

            // set form data
            form.setData(catererProductObserver.current);
        } else {
            form.clear();
            catererProductObserver.current = await {};
        }
    }, [form])

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);

    const handleProductImageUpload = (args) => {
        console.log(args);
    }

    const handleAddProductIngredientFromMarket = (e) => {
        // clear selection
        setProductIngredient({});

        // show dialog
        productIngredientsMarketDialog.show({
            title: "Shop for Products",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        catererProductIngredientsMarketDialogObserver.current({
            data: null
        });

    }

    const handleDeleteProductIngredient = (e) => {
        if (productIngredientDatagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (productIngredientDatagrid.selectedRowsCount() + ((productIngredientDatagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((productIngredientDatagrid.selectedRowsCount() > 1) ? "these products?" : "this product?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    console.log(productIngredientDatagrid.getSelectedRowsKeyValue());
                    catererProductService.removeProductIngredients({
                        productId: catererProductObserver.current?.productId,
                        productIngredients: productIngredientDatagrid.getSelectedRowsKeyValue()
                    }).then(response => {
                        getCatererProductIngredients({
                            catererProductId: catererProductObserver.current?.productId,
                            queryString: productIngredientDatagridQueryString,
                            pageNumber: productIngredientDatagridPageNumber,
                            rowsPerPage: productIngredientDatagridRowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No product selected!",
                message: "Please select a product from the list to continue"
            });
        }
    }

    const handleSaveOrUpdate = async (args) => {
        pageLoader.show({
            message: "Loading, please wait...",
        });
        if (args?.isNew) {
            await catererProductService.save(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader?.hide();
            }).catch((error) => {
                args?.error(error);
            });
        } else {
            await catererProductService.update(args?.data).then((response) => {
                args?.successful({
                    data: args?.data,
                    callback: args?.callback
                });
                pageLoader?.hide();
            }).catch((error) => {
                args?.error(error);
            });
        }
    }


    return {
        product: product,
        media: catererProductObserver.current,
        reactiveForm: catererProductReactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // media
        imageUploaderAttributes: {
            name: "createoredit-caterer-product-image-uploader",
            formElement: true,
            id: "mediaId",
            key: "mediaId",
            url: "mediaUrl",
            size: themes?.sizeAbsolute,
            leading: icons?.PhotoSizeSelectActualIcon,
            style: customUIThemes?.image,
            model: catererProductObserver.current,
            onUploadCompleted: handleProductImageUpload,
            observer: imageUploaderObserver,
            config: {
                ownerId: appConfig?.sessionAuthDetails?.userId,
                uploadUrl: catererProductService?.uploadUrl,
                downloadUrl: catererProductService?.downloadUrl
            }
        },

        // product name
        productNameFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "Choose a name for this product",
            id: "productName",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // product description
        productDescriptionFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "Briefly describe this product",
            id: "productDescription",
            isMultiline: true,
            value: product?.productDescription,
            style: themes?.field?.multiTextField,
            observer: componentObserver
        },

        // product category
        productCategoryDropDownFieldAttributes: {
            name: "category-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select a category",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: customUIThemes?.singleSelectDropDownField,
            formElement: true,
            key: "productCategoryId",
            title: "productCategoryName",
            value: "productCategoryId",
            items: productCategories.current,
            model: product,
        },

        // variations
        variationSingleSelectDropDownFieldAttributes: {
            name: "variation-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select size/weight",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productVariationId",
            title: "productVariationName",
            value: "productVariationId",
            isStripped: false,
            isSelectable: true,
            items: productVariations.current,
            model: product,
        },

        // price
        productPriceFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "0.00",
            id: "serviceFee",
            value: product?.serviceFee,
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // discount
        productDiscountFieldAttributes: {
            formElement: true,
            validate: false,
            placeholder: "0%",
            id: "discount",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // colors
        colorSingleSelectDropDownFieldAttributes: {
            name: "color-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select color",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productColorId",
            title: "productColorName",
            value: "productColorId",
            isStripped: false,
            isSelectable: true,
            items: productColors.current,
            model: product,
        },

        // types
        typeSingleSelectDropDownFieldAttributes: {
            name: "type-selectable-dropdownlist-field",
            type: TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTFIELD,
            placeholder: "Select types",
            onClick: (e, args) => { },
            observer: componentObserver,
            style: themes?.field?.singleSelectDropDownField,
            formElement: true,
            key: "productTypeId",
            title: "productTypeName",
            value: "productTypeId",
            isStripped: false,
            isSelectable: true,
            items: productTypes.current,
            model: product,
        },

        // service fee
        serviceFeeFieldAttributes: {
            formElement: true,
            validate: true,
            placeholder: "0.00",
            id: "serviceFee",
            style: themes?.field?.textField,
            observer: componentObserver
        },

        // product ingredients datagrid attributes
        productIngredientDatagridToolbarAttributes: {
            name: productIngredientDatagridToolbarName,
            items: [
                {
                    name: productIngredientDatagridToolbarButtons?.addProductButton,
                    type: TOOLBARITEMTYPE.BUTTON,
                    text: "Add",
                    leading: icons?.AddRoundedIcon,
                    onClick: (e) => handleAddProductIngredientFromMarket(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
                {
                    name: productIngredientDatagridToolbarButtons?.deleteProductButton,
                    type: TOOLBARITEMTYPE.BUTTON,
                    leading: icons?.DeleteRoundedIcon,
                    onClick: (e) => handleDeleteProductIngredient(e),
                    observer: componentObserver,
                    style: themes?.toolbar?.button
                },
            ]
        },

        // 
        productIngredientDatagridAttributes: {
            name: productIngredientDatagridName,
            key: "marketProductInventoryId",
            observer: productIngredientDatagridObserver,
            columns: [
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: "marketProductInventoryId",
                    style: themes?.width30px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.IMAGETHUMBNAIL,
                    value: "media1Url",
                    default: "",
                    style: themes?.width35px,
                    image: {
                        id: "media1Id",
                        key: "media1Id",
                        url: "media1Url",
                        size: themes?.size35px,
                        leading: icons?.BrandingWatermarkRoundedIcon,
                        style: themes?.image,
                        config: {
                            downloadUrl: productImageService?.downloadUrl
                        },
                    }
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Product",
                    value: "productName",
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    title: "Price",
                    value: "priceAlias",
                    default: "",
                    textAlign: ALIGNMENTTYPE.RIGHT,
                    style: themes?.width90px
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.TEXTFIELD,
                    title: "Qty",
                    value: "quantity",
                    default: "",
                    style: themes?.width50px
                }
            ],
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.UNDOCK,
            dataset: productIngredientObserver.current
        },

        // product markets dialog
        productIngredientsMarketsDialogAttributes: {
            name: productIngredientsMarketDialogName,
            data: null,
            observer: catererProductIngredientsMarketDialogObserver,
            widget: CatererPurchaseMarketViewView,
            style: {
                window: "max-width-1100px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                // {
                //     text: "Apply",
                //     style: themes?.button?.secondary,
                //     onClick: (e, winArgs) => {
                //         winArgs.apply(e, async (args) => {
                //             await setCatererProductIngredientsList(args?.data);
                //         });
                //     }
                // },
                {
                    text: "Continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, async (args) => {
                            await setCatererProductIngredientsList(args?.data);
                        });
                    }
                }
            ]
        },

        themes: themes,
    }
}

export default useCreateOrEditCatererHook;