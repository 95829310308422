import React from 'react';
import useCourierDeliveryHistoryHook from './courier.delivery.history.hook';

const CourierDeliveryHistoryView = ({ attributes }) => {

    const hook = useCourierDeliveryHistoryHook(attributes);

    return (
        <div className={"w-100 position-relative p-4 pt-4 pb-5"}>
            <form ref={hook?.reactiveForm}
                method={attributes.method}
                autoComplete={"false"}
                className={"w-100 text-left pt-2"} noValidate>
                <div className={"w-100 height-400px"}>
                    
                </div>
            </form>
        </div >
    );
}

export default CourierDeliveryHistoryView;