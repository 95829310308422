import React from "react";
import { COMPONENTYPE } from "../../enums/enums";

const MultiTextField = ({ attributes, dataObject, onChange }) => {

    const handleOnChange = (e) => {
        if (onChange)
            onChange(e.target);
    }

    return (
        <div className={"d-flex position-relative form-control p-0 " + (attributes?.style?.g + " " + attributes?.style?.bgColor)}>
            <textarea
                aria-controls={attributes?.formElement}
                aria-roledescription={COMPONENTYPE.TEXTFIELD}
                aria-required={(attributes?.validate) ? "true" : "false"}
                aria-errormessage={attributes?.errorMessage}
                onChange={handleOnChange}
                id={attributes?.id}
                placeholder={attributes?.placeholder}
                defaultValue={attributes?.value || ""}
                type={'text'}
                className={"position-absolute top-0px bottom-0px end-0px start-0px w-100 form-control ps-2 text-13px rounded-5px border-0px bg-transparent " + attributes?.style?.text} />
            <div className={"position-absolute top-0px bottom-0px end-0px"}>
            </div>
        </div>
    );
}
export { MultiTextField };