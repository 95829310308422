import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TransactionService } from '../order.transaction.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useForm } from '../../../../../shared/components/form/form.component';
import { useStackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import { useDataGrid } from '../../../../../shared/components/datagrid';
import { useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';


const useTransactionDetailsHook = (attributes) => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [transaction, setTransaction] = useState([]);
    const [queryString, setQueryString] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(35);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRows, setTotalRows] = useState(0);

    const actionButtonsMenuItems = {
        fundTransactionMenuItem: "fund-transaction-menuitem",
        orderToTransactionTransferMenuItem: "transaction-to-transaction-transfer-menuitem",
        bankTransferMenuItem: "bank-transfer-menuitem",
        placeLienOnTransactionMenuItem: "place-lien-on-transaction-menuitem",
        lockTransactionAccountMenuItem: "lock-transaction-account-menuitem",
        sendMessageMenuItem: "send-message-menuitem",
        printAccountStatmentMenuItem: "print-account-statment-menuitem",
        downloadAccountStatmentPdfMenuItem: "download-account-statment-pdf-menuitem",
        shareAccountStatmentPdfMenuItem: "share-account-statment-pdf-menuitem",
    };

    // refs
    const componentObserver = useRef(null);
    const reactiveForm = useRef(null);

    const stackablePanelComponentName = "transaction-stackable-panel";
    const orderOptionToolbarName = "transaction-option-toolbar";
    const orderProductsDatagridName = "transaction-products-datagrid";
    const orderProductsToolbarName = "transaction-products-toolbar";

    // components
    const form = useForm({
        ref: reactiveForm
    });
    const stackablePanelComponent = useStackablePanel({
        name: stackablePanelComponentName
    });

    const orderOptionToolbarButtons = {
        actionsButtonName: "add-market-product-button",
    };
    const orderProductsDatagrid = useDataGrid({
        name: orderProductsDatagridName
    });

    const orderProductsToolbar = useToolbar({
        name: orderProductsToolbarName
    });
    const orderProductsToolbarButtons = {
        previousButtonName: "ops-previous-button",
        pagesLabelName: "ops-pages-info-label",
        nextButtonName: "ops-next-button",
    }

    // callbacks
    const widgetReactiveFormModule = useCallback((args) => {
        const isEmptyField = form.validate(args.data);
        if (isEmptyField)
            return;
        // handleSaveOrUpdate(args);
    }, [form])

    const widgetObserver = useCallback(async (args) => {
        setTransaction(args?.data);

    }, [])

    // services
    const orderService = TransactionService();

    useEffect(() => {
        (async () => {
            // adapter
            if (attributes?.observer)
                attributes.observer.current = await widgetObserver;

            // reactive form module
            if (attributes?.reactiveFormModule)
                attributes.reactiveFormModule.current = await widgetReactiveFormModule;
        })();
    }, []);


    // methods

    const handleTransactionToolbarOptionsMenuItemClick = (args) => {
        switch (args.name) {
            case actionButtonsMenuItems?.lockTransactionAccountMenuItem:
                // updateMarketWithNewProducts();
                break;
            case actionButtonsMenuItems?.placeLienOnTransactionMenuItem:
                // addProductsToMarket();
                break;
            case actionButtonsMenuItems?.bankTransferMenuItem:
                //
                break;
            case actionButtonsMenuItems?.orderToTransactionTransferMenuItem:
                //
                break;
            case actionButtonsMenuItems.fundTransactionMenuItem:
            default:
                //
                break;
        }
    }

    return {
        transaction: transaction,
        componentObserver: componentObserver,
        reactiveForm: reactiveForm,

        sectionHorizontalSeparatorAttributes: {
            style: themes?.horizontalSeparator
        },

        // profile photo
        profilePhotoAttributes: {
            formElement: true,
            id: "media",
            value: transaction?.media,
            placeholder: icons?.PersonIcon,
            style: themes?.avatar280px,
            observer: componentObserver
        },

        // actions toolbar
        actionsMenuButton: {
            name: orderOptionToolbarName,
            items: [
                {
                    name: orderOptionToolbarButtons.actionsButtonName,
                    type: TOOLBARITEMTYPE.DROPDOWNBUTTON,
                    menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                    leading: icons?.MenuRoundedIcon,
                    onClick: (e) => { },
                    observer: componentObserver,
                    style: themes?.toolbar?.button,
                    widget: <MenuList attributes={{
                        items: [
                            {
                                leading: icons?.DataSaverOnIcon,
                                name: actionButtonsMenuItems.fundTransactionMenuItem,
                                title: "Reorder",
                            },
                            {
                                leading: icons?.AccessTimeIcon,
                                name: actionButtonsMenuItems.orderToTransactionTransferMenuItem,
                                title: "Set as recurring ",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.MailIcon,
                                name: actionButtonsMenuItems?.sendMessageMenuItem,
                                title: "Send message",
                            },
                            {
                                type: MENUITEMTYPE.SEPARATOR,
                            },
                            {
                                leading: icons?.LocalPrintshopIcon,
                                name: actionButtonsMenuItems?.printAccountStatmentMenuItem,
                                title: "Print statment",
                            },
                            {
                                leading: icons?.ShareIcon,
                                name: actionButtonsMenuItems?.shareAccountStatmentPdfMenuItem,
                                title: "Share statment as PDF",
                            },
                        ],
                        style: themes?.menuList,
                        onItemClick: handleTransactionToolbarOptionsMenuItemClick,
                        isSelectable: false
                    }} />
                }
            ]
        },

        // status flag
        statusFlagAttributes: {
            value: parseInt((() => transaction?.status)()),
            flags: [
                {
                    title: "Completed",
                    color: "bg-success-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-success-info-border-theme text-success-info-dark-theme",
                    icon: icons?.CheckRoundedIcon
                },
                {
                    title: "Cancelled",
                    color: "bg-danger-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-danger-info-theme text-danger-info-dark-theme",
                    icon: icons?.ClearRoundedIcon
                },
                {
                    title: "Discontinued",
                    color: "bg-disabled-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-disabled-info-theme text-disabled-info-dark-theme",
                    icon: icons?.DoDisturbRoundedIcon
                },
                {
                    title: "Low On Stock",
                    color: "bg-warning-info-theme border border-top-1px border-start-1px border-bottom-1px border-end-1px border-warning-info-theme text-warning-info-dark-theme",
                    icon: icons?.AccessTimeRoundedIcon
                }
            ]
        },

        // 
        shopperPhotoAttributes: {
            formElement: true,
            id: "media",
            value: transaction?.media,
            placeholder: icons?.PersonIcon,
            style: themes?.avatar100px,
            observer: componentObserver
        },

        // 
        marketAddressIconAttributes: {
            formElement: true,
            id: "media",
            value: transaction?.media,
            placeholder: icons?.StorefrontRoundedIcon,
            style: themes?.avatar100px,
            observer: componentObserver
        },

        // 
        dropOffAddressIconAttributes: {
            formElement: true,
            id: "media",
            value: transaction?.media,
            placeholder: icons?.HouseIcon,
            style: themes?.avatar100px,
            observer: componentObserver
        },

        themes: themes,
        icons: icons,

    }
}

export default useTransactionDetailsHook;