import React, { useEffect } from "react";
import { COMPONENTYPE } from "../../enums/enums";

const TextField = ({ attributes }) => {

    useEffect(() => {
        //
    }, [attributes]);

    const handleOnChange = (e) => {
        if (attributes?.onChange)
        attributes?.onChange(e.target);
    }

    const handleOnFocus = (e) => {
        if (attributes?.onFocus)
            attributes?.onFocus(e.target);
    }

    const handleOnLostFocus = (e) => {
        if (attributes?.onLostFocus)
            attributes?.onLostFocus(e.target);
    }

    return (
        <div className={"d-flex align-items-center w-100 position-relative form-control ps-2 " + (attributes?.style?.g + " " + attributes?.style?.bgColor)}>
            <i aria-controls="leading" className={"d-flex justify-content-center align-items-center m-0 me-2 " + ((attributes?.leading) ? "" : "hide ") + (attributes?.text ? "me-2 " : "") + " icon " + attributes?.style?.leading}>
                {attributes?.leading}
            </i>
            <input
                aria-controls={attributes?.formElement}
                aria-roledescription={COMPONENTYPE.TEXTFIELD}
                aria-required={attributes?.validate}
                aria-errormessage={attributes?.errorMessage}
                data-defaultvalue ={attributes?.defaultValue}
                onChange={handleOnChange}
                onBlur={handleOnLostFocus}
                onFocus={handleOnFocus}
                id={attributes?.id}
                placeholder={attributes?.placeholder}
                defaultValue={attributes?.value?.toString() || ""}
                type={'text'}
                className={"w-100 form-control text-13px rounded-3px border-0px p-0 bg-transparent " + attributes?.style?.text} />
            <i aria-controls="trailing" className={"d-flex justify-content-center align-items-center " + ((attributes?.trailing) ? "" : "hide ") + "m-0 ms-1 icon " + attributes?.style?.trailing}>
                {attributes?.trailing}
            </i>
        </div>
    );
}
export { TextField };