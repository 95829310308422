import React from 'react';
import useCatererProductsHook from './caterer.products.hook';
import { StackablePanel } from '../../../../../shared/components/stackable.panel/stackable.panel.component';

const CatererProductsView = ({ attributes }) => {

    const hook = useCatererProductsHook(attributes);

    return (
        <div className={"position-absolute top-0 start-0 end-0 bottom-13px w-100 p-0 flex-column rounded"}>
            <div className={"position-absolute top-0px start-0px end-0px bottom-0px"}>
                <StackablePanel attributes={hook?.stackablePanelAttributes} />
            </div>
        </div>
    );
}

export default CatererProductsView;