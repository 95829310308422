import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded';
import CompareArrowsRoundedIcon from '@mui/icons-material/CompareArrowsRounded';
import PersonalVideoRoundedIcon from '@mui/icons-material/PersonalVideoRounded';
import BrandingWatermarkRoundedIcon from '@mui/icons-material/BrandingWatermarkRounded';
import WebhookRoundedIcon from '@mui/icons-material/WebhookRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import NewspaperRoundedIcon from '@mui/icons-material/NewspaperRounded';
import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import PointOfSaleRoundedIcon from '@mui/icons-material/PointOfSaleRounded';
import PermMediaRoundedIcon from '@mui/icons-material/PermMediaRounded';
import SupportRoundedIcon from '@mui/icons-material/SupportRounded';
import SupervisedUserCircleRoundedIcon from '@mui/icons-material/SupervisedUserCircleRounded';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import AddBusinessRoundedIcon from '@mui/icons-material/AddBusinessRounded';
import AddAPhotoRoundedIcon from '@mui/icons-material/AddAPhotoRounded';
import AddAlarmRoundedIcon from '@mui/icons-material/AddAlarmRounded';
import AddCardRoundedIcon from '@mui/icons-material/AddCardRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import AddCommentRoundedIcon from '@mui/icons-material/AddCommentRounded';
import AddHomeRoundedIcon from '@mui/icons-material/AddHomeRounded';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import AddLocationAltRoundedIcon from '@mui/icons-material/AddLocationAltRounded';
import AddLocationRoundedIcon from '@mui/icons-material/AddLocationRounded';
import AddModeratorRoundedIcon from '@mui/icons-material/AddModeratorRounded';
import AddPhotoAlternateRoundedIcon from '@mui/icons-material/AddPhotoAlternateRounded';
import AddReactionRoundedIcon from '@mui/icons-material/AddReactionRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddShoppingCartRoundedIcon from '@mui/icons-material/AddShoppingCartRounded';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import AddToPhotosRoundedIcon from '@mui/icons-material/AddToPhotosRounded';
import AddToQueueRoundedIcon from '@mui/icons-material/AddToQueueRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import AlarmAddRoundedIcon from '@mui/icons-material/AlarmAddRounded';
import BookmarkAddRoundedIcon from '@mui/icons-material/BookmarkAddRounded';
import BookmarkAddedRoundedIcon from '@mui/icons-material/BookmarkAddedRounded';
import DownloadDoneRoundedIcon from '@mui/icons-material/DownloadDoneRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import LeakAddRoundedIcon from '@mui/icons-material/LeakAddRounded';
import NoteAddRoundedIcon from '@mui/icons-material/NoteAddRounded';
import PaddingRoundedIcon from '@mui/icons-material/PaddingRounded';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import PersonAddAlt1RoundedIcon from '@mui/icons-material/PersonAddAlt1Rounded';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PlaylistAddCircleRoundedIcon from '@mui/icons-material/PlaylistAddCircleRounded';
import PlaylistAddRoundedIcon from '@mui/icons-material/PlaylistAddRounded';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import AlternateEmailRoundedIcon from '@mui/icons-material/AlternateEmailRounded';
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded';
import BatterySaverRoundedIcon from '@mui/icons-material/BatterySaverRounded';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import ControlPointDuplicateRoundedIcon from '@mui/icons-material/ControlPointDuplicateRounded';
import ControlPointRoundedIcon from '@mui/icons-material/ControlPointRounded';
import CreateNewFolderRoundedIcon from '@mui/icons-material/CreateNewFolderRounded';
import DataSaverOnRoundedIcon from '@mui/icons-material/DataSaverOnRounded';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import EnhancedEncryptionRoundedIcon from '@mui/icons-material/EnhancedEncryptionRounded';
import ExposureRoundedIcon from '@mui/icons-material/ExposureRounded';
import ExtensionRoundedIcon from '@mui/icons-material/ExtensionRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import InsertCommentRoundedIcon from '@mui/icons-material/InsertCommentRounded';
import InsertLinkRoundedIcon from '@mui/icons-material/InsertLinkRounded';
import IsoRoundedIcon from '@mui/icons-material/IsoRounded';
import LibraryBooksRoundedIcon from '@mui/icons-material/LibraryBooksRounded';
import LibraryMusicRoundedIcon from '@mui/icons-material/LibraryMusicRounded';
import MarginRoundedIcon from '@mui/icons-material/MarginRounded';
import MoreTimeRoundedIcon from '@mui/icons-material/MoreTimeRounded';
import QueueMusicRoundedIcon from '@mui/icons-material/QueueMusicRounded';
import QueuePlayNextRoundedIcon from '@mui/icons-material/QueuePlayNextRounded';
import QueueRoundedIcon from '@mui/icons-material/QueueRounded';
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import TextIncreaseRoundedIcon from '@mui/icons-material/TextIncreaseRounded';
import VideoCallRoundedIcon from '@mui/icons-material/VideoCallRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import CreditCardOffRoundedIcon from '@mui/icons-material/CreditCardOffRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import EditAttributesRoundedIcon from '@mui/icons-material/EditAttributesRounded';
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded';
import EditLocationAltRoundedIcon from '@mui/icons-material/EditLocationAltRounded';
import EditLocationRoundedIcon from '@mui/icons-material/EditLocationRounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import EditNotificationsRoundedIcon from '@mui/icons-material/EditNotificationsRounded';
import EditOffRoundedIcon from '@mui/icons-material/EditOffRounded';
import EditRoadRoundedIcon from '@mui/icons-material/EditRoadRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import ModeEditOutlineRoundedIcon from '@mui/icons-material/ModeEditOutlineRounded';
import ModeEditRoundedIcon from '@mui/icons-material/ModeEditRounded';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import AutoFixNormalRoundedIcon from '@mui/icons-material/AutoFixNormalRounded';
import AutoFixOffRoundedIcon from '@mui/icons-material/AutoFixOffRounded';
import CompareRoundedIcon from '@mui/icons-material/CompareRounded';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import FilterRoundedIcon from '@mui/icons-material/FilterRounded';
import FilterVintageRoundedIcon from '@mui/icons-material/FilterVintageRounded';
import FlareRoundedIcon from '@mui/icons-material/FlareRounded';
import FlipCameraAndroidRoundedIcon from '@mui/icons-material/FlipCameraAndroidRounded';
import FlipRoundedIcon from '@mui/icons-material/FlipRounded';
import LineWeightRoundedIcon from '@mui/icons-material/LineWeightRounded';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
import AutoAwesomeMosaicRoundedIcon from '@mui/icons-material/AutoAwesomeMosaicRounded';
import AutoAwesomeMotionRoundedIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import BackspaceRoundedIcon from '@mui/icons-material/BackspaceRounded';
import BookmarkRemoveRoundedIcon from '@mui/icons-material/BookmarkRemoveRounded';
import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import CancelScheduleSendRoundedIcon from '@mui/icons-material/CancelScheduleSendRounded';
import ClearAllRoundedIcon from '@mui/icons-material/ClearAllRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DeleteSweepRoundedIcon from '@mui/icons-material/DeleteSweepRounded';
import DehazeRoundedIcon from '@mui/icons-material/DehazeRounded';
import DisabledByDefaultRoundedIcon from '@mui/icons-material/DisabledByDefaultRounded';
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded';
import DoDisturbRoundedIcon from '@mui/icons-material/DoDisturbRounded';
import DoDisturbOffRoundedIcon from '@mui/icons-material/DoDisturbOffRounded';
import DoDisturbOnRoundedIcon from '@mui/icons-material/DoDisturbOnRounded';
import DoNotDisturbOnRoundedIcon from '@mui/icons-material/DoNotDisturbOnRounded';
import DoNotDisturbOffRoundedIcon from '@mui/icons-material/DoNotDisturbOffRounded';
import DoNotDisturbAltRoundedIcon from '@mui/icons-material/DoNotDisturbAltRounded';
import DoNotDisturbRoundedIcon from '@mui/icons-material/DoNotDisturbRounded';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import FolderDeleteRoundedIcon from '@mui/icons-material/FolderDeleteRounded';
import GppBadRoundedIcon from '@mui/icons-material/GppBadRounded';
import GroupRemoveRoundedIcon from '@mui/icons-material/GroupRemoveRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import LayersClearRoundedIcon from '@mui/icons-material/LayersClearRounded';
import LeakRemoveRoundedIcon from '@mui/icons-material/LeakRemoveRounded';
import PersonRemoveRoundedIcon from '@mui/icons-material/PersonRemoveRounded';
import PersonRemoveAlt1RoundedIcon from '@mui/icons-material/PersonRemoveAlt1Rounded';
import PlaylistRemoveRoundedIcon from '@mui/icons-material/PlaylistRemoveRounded';
import PhonelinkEraseRoundedIcon from '@mui/icons-material/PhonelinkEraseRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import RemoveDoneRoundedIcon from '@mui/icons-material/RemoveDoneRounded';
import RemoveFromQueueRoundedIcon from '@mui/icons-material/RemoveFromQueueRounded';
import RemoveModeratorRoundedIcon from '@mui/icons-material/RemoveModeratorRounded';
import RemoveRedEyeRoundedIcon from '@mui/icons-material/RemoveRedEyeRounded';
import RemoveRoadRoundedIcon from '@mui/icons-material/RemoveRoadRounded';
import RemoveShoppingCartRoundedIcon from '@mui/icons-material/RemoveShoppingCartRounded';
import RestoreFromTrashRoundedIcon from '@mui/icons-material/RestoreFromTrashRounded';
import RuleFolderRoundedIcon from '@mui/icons-material/RuleFolderRounded';
import SignalWifiBadRoundedIcon from '@mui/icons-material/SignalWifiBadRounded';
import TextDecreaseRoundedIcon from '@mui/icons-material/TextDecreaseRounded';
import UnsubscribeRoundedIcon from '@mui/icons-material/UnsubscribeRounded';
import WrongLocationRoundedIcon from '@mui/icons-material/WrongLocationRounded';

import StoreMallDirectoryRoundedIcon from '@mui/icons-material/StoreMallDirectoryRounded';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import LocalMallRoundedIcon from '@mui/icons-material/LocalMallRounded';

import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import RotateLeftRoundedIcon from '@mui/icons-material/RotateLeftRounded';

import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import WestRoundedIcon from '@mui/icons-material/WestRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import FirstPageRoundedIcon from '@mui/icons-material/FirstPageRounded';

import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import LastPageRoundedIcon from '@mui/icons-material/LastPageRounded';

import ControlCameraRoundedIcon from '@mui/icons-material/ControlCameraRounded';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';

import ReplyAllRoundedIcon from '@mui/icons-material/ReplyAllRounded';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';

import ArrowCircleUpRoundedIcon from '@mui/icons-material/ArrowCircleUpRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import BackupRoundedIcon from '@mui/icons-material/BackupRounded';
import CloudUploadRoundedIcon from '@mui/icons-material/CloudUploadRounded';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import FileUploadRoundedIcon from '@mui/icons-material/FileUploadRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import NorthRoundedIcon from '@mui/icons-material/NorthRounded';

import ArrowCircleDownRoundedIcon from '@mui/icons-material/ArrowCircleDownRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import CloudDownloadRoundedIcon from '@mui/icons-material/CloudDownloadRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';
import SouthRoundedIcon from '@mui/icons-material/SouthRounded';

import PublishRoundedIcon from '@mui/icons-material/PublishRounded';
import PublishedWithChangesRoundedIcon from '@mui/icons-material/PublishedWithChangesRounded';
// import UnpublishedRoundedIcon from '@mui/icons-material/UnpublishedRounded';

import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

import ArchiveRoundedIcon from '@mui/icons-material/ArchiveRounded';
import UnarchiveRoundedIcon from '@mui/icons-material/UnarchiveRounded';

import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';

import FolderZipRoundedIcon from '@mui/icons-material/FolderZipRounded';

import LockRoundedIcon from '@mui/icons-material/LockRounded';

import VpnLockRoundedIcon from '@mui/icons-material/VpnLockRounded';
// import TravelExploreRoundedIcon from '@mui/icons-material/TravelExploreRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';

import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';

// wysiwyg
import FormatBoldRoundedIcon from '@mui/icons-material/FormatBoldRounded';
import FormatItalicRoundedIcon from '@mui/icons-material/FormatItalicRounded';
import FormatUnderlinedRoundedIcon from '@mui/icons-material/FormatUnderlinedRounded';
import FormatColorTextRoundedIcon from '@mui/icons-material/FormatColorTextRounded';
import ColorLensRoundedIcon from '@mui/icons-material/ColorLensRounded';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';

// 
import DeliveryDiningRoundedIcon from '@mui/icons-material/DeliveryDiningRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

import CropFreeRoundedIcon from '@mui/icons-material/CropFreeRounded';
import QrCode2RoundedIcon from '@mui/icons-material/QrCode2Rounded';
import QrCodeRoundedIcon from '@mui/icons-material/QrCodeRounded';
import QrCodeScannerRoundedIcon from '@mui/icons-material/QrCodeScannerRounded';

import VideoFileRoundedIcon from '@mui/icons-material/VideoFileRounded';
import MovieCreationRoundedIcon from '@mui/icons-material/MovieCreationRounded';
import BurstModeRoundedIcon from '@mui/icons-material/BurstModeRounded';
import SlowMotionVideoRoundedIcon from '@mui/icons-material/SlowMotionVideoRounded';

import WalletRoundedIcon from '@mui/icons-material/WalletRounded';
import LoyaltyRoundedIcon from '@mui/icons-material/LoyaltyRounded';
import ReceiptRoundedIcon from '@mui/icons-material/ReceiptRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import SavingsRoundedIcon from '@mui/icons-material/SavingsRounded';
import TollRoundedIcon from '@mui/icons-material/TollRounded';

import SubtitlesRoundedIcon from '@mui/icons-material/SubtitlesRounded';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InsertPhotoRoundedIcon from '@mui/icons-material/InsertPhotoRounded';

import FormatIndentDecreaseRoundedIcon from '@mui/icons-material/FormatIndentDecreaseRounded';
import FormatIndentIncreaseRoundedIcon from '@mui/icons-material/FormatIndentIncreaseRounded';
import GridOnRoundedIcon from '@mui/icons-material/GridOnRounded';

import PhotoSizeSelectActualRoundedIcon from '@mui/icons-material/PhotoSizeSelectActualRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';

import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import ScaleIcon from '@mui/icons-material/Scale';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import OutdoorGrillIcon from '@mui/icons-material/OutdoorGrill';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import SoupKitchenIcon from '@mui/icons-material/SoupKitchen';
import TaskIcon from '@mui/icons-material/Task';

import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import AirlineSeatReclineNormalIcon from '@mui/icons-material/AirlineSeatReclineNormal';
import GroupIcon from '@mui/icons-material/Group';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import RouteIcon from '@mui/icons-material/Route';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import StyleIcon from '@mui/icons-material/Style';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import TimelineIcon from '@mui/icons-material/Timeline';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import VerifiedIcon from '@mui/icons-material/Verified';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import PanToolIcon from '@mui/icons-material/PanTool';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import ShareIcon from '@mui/icons-material/Share';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import Groups2Icon from '@mui/icons-material/Groups2';
import MailIcon from '@mui/icons-material/Mail';
import TagIcon from '@mui/icons-material/Tag';
import FlagIcon from '@mui/icons-material/Flag';
import HouseIcon from '@mui/icons-material/House';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreHoriz';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import CollectionsIcon from '@mui/icons-material/Collections';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import AppsIcon from '@mui/icons-material/Apps';
import DownloadingIcon from '@mui/icons-material/Downloading';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import PaymentsIcon from '@mui/icons-material/Payments';

import PowerOffIcon from '@mui/icons-material/PowerOff';
import PowerIcon from '@mui/icons-material/Power';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';

import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowLeftAltIcon from '@mui/icons-material/ArrowLeft';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import PostAddIcon from '@mui/icons-material/PostAdd';
import NoteIcon from '@mui/icons-material/Note';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ArticleIcon from '@mui/icons-material/Article';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import LayersIcon from '@mui/icons-material/Layers';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import DangerousRoundedIcon from '@mui/icons-material/DangerousRounded';

import FolderIcon from '@mui/icons-material/Folder';
import ViewListIcon from '@mui/icons-material/ViewList';

const useIconsThemes = () => {

    // theme
    const roundedIcons = {

        FolderIcon: <FolderIcon />,
        ViewListIcon: <ViewListIcon />,
        
        WarningRoundedIcon: <WarningRoundedIcon />,
        DangerousRoundedIcon: <DangerousRoundedIcon />,
        ErrorRoundedIcon: <ErrorRoundedIcon />,
        InfoRoundedIcon: <InfoRoundedIcon />,
        LayersClearIcon: <LayersClearIcon />,
        LayersIcon: <LayersIcon />,
        MenuBookIcon: <MenuBookIcon />,
        DocumentScannerIcon: <DocumentScannerIcon />,
        ArticleIcon: <ArticleIcon />,
        FindInPageIcon: <FindInPageIcon />,
        NoteAddIcon: <NoteAddIcon />,
        NoteIcon: <NoteIcon />,
        PostAddIcon: <PostAddIcon />,
        FileOpenIcon: <FileOpenIcon />,
        AutoStoriesIcon: <AutoStoriesIcon />,
        ArrowLeftAltIcon: <ArrowLeftAltIcon />,
        ArrowRightAltIcon: <ArrowRightAltIcon />,
        PhotoSizeSelectActualIcon: <PhotoSizeSelectActualIcon />,
        PowerOffIcon: <PowerOffIcon />,
        PowerIcon: <PowerIcon />,
        PaymentsIcon: <PaymentsIcon />,
        DownloadingIcon: <DownloadingIcon />,
        PublishedWithChangesIcon: <PublishedWithChangesIcon />,
        AppsIcon: <AppsIcon />,
        FiberNewIcon: <FiberNewIcon />,
        ViewCarouselIcon: <ViewCarouselIcon />,
        CategoryCardsRoundedIcon: <DashboardIcon />,
        DashboardIcon: <DashboardIcon />,
        ModelTrainingIcon: <ModelTrainingIcon />,
        PlaylistAddCircleIcon: <PlaylistAddCircleIcon />,
        PlaylistAddCheckCircleIcon: <PlaylistAddCheckCircleIcon />,
        FastfoodIcon: <FastfoodIcon />,
        TipsAndUpdatesIcon: <TipsAndUpdatesIcon />,
        LunchDiningIcon: <LunchDiningIcon />,
        CollectionsIcon: <CollectionsIcon />,
        FormatShapesIcon: <FormatShapesIcon />,
        MoreHorizIcon: <MoreHorizIcon />,
        MoreVertIcon: <MoreVertIcon />,
        AccessTimeIcon: <AccessTimeIcon />,
        HouseIcon: <HouseIcon />,
        FlagIcon: <FlagIcon />,
        TagIcon: <TagIcon />,
        MailIcon: <MailIcon />,
        Groups2Icon: <Groups2Icon />,
        Diversity2Icon: <Diversity2Icon />,
        DataSaverOnIcon: <DataSaverOnIcon />,
        ShareIcon: <ShareIcon />,
        LocalPrintshopIcon: <LocalPrintshopIcon />,
        PanToolIcon: <PanToolIcon />,
        MoveDownIcon: <MoveDownIcon />,
        MoveUpIcon: <MoveUpIcon />,
        TransferWithinAStationIcon: <TransferWithinAStationIcon />,
        AccountBalanceWalletIcon: <AccountBalanceWalletIcon />,
        BadgeIcon: <BadgeIcon />,
        VerifiedIcon: <VerifiedIcon />,
        LocalPhoneIcon: <LocalPhoneIcon />,
        LocationOnIcon: <LocationOnIcon />,
        PersonIcon: <PersonIcon />,
        AccountCircleIcon: <AccountCircleIcon />,
        AccountTreeIcon: <AccountTreeIcon />,
        AdminPanelSettingsIcon: <AdminPanelSettingsIcon />,
        NewReleasesIcon: <NewReleasesIcon />,
        ThumbUpAltIcon: <ThumbUpAltIcon />,
        ThumbDownAltIcon: <ThumbDownAltIcon />,
        TimelineIcon: < TimelineIcon />,
        WorkHistoryIcon: <WorkHistoryIcon />,
        StyleIcon: <StyleIcon />,
        CreditCardIcon: <CreditCardIcon />,
        LocalMallIcon: <LocalMallIcon />,
        RouteIcon: <RouteIcon />,
        DirectionsBikeIcon: <DirectionsBikeIcon />,
        GroupIcon: <GroupIcon />,
        AirlineSeatReclineNormalIcon: <AirlineSeatReclineNormalIcon />,
        ShareLocationIcon: <ShareLocationIcon />,
        LocalPharmacyIcon: <LocalPharmacyIcon />,
        TaskIcon: <TaskIcon />,
        SoupKitchenIcon: <SoupKitchenIcon />,
        VolunteerActivismIcon: <VolunteerActivismIcon />,
        OutdoorGrillIcon: <OutdoorGrillIcon />,
        RestaurantIcon: <RestaurantIcon />,
        CompareArrowsIcon: <CompareArrowsIcon />,
        ScaleIcon: <ScaleIcon />,
        AccessTimeRoundedIcon: <AccessTimeRoundedIcon />,
        CheckRoundedIcon: <CheckRoundedIcon />,
        GridOnRoundedIcon: <GridOnRoundedIcon />,
        PhotoSizeSelectActualRoundedIcon: <PhotoSizeSelectActualRoundedIcon />,
        WorkspacePremiumRoundedIcon: <WorkspacePremiumRoundedIcon />,

        // 
        FormatIndentDecreaseRoundedIcon: <FormatIndentDecreaseRoundedIcon />,
        FormatIndentIncreaseRoundedIcon: <FormatIndentIncreaseRoundedIcon />,

        // 
        InsertPhotoRoundedIcon: <InsertPhotoRoundedIcon />,
        CloseRoundedIcon: <CloseRoundedIcon />,
        SubtitlesRoundedIcon: <SubtitlesRoundedIcon />,

        // 
        WalletRoundedIcon: <WalletRoundedIcon />,
        LoyaltyRoundedIcon: <LoyaltyRoundedIcon />,
        ReceiptRoundedIcon: <ReceiptRoundedIcon />,
        ReceiptLongRoundedIcon: <ReceiptLongRoundedIcon />,
        AccountBalanceRoundedIcon: <AccountBalanceRoundedIcon />,
        SavingsRoundedIcon: <SavingsRoundedIcon />,
        TollRoundedIcon: <TollRoundedIcon />,

        // media 
        VideoFileRoundedIcon: <VideoFileRoundedIcon />,
        MovieCreationRoundedIcon: <MovieCreationRoundedIcon />,
        BurstModeRoundedIcon: <BurstModeRoundedIcon />,
        SlowMotionVideoRoundedIcon: <SlowMotionVideoRoundedIcon />,

        // qr code
        CropFreeRoundedIcon: <CropFreeRoundedIcon />,
        QrCode2RoundedIcon: <QrCode2RoundedIcon />,
        QrCodeRoundedIcon: <QrCodeRoundedIcon />,
        QrCodeScannerRoundedIcon: <QrCodeScannerRoundedIcon />,

        // expand and collapse
        ExpandLessRoundedIcon: <ExpandLessRoundedIcon />,
        ExpandMoreRoundedIcon: <ExpandMoreRoundedIcon />,

        // 
        DeliveryDiningRoundedIcon: <DeliveryDiningRoundedIcon />,
        PeopleRoundedIcon: <PeopleRoundedIcon />,
        LocalOfferRoundedIcon: <LocalOfferRoundedIcon />,

        // wysiwyg
        FormatBoldRoundedIcon: <FormatBoldRoundedIcon />,
        FormatItalicRoundedIcon: <FormatItalicRoundedIcon />,
        FormatUnderlinedRoundedIcon: <FormatUnderlinedRoundedIcon />,
        FormatColorTextRoundedIcon: <FormatColorTextRoundedIcon />,
        ColorLensRoundedIcon: <ColorLensRoundedIcon />,
        FormatListBulletedRoundedIcon: <FormatListBulletedRoundedIcon />,
        FormatListNumberedRoundedIcon: <FormatListNumberedRoundedIcon />,

        // 
        VpnLockRoundedIcon: <VpnLockRoundedIcon />,

        // lock rounded
        LockRoundedIcon: <LockRoundedIcon />,

        // globe
        LanguageRoundedIcon: <LanguageRoundedIcon />,

        // search
        SearchRoundedIcon: <SearchRoundedIcon />,

        // archive
        ArchiveRoundedIcon: <ArchiveRoundedIcon />,
        UnarchiveRoundedIcon: <UnarchiveRoundedIcon />,
        FolderZipRoundedIcon: <FolderZipRoundedIcon />,

        // menu
        MenuRoundedIcon: <MenuRoundedIcon />,

        // options
        MoreVertRoundedIcon: <MoreVertRoundedIcon />,

        // published & unpublished
        PublishRoundedIcon: <PublicRoundedIcon />,
        PublishedRoundedIcon: <PublishRoundedIcon />,
        PublishedWithChangesRoundedIcon: <PublishedWithChangesRoundedIcon />,
        UnpublishedRoundedIcon: <VpnLockRoundedIcon />,

        // down arrow
        ArrowCircleDownRoundedIcon: <ArrowCircleDownRoundedIcon />,
        ArrowDropDownRoundedIcon: <ArrowDropDownRoundedIcon />,
        ArrowDownwardRoundedIcon: <ArrowDownwardRoundedIcon />,
        DownloadRoundedIcon: <DownloadRoundedIcon />,
        CloudDownloadRoundedIcon: <CloudDownloadRoundedIcon />,
        FileDownloadRoundedIcon: <FileDownloadRoundedIcon />,
        KeyboardArrowDownRoundedIcon: <KeyboardArrowDownRoundedIcon />,
        KeyboardDoubleArrowDownRoundedIcon: <KeyboardDoubleArrowDownRoundedIcon />,
        SouthRoundedIcon: <SouthRoundedIcon />,

        // up arrow
        ArrowCircleUpRoundedIcon: <ArrowCircleUpRoundedIcon />,
        ArrowDropUpRoundedIcon: <ArrowDropUpRoundedIcon />,
        ArrowUpwardRoundedIcon: <ArrowUpwardRoundedIcon />,
        BackupRoundedIcon: <BackupRoundedIcon />,
        CloudUploadRoundedIcon: <CloudUploadRoundedIcon />,
        DriveFolderUploadRoundedIcon: <DriveFolderUploadRoundedIcon />,
        FileUploadRoundedIcon: <FileUploadRoundedIcon />,
        KeyboardArrowUpRoundedIcon: <KeyboardArrowUpRoundedIcon />,
        KeyboardDoubleArrowUpRoundedIcon: <KeyboardDoubleArrowUpRoundedIcon />,
        NorthRoundedIcon: <NorthRoundedIcon />,

        // right arrows
        ArrowRightRoundedIcon: <ArrowRightRoundedIcon />,
        ChevronRightRoundedIcon: <ChevronRightRoundedIcon />,
        KeyboardArrowRightRoundedIcon: <KeyboardArrowRightRoundedIcon />,
        KeyboardDoubleArrowRightRoundedIcon: <KeyboardDoubleArrowRightRoundedIcon />,
        NavigateNextRoundedIcon: <NavigateNextRoundedIcon />,
        EastRoundedIcon: <EastRoundedIcon />,
        ArrowForwardIosRoundedIcon: <ArrowForwardIosRoundedIcon />,
        ArrowForwardRoundedIcon: <ArrowForwardRoundedIcon />,
        LastPageRoundedIcon: <LastPageRoundedIcon />,

        // left arrows
        ArrowLeftRoundedIcon: <ArrowLeftRoundedIcon />,
        ChevronLeftRoundedIcon: <ChevronLeftRoundedIcon />,
        KeyboardArrowLeftRoundedIcon: <KeyboardArrowLeftRoundedIcon />,
        KeyboardDoubleArrowLeftRoundedIcon: <KeyboardDoubleArrowLeftRoundedIcon />,
        KeyboardBackspaceRoundedIcon: <KeyboardBackspaceRoundedIcon />,
        WestRoundedIcon: <WestRoundedIcon />,
        ArrowBackIosRoundedIcon: <ArrowBackIosRoundedIcon />,
        ArrowBackRoundedIcon: <ArrowBackRoundedIcon />,
        FirstPageRoundedIcon: <FirstPageRoundedIcon />,

        // login
        LoginRoundedIcon: <LoginRoundedIcon />,

        // reply
        ReplyAllRoundedIcon: <ReplyAllRoundedIcon />,
        ReplyRoundedIcon: <ReplyRoundedIcon />,

        // camera
        ControlCameraRoundedIcon: <ControlCameraRoundedIcon />,

        // store & shop
        StoreMallDirectoryRoundedIcon: <StoreMallDirectoryRoundedIcon />,
        StorefrontRoundedIcon: <StorefrontRoundedIcon />,
        InventoryRoundedIcon: <InventoryRoundedIcon />,
        LocalMallRoundedIcon: <LocalMallRoundedIcon />,
        ShoppingBasketRoundedIcon: <ShoppingBasketRoundedIcon />,
        ShoppingCartRoundedIcon: <ShoppingCartRoundedIcon />,

        // groups
        GroupAddRoundedIcon: <GroupAddRoundedIcon />,

        // 
        DashboardCustomizeRoundedIcon: <DashboardCustomizeRoundedIcon />,
        Inventory2RoundedIcon: <Inventory2RoundedIcon />,
        CompareArrowsRoundedIcon: <CompareArrowsRoundedIcon />,
        PersonalVideoRoundedIcon: <PersonalVideoRoundedIcon />,
        BrandingWatermarkRoundedIcon: <BrandingWatermarkRoundedIcon />,
        WebhookRoundedIcon: <WebhookRoundedIcon />,
        DesignServicesRoundedIcon: <DesignServicesRoundedIcon />,
        NewspaperRoundedIcon: <NewspaperRoundedIcon />,
        ArticleRoundedIcon: <ArticleRoundedIcon />,
        PointOfSaleRoundedIcon: <PointOfSaleRoundedIcon />,
        PermMediaRoundedIcon: <PermMediaRoundedIcon />,
        SupportRoundedIcon: <SupportRoundedIcon />,
        SupervisedUserCircleRoundedIcon: <SupervisedUserCircleRoundedIcon />,
        AssessmentRoundedIcon: <AssessmentRoundedIcon />,
        SettingsRoundedIcon: <SettingsRoundedIcon />,

        AlarmAddRoundedIcon: <AlarmAddRoundedIcon />,
        BookmarkAddRoundedIcon: <BookmarkAddRoundedIcon />,
        BookmarkAddedRoundedIcon: <BookmarkAddedRoundedIcon />,
        DownloadDoneRoundedIcon: <DownloadDoneRoundedIcon />,
        LeakAddRoundedIcon: <LeakAddRoundedIcon />,
        NoteAddRoundedIcon: <NoteAddRoundedIcon />,
        PaddingRoundedIcon: <PaddingRoundedIcon />,
        PersonAddRoundedIcon: <PersonAddRoundedIcon />,
        PersonAddAlt1RoundedIcon: <PersonAddAlt1RoundedIcon />,

        // playlist
        PlaylistAddCheckCircleRoundedIcon: <PlaylistAddCheckCircleRoundedIcon />,
        PlaylistAddCheckRoundedIcon: <PlaylistAddCheckRoundedIcon />,
        PlaylistAddCircleRoundedIcon: <PlaylistAddCircleRoundedIcon />,
        PlaylistAddRoundedIcon: <PlaylistAddRoundedIcon />,
        PlaylistRemoveRoundedIcon: <PlaylistRemoveRoundedIcon />,

        // post
        PostAddRoundedIcon: <PostAddRoundedIcon />,

        AlternateEmailRoundedIcon: <AlternateEmailRoundedIcon />,
        AttachFileRoundedIcon: <AttachFileRoundedIcon />,
        BatterySaverRoundedIcon: <BatterySaverRoundedIcon />,

        // contact
        ContactMailRoundedIcon: <ContactMailRoundedIcon />,
        ContactsRoundedIcon: <ContactsRoundedIcon />,

        ControlPointDuplicateRoundedIcon: <ControlPointDuplicateRoundedIcon />,
        ControlPointRoundedIcon: <ControlPointRoundedIcon />,

        DataSaverOnRoundedIcon: <DataSaverOnRoundedIcon />,
        DnsRoundedIcon: <DnsRoundedIcon />,
        EnhancedEncryptionRoundedIcon: <EnhancedEncryptionRoundedIcon />,
        ExposureRoundedIcon: <ExposureRoundedIcon />,
        ExtensionRoundedIcon: <ExtensionRoundedIcon />,
        HealthAndSafetyRoundedIcon: <HealthAndSafetyRoundedIcon />,
        HomeRoundedIcon: <HomeRoundedIcon />,
        ImportContactsRoundedIcon: <ImportContactsRoundedIcon />,
        InsertCommentRoundedIcon: <InsertCommentRoundedIcon />,
        InsertLinkRoundedIcon: <InsertLinkRoundedIcon />,
        IsoRoundedIcon: <IsoRoundedIcon />,
        LibraryBooksRoundedIcon: <LibraryBooksRoundedIcon />,
        LibraryMusicRoundedIcon: <LibraryMusicRoundedIcon />,
        MarginRoundedIcon: <MarginRoundedIcon />,
        MoreTimeRoundedIcon: <MoreTimeRoundedIcon />,
        QueueMusicRoundedIcon: <QueueMusicRoundedIcon />,
        QueuePlayNextRoundedIcon: <QueuePlayNextRoundedIcon />,
        QueueRoundedIcon: <QueueRoundedIcon />,

        // phone/video call
        VideoCallRoundedIcon: <VideoCallRoundedIcon />,

        CachedRoundedIcon: <CachedRoundedIcon />,
        ReplayRoundedIcon: <ReplayRoundedIcon />,
        RotateLeftRoundedIcon: <RotateLeftRoundedIcon />,

        // 
        AppRegistrationRoundedIcon: <AppRegistrationRoundedIcon />,
        AutoAwesomeMosaicRoundedIcon: <AutoAwesomeMosaicRoundedIcon />,
        AutoAwesomeMotionRoundedIcon: <AutoAwesomeMotionRoundedIcon />,
        AutoAwesomeRoundedIcon: <AutoAwesomeRoundedIcon />,

        AutoFixHighRoundedIcon: <AutoFixHighRoundedIcon />,
        AutoFixNormalRoundedIcon: <AutoFixNormalRoundedIcon />,
        AutoFixOffRoundedIcon: <AutoFixOffRoundedIcon />,

        // compare
        CompareRoundedIcon: <CompareRoundedIcon />,

        CategoryRoundedIcon: <CategoryRoundedIcon />,

        // filter, find & search
        FilterAltRoundedIcon: <FilterAltRoundedIcon />,
        FilterRoundedIcon: <FilterRoundedIcon />,
        FilterVintageRoundedIcon: <FilterVintageRoundedIcon />,

        // flip
        FlipCameraAndroidRoundedIcon: <FlipCameraAndroidRoundedIcon />,
        FlipRoundedIcon: <FlipRoundedIcon />,

        FlareRoundedIcon: <FlareRoundedIcon />,
        LineWeightRoundedIcon: <LineWeightRoundedIcon />,
        ListRoundedIcon: <ListRoundedIcon />,
        ModeEditOutlineRoundedIcon: <ModeEditOutlineRoundedIcon />,
        ModeEditRoundedIcon: <ModeEditRoundedIcon />,
        StraightenRoundedIcon: <StraightenRoundedIcon />,
        LayersRoundedIcon: <LayersRoundedIcon />,

        // add
        AddBusinessRoundedIcon: <AddBusinessRoundedIcon />,
        AddAPhotoRoundedIcon: <AddAPhotoRoundedIcon />,
        AddAlarmRoundedIcon: <AddAlarmRoundedIcon />,
        AddCardRoundedIcon: <AddCardRoundedIcon />,
        AddCircleOutlineRoundedIcon: <AddCircleOutlineRoundedIcon />,
        AddCircleRoundedIcon: <AddCircleRoundedIcon />,
        AddCommentRoundedIcon: <AddCommentRoundedIcon />,
        AddHomeRoundedIcon: <AddHomeRoundedIcon />,
        AddLinkRoundedIcon: <AddLinkRoundedIcon />,
        AddLocationAltRoundedIcon: <AddLocationAltRoundedIcon />,
        AddLocationRoundedIcon: <AddLocationRoundedIcon />,
        AddModeratorRoundedIcon: <AddModeratorRoundedIcon />,
        AddPhotoAlternateRoundedIcon: <AddPhotoAlternateRoundedIcon />,
        AddReactionRoundedIcon: <AddReactionRoundedIcon />,
        AddRoundedIcon: <AddRoundedIcon />,
        AddShoppingCartRoundedIcon: <AddShoppingCartRoundedIcon />,
        AddTaskRoundedIcon: <AddTaskRoundedIcon />,
        AddToPhotosRoundedIcon: <AddToPhotosRoundedIcon />,
        AddToQueueRoundedIcon: <AddToQueueRoundedIcon />,
        AddchartRoundedIcon: <AddchartRoundedIcon />,
        CreateNewFolderRoundedIcon: <CreateNewFolderRoundedIcon />,

        // edit
        EditAttributesRoundedIcon: <EditAttributesRoundedIcon />,
        EditCalendarRoundedIcon: <EditCalendarRoundedIcon />,
        EditLocationAltRoundedIcon: <EditLocationAltRoundedIcon />,
        EditLocationRoundedIcon: <EditLocationRoundedIcon />,
        EditNoteRoundedIcon: <EditNoteRoundedIcon />,
        EditNotificationsRoundedIcon: <EditNotificationsRoundedIcon />,
        EditOffRoundedIcon: <EditOffRoundedIcon />,
        EditRoadRoundedIcon: <EditRoadRoundedIcon />,
        EditRoundedIcon: <EditRoundedIcon />,

        // delete, backspace, block
        DeleteForeverRoundedIcon: <DeleteForeverRoundedIcon />,
        DeleteOutlineRoundedIcon: <DeleteOutlineRoundedIcon />,
        DeleteRoundedIcon: <DeleteRoundedIcon />,
        DeleteSweepRoundedIcon: <DeleteSweepRoundedIcon />,

        AutoDeleteRoundedIcon: <AutoDeleteRoundedIcon />,
        BackspaceRoundedIcon: <BackspaceRoundedIcon />,
        BookmarkRemoveRoundedIcon: <BookmarkRemoveRoundedIcon />,
        BlockRoundedIcon: <BlockRoundedIcon />,

        // cancel
        CancelPresentationRoundedIcon: <CancelPresentationRoundedIcon />,
        CancelScheduleSendRoundedIcon: <CancelScheduleSendRoundedIcon />,
        ClearAllRoundedIcon: <ClearAllRoundedIcon />,
        ClearRoundedIcon: <ClearRoundedIcon />,

        DehazeRoundedIcon: <DehazeRoundedIcon />,
        DisabledByDefaultRoundedIcon: <DisabledByDefaultRoundedIcon />,

        // do not disturb
        DoDisturbAltRoundedIcon: <DoDisturbAltRoundedIcon />,
        DoDisturbRoundedIcon: < DoDisturbRoundedIcon />,
        DoDisturbOffRoundedIcon: <DoDisturbOffRoundedIcon />,
        DoDisturbOnRoundedIcon: <DoDisturbOnRoundedIcon />,
        DoNotDisturbOnRoundedIcon: <DoNotDisturbOnRoundedIcon />,
        DoNotDisturbOffRoundedIcon: <DoNotDisturbOffRoundedIcon />,
        DoNotDisturbAltRoundedIcon: <DoNotDisturbAltRoundedIcon />,
        DoNotDisturbRoundedIcon: <DoNotDisturbRoundedIcon />,

        EventBusyRoundedIcon: <EventBusyRoundedIcon />,
        FolderDeleteRoundedIcon: <FolderDeleteRoundedIcon />,
        GppBadRoundedIcon: <GppBadRoundedIcon />,
        HighlightOffRoundedIcon: <HighlightOffRoundedIcon />,
        LayersClearRoundedIcon: <LayersClearRoundedIcon />,
        LeakRemoveRoundedIcon: <LeakRemoveRoundedIcon />,


        PhonelinkEraseRoundedIcon: <PhonelinkEraseRoundedIcon />,

        // remove
        GroupRemoveRoundedIcon: <GroupRemoveRoundedIcon />,
        PersonRemoveRoundedIcon: <PersonRemoveRoundedIcon />,
        PersonRemoveAlt1RoundedIcon: <PersonRemoveAlt1RoundedIcon />,
        RemoveRoundedIcon: <RemoveRoundedIcon />,
        RemoveCircleOutlineRoundedIcon: <RemoveCircleOutlineRoundedIcon />,
        RemoveCircleRoundedIcon: <RemoveCircleRoundedIcon />,
        RemoveDoneRoundedIcon: <RemoveDoneRoundedIcon />,
        RemoveFromQueueRoundedIcon: <RemoveFromQueueRoundedIcon />,
        RemoveModeratorRoundedIcon: <RemoveModeratorRoundedIcon />,
        RemoveRedEyeRoundedIcon: <RemoveRedEyeRoundedIcon />,
        RemoveRoadRoundedIcon: <RemoveRoadRoundedIcon />,
        RemoveShoppingCartRoundedIcon: <RemoveShoppingCartRoundedIcon />,

        // restore
        RestoreFromTrashRoundedIcon: <RestoreFromTrashRoundedIcon />,

        // 
        RuleFolderRoundedIcon: <RuleFolderRoundedIcon />,
        SignalWifiBadRoundedIcon: <SignalWifiBadRoundedIcon />,

        // text increase & decrease
        TextIncreaseRoundedIcon: <TextIncreaseRoundedIcon />,
        TextDecreaseRoundedIcon: <TextDecreaseRoundedIcon />,

        // subscribe & unsubscribe
        UnsubscribeRoundedIcon: <UnsubscribeRoundedIcon />,
        WrongLocationRoundedIcon: <WrongLocationRoundedIcon />,

        // credit
        CreditCardOffRoundedIcon: <CreditCardOffRoundedIcon />,
        CreditCardRoundedIcon: <CreditCardRoundedIcon />,
        CreditScoreRoundedIcon: <CreditScoreRoundedIcon />,

    };

    return {
        rounded: roundedIcons
    };
}

export { useIconsThemes };