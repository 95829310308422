import React from 'react';
import { TextField } from '../../../../../shared/components/field/textfield.component';
import useCreateOrEditMarketHook from './createoredit.market.hook';
import Label from '../../../../../shared/components/label/label.component';
import { MultiTextField } from '../../../../../shared/components/field/multi.textfield.component';
import { MultiSelectDropDownField } from '../../../../../shared/components/field/multi.select.dropdown.field.component';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { ProfilePhotoUploader } from '../../../../../shared/components/image/profile.photo.uploader.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';

const CreateOrEditMarketView = ({ attributes }) => {

    const hook = useCreateOrEditMarketHook(attributes);

    return (
        <div className={"w-100 position-relative"}>
            <div className={"w-100 height-460px position-relative "}>
                <form ref={hook?.reactiveForm} method={attributes.method} autoComplete={"false"} className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 flex-column pb-5"}>
                        {/* media upload */}
                        <div className={"mb-0 mb-4 d-flex justify-content-between align-items-start"}>
                            <div className={"position-relative mb-4 rounded-5px max-width-90px width-90px height-90px"}>
                                <ProfilePhotoUploader attributes={hook?.marketPhotoUploaderAttributes} />
                            </div>
                        </div>

                        {/* name field */}
                        <div className={"w-100 m-0 mb-4"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.marketNameLabel} />
                            </div>
                            <TextField
                                attributes={hook?.marketNameField} />
                        </div>

                        {/* contact address field */}
                        <div className={"w-100 m-0 mb-2"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.contactAddressLabel} />
                            </div>
                            <MultiTextField
                                attributes={hook?.contactAddressField} />
                        </div>

                        <div className={"d-flex justify-content-center mb-2"}>
                            {/* country field */}
                            <div className={"w-100 m-0 me-2"}>
                                <SingleSelectDropDownField
                                    attributes={hook?.countrySingleSelectDropDownFieldAttributes} />
                            </div>

                            {/* state field */}
                            <div className={"w-100 m-0 mb-0"}>
                                <SingleSelectDropDownField
                                    attributes={hook?.stateSingleSelectDropDownFieldAttributes} />
                            </div>
                        </div>

                        <div className={"d-flex justify-content-center mb-4"}>
                            {/* city field */}
                            <div className={"w-100 m-0 me-2"}>
                                <SingleSelectDropDownField
                                    attributes={hook?.citySingleSelectDropDownFieldAttributes} />
                            </div>

                            {/* postal code field */}
                            <div className={"w-100 max-width-150px m-0 mb-0"}>
                                <TextField
                                    attributes={hook?.postalCodeField} />
                            </div>
                        </div>

                        {/* about field */}
                        <div className={"w-100 m-0 mb-4"}>
                            <div className={"mb-2"}>
                                <Label attributes={hook?.marketDescriptionLabel} />
                            </div>
                            <MultiTextField
                                attributes={hook?.marketDescriptionField} />
                        </div>


                    </div>
                </form>
            </div>
        </div>
    );
}

export default CreateOrEditMarketView;