import React from 'react';
import useShopperWalletTransactionsHook from './shopper.wallet.transactions.hook';
import { TextField } from '../../../../../shared/components/field';

const ShopperWalletTransactionsView = ({ attributes }) => {

    const hook = useShopperWalletTransactionsHook(attributes);

    return (
        <div className={"w-100 position-relative p-4 pt-4 pb-5"}>
            <form ref={hook?.reactiveForm}
                method={attributes.method}
                autoComplete={"false"}
                className={"w-100 text-left pt-2"} noValidate>
                <div className={"w-100 height-400px"}>
                    
                </div>
            </form>
        </div >
    );
}

export default ShopperWalletTransactionsView;