import { https } from '../../../../../shared/utilities';


const MarketProductService = () => {
    const request = https();
    const controller = "/market-products";

    return { 
        loadMarketWithProducts: async (data) => await request.post(controller + "/load-market-with-products", data), 
        getMarketInventory: async (data) => await request.post(controller + "/list-market-inventory", data), 
        getMarketProducts: async (data) => await request.post(controller + "/list-market-products", data), 
        get: async (id) => await request.post(controller + "/get", id), 
        save: async (data) => await request.post(controller + "/save", data), 
        update: async (data) => await request.post(controller + "/update", data),
        removeProductFromMarketInventory: async (data) => await request.post(controller + "/delete-market-inventory", data),
        removeMarketProduct: async (data) => await request.post(controller + "/delete-market-product", data)
    }
}

export { MarketProductService }