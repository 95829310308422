import React from 'react';
import useShopperDetailsHook from './shopper.details.hook';
import { Avatar } from '../../../../../shared/components/avatar';
import HorizontalSpace from '../../../../../shared/components/separator/horizontal.space.component';
import { AddressLocationCard } from '../../../../../shared/components/card';
import { ModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { Toolbar } from '@mui/material';
import { DataGrid } from '../../../../../shared/components/datagrid';

const ShopperDetailsView = ({ attributes }) => {

    const hook = useShopperDetailsHook(attributes);

    return (
        <div className={"w-100 position-relative p-0 pt-0 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <div className={"w-100 d-flex"}>
                    <div className={"w-100 flex-column"}>
                        {/* media upload */}
                        <div className={"mb-0 p-3 pb-0 rounded-5px min-height-80px"}>
                            <div className={"d-flex w-100 mb-0"}>
                                <div className={"me-3 p-0 max-width-100px min-width-100px height-100px position-relative"}>
                                    <Avatar attributes={hook?.profilePhotoAttributes} />
                                </div>
                                <div className={"w-100 float-start flex-column"}>
                                    <div className={"w-100 flex-column position-relative"}>
                                        <div className={"d-flex align-items-center text-16px text-bold mb-1 max-width-200px"}>
                                            <span className={"me-1 text-truncate"}>{hook?.shopper?.name}</span>
                                            <i className={"d-flex icon-19px text-green-theme"}>
                                                {hook?.icons?.VerifiedIcon}
                                            </i>
                                        </div>
                                        <div className={"d-flex text-12px mb-3 opacity-8"}>
                                            <div className={"d-flex align-items-center justify-content-start text-12px me-3"}>
                                                <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.LocalPhoneIcon}</i>
                                                <span className={"d-flex text-truncate max-width-80px"}>{hook?.shopper?.phoneNumber}</span>
                                            </div>

                                            <div className={"d-flex align-items-center w-100 justify-content-start text-12px"}>
                                                <i className={"d-flex me-1 icon-14px"}>{hook?.icons?.ContactMailRoundedIcon}</i>
                                                <span className={"d-flex text-truncate max-width-100px"}>{hook?.shopper?.emailAddress}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start text-12px"}>
                                        <div className={"d-flex w-100- width-119px min-height-38px border border-1px border-dashed align-items-center justify-content-start text-12px me-3 p-2 pt-1 pb-1 rounded-3px"}>
                                            <i className={"d-flex me-1 icon-17px text-12px text-info"}>{hook?.icons?.ArrowUpwardRoundedIcon}</i>
                                            <span className={"text-truncate text-14px text-bold"}>NGN76,000</span>
                                        </div>
                                        <div className={"d-flex w-100- width-119px min-height-38px border border-1px border-dashed align-items-center justify-content-start text-12px p-2 pt-1 pb-1 rounded-3px"}>
                                            <i className={"d-flex me-1 icon-17px text-12px text-warning"}>{hook?.icons?.ShoppingBasketRoundedIcon}</i>
                                            <span className={"text-truncate text-14px text-bold"}>18 orders</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* details */}
                        <div className={"mb-0 p-3 position-relative"}>
                            <div className={"bg-blue-light-theme min-height-100px rounded-3px mb-3 mt-1 p-3"}>
                                <div className={"d-flex position-relative w-100 height-45px mb-2"}>
                                    <div className={"w-100 me-2"}>
                                        <div>Contact Address</div>
                                        <div className={"text-bold"}>{hook?.shopper?.contactAddress}</div>
                                    </div>
                                    <div className={"w-100 max-width-114px"}>
                                        <div>Postal Code:</div>
                                        <div className={"text-bold"}>{hook?.shopper?.postalCode || "Not available"}</div>
                                    </div>
                                </div>
                                <div className={"d-flex position-relative height-45px mb-0"}>
                                    <div className={"w-100 me-2"}>
                                        <div>LGA. of Residence</div>
                                        <div className={"text-bold"}>Not available</div>
                                    </div>
                                    <div className={"w-100 me-2"}>
                                        <div>City</div>
                                        <div className={"text-bold"}>Not available</div>
                                    </div>
                                    <div className={"w-100"}>
                                        <div>State</div>
                                        <div className={"text-bold"}>Not available</div>
                                    </div>
                                </div>
                            </div>

                            {/* market address */}
                            <div className={"mb-5"}>
                                <AddressLocationCard attributes={hook?.marketAddressAttributes} />
                            </div>

                            {/* shopper history */}
                            <div className={"mb-5"}>
                                <div className={"d-flex align-items-center justify-content-start text-18px mb-3"}>
                                    <div className={"w-100 d-flex align-items-center justify-content-start text-18px me-2"}>
                                        <div className={"d-flex align-items-center justify-content-start me-2 line-height-20px"}>
                                            <span className={"text-bold"}>Purchase History</span>
                                        </div>
                                    </div>

                                    <div className={"d-flex align-items-center justify-content-start"}>
                                        {/* <Toolbar attributes={hook?.ordersToolbarAttributes} /> */}
                                    </div>
                                </div>

                                <div className={"position-relative"}>
                                    <DataGrid attributes={hook?.purchaseHistoryDatagridAttributes} />
                                </div>

                            </div>
                        </div>

                        {/* fund shopper */}
                        <ModalDialog attributes={hook?.fundShopperModalDialogAttributes} />

                        {/* shopper transfer */}
                        <ModalDialog attributes={hook?.shopperTransferModalDialogAttributes} />

                        {/* bank transfer */}
                        <ModalDialog attributes={hook?.bankTransferModalDialogAttributes} />

                    </div>
                </div>
            </div >
        </div >
    );
}

export default ShopperDetailsView;