import React, { useEffect, useRef } from "react";
import Button from "../button/button.component";
import { TOOLBARITEMTYPE } from "../../enums/enums";
import LabelledIcon from "../label/labelled.icon.component";
import DropDownButton from "../button/dropdown.button.component";
import DropDownMenuButton from "../button/dropdown.menu.button.component";
import SearchField from "../searchfield/searchfield.component";
import { SelectableDropDownListButton } from "../button/select.dropdownlist.button.component";
import UploadButton from "../button/upload.button.component";
import PageFieldButton from "../label/page.field.button.component";
import Label from "../label/label.component";

const Toolbar = ({ attributes }) => {

    const toolbarRef = useRef()

    useEffect(() => {
        (async () => {
            if (attributes?.observer)
                attributes.observer.current = await notifier;
        })();
    }, [attributes])

    const notifier = (e) => {
        // 
    }

    return (
        <div id={attributes?.name} ref={toolbarRef} className={"d-flex"} aria-rowindex={attributes?.key}>
            <div className={"position-relative d-flex justify-content-center align-items-center ps-0 pe-0"}>
                {
                    (() => {
                        return attributes?.items?.map((itemAttributes, i) => {
                            itemAttributes["trigger"] = attributes?.trigger;
                            itemAttributes["name"] = attributes?.name + "-" + itemAttributes?.name;
                            itemAttributes["baseClickHandler"] = (e) => {
                                if (attributes?.baseClickHandler) {
                                    attributes?.baseClickHandler(e);
                                }
                            };

                            return (
                                <div key={i}>
                                    {
                                        (() => {
                                            switch (itemAttributes.type) {
                                                case TOOLBARITEMTYPE.LABELLEDICON:
                                                    return (<LabelledIcon attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.LABEL:
                                                    return (<Label attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.DROPDOWNBUTTON:
                                                    return (<DropDownButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.DROPDOWNMENUBUTTON:
                                                    return (<DropDownMenuButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.SPACE:
                                                    return (<div className={itemAttributes?.style}></div>);
                                                case TOOLBARITEMTYPE.SEARCHFIELD:
                                                    return (<SearchField attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.SELECTABLEDROPDOWNLISTBUTTON:
                                                    return (<SelectableDropDownListButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.UPLOADBUTTON:
                                                    return (<UploadButton attributes={itemAttributes} />);
                                                case TOOLBARITEMTYPE.PAGEFIELDBUTTON:
                                                    return (<PageFieldButton attributes={itemAttributes} />);
                                                default:
                                                    return (<Button attributes={itemAttributes} />);
                                            };
                                        })()
                                    }
                                </div>
                            )
                        })
                    })()
                }
            </div>
        </div>
    );
}

const useToolbar = (toolbarAttr) => {

    const toolbar = () => {
        return document.getElementById(toolbarAttr.name);
    }

    const toggleButton = (buttonAttribute) => {
        const buttons = document.getElementById(buttonAttribute.name).querySelectorAll("button");
        buttons.forEach(button => {
            button.classList.remove("hide");
        });
        buttons[buttonAttribute?.state ? 0 : 1].classList.add("hide");
    }

    const disableButton = (buttonAttribute) => {
        const buttons = document.getElementById(buttonAttribute.name).querySelectorAll("button");
        buttons.forEach(button => {
            button.classList.remove("hide");
        });
        buttons[buttonAttribute?.state ? 0 : 1].classList.add("hide");
    }

    const disableButtonMenuItem = (menuItemsAttribute) => {
        menuItemsAttribute.forEach(menuItemsAttribute => {
            //
        });
    }

    const button = (args) => {
        const _toolbar = toolbar();
        const button = _toolbar.querySelector("div[id=\"" + toolbarAttr?.name + "-" + args.name + "\"]")?.querySelector("button");
        return {
            setText: (text) => {
                button.querySelector("*[aria-controls=\"label\"]").innerHTML = text;
            }
        }
    }

    const label = (args) => {
        const _toolbar = toolbar();
        const label = _toolbar.querySelector("div[id=\"" + toolbarAttr?.name + "-" + args.name + "\"]")?.querySelector("div");
        return {
            setText: (text) => {
                label.innerHTML = text;
            }
        }
    }

    return {
        button,
        label,
        disableButton,
        disableButtonMenuItem,
        toolbar,
        toggleButton
    };
}
export { Toolbar, useToolbar };