import React, { useEffect, useState } from "react";

const Flag = ({ attributes }) => {

    const [color, setColor] = useState("");
    const [label, setLabel] = useState("");
    const [icon, setIcon] = useState("");

    useEffect(() => {
        if (attributes?.flags?.length > 0) {
            setColor(attributes?.flags[attributes?.value]?.color);
            if (attributes?.showLabel)
                setLabel(attributes?.flags[attributes?.value]?.label);
            setIcon(attributes?.flags[attributes?.value]?.icon);
        }
    }, [attributes])

    return (
        <div className={"d-flex justify-content-left"}>
            <div type={attributes?.type} className={"d-flex justify-content-center align-items-center position-relative border-0px- p-1 " + (((attributes?.style?.button) ? attributes?.style?.button : " border-0px height-20px rounded-1 ")) + color}>
                <div className={"d-flex justify-content-center align-items-center"}>
                    <span className={((label) ? "" : "hide") + " d-flex justify-content-center me-1 padding-start-4px text-uppercase text-10px text-bold"}>{label}</span>
                    <i className={((icon) ? "" : "hide") + " d-flex icon-14px"}>{icon}</i>
                </div>
            </div>
        </div>
    );
}
export default Flag;