import React from 'react';
import { TextField } from '../../../../../shared/components/field/textfield.component';
import Label from '../../../../../shared/components/label/label.component';
import { MultiSelectDropDownField } from '../../../../../shared/components/field/multi.select.dropdown.field.component';
import useCreateOrEditOrderHook from './createoredit.order.hook';
import { ProfilePhotoUploader } from '../../../../../shared/components/image/profile.photo.uploader.component';

const CreateOrEditOrderView = ({ attributes }) => {

    const hook = useCreateOrEditOrderHook(attributes);

    return (
        <div className={"w-100 position-relative p-3 pt-0 pb-5"}>
            <div className={"w-100 position-relative pb-0"}>
                <form ref={hook?.reactiveForm}
                    method={attributes.method}
                    autoComplete={"false"}
                    className={"w-100 text-left pt-2"} noValidate>
                    <div className={"w-100 height-460px- d-flex"}>
                        <div className={"w-100 flex-column"}>
                            {/* media upload */}
                            <div className={"mb-4"}>
                                <div className={"mb-2"}>
                                    <Label attributes={hook?.mediaHeaderLabelAttributes} />
                                </div>
                                <div className={"mb-4 rounded-5px"}>
                                    <ProfilePhotoUploader attributes={hook?.mediaUploaderAttributes} />
                                </div>
                            </div>

                            {/* order details */}
                            <div className={"mb-4"}>
                                <div className={"mb-3"}>
                                    <Label attributes={hook?.orderDetailsHeaderLabelAttributes} />
                                </div>

                                {/* order name field */}
                                <div className={"w-100 mb-4"}>
                                    <div className={"mb-2"}>
                                        <Label attributes={hook?.orderNameLabelAttributes} />
                                    </div>
                                    <TextField
                                        attributes={hook?.orderNameFieldAttributes} />
                                </div>

                                {/* category and barcode */}
                                <div className={"w-100 d-flex mb-4"}>
                                    {/* parent category field */}
                                    <div className={"w-60 m-0 me-3"}>
                                        <div className={"mb-2"}>
                                            <Label attributes={hook?.orderCategoryLabelAttributes} />
                                        </div>
                                        <MultiSelectDropDownField
                                            attributes={hook?.orderCategoryMultiSelectDropDownFieldAttributes} />
                                    </div>

                                    {/* order barcode title field */}
                                    <div className={"w-40 m-0"}>
                                        <div className={"mb-2"}>
                                            <Label attributes={hook?.orderBarcodeLabelAttributes} />
                                        </div>
                                        <TextField
                                            attributes={hook?.orderBarcodeFieldAttributes} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div >
        </div >
    );
}

export default CreateOrEditOrderView;