import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Toolbar, useToolbar } from '../../../../../shared/components/toolbar/toolbar.component';
import { DataGrid, useDataGrid } from '../../../../../shared/components/datagrid';
import { COLUMNTYPE, DOCKTYPE, MENUALIGNMENTTYPE, MENUITEMTYPE, TOOLBARITEMTYPE } from '../../../../../shared/enums/enums';
import { useAlertDialog } from '../../../../../shared/components/alert/alert.dialog.component';
import { useConfirmDialog } from '../../../../../shared/components/confirm/confirm.dialog.component';
import { useModalDialog } from '../../../../../shared/components/dialog/modal.dialog.component';
import { usePageLoader } from '../../../../../shared/components/loader/page.loader.component';
import '../../../../../shared/helpers/global.variable.helper';
import { ProductColorService } from './product.color.service';
import { usePrimaryUIThemes } from '../../../../../shared/hooks/ui.themes/index';
import { useIconsThemes } from '../../../../../shared/hooks/ui.icons.hook';
import CreateOrEditProductColorView from './createoredit/createoredit.product.color.view';

const useProductColorsHook = () => {

    // theme
    const themes = usePrimaryUIThemes();
    const icons = useIconsThemes()?.rounded;

    // state objects
    const [productColors, setProductColors] = useState([]);
    const [selectedProductColor, setSelectedProductColor] = useState({});
    const [colorQueryString, setColorQueryString] = useState("");
    const [colorPageNumber, setColorPageNumber] = useState(1);
    const [colorRowsPerPage, setColorRowsPerPage] = useState(25);
    const [colorTotalPages, setColorTotalPages] = useState(0);
    const [colorTotalRows, setColorTotalRows] = useState(0);

    const messages = {
        oopsErrorMessage: {
            title: "Oops! something went wrong",
            message: "We're sorry your requests can't be completed at the moment. Please try again later."
        },

        noRecordSelectedErrorMessage: {
            title: "No color selected!",
            message: "Please select an color from the list to continue"
        },

        handleCreate: {
            dialogTitle: "New color",
            successMessage: {
                title: "Color created successfully!",
                message: "Color was successfully created."
            }
        },

        handleEdit: {
            dialogTitle: "Edit color details",
            alertMessage: {
                title: "Multiple colors selected!",
                message: "Oops!, you selected multiple colors. Please select a color from the list to continue"
            },
            successMessage: {
                title: "Color updated successfully!",
                message: "Color was successfully updated."
            }
        },

        handleDelete: {
            dialogTitle: "Edit color details",
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to delete " + ((count > 1) ? "these colors?" : "this color?")
                }
            },
            successMessage: {
                title: "Color deleted successfully!",
                message: "Color was successfully deleted."
            }
        },

        handlePublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to publish " + ((count > 1) ? "these colors?" : "this color?")
                }
            },
            successMessage: {
                title: "Color published successfully!",
                message: "Color was successfully published."
            }
        },

        handleUnpublish: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to unpublish " + ((count > 1) ? "these colors?" : "this color?")
                }
            },
            successMessage: {
                title: "Color published successfully!",
                message: "Color was successfully published."
            }
        },

        handleArchive: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to archive " + ((count > 1) ? "these colors?" : "this color?")
                }
            },
            successMessage: {
                title: "Color archived successfully!",
                message: "Color was successfully archived."
            }
        },

        handleRestore: {
            confirmMessage: (count) => {
                return {
                    title: (datagrid.selectedRowsCount() + ((count > 1) ? " Items" : " Item") + " Selected"),
                    message: "Are you sure you want to restore " + ((count > 1) ? "these colors?" : "this color?")
                }
            },
            successMessage: {
                title: "Color restored successfully!",
                message: "Color was successfully restored."
            }
        },

        loader: {
            loadingMessage: "Loading, please wait...",
            fetchingMessage: "Fetching available colors, please wait..."
        }
    };

    // observers
    const componentObserver = useRef();

    // adapters
    const createOrEditColorObserver = useRef();

    // components
    const datagridName = "product-colors-datagrid";
    const datagrid = useDataGrid({
        name: datagridName
    });

    const leftPaneToolbarName = "product-colors-left-pane-toolbar";
    const leftPaneToolbar = useToolbar({
        name: leftPaneToolbarName
    });
    const leftToolbarButtons = {
        addColorButtonName: "add-color-button",
        editColorButtonName: "edit-color-button",
        deleteColorButtonName: "delete-color-button",
        reloadColorButtonName: "reload-color-button",
    }

    const rightPaneToolbarName = "product-colors-right-pane-toolbar";
    const rightPaneToolbar = useToolbar({
        name: rightPaneToolbarName
    });
    const rightToolbarButtons = {
        filterButtonName: "filter-button",
        previousButtonName: "previous-button",
        pagesLabelName: "pages-label",
        nextButtonName: "next-button",
    }

    // services
    const colorService = ProductColorService();

    // dialogs
    const createOrEditColorDialogName = "createoredit-color-modal";
    const filterColorDialogName = "filter-color-modal";
    const createOrEditColorDialog = useModalDialog({
        name: createOrEditColorDialogName
    });
    const alertDialog = useAlertDialog();
    const confirmDialog = useConfirmDialog();

    // loader
    const pageLoader = usePageLoader();

    // services
    const getColors = useCallback(async (request) => {

        datagrid?.showLoader(messages?.loader?.fetchingMessage);
        datagrid.clearSelection();

        await colorService.getAll(request)
            .then(response => {
                datagrid?.hideLoader();

                // populate datagrid 
                setProductColors(response?.data?.list);

                // set page
                setColorPageNumber(response?.data?.page?.pageNumber);
                setColorRowsPerPage(response?.data?.page?.rowsPerPage);
                setColorTotalPages(response?.data?.page?.totalPages);
                setColorTotalRows(response?.data?.page?.totalRows);

                rightPaneToolbar.button({
                    name: rightToolbarButtons.pagesLabelName
                }).setText("Page " + response?.data?.page?.pageNumber + " of " + response?.data?.page?.totalPages);

            })
            .catch(error => {
                datagrid?.hideLoader();
            });

    }, []);

    useEffect(() => {
        getColors({
            queryString: colorQueryString,
            pageNumber: colorPageNumber,
            rowsPerPage: colorRowsPerPage
        });
    }, []);

    // color
    const handleCreate = (e) => {
        // clear selection
        setSelectedProductColor({});

        // show dialog
        createOrEditColorDialog.show({
            title: "New color",
            showCloseButton: true,
            isNew: true
        });

        // listen to dialog for changes
        createOrEditColorObserver.current({
            data: null
        });

    }

    const handleEdit = (e) => {
        if (datagrid.selectedRowsCount() > 1) {
            alertDialog.show({
                title: "Multiple colors selected!",
                message: "Oops!, you selected multiple colors. Please select a color from the list to continue"
            });
        } else {
            if (datagrid.selectedRowsCount() > 0) {
                const selectedColor = productColors.find(c => c.productColorId === datagrid.getSelectedRowKeyValue());
                setSelectedProductColor(selectedColor);

                createOrEditColorDialog.show({
                    title: "Edit color",
                    showCloseButton: true,
                    isNew: false
                });

                // listen to dialog for changes
                createOrEditColorObserver.current({
                    data: selectedColor,
                    callback: () => {
                        getColors({
                            queryString: colorQueryString,
                            pageNumber: colorPageNumber,
                            rowsPerPage: colorRowsPerPage
                        });
                    }
                });
            } else {
                alertDialog.show({
                    title: "No color selected!",
                    message: "Please select a color from the list to continue"
                });
            }
        }
    }

    const handleDelete = (e) => {
        if (datagrid.selectedRowsCount() > 0) {
            confirmDialog.show({
                title: (datagrid.selectedRowsCount() + ((datagrid.selectedRowsCount() > 1) ? " Items" : " Item") + " Selected"),
                message: "Are you sure you want to delete " + ((datagrid.selectedRowsCount() > 1) ? "these colors?" : "this color?"),
                onConfirmed: (e) => {
                    pageLoader.show({
                        message: "Loading, please wait...",
                    });

                    colorService.remove(datagrid.getSelectedRowsKeyValue()).then(response => {
                        getColors({
                            queryString: colorQueryString,
                            pageNumber: colorPageNumber,
                            rowsPerPage: colorRowsPerPage
                        });
                        pageLoader.hide();
                    });
                }
            });
        } else {
            alertDialog.show({
                title: "No color selected!",
                message: "Please select a color from the list to continue"
            });
        }
    }

    const handleSearchRecords = (e, args) => {
        getColors({
            queryString: args?.value,
            pageNumber: colorPageNumber,
            rowsPerPage: colorRowsPerPage
        });
    }

    const handlePreviousPage = (e, args) => {
        if (colorPageNumber > 1)
            getColors({
                queryString: colorQueryString,
                pageNumber: colorPageNumber - 1,
                rowsPerPage: colorRowsPerPage
            });
    }

    const handleNextPage = (e, args) => {
        if (colorPageNumber < colorTotalPages)
            getColors({
                queryString: colorQueryString,
                pageNumber: colorPageNumber + 1,
                rowsPerPage: colorRowsPerPage
            });
    }

    return {
        componentObserver,
        productColors,

        toolbar: {
            widget: (() => {
                return (
                    <div className={themes?.toolbarCollectionWrapper}>
                        <div className={"w-50"}>
                            <Toolbar attributes={{
                                name: leftPaneToolbarName,
                                items: [
                                    {
                                        name: leftToolbarButtons.addColorButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        text: "Add color",
                                        
                                        leading: icons?.AddRoundedIcon,
                                        onClick: (e) => handleCreate(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.editColorButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Edit",
                                        leading: icons?.EditRoundedIcon,
                                        onClick: (e) => handleEdit(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: leftToolbarButtons.deleteColorButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        leading: icons?.DeleteRoundedIcon,
                                        onClick: (e) => handleDelete(e),
                                        observer: componentObserver,
                                        style: themes?.toolbar?.button
                                    },
                                ]
                            }} />
                        </div>
                        <div className={"w-50 d-flex justify-content-end"}>
                            <Toolbar attributes={{
                                name: rightPaneToolbarName,
                                items: [
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space15
                                    },
                                    {
                                        name: rightToolbarButtons.filterButtonName,
                                        type: TOOLBARITEMTYPE.SEARCHFIELD,
                                        menuPosition: MENUALIGNMENTTYPE.BOTTOMRIGHT,
                                        leading: icons?.SearchRoundedIcon,
                                        placeholder: "Search",
                                        trailing: icons?.ArrowForwardRoundedIcon,
                                        observer: componentObserver,
                                        style: themes?.toolbar?.searchField,
                                        onClick: (e, args) => { handleSearchRecords(e, args) },
                                    },
                                    {
                                        type: TOOLBARITEMTYPE.SPACE,
                                        style: themes?.toolbar?.space?.space4
                                    },
                                    {
                                        name: rightToolbarButtons.previousButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Previous",
                                        leading: icons?.ArrowBackIosRoundedIcon,
                                        onClick: (e, args) => { handlePreviousPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.startDropDownButton
                                    },
                                    {
                                        name: rightToolbarButtons.nextButtonName,
                                        type: TOOLBARITEMTYPE.BUTTON,
                                        tooltip: "Next",
                                        leading: icons?.ArrowForwardIosRoundedIcon,
                                        onClick: (e, args) => { handleNextPage(e, args) },
                                        observer: componentObserver,
                                        style: themes?.toolbar?.endDropDownButton
                                    },
                                ]
                            }} />
                        </div>
                    </div>
                )
            })()
        },

        datagrid,
        datagridAttributes: <DataGrid attributes={{
            name: datagridName,
            key: ["productColorId"],
            columns: [
                {
                    default: "",
                    style: themes?.width15px
                },
                {
                    type: COLUMNTYPE.CHECKBOX,
                    value: ["productColorId"],
                    style: themes?.width30px
                },
                {
                    title: "Color",
                    value: ["productColorName"],
                    default: "",
                    style: themes?.width100
                },
                {
                    default: "",
                    style: themes?.width10px
                },
                {
                    type: COLUMNTYPE.DATETIME,
                    title: "Created",
                    value: ["dateCreated"],
                    default: "",
                    style: themes?.width110px
                },
                {
                    default: "",
                    style: themes?.width5px
                },
            ],
            rowHeight: 40,
            isStripped: true,
            isSelectable: true,
            dockType: DOCKTYPE.FILL,
            dataset: productColors
        }} />,

        createOrEditDialog: {
            name: createOrEditColorDialogName,
            data: selectedProductColor,
            observer: createOrEditColorObserver,
            widget: CreateOrEditProductColorView,
            style: {
                window: "max-width-420px"
            },
            buttons: [
                {
                    text: "Cancel",
                    style: themes?.button?.secondary,
                    onClick: (e, winArgs) => winArgs.close(e)
                },
                {
                    text: "Save & continue",
                    style: themes?.button?.primary,
                    onClick: (e, winArgs) => {
                        winArgs.submit(e, args => {
                            getColors({
                                queryString: colorQueryString,
                                pageNumber: colorPageNumber,
                                rowsPerPage: colorRowsPerPage
                            });
                        });
                    }
                }
            ]
        },

    };
}

export default useProductColorsHook;