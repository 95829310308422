const httpPostHeaderOptions = {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
};

const httpGetHeaderOptions = {
    "content-type": "application/json",
    "Access-Control-Allow-Origin": "*"
};

export { httpPostHeaderOptions, httpGetHeaderOptions };