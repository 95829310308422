import React from 'react';
import useShoppersHook from './shoppers.hook';
import { ModalDialog } from '../../../../shared/components/dialog/modal.dialog.component';
import { CalloutWindow } from '../../../../shared/components/callout/callout.window.component';
import { DataGrid } from '../../../../shared/components/datagrid';

const ShoppersView = () => {
    const hook = useShoppersHook();

    return (
        <div>
            {hook?.toolbar}
            {hook?.gridArea}

            <ModalDialog attributes={hook?.shopperKycDialog} />
            <ModalDialog attributes={hook?.viewShopperWalletTransactionsDialog} />
            <ModalDialog attributes={hook?.fundShopperWalletDialog} />
            <ModalDialog attributes={hook?.viewShopperShoppingHistoryDialog} />
        </div >
    );
}

export default ShoppersView;