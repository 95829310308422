import React from 'react';
import { ModalDialogCollection } from '../../../../shared/components/dialog/modal.dialog.collection.component';
import { StackablePanel } from '../../../../shared/components/stackable.panel/stackable.panel.component';
import useInvestorManagementHook from './investor.management.hook';

const InvestorManagementView = () => {

    const hook = useInvestorManagementHook();

    return (
        <div>
            <StackablePanel attributes={hook?.stackablePanelAttributes} />
            <ModalDialogCollection attributes={hook?.modalDialogCollectionAttributes} />
        </div>
    );
}

export default InvestorManagementView;