import React, { useEffect, useRef, useState } from 'react';
import CodeVerifierWidget from '../../shared/widgets/code.verifier/code.verifier.widget';
import Button from '../../shared/components/button/button.component';
import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';

const VerifyCodeComponent = () => {
    const [viewHeight, setViewHeight] = useState(window.innerHeight);
    const [isValidCode, setIsValidCode] = useState(false);

    const authWidgetObserver = useRef(null);
    const authWidgetErrorObserver = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        setViewHeight(window.innerHeight);
        const windowResizer = (e) => {
            setViewHeight(window.innerHeight);
        }
        window.addEventListener('resize', windowResizer);

        // garbage collection
        return () => {
            window.removeEventListener('resize', windowResizer);
        }
    }, [viewHeight]);

    const handleVerifyCode = (e) => {
        authWidgetObserver.current({
            inProgress: true,
            message: "Loading, please wait..."
        });
        // setTimeout(() => {
        const result = authWidgetObserver.current({
            inProgress: false,
            errorOccurred: false,
            message: "Done!"
        });

        // verify code
        if (result.code === "demo@123") {
            setIsValidCode(true);
        } else {
            setIsValidCode(false);
        }
        // }, 3000);

        e.cancelBubble = true;
        e.preventDefault();
    }

    const handleBackToForgotPassword = (e) => {
        navigate("/reset-password");
    }

    const handleCodeVerificationSuccess = (e) => {
        navigate("/");
    }

    const getCodeVerificationWidget = () => {
        return (
            <div className={(isValidCode ? "hide" : "") + " max-width-570px"}>
                <div className={"width-390px height-100px"}>
                    <Button attributes={{
                        type: "button",
                        className: "ps-1 pe-1 text-center manrope-bold text-14px height-35px bg-light",
                        logo: <IoChevronBackOutline />,
                        text: "Back",
                        onClick: handleBackToForgotPassword,
                    }}></Button>
                </div>
                <div className={"width-360px height-500px mx-auto mt-3"}>
                    <CodeVerifierWidget method={'post'} observer={{
                        widgetObserver: authWidgetObserver,
                        errorObserver: authWidgetErrorObserver
                    }} onSubmit={(e) => handleVerifyCode(e)} />
                </div>
            </div>
        );
    }

    const getCodeVerificationSuccessfulWidget = () => {
        return (
            <div className={(!isValidCode ? "hide" : "") + " max-width-570px"}>
                <div className={"height-100px"}></div>
                <div className={"width-360px height-500px mt-3"}>
                    {/* <SucessMessageWidget
                        method={'post'}
                        observer={{
                            formObserver: authWidgetObserver,
                            errorObserver: authWidgetErrorObserver
                        }}
                        attributes={{
                            title: "Hooray!",
                            message: "Code verification successful. Click on the 'Continue' button to choose a new password.",
                            okayLabel: "Continue to login",
                            onClick: handleCodeVerificationSuccess
                        }} /> */}
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className={"position-relative height-" + viewHeight + "px d-flex align-items-center"}>
                <div className={"position-absolute start-0px top-0px bottom-0px width-60 d-flex justify-content-start align-items-center mx-auto bg-light banner-1"}>
                </div>

                <div className={"position-absolute end-0px top-0px bottom-0px width-40 d-flex justify-content-center align-items-start p-5 mx-auto bg-white"}>
                    {
                        (() => {
                            if (!isValidCode) {
                                return getCodeVerificationWidget()
                            } else {
                                return getCodeVerificationSuccessfulWidget()
                            }
                        })()
                    }
                </div>
            </div>
        </div>
    );
}

export default VerifyCodeComponent;