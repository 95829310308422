import React from 'react';
import useCreateOrEditCourierHook from './createoredit.courier.hook';
import { TextField } from '../../../../../shared/components/field';
import { ProfilePhotoUploader } from '../../../../../shared/components/image/profile.photo.uploader.component';
import { SingleSelectDropDownField } from '../../../../../shared/components/field/single.select.dropdown.field.component';
import { DateOfBirthDropDownFieldAttributes } from '../../../../../shared/components/field/dob.selection.field.component';
import MenuList from '../../../../../shared/components/menu/menu.list.component';
import { Toolbar } from '../../../../../shared/components/toolbar/toolbar.component';

const CreateOrEditCourierView = ({ attributes }) => {

    const hook = useCreateOrEditCourierHook(attributes);

    return (
        <div className={"w-100 position-relative p-0 pt-2 pb-2"}>
            <form ref={hook?.reactiveForm}
                method={attributes.method}
                autoComplete={"false"}
                className={"w-100 text-left pt-2"} noValidate>
                <div className={"w-100"}>
                    {/* media upload */}
                    <div className={"mb-0 mb-4 d-flex justify-content-between align-items-start"}>
                        <div className={"position-relative mb-4 rounded-5px max-width-130px width-130px height-130px"}>
                            <ProfilePhotoUploader attributes={hook?.profilePhotoUploaderAttributes} />
                        </div>

                        <div className={"d-flex align-items-start"}>
                            <Toolbar attributes={hook?.toggleToolbarAttributes} />
                        </div>
                    </div>

                    {/* agent details */}
                    <div className={"mb-0"}>
                        {/* agent name field */}
                        <div className={"w-100 mb-4"}>
                            {/*  */}
                            <div className={"mb-2 text-blue-theme"}>Name:</div>

                            <div className={"w-100 d-flex"}>
                                {/* first name */}
                                <div className={"w-100 me-1"}>
                                    <TextField
                                        attributes={hook?.firstNameFieldAttributes} />
                                </div>

                                {/* last name */}
                                <div className={"w-100 ms-2"}>
                                    <TextField
                                        attributes={hook?.lastNameFieldAttributes} />
                                </div>
                            </div>
                        </div>

                        {/* gender and dob */}
                        <div className={"w-100 d-flex mb-4"}>
                            {/* gender field */}
                            <div className={"w-30 m-0 me-1"}>
                                <div className={"mb-2 text-blue-theme"}>
                                    Gender:
                                </div>
                                <SingleSelectDropDownField
                                    attributes={hook?.genderSelectDropDownFieldAttributes} />
                            </div>

                            {/* dob field */}
                            <div className={"w-70 m-0 ms-2"}>
                                <div className={"mb-2 text-blue-theme"}>
                                    Date of birth:
                                </div>
                                <div className={"m-0"}>
                                    <DateOfBirthDropDownFieldAttributes attributes={hook?.dobDropDownFieldAttributes} />
                                </div>
                            </div>
                        </div>

                        {/* contact address */}
                        <div className={"w-100 mb-4"}>
                            <div className={"mb-2"}>
                                <div className={"mb-2 text-blue-theme"}>
                                    Contact address:
                                </div>
                                <TextField
                                    attributes={hook?.contactAddressFieldAttributes} />
                            </div>

                            <div className={"d-flex mb-2"}>
                                <div className={"me-2 w-100"}>
                                    <SingleSelectDropDownField
                                        attributes={hook?.countrySelectDropDownFieldAttributes} />
                                </div>
                                <div className={"w-100"}>
                                    <SingleSelectDropDownField
                                        attributes={hook?.stateSelectDropDownFieldAttributes} />
                                </div>
                            </div>

                            <div className={"d-flex"}>
                                <div className={"w-70 me-2"}>
                                    <SingleSelectDropDownField
                                        attributes={hook?.citySelectDropDownFieldAttributes} />
                                </div>
                                <div className={"w-30"}>
                                    <TextField
                                        attributes={hook?.postalCodeFieldAttributes} />
                                </div>
                            </div>
                        </div>

                        {/* email and phone number */}
                        <div className={"w-100 d-flex mb-4"}>
                            <div className={"w-60 m-0 me-1"}>
                                <div className={"mb-2 text-blue-theme"}>
                                    Email address:
                                </div>
                                <TextField
                                    attributes={hook?.emailAddressFieldAttributes} />
                            </div>

                            <div className={"w-40 m-0 ms-2"}>
                                <div className={"mb-2 text-blue-theme"}>
                                    Phone number:
                                </div>
                                <TextField
                                    attributes={hook?.phoneNumberFieldAttributes} />
                            </div>
                        </div>

                        {/* market details */}
                        <div className={"w-100 d-flex- mb-4"}>
                            <div className={"mb-2 text-blue-theme"}>
                                Market:
                            </div>
                            <SingleSelectDropDownField
                                attributes={hook?.marketDropDownFieldAttributes} />
                        </div>

                    </div>

                </div>
            </form>
        </div >
    );
}

export default CreateOrEditCourierView;